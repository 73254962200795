/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContextThemeProvider } from "../../context/ThemeContext";


const FinancialDetails = ({employeeInfo}) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);

  const { empId } = useParams();
  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }


  const financialDetails = [
    {
      infoLabel: "Bank Name",
      value: "emp_bankName",
      infoValue: employeeInfo?.employee?.financial?.emp_bankName,
    },
    {
      infoLabel: "Branch",
      value: "emp_branch",
      infoValue: employeeInfo?.employee?.financial?.emp_branch,
    },
    {
      infoLabel: "Account Holder Name",
      value: "emp_accountName",
      infoValue: employeeInfo?.employee?.financial?.emp_accountName,
    },
    {
      infoLabel: "Bank Account Number",
      value: "emp_accountNumber",
      infoValue: employeeInfo?.employee?.financial?.emp_accountNumber,
    },
    {
      infoLabel: "IFSC Code",
      value: "emp_ifsc",
      infoValue: employeeInfo?.employee?.financial?.emp_ifsc,
    },
    {
      infoLabel: "UPI ID Account Name",
      value: "emp_ifsc",
      infoValue: employeeInfo?.employee?.upi?.emp_upiHolderName,
    },
    {
      infoLabel: "UPI ID",
      value: "emp_ifsc",
      infoValue: employeeInfo?.employee?.upi?.emp_upiId,
    },
  ];

  const identityDetails = [
    {
      infoLabel: "Name",
      value: "emp_name",
      infoValue: employeeInfo?.employee?.emp_name,
    },
    {
      infoLabel: "Date of Birth",
      value: "emp_dob",
      infoValue: formatDate(employeeInfo?.employee?.emp_dob),
    },
    {
      infoLabel: "Adhar Card Number",
      value: "",
      infoValue: employeeInfo?.employee?.emp_AadharCardNo,
    },
    {
      infoLabel: "Pan Card Number",
      value: "",
      infoValue: employeeInfo?.employee?.emp_PanCardNo,
    },
    {
      infoLabel: "Driving License Number (Optional)",
      value: "",
      infoValue: employeeInfo?.employee?.emp_VoterCardNo ? employeeInfo?.employee?.emp_VoterCardNo :"-",
    },
    {
      infoLabel: "Passport Number (Optional)",
      value: "",
      infoValue: employeeInfo?.employee?.emp_DrivingLicenceNo ? employeeInfo?.employee?.emp_DrivingLicenceNo :"-",
    },
  ];

  return (
    <div
      className={`px-[72px] py-[33px] cardShadow rounded-lg ${
        organisation?.organisationTheme
?.name === "dark"
          ? "bg-secondaryDark border border-white rounded-lg"
          : "bg-white"
      }`}
    >
      <section className="grid grid-cols-3 gap-6 items-center">
        {financialDetails?.map((detail) => (
          <div>
            <p className={`text-[14px] text-primaryText mb-[6px]`}>
              {detail?.infoLabel}
            </p>
            <input
              type="text"
              name={detail?.infoLabel}
              value={detail?.infoValue}
              readOnly
              className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                organisation?.organisationTheme
?.name === "dark" ? "bg-transparent" : ""
              } `}
            />
          </div>
        ))}
      </section>

      <div className="mt-[24px]">
        <h1 className="text-primaryText text-[22px] font-semibold mb-6">
          Identity Details
        </h1>
        <section className="grid grid-cols-3 gap-6 items-center">
          {identityDetails?.map((detail) => (
            <div>
              <p className={`text-[14px] text-primaryText mb-[6px]`}>
                {detail?.infoLabel}
              </p>
              <input
                type="text"
                name={detail?.infoLabel}
                value={detail?.infoValue}
                readOnly
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme
?.name === "dark" ? "bg-transparent" : ""
                } `}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default FinancialDetails;
