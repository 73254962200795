/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useState } from "react";
import {
  AiOutlineClose,
  AiFillCloseCircle,
  AiFillCheckCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
const SnackbarContext = createContext();

//custom funcition
export function useSnackbar() {
  return useContext(SnackbarContext);
}

function Snackbar({ message, color, onClose }) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      hideSnackbar();
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  const hideSnackbar = () => {
    // You can add logic here to hide the Snackbar
    onClose();
  };

  return (
    <div
      className={`flex border-b-2 p-4 ml-4 absolute bottom-[90px] left-0 justify-between shadow-md gap-4 rounded-lg w-[30%] transition-all duration-500 ease-in-out transform ${
        color === "green"
          ? " bg-[#d2fce3]"
          : color === "red"
          ? "bg-[#ffc9ce]"
          : "bg-[#cdf4fc]"
      }`}
    >
      <div className="flex justify-start ">
        {color === "green" && <AiFillCheckCircle color="#00DF80" size={20} />}
        {color === "red" && <AiFillCloseCircle color="#F04248" size={20} />}
        {color === "blue" && <AiOutlineExclamationCircle size={20} />}
      </div>
      <div className="flex flex-col">
        <span className="text-[#000000CC] font-normal text-[16px]">
          {message}
        </span>
      </div>
      <div onClick={() => hideSnackbar()} className="cursor-pointer">
        <AiOutlineClose color="" size={20} />
      </div>
    </div>
  );
}

const SnackbarContextProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState(false);

  const showSnackbar = (message, color) => {
    setSnackbarData({ message, color });
  };

  const hideSnackbar = () => {
    setSnackbarData(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      {snackbarData && <Snackbar {...snackbarData} onClose={hideSnackbar} />}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
