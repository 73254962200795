/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey} from "../../utils/config";

const ViewUserBenefit = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { benefitId } = useParams();

  const [benefitDetails, setBenefitDetails] = useState();
  console.log("benefitDetails", benefitDetails);

  const getBenefitDetails = async () => {
    try {
  

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/benefit/get-one-benefit?id=${benefitId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      if (result?.data) {
        setBenefitDetails(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBenefitDetails();
  }, [benefitId]);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/user-benefits"
        className={`text-[32px] flex items-center gap-1 text-primaryText`}
      >
        <MdOutlineKeyboardBackspace /> View Benefit
      </Link>
      <section className="h-[1488px] w-full my-5 p-[66px] bg-white shadow-sm">
        <h1 className="text-[#333] text-center text-[36px]">
          {benefitDetails?.benefit_heading}
        </h1>
        <p className="w-[100px] h-1 bg-[#3D4EFE] my-[60px]"></p>
        <div
          className="my-4"
          dangerouslySetInnerHTML={{ __html: benefitDetails?.benefitBody }}
        ></div>
      </section>
    </div>
  );
};

export default ViewUserBenefit;
