/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import { useLocation } from "react-router-dom";
import { LOCALHOST_BASE_URL,apiKey, token } from "../utils/config";
import axios from "axios";
import Button from "./Button";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "../context/SnackbarContext";


const CreatePayRoll = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const payrollId = searchParams.get("payrollId");
  const [employee, setEmployee] = useState();

  // get all employees
  const [getAllEmployees, setGetAllEmployees] = useState();
  // console.log("getAllEmployees", getAllEmployees);

  const getAllEmployeesFunc = async () => {
    try {
      const result = await axios.get(`${LOCALHOST_BASE_URL}/employee/get-all-employees`, {
        headers: { 'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=' },
      });
      setGetAllEmployees(result?.data);
    } catch (error) {
      console.error(error);
  
      // Handle any 404 error
      if (error.response && error.response.status === 404) {
        showSnackbar(`Data not found.`, "red");
      } else {
        showSnackbar(`Something is wrong, please try again.`, "red");
      }
    }
  };
  

  const [allPayroll, setAllPayroll] = useState({
    netPayable: 0, 
  });
  const getAllPayroll = async () => {
    try {
      const result = await axios.get(`${LOCALHOST_BASE_URL}/payRoll/get-payRoll?id=${payrollId}`, {
        headers: { 'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=' },
      });
      setAllPayroll(result?.data);
    } catch (error) {
      console.error(error);
  
      // Handle any 404 error
      if (error.response && error.response.status === 404) {
        showSnackbar(`Data not found.`, "red");
      } else {
        showSnackbar(`Something is wrong, please try again.`, "red");
      }
    }
  };
  

  console.log("allPayroll", allPayroll);

  useEffect(() => {
    getAllEmployeesFunc()
  }, []);


  useEffect(() => {
    if (payrollId) {
      getAllPayroll();
    }
  }, [payrollId]);



  const handleInputChange = (fieldName, newValue) => {
    // Update the specified field in 'allPayroll'
    setAllPayroll({
      ...allPayroll,
      [fieldName]: newValue,
    });
  };


  const [employeeData, setGetEmployee] = useState();
  const getGetEmployee = async () => {
    let url;
  
    if (employee?.employeeId) {
      url = `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${employee?.employeeId}`;
    } else if (allPayroll?.emp_id?._id) {
      url = `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${allPayroll?.emp_id._id}`;
    } else {
      // Handle the case when neither employeeId nor emp_id._id is available
      return;
    }
  
    try {
      // Set the headers with the API key
      const headers = { 'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=' };
  
      const result = await axios.get(url, { headers });
  
      setGetEmployee(result?.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error(error);
  
      // Show the snackbar for the error
      showSnackbar(`Something is wrong, please try again.`, "red");
    }
  };
  
  
  useEffect(() => {
    if (employee?.employeeId || allPayroll?.emp_id?._id) {
      getGetEmployee();
    }
  }, [employee?.employeeId, allPayroll?.emp_id?._id]);
  

  function getDaysInMonth(year, month) {
    // JavaScript months are zero-based, so we subtract 1 from the month number
    const date = new Date(year, new Date(Date.parse(month + ' 1, ' + year)).getMonth() + 1, 0);
    return date.getDate();
  }
  
  const month = allPayroll?.month;
  let daysInMonth;
  
  if (month) {
    const [year, monthName] = month.split("-");
    daysInMonth = getDaysInMonth(year, monthName);
    console.log(daysInMonth);
  } else {
    // Handle the case where month is undefined, e.g., set a default value or show an error message.
    console.error("Month is undefined in allPayroll.");
  }
  
    const monthDays = employee?.month ?? daysInMonth; // Assuming 30 days in a month
    const workingDays = employee?.workingDays ?? allPayroll?.workingDays; // Assuming 20 working days in a month
    const inhandSalary = employeeData?.employee?.emp_offerLetter?.inhandSalary;
    // Calculate values based on the given formulas
    const basic = ((inhandSalary * 0.5) / monthDays) * workingDays;
    const hra = ((inhandSalary * 0.25) / monthDays) * workingDays;
    const conveyance = ((basic * 0.15) / monthDays) * workingDays;
    const statutoryBonus = ((basic * 0.20) / monthDays) * workingDays;
    const otherAllowance = inhandSalary - (basic + hra + conveyance + statutoryBonus);
    const wifiAllowance = allPayroll?.wifi_mobile_recharge ?? 0; 
    const overtime = allPayroll?.overTime ?? 0; 
    const incentive = allPayroll?.incentive ?? 0; 
    const otherBonus = allPayroll?.bonus ?? 0; 
    const arrears = allPayroll?.arrears ?? 0; 
    const esi = ((inhandSalary - (wifiAllowance + overtime + incentive + otherBonus + arrears)) * 0.75) / 100;
    const mediclaimPremium = allPayroll?.mediclaim_gratuity ?? 0; 
    const halfDay = allPayroll?.half_day ?? 0; 
    const lateLogin = allPayroll?.late_login ?? 0; 
    const unpaidLeave = allPayroll?.unpaid_leave ?? 0; 
    const securityDeposit = (basic + hra + conveyance + statutoryBonus + otherAllowance) * 0.05;
    const tds = allPayroll?.tds ?? 0; 
    const pf = allPayroll?.pf ?? 0; 
    const otherDeductions = allPayroll?.other_deductions ?? 0; // You need to provide a value for otherDeductions
    const totalEarnings = basic + hra + conveyance + statutoryBonus + otherAllowance + wifiAllowance + overtime + incentive + otherBonus + arrears;
    const totalDeductions = esi +pf+ mediclaimPremium + halfDay + lateLogin + unpaidLeave + securityDeposit + tds + otherDeductions;
    const netPayable = totalEarnings - totalDeductions;

    const esiTest = 23.567888
    const newEsi = Math.round(esiTest);
    console.log("newEsi", newEsi); 


useEffect(() => {
  // ... other calculations to update netPayable
  const newNetPayable = totalEarnings - totalDeductions;
  // Update the netPayable property in the allPayroll state
  setAllPayroll((prevAllPayroll) => ({
    ...prevAllPayroll,
    netPayable: newNetPayable,
  }));
}, [totalEarnings,totalDeductions]);



const createPayRoll = (id) => {
  try {
    // Set the headers with the API key and Authorization token
    const headers = {
      'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=',
      'Authorization': `Bearer ${token}`,
    };

    axios
      .post(`${LOCALHOST_BASE_URL}/payRoll/add-payRoll`, {
        ...allPayroll,
        esi: Math.round(esi.toFixed(2)),
        gross_Salary: Math.round(inhandSalary.toFixed(2)),
        basic_Salary: Math.round(basic.toFixed(2)),
        hra: Math.round(hra.toFixed(2)),
        convyance: Math.round(conveyance.toFixed(2)),
        statutory_bonus: Math.round(statutoryBonus.toFixed(2)),
        other_allowance: Math.round(otherAllowance.toFixed(2)),
        netPayable: Math.round(netPayable.toFixed(2)),
      }, { headers })
      .then((response) => {
        console.log("Response data:", response.data);
        navigate('/pay-roll');
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(`Something is wrong, please try again.`, "red");
      });
  } catch (error) {
    console.error(error);
  }
};


  const currentYear = new Date().getFullYear();

  const generateMonthOptions = () => {
    const monthOptions = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let month = 0; month < 12; month++) {
      const monthName = monthNames[month];
      const monthDays = new Date(currentYear, month + 1, 0).getDate();

      monthOptions.push(
        <option key={month} value={`${monthName} (${monthDays} days)`}>
          {monthName} ({monthDays} days)
        </option>
      );
    }

    return monthOptions;
  };
  const updatePayRoll = (id) => {
    try {
  
      // Set the headers with the API key and Authorization token
      const headers = {
        'api-key': apiKey,
        'Authorization': `Bearer ${token}`,
      };
  
      axios
        .put(`${LOCALHOST_BASE_URL}/payRoll/update-payRoll?id=${id}`, allPayroll, { headers })
        .then((response) => {
          console.log("Response data:", response.data);
          navigate('/pay-roll');
        })
        .catch((error) => {
          console.log(error);
          showSnackbar(`Something is wrong, please try again.`, "red");
        });
    } catch (error) {
      console.error(error);
    }
  };
  


  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
        } `}
    >
      {!payrollId ? (
        <Link
          to="/pay-roll"
          className={`text-[32px] flex items-center gap-[11px] ${mode === 'dark' ? 'text-primaryTextDark' : 'text-primaryTextLight'
            }`}
        >
          <BsArrowLeft />
          Create Payroll
        </Link>
      ) : (
        <Link
          to="/pay-roll"
          className={`text-[32px] flex items-center gap-[11px] ${mode === 'dark' ? 'text-primaryTextDark' : 'text-primaryTextLight'
            }`}
        >
          <BsArrowLeft />
          Update Payroll
        </Link>
      )
      }
      {!payrollId ? (
        <div>
          <div
            className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme
?.name === "dark"
              ? "bg-primaryDark text-primaryTextDark"
              : "bg-primaryLight text-primaryTextLight"
              }`}
          >
            Earnings
          </div>
          <section className="grid grid-cols-4 gap-5">
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="month"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Select Month
              </label>
              <select
                name="month"
                id="month"
                className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                    : "border border-primaryTextLight text-primaryTextLight"
                  } `}
                onChange={(e) => {
                  const selectedValue = e.target.value; // e.g., "January (31 days)"
                  const [selectedMonth, selectedDays] = selectedValue.match(/\b\w+\b/g); // Extract the month name and the number of days
                  const currentDate = new Date();
                  const currentYear = currentDate.getFullYear();
                  setEmployee({
                    ...employee,
                    month: selectedDays,
                  });
                  const formattedMonth = `${currentYear}-${selectedMonth}`;
                  handleInputChange("month", formattedMonth);
                }}
              >
                <option selected disabled> Select Month</option>
                {generateMonthOptions()}
              </select>

            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="gender"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Employee Name
              </label>
              <select
                name="employeeId"
                id="employeeId"
                className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    employeeId: e.target.value,
                  });
                  handleInputChange("emp_id", e.target.value);
                }}
              >
                <option selected disabled> Select Employee</option>
                {getAllEmployees?.map((emp) => (
                  <option key={emp?._id} value={emp?._id}>
                    {emp?.emp_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="workingDays"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Working Days
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    workingDays: e.target.value,
                  });
                  handleInputChange("workingDays", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="grossSalary"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Inhand Salary
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(netPayable)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="basicSalary"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Basic salary
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(basic.toFixed(2))}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="hra"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                HRA
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(hra.toFixed(2))}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="conveyance"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Conveyance
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(conveyance.toFixed(2))}`} disabled
                onChange={(e) => handleInputChange('conveyance', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="statutoryBonus"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Statutory Bonus
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(statutoryBonus.toFixed(2))}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="otherAllowances"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Other Allowances
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(otherAllowance.toFixed(2))}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="wifi/MobileRecharge"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Wifi / Mobile Recharge
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('wifi_mobile_recharge', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="overtime"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Overtime
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('overTime', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="incentive"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Incentive
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('incentive', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="arrears"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Arrears
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('arrears', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="bonus"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Bonus
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('bonus', parseFloat(e.target.value))}
              />
            </div>
          </section>
          <div
            className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme
?.name === "dark"
              ? "bg-primaryDark text-primaryTextDark"
              : "bg-primaryLight text-primaryTextLight"
              }`}
          >
            Deductions
          </div>
          <section className="grid grid-cols-4 gap-5">
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="esi"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                ESI
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} 
                  value={`${Math.round(esi)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="pf"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                PF
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('pf', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="mediclaimGratuity"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Mediclaim Gratuity
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('mediclaim_gratuity', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="halfDay"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Half Day
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('half_day', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="unpaidLeave"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Unpaid Leave
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('unpaid_leave', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="lateLogin"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Late Login
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('late_login', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="tds"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                TDS
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('tds', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="otherDeductions"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Other Deductions
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                  onChange={(e) => handleInputChange('other_deductions', parseFloat(e.target.value))}
              />
            </div>
          </section>
          <div className=" flex justify-center">
            <Button intent="primary" mode={mode}
              onClick={() => createPayRoll()}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div >
          <div
            className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme
?.name === "dark"
              ? "bg-primaryDark text-primaryTextDark"
              : "bg-primaryLight text-primaryTextLight"
              }`}
          >
            Earnings
          </div>
          <section className="grid grid-cols-4 gap-5">
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="employeeName"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Employee Name
              </label>
              <input
                type="text"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${allPayroll?.emp_id?.emp_name}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="grossSalary"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Inhand Salary
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.netPayable)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="basicSalary"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Basic salary
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.basic_Salary)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="hra"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                HRA
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.hra)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="conveyance"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Conveyance
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.convyance)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="statutoryBonus"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Statutory Bonus
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.statutory_bonus)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="otherAllowances"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`} 
              >
                Other Allowances
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.other_allowance)}`} disabled
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="wifi/MobileRecharge"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Wifi / Mobile Recharge
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.wifi_mobile_recharge)}`}
                onChange={(e) => handleInputChange('wifi_mobile_recharge', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="overtime"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Overtime
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.overTime)}`}
                onChange={(e) => handleInputChange('overTime', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="incentive"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Incentive
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.incentive)}`}
                onChange={(e) => handleInputChange('incentive', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="arrears"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Arrears
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.arrears)}`}
                onChange={(e) => handleInputChange('arrears', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="bonus"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Bonus
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.bonus)}`}
                onChange={(e) => handleInputChange('bonus', parseFloat(e.target.value))}
              />
            </div>
          </section>
          <div
            className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme
?.name === "dark"
              ? "bg-primaryDark text-primaryTextDark"
              : "bg-primaryLight text-primaryTextLight"
              }`}
          >
            Deductions
          </div>
          <section className="grid grid-cols-4 gap-5">
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="esi"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                ESI
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.esi)}`}
                onChange={(e) => handleInputChange('esi', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="pf"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                PF
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.pf)}`}
                onChange={(e) => handleInputChange('pf', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="mediclaimGratuity"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Mediclaim Gratuity
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.mediclaim_gratuity)}`}
                onChange={(e) => handleInputChange('mediclaim_gratuity', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="halfDay"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Half Day
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.half_day)}`}
                onChange={(e) => handleInputChange('half_day', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="unpaidLeave"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Unpaid Leave
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.unpaid_leave)}`}
                onChange={(e) => handleInputChange('unpaid_leave', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="lateLogin"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Late Login
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.late_login)}`}
                onChange={(e) => handleInputChange('late_login', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="tds"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                TDS
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.tds)}`}
                onChange={(e) => handleInputChange('tds', parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="otherDeductions"
                className={`text-[14px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
                  }`}
              >
                Other Deductions
              </label>
              <input
                type="number"
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `} value={`${Math.round(allPayroll?.other_deductions)}`}
                onChange={(e) => handleInputChange('other_deductions', parseFloat(e.target.value))}
              />
            </div>
          </section>
          <div className=" flex justify-center">
            <Button intent="primary" mode={mode}
              // onClick={redirectToCreatePayroll}
              onClick={() => updatePayRoll(allPayroll?._id)}
            >
              Update
            </Button>
          </div>
        </div>
      )
      }
    </div>
  );
};

export default CreatePayRoll;
