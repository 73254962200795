/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const ViewResignation = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { resignationId } = useParams();
  console.log("resignationId", resignationId);
  const { showSnackbar } = useSnackbar();
  const [resignationDetails, setResignationDetails] = useState();
  console.log("resignationDetails", resignationDetails);
  console.log("resignationDetails", resignationDetails);

// Helper function to handle errors and show a snackbar message
const handleError = (error, errorMessage) => {
  if (error.response) {
    if (error.response.status === 404) {
      showSnackbar(`Resource not found. ${errorMessage}`, "red");
    } else {
      showSnackbar(`Something went wrong. ${errorMessage}`, "red");
    }
  } else {
    showSnackbar(`Something went wrong. ${errorMessage}`, "red");
  }
};

const getResignationDetails = async () => {
  try {
    const result = await axios.get(
      `${LOCALHOST_BASE_URL}/resignation/get-resignation?id=${resignationId}`,
      {
        headers: {
          'api-key': apiKey,
        },
      }
    );
    setResignationDetails(result?.data);
  } catch (error) {
    handleError(error, "while fetching resignation details.");
  }
};


  useEffect(() => {
    getResignationDetails(resignationDetails);
  }, [resignationId]);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <h1 className={`text-[32px] text-primaryText`}>View Resignation</h1>
        <Button intent="primary" mode={mode}>
          Download
        </Button>
      </section>

      <div className="flex justify-center mt-[50px]">
        <section className="flex justify-center bg-white w-[595px] h-[842px] cardShadow">
          <div className="">
            <svg
              width="595"
              height="202"
              viewBox="0 0 595 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.5V0H594.5V201.5C594.5 10 403 4.5 0 4.5Z"
                fill="url(#paint0_linear_2161_6066)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2161_6066"
                  x1="0"
                  y1="201.496"
                  x2="594.5"
                  y2="201.496"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="var(--primaryBg)" />
                  <stop offset="1" stop-color="var(--primaryBg)" />
                </linearGradient>
              </defs>
            </svg>
            <div className="mt-[-120px] px-[62px]">
              <section className="flex justify-between items-center">
                <div className="text-[14px] font-semibold leading-4 uppercase text-[#808080]">
                  <p>jkj</p>
                  <p>Badden kherr</p>
                  <p>Design</p>
                  <p>UI/UX Designer</p>
                </div>
                <img
                  src="https://miratsfilestorage.s3.ap-south-1.amazonaws.com/onboardEmail/Group+376.png"
                  alt="logo"
                  className="w-[85px] h-[40px] object-cover"
                />
              </section>
              <div className="mt-[10px] mb-[31px]">
                <h2 className="text-[15px]">
                  Apply for the designation UI/UX Designer.
                </h2>
                <p className="w-9 h-[2px] bg-primaryBg mt-[10px]"></p>
              </div>
              <section className="text-[11px] text-[#222]">
                <h3 className="mb-3">Dear HR,</h3>
                <p>
                  Please accept this letter as a formal notification of my
                  intention to resign from my position as{" "}
                  {resignationDetails?.emp_designation?.des_name}
                  with ATOMOS IT SERVICES PRIVATE LIMITED. My final working day
                  will be {resignationDetails?.notice_date} Thank you for all
                  the support and opportunities you have provided me. I have
                  truly enjoyed my time working at ATOMOS IT SERVICES PRIVATE
                  LIMITED and I am grateful for the encouragement you have given
                  me to pursue my professional development. Please let me know
                  if there is anything else I can do to help during this
                  transition period. I wish the company continued success in the
                  future.
                </p>
              </section>

              <section className="mt-3 pb-3 border-b border-[#DEE4EB]">
                <h3 className="text-[11px] font-[700] text-[#222]">
                  Reason for leaving
                </h3>
                <p className="text-[11px] my-2">
                  Looking for career growth, Career change, Better compensation,
                  Health reasons, Organizational restructuring.
                </p>
                <h3 className="text-[11px] font-[700] text-[#222]">
                  All question's answer are given below:
                </h3>
                <ul className="mt-2 list-none">
                  <li className="pb-2">
                    <h3 className="text-[11px] pb-[2px]">
                      1. Why are you resigning from this job?
                    </h3>
                    <p className="text-[10px] text-primaryBg">Ans - answer</p>
                  </li>
                  <li className="pb-2">
                    <h3 className="text-[11px] pb-[2px]">
                      2. Do you think management gave you enough opportunities
                      to air your views?
                    </h3>
                    <p className="text-[10px] text-primaryBg">Ans - answer</p>
                  </li>
                  <li className="pb-2">
                    <h3 className="text-[11px] pb-[2px]">
                      3. Did you have any goals when you joined the company?
                    </h3>
                    <p className="text-[10px] text-primaryBg">Ans - answer</p>
                  </li>
                  <li className="pb-2">
                    <h3 className="text-[11px] pb-[2px]">
                      4. What would you need to change for you to consider
                      returning to this job?
                    </h3>
                    <p className="text-[10px] text-primaryBg">Ans - answer</p>
                  </li>
                  <li className="pb-2">
                    <h3 className="text-[11px] pb-[2px]">
                      5. If you were to change anything here, what would it be?
                    </h3>
                    <p className="text-[10px] text-primaryBg">Ans - answer</p>
                  </li>
                </ul>
              </section>

              <footer>
                <h3 className="text-[11px] pb-1 mt-3">All the best,</h3>
                <h3 className="text-[11px]">
                  {resignationDetails?.emp_id?.emp_name}
                </h3>
              </footer>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewResignation;
