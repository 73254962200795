/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL, apiKey } from "../utils/config";
import { utils, writeFile } from "xlsx";
import { XLSX } from "exceljs";
import Exceljs from "exceljs";
import { useSnackbar } from "../context/SnackbarContext";

const Attendance = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sheet, setSheet] = useState();

  const [attendanceSheet, setAttendanceSheet] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();

  const months = [
    { value: "01", leable: "Jan" },
    { value: "02", leable: "Feb" },
    { value: "03", leable: "Mar" },
    { value: "04", leable: "Apr" },
    { value: "05", leable: "May" },
    { value: "06", leable: "Jun" },
    { value: "07", leable: "Jul" },
    { value: "08", leable: "Aug" },
    { value: "09", leable: "Sep" },
    { value: "10", leable: "Oct" },
    { value: "11", leable: "Nov" },
    { value: "12", leable: "Dec" },
  ];
  const currentYear = new Date().getFullYear();
  const startYear = 2010;
  const year = [];

  for (let i = startYear; i <= currentYear; i++) {
    year.push(i);
  }
  const today = new Date();

  const currentMonth = today.getMonth() + 1;

  const [yearSelect, setYearSelect] = useState();
  const [monthSelect, setMonthSelect] = useState();
  // console.log("attendanceSheet", deptData);

  //   for day
  //   const dateString = "2023-09-01";
  //   const dateObject = new Date(dateString);
  //   const day = dateObject.getDate();

  //   console.log(`Day: ${day}`);

  //   Object?.entries(attendanceSheet)?.map(([key, value]) => {
  //     console.log(value);
  //   });

  const [todaysDate, setTodaysDate] = useState();

  const getTodayDate = () => {
    const today = new Date();

    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  const [combinedValue, setCombinedValue] = useState("");

  useEffect(() => {
    let newCombinedValue = ""; // Initialize with an empty string

    if (yearSelect && monthSelect) {
      newCombinedValue = `${yearSelect}-${monthSelect}`;
    }

    setCombinedValue(newCombinedValue);
  }, [yearSelect, monthSelect]);

  console.log("combinedValue", combinedValue);

  useEffect(() => {
    getTodayDate();
    getAttendanceSheet();
  }, [combinedValue, todaysDate]);

  const getAttendanceSheet = async () => {
    try {
      let result;
      if (!combinedValue) {
        result = await axios.get(
          `${LOCALHOST_BASE_URL}/attendance/getAttendanceSheet?month=${todaysDate}`,
          {
            headers: {
              "api-key": apiKey,
            },
          }
        );
      } else {
        result = await axios.get(
          `${LOCALHOST_BASE_URL}/attendance/getAttendanceSheet?month=${combinedValue}`,

          {
            headers: {
              "api-key": apiKey,
            },
          }
        );
      }

      if (result?.status === 200) {
        setAttendanceSheet(result?.data?.attendanceSheet);
      } else {
        console.log("Error fetching shift");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  function getNumberOfDaysInMonth(yearMonth) {
    const [year, month] = yearMonth.split("-").map(Number);
    return new Date(year, month, 0).getDate();
  }

  // const handleDownload = () => {
  //   try {
  //     const sheetData = [];
  //     const month= combinedValue.split('-')[1];
  //     const year= combinedValue.split('-')[0];
  //     console.log("Here", month, year)
  //     const headers = ["Employee Name", "Date", `01-${month}-${year}`, `02-${month}-${year}`, `03-${month}-${year}`, `04-${month}-${year}`, `05-${month}-${year}`, `06-${month}-${year}`, `07-${month}-${year}`, `08-${month}-${year}`, `09-${month}-${year}`, `10-${month}-${year}`, `11-${month}-${year}`, `12-${month}-${year}`, `13-${month}-${year}`, `14-${month}-${year}`, `15-${month}-${year}`, `16-${month}-${year}`, `17-${month}-${year}`, `18-${month}-${year}`, `19-${month}-${year}`, `20-${month}-${year}`, `21-${month}-${year}`, `22-${month}-${year}`, `23-${month}-${year}`, `24-${month}-${year}`, `25-${month}-${year}`, `26-${month}-${year}`, `27-${month}-${year}`, `28-${month}-${year}`, `29-${month}-${year}`, `30-${month}-${year}`, `31-${month}-${year}`];

  //     console.log("Here is the headers", headers);
  //     sheetData.push(headers);

  //     attendanceSheet.map((data) => {
  //       let arr = [data?.employeeName];
  //       data.attendance?.forEach((att) => {
  //         arr.push(att.status);
  //       });
  //       sheetData.push(arr);
  //     });

  //     // Create a worksheet
  //     const workSheet = utils.aoa_to_sheet(sheetData);

  //     // Apply a style to the header row
  //     const headerStyle = {
  //       font: { bold: true },
  //       fill: { fgColor: { rgb: "FFFF00" } },
  //     };
  //     workSheet["A1"].s = headerStyle;
  //     workSheet["B1"].s = headerStyle;
  //     workSheet["C1"].s = headerStyle;

  //     // Create a workbook and add the worksheet
  //     const workBook = utils.book_new();
  //     utils.book_append_sheet(workBook, workSheet, "Sheet1");

  //     // Create and download the sheet
  //     writeFile(
  //       workBook,
  //       `AttendanceSheet- ${new Date().toLocaleDateString("en-CA", {
  //         month: "long",
  //         year: "numeric",
  //       })}.xlsx`
  //     );
  //     // }
  //   } catch (error) {
  //     console.log("Here is the Error from Try-Catch", error);
  //   }
  // };

  // DOWNLOAD EXCEL SHEET:
  const handleDownload = () => {
    try {
      const sheetData = [];
      const [year, month] = combinedValue.split("-");
      const daysInMonth = new Date(year, month, 0).getDate();

      const headers = ["Employee Name"];
      for (let i = 1; i <= daysInMonth; i++) {
        const day = ("0" + i).slice(-2);
        headers.push(`${day}-${month}-${year}`);
      }

      console.log("Here is the headers", headers);
      sheetData.push(headers);

      attendanceSheet.map((data) => {
        let arr = [data?.employeeName];
        data.attendance?.forEach((att) => {
          arr.push(att.status);
        });
        sheetData.push(arr);
      });

      // Create a worksheet
      const workSheet = utils.aoa_to_sheet(sheetData);

      // Apply a style to the header row
      const headerStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: "FFFF00" } },
      };

      // Loop through the headers and set the style
      headers.forEach((header, index) => {
        const cellAddress = utils.encode_cell({ r: 0, c: index });
        workSheet[cellAddress].s = headerStyle;
      });

      // Create a workbook and add the worksheet
      const workBook = utils.book_new();
      utils.book_append_sheet(workBook, workSheet, "Sheet1");

      // Create and download the sheet
      writeFile(
        workBook,
        `AttendanceSheet- ${new Date().toLocaleDateString("en-CA", {
          month: "long",
          year: "numeric",
        })}.xlsx`
      );
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
    }
  };

  const filterData = attendanceSheet?.filter((blog) => {
    const autherData = departmentSelect
      ? blog?.department === departmentSelect
      : true;
    return autherData;
  });

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <h1
          className={`text-[32px] ${
            organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
          }`}
        >
          Attendance
        </h1>
        <Button intent="primary" mode={mode} onClick={() => handleDownload()}>
          Download Excel Sheet
        </Button>
      </section>
      <div className="my-[32px] flex gap-4 items-center">
        <select
          name="dept"
          id="dept"
          className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect : ""}
        >
          <option value="dept" selected>
            Department
          </option>
          {deptData
            ?.filter((d) =>
              attendanceSheet?.some((data) => data?.department === d.dept_name)
            )
            ?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.dept_name}
              </option>
            ))}
        </select>
        <select
          name="month"
          id="month"
          className={`py-2 px-2 w-[92px] text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          value={
            monthSelect
              ? monthSelect
              : (new Date().getMonth() + 1).toString()?.padStart(2, "0")
          }
          onChange={(e) => setMonthSelect(e.target.value)}
        >
          <option value="month" selected>
            Month
          </option>
          {months?.map((emp) => (
            <option key={emp?._id} value={emp?.value}>
              {emp?.leable}
            </option>
          ))}
        </select>
        <select
          name="year"
          id="year"
          className={`py-2 px-2 w-[92px] text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          value={yearSelect ? yearSelect : new Date().getFullYear()}
          onChange={(e) => setYearSelect(e.target.value)}
        >
          <option value="year" selected>
            Year
          </option>
          {year?.map((emp) => (
            <option key={emp} value={emp}>
              {emp}
            </option>
          ))}
        </select>
        <button
          className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect("");
            setYearSelect("");
            setMonthSelect("");
            setCombinedValue(""); // Add this line to clear combinedValue
          }}
        >
          Clear Filter
        </button>
      </div>

      <div className="overflow-auto">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <p className="pl-2">Employee Name</p>
                </Th>
                {[
                  ...new Array(
                    new Date(
                      yearSelect ? yearSelect : new Date().getFullYear(),
                      1,
                      0
                    )?.getDate() + 1
                  ),
                ]?.map((_, i) => {
                  return i ? <Th key={i}>{i}</Th> : null;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData
                ?.slice(
                  (pageNumber - 1) * rowsPerPage,
                  pageNumber * rowsPerPage
                )
                .map((d, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>
                        <div className="flex items-center gap-2 pl-2">
                          {d?.profile ? (
                            <Avatar type="img" src={d?.profile} size="md" />
                          ) : (
                            <Avatar size="md" type="text">
                              {d?.employeeName?.slice(0, 1)}
                            </Avatar>
                          )}

                          <div className="">
                            <h4 className="text-primaryText text-[14px]">
                              {d?.employeeName}
                            </h4>
                            <h4 className="text-primaryText text-[10px]">
                              {d?.designation}
                            </h4>
                          </div>
                        </div>
                      </Td>
                      {d?.attendance?.map((att) => (
                        <Td>
                          <div
                            className={`w-[17px] h-[17px] text-[10px] flex justify-center items-center rounded-full ${
                              att?.status === "P"
                                ? "bg-[#D1F4DF] text-[#299E00]"
                                : att?.status === "W"
                                ? "bg-[#FFC8EC] text-[#885275]"
                                : att?.status === "-"
                                ? ""
                                : att?.status === "A"
                                ? "bg-[#FEE3EB] text-[#FB1C24]"
                                : att?.status === "OT"
                                ? "bg-[#FBEEDA] text-[#F9A000]"
                                : att?.status === "HD"
                                ? "bg-[#C5E7E8] text-[#328B89]"
                                : att?.status === "U"
                                ? "bg-[#F9C7A4] text-[#A1673F]"
                                : att?.status === "HO"
                                ? "bg-[#E0D5D4] text-[#312D2C]"
                                : ""
                            }`}
                          >
                            {att?.status}
                          </div>
                        </Td>
                        // <Td>{att?.status}</Td>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={attendanceSheet?.length || 0}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </TableWithPagination>
      </div>
    </div>
  );
};

export default Attendance;
