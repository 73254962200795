/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../context/SnackbarContext";
import ReactToPrint from "react-to-print";

const ViewPayslips = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const [defaultStyling, setStyling] = useState();
  const { showSnackbar } = useSnackbar();
  const componentRef = useRef();

  const contentRef = useRef();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const payslipId = searchParams.get("payslipId");
  const [orgData, setorgData] = useState({});
  const [payslip, setpayslip] = useState({});

  // Helper function to handle errors and show a snackbar message
  const handleError = (error, errorMessage) => {
    if (error.response) {
      if (error.response.status === 404) {
        showSnackbar(`Resource not found. ${errorMessage}`, "red");
      } else {
        showSnackbar(`Something went wrong. ${errorMessage}`, "red");
      }
    } else {
      showSnackbar(`Something went wrong. ${errorMessage}`, "red");
    }
  };

  const getStyling = async () => {
    try {

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/salaryTemp/get-all-salaryTemp`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setStyling(result?.data);
    } catch (error) {
      handleError(error, "while fetching styling data.");
    }
  };

  const getpayslip = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/payslip/get-payslip?id=${payslipId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setpayslip(result?.data?.getpaySlip);
    } catch (error) {
      handleError(error, "while fetching payslip data.");
    }
  };

  const getorgData = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/organisation/getOne-organisation`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setorgData(result?.data?.result?.[0]);
    } catch (error) {
      handleError(error, "while fetching organization data.");
    }
  };

  useEffect(() => {
    getStyling();
    getpayslip();
    getorgData();
  }, [payslipId]);

  console.log("org", orgData);
  console.log("organisation", organisation);

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function formatMonth(monthString) {
    if (!monthString) return "";
    const date = new Date(monthString + "-01"); // Assuming the day is always 01
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <Link
          to="/payslip"
          className={`text-[32px] flex items-center gap-1 text-primaryText`}
        >
          <MdOutlineKeyboardBackspace /> View Payslips
        </Link>
        <div className="flex items-center gap-1">
          <ReactToPrint
            trigger={() => (
              <Button intent="primary" mode={mode}>
                Download
              </Button>
            )}
            content={() => componentRef.current}
          />
          {/* <small className="font-bold">Download</small> */}
        </div>
      </section>

      <section className="flex justify-center my-[52px]" ref={componentRef}>
        <div className="shadow-sm w-[643px] h-[940px] p-[18px] relative bg-white">
          <section className="flex justify-between items-center px-3 border-b border-[#3D4EFE] pb-2">
            <h2 className="text-[18px] font-[700]">
              {orgData?.organisationName}
            </h2>
            <img
              // src={orgData?.organisationLogo}
              src={orgData?.organisationLogo}
              alt="logo"
              className="w-[64px] h-[28px] object-cover"
            />
          </section>
          <section
            className="h-[220px] pt-3 border-b border-[#3D4EFE] px-3"
            style={orgData?.organisationPaySlip?.paySummarySection}
          >
            <h2 className="text-[14px] text-[#333] font-[700]">
              Payslip for the month of {formatMonth(payslip?.paySlip_id?.month)}
            </h2>
            <p className="text-[12px] my-2 text-[#3D4EFE]">
              EMPLOYEE PAY SUMMARY
            </p>
            <div className="text-[#333] flex justify-between">
              <section className="w-[50%]">
                <table className="text-[12px] w-full">
                  <tr>
                    <td className="py-1">Employee Name</td>
                    <td className="font-semibold">
                      : {payslip?.paySlip_id?.emp_id?.emp_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1">Designation</td>
                    <td className="font-semibold">
                      : {payslip?.paySlip_id?.emp_id?.emp_designation?.des_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1">Date of Joining</td>
                    <td className="font-semibold">
                      : {formatDate(payslip?.paySlip_id?.emp_id?.emp_joingDate)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1">Pay Period</td>
                    <td className="font-semibold">
                      : {formatMonth(payslip?.paySlip_id?.month)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-1">Pay Date</td>
                    <td className="font-semibold">
                      :{" "}
                      {payslip?.paymentDate
                        ? payslip?.paymentDate
                        : "Payment Pending"}
                    </td>
                  </tr>
                </table>
              </section>
              <section className="mr-7 mt-5">
                <h2 className="text-[10px] text-center">Employee Net Pay</h2>
                <h1 className="text-[20px] font-[600]">
                  ₹ {payslip?.paySlip_id?.gross_Salary}
                </h1>
              </section>
            </div>
          </section>
          <div className="">
            <table className="w-full text-[12px]">
              <thead
                className="h-[30px] text-[#3D4EFE]"
                // style={orgData?.organisationPaySlip?.earningsBg}
              >
                <th className="w-[50%] text-left font-[400] px-3">EARNINGS</th>
                <th className="w-[50%] text-right pr-10 font-[400]">AMOUNT</th>
              </thead>
              <tbody
                className="text-[12px] text-[#333]"
                style={orgData?.organisationPaySlip?.tableBodyBg}
              >
                <tr className="">
                  <td className="px-3 py-1 font-semibold">Basic </td>
                  <td className="text-right pr-10 font-semibold">
                    ₹{" "}
                    {payslip?.paySlip_id?.basic_Salary
                      ? payslip?.paySlip_id?.basic_Salary
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">
                    House Rent Allowance{" "}
                  </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.hra
                      ? `₹ ${payslip?.paySlip_id?.hra}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">
                    Conveyance Allowance{" "}
                  </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.convyance
                      ? `₹ ${payslip?.paySlip_id?.convyance}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Statutory Bonus </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.statutory_bonus
                      ? `₹ ${payslip?.paySlip_id?.statutory_bonus}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Other Allowances </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.other_allowance
                      ? `₹ ${payslip?.paySlip_id?.other_allowance}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">
                    Wifi/Mobile Recharge{" "}
                  </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.wifi_mobile_recharge
                      ? `₹ ${payslip?.paySlip_id?.wifi_mobile_recharge}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Overtime </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.overTime
                      ? `₹ ${payslip?.paySlip_id?.overTime}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Incentive </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.incentive
                      ? `₹ ${payslip?.paySlip_id?.incentive}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Arrears </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.arrears
                      ? `₹ ${payslip?.paySlip_id?.arrears}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Bonus </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.bonus
                      ? `₹ ${payslip?.paySlip_id?.bonus}`
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="">
            <table className="w-full text-[12px]">
              <thead
                className="h-[30px] text-[#3D4EFE]"
                style={orgData?.organisationPaySlip?.earningsBg}
              >
                <th className="w-[50%] text-left font-[400] px-3">
                  DEDUCTIONS
                </th>
                <th className="w-[50%] text-right pr-10 font-[400]">AMOUNT</th>
              </thead>
              <tbody
                className="text-[12px] text-[#333]"
                style={orgData?.organisationPaySlip?.tableBodyBg}
              >
                <tr className="">
                  <td className="px-3 py-1 font-semibold">ESI </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.esi
                      ? `₹ ${payslip?.paySlip_id?.esi}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">PF </td>
                  <td className="text-right pr-10">
                    {" "}
                    {payslip?.paySlip_id?.pf
                      ? `₹ ${payslip?.paySlip_id?.pf}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">
                    Mediclaim Gratuity{" "}
                  </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.mediclaim_gratuity
                      ? `₹ ${payslip?.paySlip_id?.mediclaim_gratuity}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Half Day </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.half_day
                      ? `₹ ${payslip?.paySlip_id?.half_day}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Unpaid Leave </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.unpaid_leave
                      ? `₹ ${payslip?.paySlip_id?.unpaid_leave}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Late Login </td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.late_login
                      ? `₹ ${payslip?.paySlip_id?.late_login}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">TDS</td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.tds
                      ? `₹ ${payslip?.paySlip_id?.tds}`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-1 font-semibold">Other Deductions</td>
                  <td className="text-right pr-10 font-semibold">
                    {payslip?.paySlip_id?.other_deductions
                      ? `₹ ${payslip?.paySlip_id?.other_deductions}`
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="">
            <table className="w-full text-[12px]">
              <tbody
                className="h-[30px] text-[#3D4EFE]"
                style={orgData?.organisationPaySlip?.earningsBg}
              >
                <tr className="">
                  <td className="px-3 py-1 font-semibold">
                    NET PAY (Gross Earnings - Total Deductions){" "}
                  </td>
                  <td className="text-right pr-10 font-semibold">
                    ₹ {payslip?.paySlip_id?.gross_Salary}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <footer className="text-[10px] text-[#333] text-center absolute bottom-7">
            <h3 className="font-semibold">End Of Payslip</h3>
            <p className="mx-10">
              This is an authenticated electronic statement. Employees are
              requested to immediately notify the accounts department of any
              discrepancy in the statement.
            </p>
            <p className="font-semibold mt-2">
              Email us at -{" "}
              <a
                href="mailto:payment_issues@miratsinsights.com"
                className="text-[#1765dc]"
              >
                payment_issues@miratsinsights.com
              </a>{" "}
            </p>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default ViewPayslips;
