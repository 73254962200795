/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import { useNavigate } from "react-router";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";
import Badge from "../components/Badge";
import { PiListFill } from "react-icons/pi";
import { BsFillGridFill } from "react-icons/bs";
import Modal from "../components/Modal";
import payrollLight from "../assets/offerLetterLight.svg";
import payrollDark from "../assets/PayrollBlack.svg";
import { useSnackbar } from "../context/SnackbarContext";

const OfferLetter = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let [tab, setTab] = useState("Offer letter layout");
  // get all OfferLetter
  const [offerLetter, setOfferLetter] = useState([]);
  // console.log("OfferLetter", OfferLetter);

  const tabData = [
    {
      id: 1,
      tabName: "Offer letter layout",
    },
    {
      id: 2,
      tabName: "Send offer letter",
    },
  ];

  // get all OfferLetter
  const getAllOfferLetter = async () => {

    const url = `${LOCALHOST_BASE_URL}/offerLetterTemp/get-All-offerLetterTemp`;

    try {
      const response = await axios.get(url, {
        headers: {
          "api-key": apiKey,
        },
      });

      if (response.status === 200) {
        setOfferLetter(response.data.result);
      } else if (response.status === 404) {
        // Handle 404 error, such as displaying an error message to the user
        console.error("OfferLetter Temp not found");
        showSnackbar("OfferLetter Temp not found. Please try again", "red");
      } else {
        // Handle other errors if needed
        console.error("An error occurred:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      // Handle any other errors that might occur during the request
      console.error("An error occurred:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getAllOfferLetter();
  }, []);

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Offer letter layout":
        return (
          <OfferLetterDetails
            OfferLetter={offerLetter}
            formatDate={formatDate}
            getAllOfferLetter={getAllOfferLetter}
          />
        );
      case "Send offer letter":
        return (
          <AssignOfferLetter
            OfferLetter={offerLetter}
            formatDate={formatDate}
            deptData={deptData}
          />
        );
      default:
    }
  };

  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark text-white"
          : "bg-white"
      } `}
    >
      {offerLetter?.length === 0 ? (
        <div className="h-[600px] w-full flex justify-center flex-col items-center">
          <img
            src={`${mode === "dark" ? payrollDark : payrollLight}`}
            className="w-[280px] h-[180px]"
          />
          <p
            className={`text-[24px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            To generate a new offer letter template, please click on the
          </p>
          <p
            className={`text-[24px] mb-4 ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            "Create Offer Letter Layout" button.
          </p>
          <Button
            intent="primary"
            mode={mode}
            onClick={() => navigate(`/create-CreateOfferLetterTmp`)}
          >
            Create Offer Letter Layout
          </Button>
        </div>
      ) : (
        <>
          <section className="flex justify-between items-center">
            <h1
              className={`text-[32px] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryTextLight"
              }`}
            >
              Offer letter
            </h1>
          </section>
          <div>
            <section className="flex gap-5 items-center border-b mt-[51px] mb-[30px]">
              {tabData.map((i) => {
                return (
                  <>
                    <h3
                      className={`pb-2 text-[14px] cursor-pointer ${
                        tab === i.tabName
                          ? "text-primaryBg border-b border-primaryBg"
                          : "text-primaryText"
                      }`}
                      onClick={() => {
                        setTab(i.tabName);
                      }}
                    >
                      {i.tabName}
                    </h3>
                  </>
                );
              })}
            </section>
            <section>{getCorrectPage(tab)}</section>
          </div>
        </>
      )}
    </div>
  );
};

const OfferLetterDetails = ({
  OfferLetter,
  mode,
  formatDate,
  getAllOfferLetter,
}) => {
  console.log("OfferLetterDetails", OfferLetter);
  const [assignModal, setAssignModal] = useState(false);
  const [cardUrl, setCardUrl] = useState("");
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const openAssignModal = () => {
    setAssignModal((prev) => !prev);
  };

  console.log("OfferLetter 212", OfferLetter);
  return (
    <>
      <section className="flex justify-start items-center mb-6">
        <Button
          intent="primary"
          mode={mode}
          onClick={() => navigate(`/create-CreateOfferLetterTmp`)}
        >
          Create Offer letter layout
        </Button>
      </section>
      <div className="grid grid-cols-3 gap-4">
        {OfferLetter?.map((agr) => (
          <section className="h-[182px] rounded border shadow-lg px-4 py-2 w-[300px]">
            <div className="flex justify-between items-center mb-2">
              <h1 className="text-primaryBg capitalize font-semibold">
                {agr?.offerLetterHeading}
              </h1>
              <div className="flex gap-1 items-center">
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                  onClick={() =>
                    navigate(`/create-CreateOfferLetterTmp/${agr?._id}`)
                  }
                >
                  <FiEdit2 color="var(--primaryText)" />
                </h2>
              </div>
            </div>
            <p className="h-[83px] text-primaryText">{agr?.description}</p>
            <div className="flex justify-center items-center gap-4">
              <Button
                intent="primary"
                mode={mode}
                onClick={() => navigate(`/ViewOfferLetterTmp/${agr?._id}`)}
              >
                View
              </Button>
              <Button
                intent="secondary"
                mode={mode}
                onClick={() => navigate(`/create-OfferrLetter/${agr?._id}`)}
              >
                Create offer letter
              </Button>
            </div>
          </section>
        ))}
        <Modal showModal={assignModal} setShowModal={setAssignModal}>
          <div className="flex flex-col gap-3 items-center h-full overflow-auto">
            <p className="text-[20px] text-primaryText "> View Document</p>
          </div>
        </Modal>
      </div>
    </>
  );
};

const AssignOfferLetter = ({ OfferLetter, mode, formatDate, deptData }) => {
  const [offerLetterData, setOfferLetterData] = useState([]);
  const [departmentSelect, setDepartmentSelect] = useState();
  const [statusSelect, setStatusSelect] = useState();

  const getOfferLetterData = async () => {
    const url = `${LOCALHOST_BASE_URL}/offerLetter/get-all-offerLetter`;

    try {
      const response = await axios.get(url, {
        headers: {
          "api-key": apiKey,
        },
      });

      if (response.status === 200) {
        setOfferLetterData(response.data);
      } else if (response.status === 404) {
        // Handle 404 error, such as displaying an error message to the user
        console.error("OfferLetter Data not found");
        showSnackbar("OfferLetter Data not found. Please try again", "red");
      } else {
        // Handle other errors if needed
        console.error("An error occurred:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      // Handle any other errors that might occur during the request
      console.error("An error occurred:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getOfferLetterData();
  }, []);

  console.log("offerLetterData 268", offerLetterData);

  const filterData = offerLetterData?.filter((blog) => {
    const autherData = departmentSelect
      ? blog?.department === departmentSelect
      : true;
    const statusData = statusSelect
      ? blog?.status?.toString() === statusSelect.toString()
      : true;
    console.log("statusData", statusData);
    return autherData && statusData;
  });

  return (
    <>
      <div className="my-[32px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="dept"
            id="dept"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[122px] ${
              mode === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setDepartmentSelect(e.target.value)}
            value={departmentSelect ? departmentSelect : ""}
          >
            <option value="dept" selected>
              Department
            </option>
            {deptData?.map((emp) => (
              <option key={emp?.dept_name} value={emp?._id}>
                {emp?.dept_name}
              </option>
            ))}
          </select>
          {/* <select
            name="month"
            id="month"
            className={`py-2 px-2 text-[12px] rounded outline-none  focus:border-primaryBg w-[92px]  ${
              mode === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="month" selected disabled>
              Month
            </option>
          </select>
          <select
            name="year"
            id="year"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              mode === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="year" selected disabled>
              Year
            </option>
          </select> */}
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              mode === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }`}
            onChange={(e) => setStatusSelect(e.target.value)}
            value={statusSelect ? statusSelect : ""}
          >
            <option value="status">Status</option>
            <option value="true" selected={statusSelect === "true"}>
              Active
            </option>
            <option value="false" selected={statusSelect === "false"}>
              Expired
            </option>
          </select>

          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setDepartmentSelect("");
              setStatusSelect("");
            }}
          >
            Clear Filter
          </button>
        </section>
      </div>

      <div>
        <AgreementAssetCard
          OfferLetter={OfferLetter}
          formatDate={formatDate}
          offerLetterData={filterData}
        />
      </div>
    </>
  );
};

const AgreementAssetCard = ({
  OfferLetter,
  formatDate,
  mode,
  offerLetterData,
}) => {
  const navigate = useNavigate();

  const deleteOfferLetter = async (id) => {
    try {
      await axios.delete(
        `${LOCALHOST_BASE_URL}/offerLetter/delete-offerLetter?id=${id}`,
        {
          headers: {
            'api-key': apiKey,
            'Authorization': `Bearer ${token}`,
          }
        }
      );
      console.log("delete id", id);
      getDesignation();
      showSnackbar("Designation Deleted Successfully", "green");
    } catch (error) {
      showSnackbar("Designation is Not Deleted", "red");
      console.error("Error deleting designation:", error);
    }
  };

  return (
    <div className="grid grid-cols-4 gap-[26px]">
      {offerLetterData?.reverse()?.map((res) => (
        <section
          className={`w-[261px] h-[230px] shadow-md rounded py-3 cardShadow ${
            mode === "dark" ? "bg-primaryDark" : "bg-white"
          }`}
        >
          <div className="flex gap-1 items-center justify-end pr-4">
          { !res?.status ? (
            <h2
              className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
              onClick={() =>
                navigate(
                  `/create-OfferrLetter/${res?.offerLetter_Id}/${res?._id}`
                )
              }
            >
              <FiEdit2 color="#389927" />
            </h2>):"" }
            
            { !res?.status ? (
              <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
              onClick={() => {
                deleteOfferLetter(res?._id);
              }}>
              <RiDeleteBin6Line color="#D20E0E" />
            </h2>
            ) :
            ""}
          </div>
          <div className={`py-[14px] text-center mt-[11px] text-primaryText`}>
            <h2 className={`text-[20px] font-medium`}>{res?.emp_name}</h2>
            <h3 className="my-[10px]">{res?.designation}</h3>
            <h3 className="text-primaryText text-[14px]">
              Joining Date : {formatDate(res?.joining_date)}
            </h3>
          </div>
          <div className="flex justify-center items-center gap-2">
            <Button intent="secondary" mode={mode}>
              Download
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => navigate(`/ViewOfferLetter/${res?._id}`)}
            >
              View
            </Button>
          </div>
        </section>
      ))}
    </div>
  );
};

export default OfferLetter;
