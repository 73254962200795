/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import Button from "../components/Button";
import { colorScheme } from "excel4node/distribution/lib/types";
import { LOCALHOST_BASE_URL ,apiKey, token} from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const RegisterEmployee = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const workingdays = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

  const [multiResponse, setMultiResponse] = useState([]);
  const [selectedOption, setSelectedOption] = useState('no');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  console.log("selectedOption", selectedOption);

  console.log("multiResponse", multiResponse);
  const [teamData, setTeamData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [desData, setDesData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [error, setError] = useState(null);

  const [offerLetter, setOfferLetter] = useState();
  console.log("offerLetter", offerLetter);
  const getOfferLetter = async () => {
    try {
      const apiKey = "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=";
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetter/get-all-offerLetter`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      const status = true
      const filterData = result?.data?.filter((blog) => {
        const autherData = status ? blog?.status === status : true;
        // const categoryData = categorySelect
        //   ? blog?.category === categorySelect
        //   : true;
        // return categoryData && autherData;
        return autherData;
      });
      setOfferLetter(filterData);
    } catch (error) {
      console.error("error", error);
    }
  };



  const getRequiredData = async () => {
    try {
      const headers = {
        "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      };

      const departmentsResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/departments/get-all-departments`,
        { headers }
      );
      const designationsResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/designations/get-all-designations`,
        { headers }
      );
      const teamResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/team/get-all-team`,
        { headers }
      );
      const shiftResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/shift/get-all-shift`,
        { headers }
      );

      // Check for successful responses and set the data
      if (departmentsResponse?.status === 200) {
        setDeptData(departmentsResponse?.data);
      } else {
        setError("Error fetching departments");
        showSnackbar(
          "Something is wrong with departments data, please try again.",
          "red"
        );
      }

      if (designationsResponse?.status === 200) {
        setDesData(designationsResponse?.data);
      } else {
        setError("Error fetching designations");
        showSnackbar(
          "Something is wrong with designations data, please try again.",
          "red"
        );
      }

      if (teamResponse?.status === 200) {
        setTeamData(teamResponse?.data);
      } else {
        setError("Error fetching teams");
        showSnackbar(
          "Something is wrong with team data, please try again.",
          "red"
        );
      }

      if (shiftResponse?.status === 200) {
        setShiftData(shiftResponse?.data);
      } else {
        setError("Error fetching shift");
        showSnackbar(
          "Something is wrong with shift data, please try again.",
          "red"
        );
      }
    } catch (error) {
      setError("An error occurred while fetching data");
      showSnackbar("Something is wrong , please try again.", "red");
    }
  };

  // start
  const [countryStateCity, setCountryStateCity] = useState({
    countries: [],
    state: [],
    cities: [],
  });

  const headers = new Headers();
  headers.append(
    "X-CSCAPI-KEY",
    "aGJKbVB4TXZwVVgzeDEzNXJId3hCeEx6MnM0UmhlUVJkTmR2NndEMw=="
  );
  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };
  //get countries:
  const getCountries = () => {
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCountryStateCity((prev) => ({ ...prev, countries: result }));
      })
      .catch((error) => console.log("error", error));
  };

  //get states:
  const getStates = (country) => {
    fetch(
      `https://api.countrystatecity.in/v1/countries/${country}/states`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountryStateCity((prev) => ({ ...prev, state: result }));
      })
      .catch((error) => console.log("error", error));
  };

  //get states:
  const getCities = (country, state) => {
    console.log("Here is the country and state", country, state);
    fetch(
      `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
        setCountryStateCity((prev) => ({ ...prev, cities: result }))
      )
      .catch((error) => console.log("error", error));
  };



  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (name, value) => {
    console.log("line 107", name, value);
    setRegistration({
      ...registration,
      [name]: value,
    });

    // Fetch states based on the selected country
    getStates(value);
  };
  //end

  const handleStateChange = (name, value) => {
    console.log("line 84", name, value);
    setRegistration({
      ...registration,
      [name]: value,
    });
    // console.log("Here -------------", registration?.PersonalCountry, value)
    // getCities(registration?.PersonalCountry, value);
  };

  const [registration, setRegistration] = useState();

  const handleRegistration = (name, value) => {
    try {
      setRegistration({
        ...registration,
        [name]: value,
      });
    } catch (error) {
      console.log(
        "Here is the Error from try-catch (handleRegistration)",
        error
      );
    }
  };
  const handleRegistrationEmployee = () => {
    try {
      console.log("Here is the Data of the Employee to Register", registration?.emp_department);
      console.log("Here is the Data of the Employee days", multiResponse);
      if (multiResponse.length > 0) {
        if (multiResponse.includes("Sun")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              sunday: true,
            },
          }));
        }
        if (multiResponse.includes("Mon")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              monday: true,
            },
          }));
        }
        if (multiResponse.includes("Tue")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              tuesday: true,
            },
          }));
        }
        if (multiResponse.includes("Wed")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              wednesday: true,
            },
          }));
        }
        if (multiResponse.includes("Thurs")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              thursday: true,
            },
          }));
        }
        if (multiResponse.includes("Fri")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              friday: true,
            },
          }));
        }
        if (multiResponse.includes("Sat")) {
          setRegistration((prev) => ({
            ...prev,
            emp_workdays: {
              ...prev.emp_workdays,
              saturday: true,
            },
          }));
        }
      }

      console.log("registration");
      axios
        .post(
          `${LOCALHOST_BASE_URL}/employee/add-employee`,
          {
            emp_name: registration?.emp_name,
            emp_phoneNo: Number(registration?.emp_phoneNo),
            emp_email: registration?.emp_email,
            emp_dob: registration?.emp_dob,
            emp_gender: registration?.emp_gender,
            emp_country: registration?.emp_country,
            emp_state: registration?.emp_state,
            emp_city: registration?.emp_city,
            emp_type: registration?.emp_type,
            emp_department: registration?.emp_department,
            emp_designation: registration?.emp_designation,
            emp_team: registration?.emp_team,
            emp_joingDate: registration?.emp_joingDate,
            emp_shiftTime: registration?.emp_shiftTime,
            emp_workCountry: registration?.emp_workCountry,
            emp_workState: registration?.emp_workState,
            emp_workCity: registration?.emp_workCity,
            emp_workdays: registration?.emp_workdays,
          },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            console.log("Employee Registered", response.data);
            navigate('/dashboard');
            showSnackbar(`Employee Registered Succesfully .`, "green");
          }
        })
        .catch((error) => {
          console.log("Here is the Error from Then-Catch", error);
          showSnackbar(`Here is the Error from Then-Catch.`, "red");
        });
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
    }
  };

  const [singleOfferLetter, setSingleOfferLetter] = useState();

  const getSingleOfferLetter = async () => {
    try {
      const apiKey = "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=";
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetter/get-offerLetter?id=${registration?.emp_offerLetter}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setRegistration(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getCountries();
    getRequiredData();
    getOfferLetter();
  }, []);

  useEffect(() => {
    getSingleOfferLetter();
  }, [registration?.emp_offerLetter]);



  console.log("registration 375",);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${organisation?.organisationTheme?.name === "dark"
        ? "bg-secondaryDark"
        : "bg-white"
        } `}
    >
      <Link
        to="/"
        className={`text-[32px] flex items-center gap-[11px] ${organisation?.organisationTheme?.name === "dark"
          ? "text-primaryTextDark"
          : "text-primaryTextLight"
          }`}
      >
        <BsArrowLeft />
        Register Employee
      </Link>
      <div
        className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark text-primaryTextDark"
          : "bg-primaryLight text-primaryTextLight"
          }`}
      >
        Personal Details
      </div>
      <section className="grid grid-cols-4 gap-5">
        <div>
          <label
            htmlFor="offerletterEmp"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Offer Letter Employee?
          </label>
          <section className="mt-4 grid grid-cols-2">
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                name="yesOrNo"
                id="no"
                value="no"
                checked={selectedOption === 'no'}
                onChange={handleRadioChange}
              />
              <label
                htmlFor="no"
                className={`text-[14px] ${organisation?.organisationTheme?.name === 'dark'
                  ? 'text-primaryTextDark'
                  : 'text-primaryTextLight'
                  }`}
              >
                No
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                name="yesOrNo"
                id="yes"
                value="yes"
                checked={selectedOption === 'yes'}
                onChange={handleRadioChange}
              />
              <label
                htmlFor="yes"
                className={`text-[14px] ${organisation?.organisationTheme?.name === 'dark'
                  ? 'text-primaryTextDark'
                  : 'text-primaryTextLight'
                  }`}
              >
                Yes
              </label>
            </div>
          </section>
          {/* <input type="checkbox" /> */}
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="empName"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Employee Name
          </label>
          {selectedOption === "yes" ? (
            <select
              id="emp_name"
              className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg ${organisation?.organisationTheme?.name === 'dark'
                ? 'border border-primaryTextDark text-primaryTextDark bg-transparent'
                : 'border border-primaryTextLight text-primaryTextLight'
                }`}
              name="emp_name"
              onChange={(e) => {
                const selectedOption = e.target.selectedOptions[0];
                handleRegistration(e.currentTarget.name, selectedOption.label);
                setRegistration({
                  ...registration,
                  'emp_offerLetter': selectedOption.value,
                });
                getSingleOfferLetter(selectedOption?.value)
              }}
            >
              <option value="emp_name" selected disabled>
                Employee Name
              </option>
              {offerLetter?.map((data) => {
                return (
                  <option key={data?._id} value={data?._id}>
                    {data?.emp_name}
                  </option>
                );
              })}
            </select>
          ) : (
            <input
              type="text"
              className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg ${organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark"
                : "border border-primaryTextLight text-primaryTextLight"
                } `}
              name="emp_name"
              onChange={(e) =>
                handleRegistration(e.currentTarget.name, e.currentTarget.value)
              }
            />
          )}

        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="phoneNo"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Phone No.
          </label>
          <input
            type="number"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === 'dark'
              ? 'border border-primaryTextDark text-primaryTextDark'
              : 'border border-primaryTextLight text-primaryTextLight'
              }  `}
            name="emp_phoneNo"
            defaultValue={ registration?.emp_phoneNo}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, parseFloat(e.currentTarget.value))
            }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="emailId"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Email Id
          </label>
          <input
            type="email"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_email"
            value={registration?.emp_email ? registration?.emp_email : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="dob"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Date of Birth
          </label>
          <input
            type="date"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_dob"
            defaultValue={registration?.emp_dob}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="gender"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Gender
          </label>
          <select
            id="gender"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg ${organisation?.organisationTheme?.name === 'dark'
              ? 'border border-primaryTextDark text-primaryTextDark bg-transparent'
              : 'border border-primaryTextLight text-primaryTextLight'
              }  `}
            name="emp_gender"
            defaultValue={registration?.emp_gender}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>

        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="country"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Country
          </label>
          <select
            // name="country"
            id="country"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_country"
            defaultValue={registration?.emp_country}
            value={
              registration
                ? registration?.emp_country
                  ? registration.emp_country
                  : selectedCountry
                : selectedCountry
            }
            onChange={(e) =>
              // handleRegistration(e.currentTarget.name, e.currentTarget.value)
              handleCountryChange(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="country" selected disabled>
              Select Country
            </option>
            {countryStateCity?.countries?.map((data) => {
              return (
                <option key={data?.name} value={data?.iso2}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="state"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            State
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={registration?.emp_state ? registration?.emp_state : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="city"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            City
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_city"
            value={registration?.emp_city ? registration?.emp_city : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
      </section>
      <div
        className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark text-primaryTextDark"
          : "bg-primaryLight text-primaryTextLight"
          }`}
      >
        Work Details
      </div>
      <section className="grid grid-cols-4 gap-5">
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="empType"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Employee Type
          </label>
          <select
            // name="empType"
            id="empType"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_type"
            value={registration?.emp_type ? registration?.emp_type : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="country" selected disabled>
              Employee Type
            </option>
            <option value="fulltime">Full Time</option>
            <option value="intern">Intern</option>
            <option value="probation">Probation</option>
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="dept"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Department
          </label>
          <select
            // name="dept"
            id="dept"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_department"
            value={registration?.department?._id ? registration?.department?._id : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="dept" selected disabled>
              Select Department
            </option>
            {deptData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.dept_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="designation"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Designation
          </label>
          <select
            // name="designation"
            id="designation"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_designation"
            value={registration?.designation?._id ? registration?.designation?._id : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="designation" selected disabled>
              Select Designation
            </option>
            {desData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.des_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="teamName"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Team Name
          </label>
          <select
            // name="teamName"
            id="emp_team"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_team"
            value={registration?.team_name?._id ? registration?.team_name?._id : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="teamName" selected disabled>
              Select Team Name
            </option>
            {teamData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.team_name}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="workType"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Work type
          </label>
          <select
            // name="workType"
            id="workType"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="ProfessionalWorkType"
            value={registration?.emp_type ? registration?.emp_type : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="workType" selected disabled>
              Select Work Type
            </option>
            <option value="fulltime">In-Office</option>
            <option value="intern">Work from Home</option>
            <option value="probation">Hybrid</option>
          </select>
        </div> */}
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="dOfJoining"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Date of Joining
          </label>
          <input
            type="date"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_joingDate"
            value={registration?.joining_date ? registration?.joining_date : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="shiftTime"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Shift Time
          </label>
          <select
            // name="shiftTime"
            id="shiftTime"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_shiftTime"
            value={registration?.shift_time?._id ? registration?.shift_time?._id : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="shiftTime" selected disabled>
              Select Shift Time
            </option>
            {shiftData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.shift_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <h2
            htmlFor="workingDays"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Working Days
          </h2>
          <section>
            {workingdays?.map((day, index) => (
              <div className="multiSelect days" key={index}>
                <label>
                  <input
                    type="checkbox"
                    id={index}
                    name={day}
                    value={day}
                    checked={registration?.working_schedule?.includes(day)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setMultiResponse((prev) => [...prev, e.target.value]);
                      } else {
                        setMultiResponse((prev) =>
                          prev?.filter((value) => value !== e.target.value)
                        );
                      }
                    }}
                  />
                  <span>{day}</span>
                </label>
              </div>
            ))}
          </section>

        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="country"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Country
          </label>
          <select
            // name="country"
            id="country"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="emp_workCountry"
            value={
              registration
                ? registration?.country
                  ? registration.country
                  : selectedCountry
                : selectedCountry
            }
            onChange={(e) =>
              // handleRegistration(e.currentTarget.name, e.currentTarget.value)
              handleCountryChange(e.currentTarget.name, e.currentTarget.value)
            }
          >
            <option value="country" selected disabled>
              Select Country
            </option>
            {countryStateCity?.countries?.map((data) => {
              return (
                <option key={data?.name} value={data?.iso2}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="state"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            State
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
              name="emp_workCity"
              value={registration?.state ? registration?.state : ""}
              onChange={(e) =>
                handleRegistration(e.currentTarget.name, e.currentTarget.value)
              }
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="city"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            City
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            name="city"
            value={registration?.city ? registration?.city : ""}
            onChange={(e) =>
              handleRegistration(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </div>
      </section>
      <Button intent="primary" mode={mode} onClick={handleRegistrationEmployee}>
        Register
      </Button>
    </div>
  );
};

export default RegisterEmployee;
