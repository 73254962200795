/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect,useRef } from "react";
import axios from "axios";
import { LOCALHOST_BASE_URL  , token , apiKey } from "../utils/config";
import { ContextThemeProvider } from "../context/ThemeContext";
import logo from "../assets/logo.svg";
import { RiNotification2Fill } from "react-icons/ri";
import { HiBell } from "react-icons/hi";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsGrid1X2 } from "react-icons/bs";
import jwt_decode from "jwt-decode";
import SidebarMenu from "../components/SidebarMenu";
import dashIcon from "../assets/sidebarIcons/whitemodeicon/Dashboard.svg";
import dashIconDark from "../assets/sidebarIcons/blackmodeicon/Dashboard.svg";
import dailyReportIcon from "../assets/sidebarIcons/whitemodeicon/Daily Report.svg";
import dailyReportIconDark from "../assets/sidebarIcons/blackmodeicon/Daily Report.svg";
import attendanceIcon from "../assets/sidebarIcons/whitemodeicon/Attendance.svg";
import attendanceIconDark from "../assets/sidebarIcons/blackmodeicon/Attendance.svg";
import leaveIcon from "../assets/sidebarIcons/whitemodeicon/Leave.svg";
import leaveIconDark from "../assets/sidebarIcons/blackmodeicon/Leave.svg";
import employeesIcon from "../assets/sidebarIcons/whitemodeicon/Employees.svg";
import employeesIconDark from "../assets/sidebarIcons/blackmodeicon/Employees.svg";
import desigIcon from "../assets/sidebarIcons/whitemodeicon/Designation.svg";
import desigIconDark from "../assets/sidebarIcons/blackmodeicon/Designation.svg";
import deptIcon from "../assets/sidebarIcons/whitemodeicon/Departments.svg";
import deptIcondark from "../assets/sidebarIcons/blackmodeicon/Departments.svg";
import teamIcon from "../assets/sidebarIcons/whitemodeicon/Team.svg";
import teamIconDark from "../assets/sidebarIcons/blackmodeicon/Team.svg";
import offerLetterIcon from "../assets/sidebarIcons/whitemodeicon/Offer Latter.svg";
import offerLetterIcondark from "../assets/sidebarIcons/blackmodeicon/Offer Latter.svg";
import payslipIcon from "../assets/sidebarIcons/whitemodeicon/Payslips.svg";
import payslipIcondark from "../assets/sidebarIcons/blackmodeicon/Payslips.svg";
import payrollIcon from "../assets/sidebarIcons/whitemodeicon/Pay roll.svg";
import payrollIcondark from "../assets/sidebarIcons/blackmodeicon/Pay roll.svg";
import reimbursementIcon from "../assets/sidebarIcons/whitemodeicon/Reimbursement.svg";
import reimbursementIcondark from "../assets/sidebarIcons/blackmodeicon/Reimbursement.svg";
import timesheetIcon from "../assets/sidebarIcons/whitemodeicon/Time Sheet.svg";
import timesheetIcondark from "../assets/sidebarIcons/blackmodeicon/Time Sheet.svg";
import sAndsIcon from "../assets/sidebarIcons/whitemodeicon/Shift & Schedule.svg";
import sAndsIcondark from "../assets/sidebarIcons/blackmodeicon/Shift & Schedule.svg";
import assetsIcon from "../assets/sidebarIcons/whitemodeicon/Assets.svg";
import assetsIcondark from "../assets/sidebarIcons/blackmodeicon/Assets.svg";
import benefitsIcon from "../assets/sidebarIcons/whitemodeicon/Benefits.svg";
import benefitsIcondark from "../assets/sidebarIcons/blackmodeicon/Benefits.svg";
import policiesIcon from "../assets/sidebarIcons/whitemodeicon/Policies.svg";
import policiesIcondark from "../assets/sidebarIcons/blackmodeicon/Policies.svg";
import terminationIcon from "../assets/sidebarIcons/whitemodeicon/Termination.svg";
import terminationIcondark from "../assets/sidebarIcons/blackmodeicon/Termination.svg";
import resigIcon from "../assets/sidebarIcons/whitemodeicon/Resignation.svg";
import resigIcondark from "../assets/sidebarIcons/blackmodeicon/Resignation.svg";
import layOffIcon from "../assets/sidebarIcons/whitemodeicon/Lay offs.svg";
import layOffIcondark from "../assets/sidebarIcons/blackmodeicon/Lay offs.svg";
import settingsIcon from "../assets/sidebarIcons/whitemodeicon/Setting.svg";
import settingsIcondark from "../assets/sidebarIcons/blackmodeicon/Setting.svg";
import orgSetting from "../assets/sidebarIcons/whitemodeicon/Organisation Setting.svg";
import orgSettingdark from "../assets/sidebarIcons/blackmodeicon/Organisation Setting.svg";
import membersIcon from "../assets/sidebarIcons/whitemodeicon/Members.svg";
import membersIcondark from "../assets/sidebarIcons/blackmodeicon/Members.svg";
import announcementIcon from "../assets/sidebarIcons/whitemodeicon/Announcement.svg";
import announcementIcondark from "../assets/sidebarIcons/blackmodeicon/Announcement.svg";
import leaveCustomisationIcon from "../assets/sidebarIcons/whitemodeicon/Leave Customisation.svg";
import leaveCustomisationIcondark from "../assets/sidebarIcons/blackmodeicon/Leave Customisation.svg";
import themeIcon from "../assets/sidebarIcons/whitemodeicon/Theme.svg";
import themeIcondark from "../assets/sidebarIcons/blackmodeicon/Theme.svg";
import planIcon from "../assets/sidebarIcons/whitemodeicon/Plan.svg";
import planIcondark from "../assets/sidebarIcons/blackmodeicon/Plan.svg";
import payslipCustomIcon from "../assets/sidebarIcons/whitemodeicon/Payslips.svg";
import payslipCustomIcondark from "../assets/sidebarIcons/blackmodeicon/Payslips.svg";
import { useSnackbar } from "../context/SnackbarContext";
import { MdLogout } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";
import Modal from "../components/Modal";
import { logOut , getAuth} from "@mirats/mirats-auth";

const MainContainer = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const { color, mode, organisation, orgData } =
    useContext(ContextThemeProvider);
  const decodedToken = jwt_decode(token);

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [gasUser, setGasUser] = useState();

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionSelect = () => {
    setDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const getUser = async () => {
    let user = await getAuth();
    setGasUser(user.currentUser);
  };
  useEffect(() => {
    getUser();
  }, []);
  console.log("gasUser",gasUser?.access);

  const access = decodedToken?.access;

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const currentURL = window.location.href;

  const [todaysDate, setTodaysDate] = useState();

  // function to get todays date
  const getTodayDate = () => {
    const today = new Date();
    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  // all anniversary and birthdays
  const [allAnniAndBirthdays, setAllAnniAndBirthdays] = useState();
  console.log("allAnniAndBirthdays", allAnniAndBirthdays);

  // birthday work anniversary modal
  const [allBirthdaysAndWorkAnnModal, setAllBirthdaysAndWorkAnnModal] =
    useState(false);
  const openAllBirthdaysAndWorkAnnModal = () => {
    setAllBirthdaysAndWorkAnnModal((prev) => !prev);
  };

  const sidebarData = [
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? dashIconDark
          : dashIcon,
      name: "Dashboard",
      link: "/dashboard",
      disable: access?.dashboard,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? dailyReportIconDark
          : dailyReportIcon,
      name: "Daily Report",
      link: "/daily-report",
      disable: access?.dailyReport,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? attendanceIconDark
          : attendanceIcon,
      name: "Attendance",
      link: "/attendance",
      disable: access?.attendance,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? leaveIconDark
          : leaveIcon,
      name: "Leave",
      link: "/leave",
      disable: access?.leave,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? employeesIconDark
          : employeesIcon,
      name: "Employees",
      link: "/employees",
      disable: access?.organization,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? desigIconDark
          : desigIcon,
      name: "Designation",
      link: "/designation",
      disable: access?.designation,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? deptIcondark
          : deptIcon,
      name: "Departments",
      link: "/department",
      disable: access?.department,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? teamIconDark
          : teamIcon,
      name: "Team",
      link: "/team",
      disable: access?.team,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? offerLetterIcondark
          : offerLetterIcon,
      name: "Offer Letter",
      link: "/offer-letter",
      disable: access?.offerlatter,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? payslipIcondark
          : payslipIcon,
      name: "Payslips",
      link: "/payslip",
      disable: access?.payslips,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? payrollIcondark
          : payrollIcon,
      name: "Pay roll",
      link: "/pay-roll",
      disable: access?.payroll,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? reimbursementIcondark
          : reimbursementIcon,
      name: "Reimbursement",
      link: "/reimbursement",
      disable: access?.reimbursement,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? timesheetIcondark
          : timesheetIcon,
      name: "Time Sheet",
      link: "/timesheet",
      disable: access?.dailyReport,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? sAndsIcondark
          : sAndsIcon,
      name: "Shift & Schedule",
      link: "/shift-and-schedule",
      disable: access?.shiftSchedule,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? assetsIcondark
          : assetsIcon,
      name: "Assets",
      link: "/agreements",
      disable: access?.agreements,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? benefitsIcondark
          : benefitsIcon,
      name: "Benefits",
      link: "/benefits",
      disable: access?.benefits,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? policiesIcondark
          : policiesIcon,
      name: "Policies",
      link: "/policies",
      disable: access?.policy,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? terminationIcondark
          : terminationIcon,
      name: "Termination ",
      link: "/termination",
      disable: access?.termination,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? resigIcondark
          : resigIcon,
      name: "Resignation",
      link: "/resignation",
      disable: access?.resignation,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? layOffIcondark
          : layOffIcon,
      name: "Lay off",
      link: "/lay-off",
      disable: access?.layOff,
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? settingsIcondark
          : settingsIcon,
      name: "Settings",
      disable: access?.organization,
      subRoutes: [
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? orgSettingdark
              : orgSetting,
          name: "Organisation Setting",
          link: "/organisation-setting",
          disable: access?.organization,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? membersIcondark
              : membersIcon,
          name: "Members",
          link: "/members",
          disable: access?.member,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? announcementIcondark
              : announcementIcon,
          name: "Announcements",
          link: "/announcements",
          disable: access?.announcement,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? leaveCustomisationIcondark
              : leaveCustomisationIcon,
          name: "Leave Customisation",
          link: "/leave-customisation",
          disable: access?.leaveCustomisation,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? themeIcondark
              : themeIcon,
          name: "Theme",
          link: "/theme",
          disable: access?.organization,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? planIcondark
              : planIcon,
          name: "Plan",
          link: "/plan",
          disable: access?.organization,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? payslipCustomIcondark
              : payslipCustomIcon,
          name: "Payslip Customisation",
          link: "/payslip-customisation",
          disable: access?.paySlipCustomisation,
        },
      ],
    },
  ];

  const [announcement, setAnnouncement] = useState();
  const getAnnouncement = async () => {
  
    const url = `${LOCALHOST_BASE_URL}/announcement/get-all-announcement`;

    try {
      const response = await axios.get(url, {
        headers: {
          "api-key": apiKey,
        },
      });

      if (response.status === 200) {
        setAnnouncement(response.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          response.status
        );
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  const getAllBirthdaysAndWorkAnniversary = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getBirthdaysAnniversaries?data=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAllAnniAndBirthdays(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // profile popup
  const [popup, setPopup] = useState(false);
  const handlePopup = () => {
    setPopup((prev) => !prev);
  };

  const onClickOutsideListener = () => {
    setPopup((prev) => !prev);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const handleLogoutAdmin = () => {
    const deleteCookie = (name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
    const handleLogout = () => {
      logOut();
      deleteCookie("tokenpeoples");
    };
  
  };

  useEffect(() => {
    getAnnouncement();
    getAllBirthdaysAndWorkAnniversary();
  }, []);

  useEffect(() => {
    if (allAnniAndBirthdays?.length > 0) {
      console.log("Opening modal");
      openAllBirthdaysAndWorkAnnModal();
    }
  }, [allAnniAndBirthdays]);
  const accessSize = gasUser?.access?.length || 0;
  return (
    <div
      className={[
        "",
        organisation?.themeColor && `theme-${organisation?.themeColor}`,
        mode && `theme-${organisation?.organisationTheme?.name}`,
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {/* modal */}
      <Modal
        showModal={allBirthdaysAndWorkAnnModal}
        setShowModal={setAllBirthdaysAndWorkAnnModal}
      >
        {allAnniAndBirthdays?.length === 0 ? (
          <h1 className="p-5 text-[25px]">No record of todays date</h1>
        ) : (
          <>
            <div className={`rounded-lg py-[27px] px-[20px]`}>
              <h1 className="text-[#333] font-[700] text-[18px] mb-[32px] text-center">
                Today's Birthdays / Work Anniversary
              </h1>
              <div
                className={`rounded-lg py-[27px] px-[20px] flex gap-4 justify-center ${
                  allAnniAndBirthdays?.length === 4
                    ? "w-[790px]"
                    : allAnniAndBirthdays?.length === 1
                    ? "w-[492px] flex flex-col justify-center items-center"
                    : allAnniAndBirthdays?.length === 2
                    ? "w-[492px]"
                    : "max-[790px] w-[790px]"
                } `}
              >
                {allAnniAndBirthdays?.map((ann) => (
                  <section
                    className={`pt-2 w-[167px] bg h-[211px] bg-no-repeat bg-center flex flex-col items-center rounded-lg cardShadow`}
                  >
                    <div className="p-1 rounded-full border-dotted border-2 border-[#4A3AFF]">
                      <img
                        src={ann?.emp_profile}
                        alt=""
                        className="w-[78px] h-[78px] object-cover rounded-full"
                      />
                    </div>
                    <h1
                      title={ann?.emp_name}
                      className="font-[700] mt-1 text-center text-ellipsis whitespace-nowrap overflow-hidden w-[150px]"
                    >
                      {ann?.emp_name}
                    </h1>
                    <p className="text-[#3D4EFE] font-semibold text-[12px] my-[7px]">
                      {ann?.announcementType}
                    </p>
                    <p className="text-[#3D4EFE] text-[14px] font-semibold capitalize">
                      - {orgData?.organisationName} Team
                    </p>
                  </section>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>

      <header
        className={`pl-[26px] pr-[50px] border-b border-borderPrimary fixed top-0 z-1000 w-full ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-primaryDark"
            : "bg-white"
        } `}
      >
        <div className="flex justify-between items-center">
          <section className="flex items-center gap-4 py-[20px]">
            <img src={logo} alt="logo" />
            <h1
              className={`${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryTextLight"
              }`}
            >
              People’s Console
            </h1>
          </section>
          <section className="flex items-center h-[100%]">
          {accessSize > 1 ? (
          <div className="relative inline-block mx-4" ref={dropdownRef}>
      <button
        id="dropdownHoverButton"
        className="border text-black bg-white-700 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
        onClick={handleDropdownToggle}
      >
        Select Service to use
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isDropdownVisible && (
        <div
          id="dropdownHover"
          className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 mt-2 absolute top-full"
        >
          <ul className="py-2 text-sm text-gray-700 " aria-labelledby="dropdownHoverButton">
          {gasUser?.access.map((accessItem) => (
          <li key={accessItem._id}>
            <a
              href={accessItem.service.serviceURL}
              className="block px-4 py-2 hover:bg-gray-100"
              onClick={() => handleOptionSelect()}
            >
              {accessItem.service.name}
            </a>
          </li>
        ))}

          </ul>
        </div>
      )}
    </div>) :""}
            <div className="flex items-center gap-4 pl-8 py-2 border-l border-borderPrimary">
              <section
                className={`${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                <p className={`font-medium`}>{decodedToken?.emp_name}</p>
                <span className="text-[14px]">
                  {decodedToken?.emp_designation}
                </span>
              </section>
              {/* {decodedToken?.emp_profile ? (
                <Avatar type="img" src={decodedToken?.emp_profile} size="lg" />
              ) : (
                <Avatar size="md" type="text">
                  {decodedToken?.emp_name?.slice(0, 1)}
                </Avatar>
              )} */}
              <div className="relative">
                <section>
                  {decodedToken?.emp_profile ? (
                    <Avatar
                      type="img"
                      src={decodedToken?.emp_profile}
                      size="lg"
                      onClick={handlePopup}
                    />
                  ) : (
                    <Avatar size="md" type="text">
                      {decodedToken?.emp_name?.slice(0, 1)}
                    </Avatar>
                  )}
                </section>
                {popup === true ? (
                  <section
                    className="absolute right-0 bg-white w-[170px] shadow-sm rounded-lg"
                    onMouseLeave={() => {
                      document.addEventListener(
                        "click",
                        onClickOutsideListener
                      );
                    }}
                  >
                    {/* <Link
                      to=""
                      className="flex items-center gap-3 py-2 px-3 border-b hover:bg-primaryLight"
                    >
                      <Avatar
                        type="img"
                        src={decodedToken?.emp_profile}
                        size="sm"
                      />
                      <span>My Account</span>
                    </Link> */}
                    <Link
                      to="/"
                      className="flex items-center gap-3 py-2 px-3 border-b hover:bg-primaryLight"
                    >
                      <span>
                        <BiLinkExternal />
                      </span>{" "}
                      <span>Go to peoples</span>
                    </Link>
                    <h3
                      className="flex items-center gap-3 py-2 px-3 border-b cursor-pointer hover:bg-primaryLight"
                      onClick={()=>handleLogoutAdmin()}
                    >
                      <span>
                        <MdLogout />
                      </span>{" "}
                      <span>Log out</span>
                    </h3>
                  </section>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </header>
      <div className="flex sidebarContainer mt-[65px]">
        <section
          className={`w-[230px] h-[100vh] border-r shadow-sm pb-20 whitespace-nowrap fixed overflow-auto ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark text-white"
              : "bg-white"
          }`}
        >
          {sidebarData?.map((data, ind) => {
            if (data?.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  data={data}
                  isOpen={isOpen}
                  className="link"
                  activeClassName="active"
                />
              );
            }

            if (data?.disable === true) {
              return (
                <NavLink
                  to={data?.link}
                  className={({ isActive }) =>
                    isActive ? "active bg-primaryBg" : "text-primaryText"
                  }
                >
                  <section className="flex items-center gap-4 pl-[32px] py-[13px] hover:bg-primaryLight hover:text-primaryBg">
                    <img src={data?.icon} alt="" className="" />
                    <h1>{data?.name}</h1>
                    {data?.disable && <h1>{data.disable}</h1>}{" "}
                    {/* Conditionally display the disable property */}
                  </section>
                </NavLink>
              );
            }

            return null; // Don't render the link if it's disabled
          })}
        </section>

        <section className={`w-full h-full ml-[230px]`}>{children}</section>
      </div>
    </div>
  );
};

export default MainContainer;
