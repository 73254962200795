import React from "react";
import { MdClose } from "react-icons/md";

export default function Modal({ children, showModal, setShowModal }) {
  return (
    <>
      {showModal ? (
        <div className="w-full h-full bg-backdrop fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-[1000] overflow-hidden">
          <div className="relative bg-white shadow-boxShadow rounded-lg pt-0 pr-[32px] pb-[32px] pl-[32px] m-[32px] transition-all duration-700 ease-in-out">
            <div className="pt-[24px]">{children}</div>
            <div
              className="bg-[#F1F1F1] absolute top-4 right-4 w-6 h-6 z-10 flex justify-center items-center shadow-backdrop rounded-full text-primary cursor-pointer hover:bg-primaryLight"
              onClick={() => setShowModal((prev) => !prev)}
            >
              <MdClose />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
