/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { MdModeEdit, MdOutlineClose } from "react-icons/md";
import Button from "../../components/Button";
import { useSnackbar } from "../../context/SnackbarContext";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import jwt_decode from "jwt-decode";
import Modal from "../../components/Modal";
import { BsUpload } from "react-icons/bs";

const UserDetails = () => {
  const { mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const decodedToken = jwt_decode(token);

  console.log("decodedToken", decodedToken);

  // get date in 14 Oct 2023 format
  function formatDate1(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const [editPersonalDetails, setEditPersonalDetails] = useState({
    coverPic: false,
    profilePic: false,
    personalInfoEdit: false,
    financialInfoEdit: false,
    upiAccDetails: false,
    workDetails: false,
  });

  console.log("editPersonalDetails", editPersonalDetails);

  const [detailsData, setDetailsData] = useState({});
  console.log("detailsData", detailsData);

  const [oneEmployee, setOneEmployee] = useState({});
  console.log("oneEmployee", oneEmployee);

  const handleChange = (btnName, value) => {
    setEditPersonalDetails({ ...editPersonalDetails, [btnName]: value });
  };

  const [coverPicModal, setCoverPicModal] = useState(false);
  const openCoverPicModal = () => {
    setCoverPicModal((prev) => !prev);
  };

  const [profilePicModal, setProfilePicModal] = useState(false);
  const openProfilePicModal = () => {
    setProfilePicModal((prev) => !prev);
  };

  const [image, setImage] = useState(null);
  console.log("image", image);

  // profile pic
  const [profileImage, setProfileImage] = useState(null);
  console.log("profileImage", profileImage);

  //handleCoverPicChange
  const handleCoverPicChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // const profileImageFile = e.target.files[0];
    // setProfileImage(profileImageFile);
  };

  //handleProfilePicChange
  const handleProfilePicChange = (e) => {
    const profileImageFile = e.target.files[0];
    setProfileImage(profileImageFile);
  };

  // handleCoverPicUpload
  const handleCoverPicUpload = async () => {
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);

        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`File upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data?.viewURl);
        return data?.viewURl;
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      throw error;
    }
  };

  // handleProfilePicUpload
  const handleProfilePicUpload = async () => {
    try {
      console.log("handleProfilePicUpload called");

      if (profileImage) {
        const formData = new FormData();
        formData.append("file", profileImage);

        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`File upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data?.viewURl);
        return data?.viewURl;
      }
    } catch (error) {
      console.error("Error uploading profileImage:", error.message);
      throw error;
    }
  };

  // get one employee
  const getOneEmployee = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setOneEmployee(result?.data?.employee);
    } catch (error) {
      console.error("error", error);
    }
  };

  // update details
  const updateInfo = async () => {
    const apiKey = "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=";

    const emp_coverUrl = await handleCoverPicUpload();

    const emp_profile = await handleProfilePicUpload();

    const updatedData = {
      // emp_coverUrl: detailsData?.emp_coverUrl ? detailsData?.emp_coverUrl : oneEmployee?.emp_coverUrl,
      emp_coverUrl: emp_coverUrl,
      emp_profile: emp_profile,
      emp_name: detailsData?.emp_name
        ? detailsData.emp_name
        : oneEmployee?.emp_name,
      emp_dob: detailsData?.emp_dob
        ? detailsData?.emp_dob
        : oneEmployee?.emp_dob,
      emp_bloodGroup: detailsData?.emp_bloodGroup
        ? detailsData?.emp_bloodGroup
        : oneEmployee?.emp_bloodGroup,
      emp_gender: detailsData?.emp_gender
        ? detailsData?.emp_gender
        : oneEmployee?.emp_gender,
      emp_email: detailsData?.emp_email
        ? detailsData?.emp_email
        : oneEmployee?.emp_email,
      emp_alternativePhoneNo: detailsData?.emp_alternativePhoneNo
        ? detailsData?.emp_alternativePhoneNo
        : oneEmployee?.emp_alternativePhoneNo,
      emp_phoneNo: detailsData?.emp_phoneNo
        ? detailsData?.emp_phoneNo
        : oneEmployee?.emp_phoneNo,
      emp_state: detailsData?.emp_state
        ? detailsData?.emp_state
        : oneEmployee?.emp_state,
      emp_city: detailsData?.emp_city
        ? detailsData?.emp_city
        : oneEmployee?.emp_city,
      emp_pinCode: detailsData?.emp_pinCode
        ? detailsData?.emp_pinCode
        : oneEmployee?.emp_pinCode,
      emp_address: detailsData?.emp_address
        ? detailsData?.emp_address
        : oneEmployee?.emp_address,
      financial: {
        emp_bankName: detailsData?.financial?.emp_bankName
          ? detailsData?.financial?.emp_bankName
          : oneEmployee?.financial?.emp_bankName,
        emp_branch: detailsData?.financial?.emp_branch
          ? detailsData?.financial?.emp_branch
          : oneEmployee?.financial?.emp_branch,
        emp_accountName: detailsData?.financial?.emp_accountName
          ? detailsData?.financial?.emp_accountName
          : oneEmployee?.financial?.emp_accountName,
        emp_accountNumber: detailsData?.financial?.emp_accountNumber
          ? detailsData?.financial?.emp_accountNumber
          : oneEmployee?.financial?.emp_accountNumber,
        emp_ifsc: detailsData?.financial?.emp_ifsc
          ? detailsData?.financial?.emp_ifsc
          : oneEmployee?.financial?.emp_ifsc,
      },

      upi: {
        emp_upiHolderName: detailsData?.upi?.emp_upiHolderName
          ? detailsData?.upi?.emp_upiHolderName
          : oneEmployee?.upi?.emp_upiHolderName,
        emp_upiService: detailsData?.upi?.emp_upiService
          ? detailsData?.upi?.emp_upiService
          : oneEmployee?.upi?.emp_upiService,
        emp_upiId: detailsData?.upi?.emp_upiId
          ? detailsData?.upi?.emp_upiId
          : oneEmployee?.upi?.emp_upiId,
      },

      // financial: {
      //   emp_accountNumber: detailsData?.financial?.emp_accountNumber
      //     ? detailsData?.financial?.emp_accountNumber
      //     : oneEmployee?.financial?.emp_accountNumber,
      // },
    };
    console.log(updatedData);
    axios
      .put(
        `${LOCALHOST_BASE_URL}/employee/update-OtherEmployee?id=${decodedToken?.id}`,
        updatedData,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        getOneEmployee();
        console.log("Details Updated");
        showSnackbar("Details Updated.", "green");
        setCoverPicModal(false);
        setProfilePicModal(false);
      })
      .catch((error) => {
        console.error("error", error);
        showSnackbar("Something went wrong, please try again.", "red");
      });
  };

  const handleSaveButtonClick = async () => {
    await handleCoverPicUpload();
    updateInfo();
  };

  const handleSaveButtonClickProfilePic = async () => {
    await handleProfilePicUpload();
    updateInfo();
  };

  useEffect(() => {
    getOneEmployee();
  }, []);

  useEffect(() => {
    setDetailsData(oneEmployee);
  }, [oneEmployee]);

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] h-full ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      {/* upload coverpic modal */}
      <Modal showModal={coverPicModal} setShowModal={setCoverPicModal}>
        <div className="w-[361px]">
          <h2 className="mb-3 text-center">Cover Pic</h2>
          <div class="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="text-primaryBg">
                  <BsUpload />
                </span>
                <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                  Upload Cover Pic
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                onChange={handleCoverPicChange}
              />
            </label>
          </div>
          <div className="">
            {image !== null ? (
              <>
                <p className={`auto text-green-500 text-[14px]`}>
                  Cover Pic Uploaded
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <section className="mt-8 flex justify-center items-center gap-4">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => setImage(null)}
            >
              Clear
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={handleSaveButtonClick}
            >
              Save
            </Button>
          </section>
        </div>
      </Modal>

      {/* upload profile pic */}
      <Modal showModal={profilePicModal} setShowModal={setProfilePicModal}>
        <div className="w-[361px]">
          <h2 className="mb-3 text-center">Profile Pic</h2>
          <div class="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="text-primaryBg">
                  <BsUpload />
                </span>
                <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                  Upload Profile Picture
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                onChange={handleProfilePicChange}
              />
            </label>
          </div>
          <div className="">
            {profileImage !== null ? (
              <>
                <p className={`auto text-green-500 text-[14px]`}>
                  Profile Picture Uploaded
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <section className="mt-8 flex justify-center items-center gap-4">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => profileImage(null)}
            >
              Clear
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={handleSaveButtonClickProfilePic}
            >
              Save
            </Button>
          </section>
        </div>
      </Modal>

      <div className="w-full h-[276px] rounded-lg border border-primaryText">
        <section className="absolute top-[15%] right-[5%]">
          {editPersonalDetails?.coverPic ? (
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => {
                handleChange("coverPic", false);
              }}
            >
              <p className="flex items-center gap-1 text-[16px]">
                {" "}
                <MdOutlineClose size={20} /> Close
              </p>
            </Button>
          ) : (
            <Button
              intent="primary"
              mode={mode}
              onClick={() => {
                handleChange("coverPic", true);
                openCoverPicModal();
              }}
            >
              <p className="flex items-center gap-1 text-[16px]">
                <MdModeEdit size={20} /> Edit
              </p>
            </Button>
          )}
        </section>
        <img
          src={oneEmployee?.emp_coverUrl}
          alt="cover pic"
          className="w-full h-full object-contain  rounded-lg"
        />
      </div>
      <section className="mb-5 ml-4 mt-[2em] flex justify-between items-center text-primaryText">
        <div className="flex gap-5 items-end text-primaryText">
          <img
            src={oneEmployee?.emp_profile}
            alt="emp_profile"
            className="w-[200px] h-[200px] object-cover rounded-full border"
          />
          <section className="mb-4">
            <h2 className="text-[24px] font-medium">{oneEmployee?.emp_name}</h2>
            <h3>{oneEmployee?.emp_email}</h3>
          </section>
        </div>
        {editPersonalDetails?.profilePic ? (
          <Button
            intent="secondary"
            mode={mode}
            onClick={() => {
              handleChange("profilePic", false);
            }}
          >
            <p className="flex items-center gap-1 text-[16px]">
              {" "}
              <MdOutlineClose size={20} /> Close
            </p>
          </Button>
        ) : (
          <Button
            intent="primary"
            mode={mode}
            onClick={() => {
              handleChange("profilePic", true);
              openProfilePicModal();
            }}
          >
            <p className="flex items-center gap-1 text-[16px]">
              <MdModeEdit size={20} /> Edit
            </p>
          </Button>
        )}
      </section>

      <section className="flex justify-between items-center px-[24px] py-[8px] bg-primaryLight border">
        <h1 className="font-[500] text-primaryText text-[20px]">
          Personal Info
        </h1>
        {editPersonalDetails?.personalInfoEdit ? (
          <Button
            intent="secondary"
            mode={mode}
            onClick={() => {
              handleChange("personalInfoEdit", false);
            }}
          >
            <p className="flex items-center gap-1 text-[16px]">
              {" "}
              <MdOutlineClose size={20} /> Close
            </p>
          </Button>
        ) : (
          <Button
            intent="primary"
            mode={mode}
            onClick={() => handleChange("personalInfoEdit", true)}
          >
            <p className="flex items-center gap-1 text-[16px]">
              <MdModeEdit size={20} /> Edit
            </p>
          </Button>
        )}
      </section>

      <section className="mb-5 border">
        <div className="grid grid-cols-3 gap-5 p-6">
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Full Name :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_name"
                value={detailsData?.emp_name}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_name: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_name}
              </p>
            )}
          </section>
          {/* dob */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Date of Birth :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_dob"
                value={formatDate1(detailsData?.emp_dob)}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_dob: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {formatDate1(oneEmployee?.emp_dob)}
              </p>
            )}
          </section>
          {/* blood group */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Blood Group :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_bloodGroup"
                value={detailsData?.emp_bloodGroup}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_bloodGroup: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_bloodGroup}
              </p>
            )}
          </section>
          {/* gender */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Gender
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_gender"
                value={detailsData?.emp_gender}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_gender: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_gender}
              </p>
            )}
          </section>
          {/* email */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Email ID :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_email"
                value={detailsData?.emp_email}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_email: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_email}
              </p>
            )}
          </section>
          {/* alternative contact no */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Alternative Contact No :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_alternativePhoneNo"
                value={detailsData?.emp_alternativePhoneNo}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_alternativePhoneNo: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_alternativePhoneNo}
              </p>
            )}
          </section>
          {/* contact no */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Contact No :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_phoneNo"
                value={detailsData?.emp_phoneNo}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_phoneNo: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_phoneNo}
              </p>
            )}
          </section>
          {/* state */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              State
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_workState"
                value={detailsData?.emp_workState}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_workState: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_workState}
              </p>
            )}
          </section>
          {/* city */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              City :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_city"
                value={detailsData?.emp_city}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_city: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_city}
              </p>
            )}
          </section>
          {/* pincode */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Pincode :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_pinCode"
                value={detailsData?.emp_pinCode}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_pinCode: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_pinCode}
              </p>
            )}
          </section>
          {/* address */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Address :
            </p>
            {editPersonalDetails?.personalInfoEdit ? (
              <input
                name="emp_address"
                value={detailsData?.emp_address}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    emp_address: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.emp_address}
              </p>
            )}
          </section>
        </div>
        {editPersonalDetails?.personalInfoEdit ? (
          <section className="flex justify-center items-cente gap-5 mt-5">
            <Button intent="secondary" mode={mode}>
              Clear
            </Button>
            <Button intent="primary" mode={mode} onClick={updateInfo}>
              Save
            </Button>
          </section>
        ) : (
          <></>
        )}
      </section>

      {/* financial details */}
      <section className="flex justify-between items-center px-[24px] py-[8px] bg-primaryLight border">
        <h1 className="font-[500] text-primaryText text-[20px]">
          Financial Details
        </h1>
        {editPersonalDetails?.financialInfoEdit ? (
          <Button
            intent="secondary"
            mode={mode}
            onClick={() => {
              handleChange("financialInfoEdit", false);
            }}
          >
            <p className="flex items-center gap-1 text-[16px]">
              {" "}
              <MdOutlineClose size={20} /> Close
            </p>
          </Button>
        ) : (
          <Button
            intent="primary"
            mode={mode}
            onClick={() => handleChange("financialInfoEdit", true)}
          >
            <p className="flex items-center gap-1 text-[16px]">
              <MdModeEdit size={20} /> Edit
            </p>
          </Button>
        )}
      </section>

      <section className="mb-5 border">
        <div className="grid grid-cols-3 gap-5 p-6">
          {/* bank name */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Bank Name :
            </p>
            {editPersonalDetails?.financialInfoEdit ? (
              <input
                name="emp_bankName"
                value={
                  detailsData?.financial?.emp_bankName ||
                  oneEmployee?.financial?.emp_bankName ||
                  ""
                }
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    financial: {
                      ...detailsData?.financial,
                      emp_bankName: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.financial?.emp_bankName}
              </p>
            )}
          </section>
          {/* bank branch */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Branch :
            </p>
            {editPersonalDetails?.financialInfoEdit ? (
              <input
                name="emp_branch"
                value={detailsData?.financial?.emp_branch}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    financial: {
                      ...detailsData?.financial,
                      emp_branch: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.financial?.emp_branch}
              </p>
            )}
          </section>
          {/* acc holder name */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Account Holder Name :
            </p>
            {editPersonalDetails?.financialInfoEdit ? (
              <input
                name="emp_accountName"
                value={detailsData?.financial?.emp_accountName}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    financial: {
                      ...detailsData?.financial,
                      emp_accountName: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.financial?.emp_accountName}
              </p>
            )}
          </section>
          {/* emp_accountNumber */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Account Number :
            </p>
            {editPersonalDetails?.financialInfoEdit ? (
              <input
                name="emp_accountNumber"
                value={detailsData?.financial?.emp_accountNumber}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    financial: {
                      ...detailsData?.financial,
                      emp_accountNumber: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.financial?.emp_accountNumber}
              </p>
            )}
          </section>
          {/* emp_ifsc */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              IFSC Code :
            </p>
            {editPersonalDetails?.financialInfoEdit ? (
              <input
                name="emp_ifsc"
                value={detailsData?.financial?.emp_ifsc}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    financial: {
                      ...detailsData?.financial,
                      emp_ifsc: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.financial?.emp_ifsc}
              </p>
            )}
          </section>
        </div>
        {editPersonalDetails?.financialInfoEdit ? (
          <section className="flex justify-center items-cente gap-5 mt-5">
            <Button intent="secondary" mode={mode}>
              Clear
            </Button>
            <Button intent="primary" mode={mode} onClick={updateInfo}>
              Save
            </Button>
          </section>
        ) : (
          <></>
        )}
      </section>

      {/* upi details */}
      <section className="flex justify-between items-center px-[24px] py-[8px] bg-primaryLight border">
        <h1 className="font-[500] text-primaryText text-[20px]">
          UPI ID Account Details
        </h1>
        {editPersonalDetails?.upiAccDetails ? (
          <Button
            intent="secondary"
            mode={mode}
            onClick={() => {
              handleChange("upiAccDetails", false);
            }}
          >
            <p className="flex items-center gap-1 text-[16px]">
              {" "}
              <MdOutlineClose size={20} /> Close
            </p>
          </Button>
        ) : (
          <Button
            intent="primary"
            mode={mode}
            onClick={() => handleChange("upiAccDetails", true)}
          >
            <p className="flex items-center gap-1 text-[16px]">
              <MdModeEdit size={20} /> Edit
            </p>
          </Button>
        )}
      </section>

      <section className="mb-5 border">
        <div className="grid grid-cols-3 gap-5 p-6">
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              UPI ID Account Name :
            </p>
            {editPersonalDetails?.upiAccDetails ? (
              <input
                name="emp_upiHolderName"
                value={detailsData?.upi?.emp_upiHolderName}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    upi: {
                      ...detailsData?.upi,
                      emp_upiHolderName: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.upi?.emp_upiHolderName}
              </p>
            )}
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Payment Service :
            </p>
            {editPersonalDetails?.upiAccDetails ? (
              <input
                name="emp_upiService"
                value={detailsData?.upi?.emp_upiService}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    upi: {
                      ...detailsData?.upi,
                      emp_upiService: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.upi?.emp_upiService}
              </p>
            )}
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              UPI Id :
            </p>
            {editPersonalDetails?.upiAccDetails ? (
              <input
                name="emp_upiId"
                value={detailsData?.upi?.emp_upiId}
                onChange={(e) =>
                  setDetailsData({
                    ...detailsData,
                    upi: {
                      ...detailsData?.upi,
                      emp_upiId: e.target.value,
                    },
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] py-2 text-[18px] text-primaryText">
                {oneEmployee?.upi?.emp_upiId}
              </p>
            )}
          </section>
        </div>
        {editPersonalDetails?.upiAccDetails ? (
          <section className="flex justify-center items-cente gap-5 mt-5">
            <Button intent="secondary" mode={mode}>
              Clear
            </Button>
            <Button intent="primary" mode={mode} onClick={updateInfo}>
              Save
            </Button>
          </section>
        ) : (
          <></>
        )}
      </section>

      {/* work details */}
      <section className="flex justify-between items-center px-[24px] py-[8px] bg-primaryLight border">
        <h1 className="font-[500] text-primaryText text-[20px]">
          Work Details
        </h1>
      </section>

      <section className="mb-5 border">
        <div className="grid grid-cols-3 gap-5 p-6">
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Job Role :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {oneEmployee?.emp_designation?.des_name || ""}
            </p>
          </section>
          {/* <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Grade :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              grade
            </p>
          </section> */}
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Date of Joining :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {formatDate1(oneEmployee?.emp_joingDate)}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Department :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {oneEmployee?.emp_department?.dept_name}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Shift Time :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {/* {oneEmployee?.emp_shiftTime?.start_time} -
              {oneEmployee?.emp_shiftTime?.end_time} */}
              {oneEmployee?.emp_shiftTime?.shift_name}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Work Location :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {oneEmployee?.emp_workCity}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Team Name :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {oneEmployee?.emp_team?.team_name}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Team Leader Name :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {oneEmployee?.emp_team?.team_leader}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Team Leader Contact :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {/* update */}
            </p>
          </section>
          <section>
            <p className="text-[18px] text-primaryText mb-[2px] font-[600]">
              Team Leader Email :
            </p>
            <p className="font-[500] py-2 text-[18px] text-primaryText">
              {/* update */}
            </p>
          </section>
        </div>
      </section>
    </div>
  );
};

export default UserDetails;
