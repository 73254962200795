/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , token , apiKey } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const CreateOfferLetterTmp = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { offerLetterTmpId } = useParams();

  // get one agreement
  const [oneAgreement, setOneAgreement] = useState();
  console.log("oneAgreement", oneAgreement);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [agreementData, setAgreementData] = useState({
    letterBody: "",
    date: "",
    offerLetterHeading: "",
    employmentType: "",
    description: "",
  });

  console.log("agreementData", agreementData);

  const handleOnChange = (name, value) => {
    setAgreementData((prev) => ({ ...prev, [name]: value }));
  };

  //   add Offer Letter Temp
  const handleAddOfferLetterTemp = async () => {
    const headers = {
      "api-key": apiKey,
      Authorization: `Bearer ${token}`,
    };

    try {
      axios
        .post(
          `${LOCALHOST_BASE_URL}/offerLetterTemp/add-offerLetterTemp`,
          {
            offerLetterHeading: agreementData?.offerLetterHeading,
            date: agreementData?.date,
            employmentType: agreementData?.employmentType,
            letterBody: agreementData?.letterBody,
            description: agreementData?.description,
          },
          { headers }
        )
        .then((response) => {
          console.log("Response data:", response.data);
          console.log("Agreement added");
          navigate(`/offer-letter`);
        })
        .catch((error) => {
          console.log("Error:", error);
          showSnackbar("Something is wrong, please try again.", "red");
        });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // get one Offer Letter Temp
  const getOneOfferLetterTemp = async () => {
  

    // Define your headers with API key
    const headers = {
      "api-key": apiKey,
    };

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetterTemp/get-offerLetterTemp?id=${offerLetterTmpId}`,
        { headers }
      );
      if (result) {
        setAgreementData((prevData) => ({
          ...prevData,
          edit: true,
          offerLetterHeading: result?.data?.offerLetterHeading,
          date: result?.data?.date,
          employmentType: result?.data?.employmentType,
          letterBody: result?.data?.letterBody,
          description: result?.data?.description,
        }));
        setOneAgreement(result?.data);
      }
    } catch (error) {
      console.error("Error fetching one agreement:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // update Offer Letter Temp
  const handleUpdateOfferLetterTemp = async () => {

    const headers = {
      "api-key": apiKey,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/offerLetterTemp/update-offerLetterTemp?id=${offerLetterTmpId}`,
        {
          offerLetterHeading: agreementData?.offerLetterHeading,
          date: agreementData?.date,
          employmentType: agreementData?.employmentType,
          letterBody: agreementData?.letterBody,
          description: agreementData?.description,
        },
        { headers }
      );
      console.log("response data", response?.data);
      console.log("agreement updated");
      navigate(`/offer-letter`);
    } catch (error) {
      console.log("error", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // add pages
  // const [pages, setPages] = useState([]);
  // const [activePage, setActivePage] = useState(0);
  // console.log("activePage", activePage);

  // const addNewPage = () => {
  //   const newPage = {
  //     offerLetterHeading: agreementData?.offerLetterHeading,
  //     date: agreementData?.date,
  //     employmentType: agreementData?.employmentType,
  //     companyName: agreementData?.companyName,
  //     companyLocation: agreementData?.companyLocation,
  //     assetSummary: agreementData?.assetSummary,
  //     assetBody: agreementData?.assetBody,
  //     variableTempBody: agreementData?.variableTempBody,
  //   };
  //   setPages([...pages, newPage]);
  // };

  // const deletePage = (index) => {
  //   const updatedPages = [...pages];
  //   updatedPages.splice(index, 1);
  //   setPages(updatedPages);
  // };

  // useEffect(() => {
  //   const initialPage = {
  //     offerLetterHeading: "",
  //     date: "",
  //     employmentType: "",
  //     description: "",
  //     letterBody: "",
  //   };
  //   setPages([initialPage]);
  // }, []);

  useEffect(() => {
    if (offerLetterTmpId) {
      getOneOfferLetterTemp();
    }
  }, [offerLetterTmpId]);

  const fullTimeTempBody = [
    {
      variableName: "Employee Name",
      variableValue: "[%%emp_name%%]",
    },
    {
      variableName: "Department",
      variableValue: "[%%department%%]",
    },
    {
      variableName: "Designation",
      variableValue: "[%%designation%%]",
    },
    {
      variableName: "Team Name",
      variableValue: "[%%team_name%%]",
    },
    {
      variableName: "Report To",
      variableValue: "[%%report_to%%]",
    },
    {
      variableName: "Date of Joining",
      variableValue: "[%%date_joining%%]",
    },
    {
      variableName: "Shift Time",
      variableValue: "[%%shift_time%%]",
    },
    {
      variableName: "Working Schedule",
      variableValue: "[%%working_schedule%%]",
    },
    {
      variableName: "Gross Salary",
      variableValue: "[%%gross_salary%%]",
    },
    {
      variableName: "Sign Date",
      variableValue: "[%%sign_date%%]",
    },
    {
      variableName: "PF Amount",
      variableValue: "[%%pf%%]",
    },
    {
      variableName: "Work Country",
      variableValue: "[%%country%%]",
    },
    {
      variableName: "Work State",
      variableValue: "[%%state%%]",
    },
    {
      variableName: "Work City",
      variableValue: "[%%city%%]",
    },
  ];

  const internTempBody = [
    {
      variableName: "Employee Name",
      variableValue: "[%%emp_name%%]",
    },
    {
      variableName: "Department",
      variableValue: "[%%department%%]",
    },
    {
      variableName: "Designation",
      variableValue: "[%%designation%%]",
    },
    {
      variableName: "Team Name",
      variableValue: "[%%team_name%%]",
    },
    {
      variableName: "Report To",
      variableValue: "[%%report_to%%]",
    },
    {
      variableName: "Start Date",
      variableValue: "[%%start_date%%]",
    },
    {
      variableName: "End Date",
      variableValue: "[%%end_date%%]",
    },
    {
      variableName: "Shift Time",
      variableValue: "[%%shift_time%%]",
    },
    {
      variableName: "Working Schedule",
      variableValue: "[%%working_schedule%%]",
    },
    {
      variableName: "Stipend",
      variableValue: "[%%stipend%%]",
    },
    {
      variableName: "Sign Date",
      variableValue: "[%%sign_date%%]",
    },
    {
      variableName: "Work Country",
      variableValue: "[%%country%%]",
    },
    {
      variableName: "Work State",
      variableValue: "[%%state%%]",
    },
    {
      variableName: "Work City",
      variableValue: "[%%city%%]",
    },
  ];

  const probationTempBody = [
    {
      variableName: "Employee Name",
      variableValue: "[%%emp_name%%]",
    },
    {
      variableName: "Department",
      variableValue: "[%%department%%]",
    },
    {
      variableName: "Designation",
      variableValue: "[%%designation%%]",
    },
    {
      variableName: "Team Name",
      variableValue: "[%%team_name%%]",
    },
    {
      variableName: "Report To",
      variableValue: "[%%report_to%%]",
    },
    {
      variableName: "Date of Joining",
      variableValue: "[%%date_joining%%]",
    },
    {
      variableName: "Shift Time",
      variableValue: "[%%shift_time%%]",
    },
    {
      variableName: "Working Schedule",
      variableValue: "[%%working_schedule%%]",
    },
    {
      variableName: "Months Worked",
      variableValue: "[%%months_worked%%]",
    },
    {
      variableName: "Gross Salary",
      variableValue: "[%%gross_salary%%]",
    },
    {
      variableName: "Notice Period",
      variableValue: "[%%notice_period%%]",
    },
    {
      variableName: "Sign Date",
      variableValue: "[%%sign_date%%]",
    },
    {
      variableName: "PF Amount",
      variableValue: "[%%pf%%]",
    },

    {
      variableName: "Work Country",
      variableValue: "[%%country%%]",
    },
    {
      variableName: "Work State",
      variableValue: "[%%state%%]",
    },
    {
      variableName: "Work City",
      variableValue: "[%%city%%]",
    },
  ];

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/offer-letter"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px]"
      >
        <MdOutlineKeyboardBackspace />
        {agreementData?.edit ? "Edit" : "New"} Offer letter Layout
      </Link>
      <div className="flex gap-4">
        <div className="w-[60%]">
          <section className="w-[100%] h-auto bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              <div>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{agreementData.companyName}</span>
                  <span>Date : {formatDate(agreementData.date)}</span>
                </h1>
                <h2 className="text-[#333]">
                  {agreementData.offerLetterHeading}
                </h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: agreementData?.letterBody,
                  }}
                ></div>
              </div>
            </div>
          </section>
        </div>
        <section className="w-[40%] h-[945px]">
          <section className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="offerLetterHeading"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Offer letter Heading
              </label>
              <input
                type="text"
                name="offerLetterHeading"
                value={agreementData?.offerLetterHeading}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("offerLetterHeading", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="description"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                value={agreementData?.description}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("description", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="date"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Date
              </label>
              <input
                type="date"
                name="date"
                value={agreementData?.date}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("date", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="employmentType"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Employment type
              </label>
              <select
                name="employmentType"
                id="employmentType"
                value={agreementData?.employmentType}
                className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("employmentType", e.target.value);
                }}
              >
                <option value="employmentType" selected disabled>
                  Select Employee Type
                </option>
                <option value="fulltime">Full Time</option>
                <option value="intern">Intern</option>
                <option value="probation">Probation</option>
              </select>
            </div>
          </section>
          <section className="my-4">
            <h2 className="text-[14px] font-semibold text-primaryText">
              Use this variable in template Body
            </h2>
            <div className="my-4">
              <section className="grid grid-cols-2 gap-4 mb-1 ">
                <h3 className="px-4 text-primaryText text-[14px]">
                  Variable Name
                </h3>
                <h3 className="px-4 text-primaryText text-[14px]">Variable</h3>
              </section>
              <section className="">
                {agreementData?.employmentType === "fulltime" &&
                fullTimeTempBody
                  ? fullTimeTempBody.map((item, i) => (
                      <div key={i}>
                        <section className="grid grid-cols-2 gap-4">
                          <input
                            id="variableName"
                            placeholder="variable name"
                            name="variableName"
                            disabled
                            value={item?.variableName}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus:border-primaryBg`}
                          />
                          <input
                            id="variableValue"
                            placeholder="variable value"
                            name="variableValue"
                            disabled
                            value={item?.variableValue}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                        </section>
                      </div>
                    ))
                  : agreementData?.employmentType === "intern" && internTempBody
                  ? internTempBody.map((item, i) => (
                      <div key={i}>
                        <section className="grid grid-cols-2 gap-4">
                          <input
                            id="variableName"
                            placeholder="variable name"
                            name="variableName"
                            disabled
                            value={item?.variableName}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                          <input
                            id="variableValue"
                            placeholder="variable value"
                            name="variableValue"
                            disabled
                            value={item?.variableValue}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                        </section>
                      </div>
                    ))
                  : agreementData?.employmentType === "probation" &&
                    probationTempBody
                  ? probationTempBody.map((item, i) => (
                      <div key={i}>
                        <section className="grid grid-cols-2 gap-4">
                          <input
                            id="variableName"
                            placeholder="variable name"
                            name="variableName"
                            disabled
                            value={item?.variableName}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                          <input
                            id="variableValue"
                            placeholder="variable value"
                            name="variableValue"
                            disabled
                            value={item?.variableValue}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                        </section>
                      </div>
                    ))
                  : // Default case or any other condition
                    fullTimeTempBody.map((item, i) => (
                      <div key={i}>
                        <section className="grid grid-cols-2 gap-4">
                          <input
                            id="variableName"
                            placeholder="variable name"
                            name="variableName"
                            disabled
                            value={item?.variableName}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus:border-primaryBg`}
                          />
                          <input
                            id="variableValue"
                            placeholder="variable value"
                            name="variableValue"
                            disabled
                            value={item?.variableValue}
                            readOnly
                            className={`px-4 py-2 mb-1 text-[12px] text-primaryText rounded outline-none focus.border-primaryBg`}
                          />
                        </section>
                      </div>
                    ))}
              </section>
            </div>

            <div className="">
              <SunEditor
                showToolbar={true}
                onChange={(content) =>
                  setAgreementData((prevData) => ({
                    ...prevData,
                    letterBody: content,
                  }))
                }
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "table"],
                    ["link", "image", "video"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                  ],
                }}
                setContents={agreementData.letterBody}
                height="200px"
              />
            </div>
          </section>
        </section>
      </div>
      <div className="flex items-center justify-center mt-8 gap-5">
        <Button
          intent="primary"
          mode={mode}
          onClick={
            agreementData?.edit
              ? handleUpdateOfferLetterTemp
              : handleAddOfferLetterTemp
          }
        >
          Save
        </Button>
        <Button intent="secondary" mode={mode}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default CreateOfferLetterTmp;
