import { cva } from "class-variance-authority";
import React from "react";

const AvatarVariants = cva(
  "flex justify-center items-center font-semibold rounded-full text-primaryBg bg-primaryLight object-cover",
  {
    variants: {
      size: {
        sm: ["w-[16px]", "h-[16px]", "text-[8px]"],
        md: ["w-[20px]", "h-[20px]", "text-[11px]"],
        lg: ["w-[50px]", "h-[50px]", "text-[20px]"],
        xlg: ["w-[70px]", "h-[70px]", "text-[35px]"],
        xxlg: ["w-[113px]", "h-[113px]", "text-[45px]"],
      },
    },
  }
);

export default function Avatar({
  type,
  children,
  size,
  src,
  onClick,
  ...restProps
}) {
  return (
    <div>
      {type === "text" ? (
        <div
          {...restProps}
          onClick={(e) => onClick(e)}
          className={`border border-primaryBg ${AvatarVariants({ size })}`}
        >
          {children}
        </div>
      ) : type === "img" ? (
        <div
          className="avatarImgContainer"
          type="img"
          onClick={(e) => onClick(e)}
        >
          <img
            className={`${AvatarVariants({ size })}`}
            src={src}
            alt="avatar"
          />
        </div>
      ) : (
        <div
          {...restProps}
          onClick={(e) => onClick(e)}
          className={`border border-primaryBg ${AvatarVariants({ size })}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}
