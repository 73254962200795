/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link, useNavigate, useParams } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import { useContext, useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Button from "../components/Button";
import axios from "axios";
import { LOCALHOST_BASE_URL , token , apiKey} from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const CreateBenefits = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const { benefitId } = useParams();
  console.log("benefitId", benefitId);
  const navigate = useNavigate();

  // get one benefit
  const [oneBenefit, setOneBenefit] = useState();
  console.log("oneBenefit", oneBenefit);

  const [benefitData, setBenefitData] = useState({
    edit: false,
    benefit_heading: "",
    date: "",
    benefitDesc: "",
    benefitBody: "",
  });
  console.log("benefitData", benefitData);

  const handleOnChange = (name, value) => {
    setBenefitData((prev) => ({ ...prev, [name]: value }));
  };

  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const getOneBenefit = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/benefit/get-one-benefit?id=${benefitId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setBenefitData(result?.data);
      } else {
        console.log("Error fetching one benefit");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error("Error fetching one benefit:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  useEffect(() => {
    if (benefitId) {
      getOneBenefit();
    }
  }, [benefitId]);

  console.log("Here is the data from benefit", benefitData);
  console.log("Here is the data one benefit", oneBenefit);

  // add benefit
  const handleAddBenefit = async () => {
    const headers = {
      "api-key": apiKey,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${LOCALHOST_BASE_URL}/benefit/add-benefit`,
        {
          benefit_heading: benefitData?.benefit_heading,
          date: benefitData?.date,
          benefit_description: benefitData?.benefit_description,
          benefitBody: benefitData?.benefitBody,
        },
        { headers }
      );

      console.log("Response data:", response?.data);
      console.log("Benefit added");
      navigate(`/benefits`);
    } catch (error) {
      console.log("Error:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // update benefits
  const handleUpdateBenefit = async () => {
    const headers = {
      "api-key": apiKey,
      Authorization: `Bearer ${token}`,
    };

    try {
      console.log("Here inside");
      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/benefit/update-benefit?id=${benefitId}`,
        {
          ...benefitData,
        },
        { headers }
      );

      console.log("Response data:", response?.data);
      console.log("Benefit updated");
      navigate(`/benefits`);
    } catch (error) {
      console.log("Error:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  const addUpdateBenefit = () => {
    if (benefitId) {
      handleUpdateBenefit();
    } else {
      handleAddBenefit();
    }
  };

  // add pages
  // const [pages, setPages] = useState([]);
  // const [activePage, setActivePage] = useState(0);
  // console.log("activePage", activePage);

  // const addNewPage = () => {
  //   const newPage = {
  //     companyName: benefitData.companyName,
  //     date: benefitData.date,
  //     benefit_heading: benefitData.benefit_heading,
  //     benefit_description: benefitData.benefit_description,
  //     benefitBody: benefitData.benefitBody,
  //   };
  //   setPages([...pages, newPage]);
  // };

  // const deletePage = (index) => {
  //   const updatedPages = [...pages];
  //   updatedPages.splice(index, 1);
  //   setPages(updatedPages);
  // };

  // useEffect(() => {
  //   const initialPage = {
  //     companyName: "",
  //     date: "",
  //     benefit_heading: "",
  //     benefit_description: "",
  //     benefitBody: "",
  //   };
  //   setPages([initialPage]);
  // }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/benefits"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px]"
      >
        <MdOutlineKeyboardBackspace />
        {benefitId ? "Edit" : "New"} Benefits Layout
      </Link>
      <div className="flex gap-4">
        {/* <section className="w-[60%] h-[842px] bg-white shadow-sm">
          <div className="px-[66px] py-[26px]">
            {benefitId ? ( // Check if editing an existing entry
              <>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{oneBenefit?.companyName}</span>
                  <span>Date : {formatDate(oneBenefit?.date)}</span>
                </h1>
                <h2 className="text-[#333]">{oneBenefit?.benefit_heading}</h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{ __html: oneBenefit?.benefitBody }}
                ></div>
              </>
            ) : (
              // Show form data when creating a new entry
              <>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{benefitData?.companyName}</span>
                  <span>Date : {formatDate(benefitData?.date)}</span>
                </h1>
                <h2 className="text-[#333]">{benefitData?.benefit_heading}</h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{ __html: benefitData?.benefitBody }}
                ></div>
              </>
            )}
          </div>
        </section>  */}

        <div className="w-[60%]">
          <section className="w-[100%] h-[842px] bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              <div>
                {/* {console.log("page", page)} */}
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{benefitData?.companyName}</span>
                  <span>Date : {formatDate(benefitData?.date)}</span>
                </h1>
                <h2 className="text-[#333]">{benefitData?.benefit_heading}</h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: benefitData?.benefitBody,
                  }}
                ></div>
              </div>
            </div>
          </section>

          {/* Pagination Component */}
          {/* <section className="flex gap-3 items-center">
            {pages.map((_, index) => (
              <button
                className={`p-2 ${
                  activePage === index ? "bg-red-400" : "bg-blue-400"
                }`}
                key={index}
                onClick={() => setActivePage(index)}
              >
                {index + 1}
              </button>
            ))}
          </section> */}
        </div>

        <section className="w-[40%] h-[945px]">
          {/* <div className="flex gap-4">
            <Button intent="primary" mode={mode} onClick={addNewPage}>
              + Add Page
            </Button>
            <Button intent="secondary" onClick={() => deletePage(index)}>
             - Delete Page
            </Button>
          </div> */}
          <section className="grid grid-cols-2 gap-5 my-4">
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="benefit_heading"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Benefit Heading
              </label>
              <input
                type="text"
                name="benefit_heading"
                // value={benefitData?.benefit_heading || ""}
                value={benefitData?.benefit_heading}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("benefit_heading", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="date"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Date
              </label>
              <input
                type="date"
                name="date"
                // value={benefitData?.date || ""}
                value={benefitData?.date}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("date", e.target.value);
                }}
              />
            </div>
          </section>
          <div className="flex flex-col gap-[6px]">
            <label
              htmlFor="benefit_description"
              className={`text-[14px] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryTextLight"
              }`}
            >
              Benefit Description
            </label>
            <input
              type="text"
              name="benefit_description"
              // value={benefitData?.benefit_description || ""}
              value={benefitData?.benefit_description}
              className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
              }  `}
              onChange={(e) => {
                handleOnChange("benefit_description", e.target.value);
              }}
            />
          </div>

          <div className="my-3">
            <h2 className="text-primaryText text-[14px] font-semibold mb-1">
              Template Body
            </h2>
            <SunEditor
              showToolbar={true}
              // value={oneBenefit?.benefitBody || benefitData?.benefitBody}
              value={benefitData?.benefitBody}
              onChange={(content) =>
                setBenefitData((prevData) => ({
                  ...prevData,
                  benefitBody: content,
                }))
              }
              setOptions={{
                buttonList: [
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "list",
                    "align",
                    "fontSize",
                    "formatBlock",
                    // "table",
                    "image",
                    "preview",
                    "link",
                  ],
                ],
              }}
              height="200px"
              setContents={benefitData?.benefitBody}
            />
          </div>
        </section>
      </div>
      <div className="flex items-center justify-center mt-8 gap-5">
        <Button intent="primary" mode={mode} onClick={addUpdateBenefit}>
          Save
        </Button>
        <Button intent="secondary" mode={mode}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default CreateBenefits;
