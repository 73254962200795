/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BsUpload } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const Termination = () => {
  const { color, mode, organisation, desData, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [selectedDesignation, setSelectedDesignation] = useState("");

  const [termination, setTermination] = useState();
  const [image, setImage] = useState(null);
  console.log("image", image);

  const handleSignature = (e) => {
    const file = e.target.files[0];
    setImage(file);

    console.log("file", file);
  };

  const handleSignatureUpload = async () => {
    try {
      if (image) {
        const formData = new FormData();
        formData.append("file", image);

        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`File upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data?.viewURl);
        return data?.viewURl;
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      throw error;
    }
  };

  // delete confirmation modal
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteTerminationModal = () => {
    setDeleteModal((prev) => !prev);
  };

  // add termination
  const [addTermination, setAddTermination] = useState({});
  console.log("addTermination", addTermination);
  const [addTerminationModal, setAddTerminationModal] = useState(false);
  const openAddTerminationModal = () => {
    setAddTerminationModal((prev) => !prev);
    setAddTermination({});
  };

  console.log("id", addTermination?._id);

  console.log("signature", addTermination?.signature);

  // get all termination
  const getAllTermination = async () => {

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/termination/get-all-termination`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setTermination(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar(`Termination data not found`, "red");
      } else {
        showSnackbar(
          `Something went wrong to get termination, please try again`,
          "red"
        );
      }
    }
  };

  const handleAddTermination = async () => {
    const signature = await handleSignatureUpload();

    axios
      .post(
        `${LOCALHOST_BASE_URL}/termination/add-termination`,
        {
          dept: addTermination?.dept,
          emp_id: addTermination?.emp_id,
          terminationType: addTermination?.terminationType,
          termi_Date: addTermination?.termi_Date,
          notice_Date: addTermination?.notice_Date,
          reason: addTermination?.reason,
          signature: signature,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Termination added");
        getAllTermination();
        setAddTermination({});
        setAddTerminationModal(false);
      })
      .catch((error) => {
        console.log(error);
        setAddTermination({});
      });
  };

  const updateTermination = async () => {

    const signature = await handleSignatureUpload();

    axios
      .put(
        `${LOCALHOST_BASE_URL}/termination/update-termination?id=${addTermination?._id}`,
        {
          dept: addTermination?.dept,
          emp_id: addTermination?.emp_id,
          terminationType: addTermination?.terminationType,
          termi_Date: addTermination?.termi_Date,
          notice_Date: addTermination?.notice_Date,
          reason: addTermination?.reason,
          signature: signature,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Termination updated");
        getAllTermination();
        setAddTermination({});
        setAddTerminationModal(false);
      })
      .catch((error) => {
        console.log(error);
        setAddTermination({});
        setAddTerminationModal(false);
      });
  };

  const deleteTermination = async (id) => {
    try {

      axios
        .delete(`${LOCALHOST_BASE_URL}/termination/delete-termination`, {
          params: { id: id },
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((ress) => {
          if (ress) {
            getAllTermination();
            setAddTermination({});
            setDeleteModal();
            console.log("Termination deleted");
          }
        })
        .catch((er) => console.log(er));
    } catch (error) {
      setAddTermination({});
      console.log("Error deleting Termination:", error);
    }
  };

  useEffect(() => {
    getAllTermination();
    updateTermination();
  }, []);

  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const filteredEmployees =
    deptData?.find((dept) => dept?.dept_name === addTermination?.dept)
      ?.employee || [];

  console.log("filteredEmployees", filteredEmployees);

  console.log("addTermination", addTermination);

  const filterData = termination?.getTermination?.filter((blog) => {
    const designationData = selectedDesignation
      ? blog?.emp_id?.emp_designation?.des_name === selectedDesignation
      : true;
    return designationData;
  });

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Termination</h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="designation"
            id="designation"
            value={selectedDesignation ? selectedDesignation : ""}
            onChange={(e) => setSelectedDesignation(e.target.value)}
            className={`py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="designation" selected disabled>
              Designation
            </option>
            {desData?.map((des) => (
              <option value={des?.des_name}>{des?.des_name}</option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setSelectedDesignation("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <Button intent="primary" mode={mode} onClick={openAddTerminationModal}>
          + Add Termination
        </Button>
      </div>

      {/* cards */}
      <div className="grid grid-cols-3 gap-4">
        {filterData?.map((card) => (
          <section
            className="h-[335px] rounded border hover:shadow-lg"
            // onClick={() => navigate(`/view-termination/${card?._id}`)}
          >
            <section className="flex justify-between items-center bg-primaryBg text-white rounded py-[11px] px-4">
              <div className="flex gap-[14px] items-center ">
                {card?.emp_id?.emp_profile ? (
                  <Avatar
                    type="img"
                    src={card?.emp_id?.emp_profile}
                    size="lg"
                  />
                ) : (
                  <Avatar size="lg" type="text">
                    {card?.emp_id?.emp_name?.slice(0, 1)}
                  </Avatar>
                )}
                <div>
                  <h2 className="font-[800]">{card?.emp_id?.emp_name}</h2>
                  <span className="text-[14px]">
                    {card?.emp_id?.emp_designation?.des_name}
                  </span>
                </div>
              </div>
              <div className="flex gap-1 items-center">
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                  onClick={() => {
                    setAddTerminationModal(true);
                    setAddTermination({
                      ...card,
                      edit: true,
                    });
                  }}
                >
                  <FiEdit2 />
                </h2>
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                  onClick={() => {
                    deleteTerminationModal();
                    setAddTermination({ id: card?._id });
                  }}
                >
                  <RiDeleteBin6Line />
                </h2>
              </div>
            </section>

            <section
              className={`px-4 pt-[10px] py-5 ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
              }`}
            >
              <div className="flex justify-between items-center">
                <section>
                  <h3
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    Termination Type
                  </h3>
                  <span
                    className={`text-[14px] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-[#E2E2E2]"
                        : "text-[#666]"
                    }`}
                  >
                    {card?.terminationType}
                  </span>
                </section>
                <section>
                  <h3
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    Termination Date
                  </h3>
                  <span
                    className={`text-[14px] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-[#E2E2E2]"
                        : "text-[#666]"
                    }`}
                  >
                    {formatDate(card?.termi_Date ? card?.termi_Date : "-")}
                  </span>
                </section>
              </div>
              <section className="my-4">
                <h3
                  className={`font-medium ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-white"
                      : "text-black"
                  }`}
                >
                  Notice Date
                </h3>
                <span
                  className={`text-[14px] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-[#E2E2E2]"
                      : "text-[#666]"
                  }`}
                >
                  {formatDate(card?.notice_Date ? card?.notice_Date : "-")}
                </span>
              </section>
              <section className="my-4">
                <h3
                  className={`font-medium ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-white"
                      : "text-black"
                  }`}
                >
                  Reason for Termination
                </h3>
                <p
                  className={`text-[14px] h-6 ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-[#E2E2E2]"
                      : "text-[#666]"
                  }`}
                >
                  {card?.reason}
                </p>
              </section>
              <div className="flex justify-center items-center gap-4">
                <Button
                  intent="secondary"
                  mode={mode}
                  // onClick={() => deleteTermination(card?._id)}
                  onClick={() => navigate(`/view-termination/${card?._id}`)}
                >
                  View
                </Button>
                {/* <Button
                  intent="primary"
                  mode={mode}
                  onClick={() => {
                    setAddTerminationModal(true);
                    setAddTermination({
                      ...card,
                      edit: true,
                    });
                  }}
                >
                  Edit
                </Button> */}
                {/* <Button
                  intent="secondary"
                  mode={mode}
                  onClick={() => deleteTermination(card?._id)}
                  onClick={() => {
                    deleteTerminationModal();
                    setAddTermination({ id: card?._id });
                  }}
                >
                  Delete
                </Button> */}
              </div>
            </section>
          </section>
          // </Link>
        ))}
      </div>

      {/* add termination modal */}
      <Modal
        showModal={addTerminationModal}
        setShowModal={setAddTerminationModal}
      >
        <div className="w-[384px]">
          <h1 className="text-center mb-[14px] text-[20px] font-medium">
            {addTermination?.edit ? "Edit" : "Add"} Termination
          </h1>
          <section className="flex flex-col gap-[6px] mb-2">
            {addTermination?.edit ? (
              ""
            ) : (
              <>
                <label htmlFor="dept">Select Department</label>
                <select
                  name="dept"
                  id="dept"
                  required
                  onChange={(e) =>
                    setAddTermination({
                      ...addTermination,
                      dept: e.target.value,
                    })
                  }
                  className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                >
                  <option value="dept" selected disabled>
                    Select Department
                  </option>
                  {deptData?.map((dept) => (
                    <option value={dept?.dept_name}>{dept?.dept_name}</option>
                  ))}
                </select>
              </>
            )}
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            {addTermination?.edit ? (
              <>
                <label htmlFor="dept">Employee Name</label>
                <input
                  type="text"
                  value={addTermination?.emp_id?.emp_name}
                  disabled
                  className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                />
              </>
            ) : (
              <>
                <label htmlFor="dept">Select Employee</label>
                <select
                  name="emp"
                  id="emp"
                  required
                  onChange={(e) =>
                    setAddTermination({
                      ...addTermination,
                      emp_id: e.target.value,
                    })
                  }
                  className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                >
                  <option value="emp" disabled selected>
                    Select Employee
                  </option>
                  {filteredEmployees?.map((emp) => (
                    <option value={emp?._id}>{emp?.emp_name}</option>
                  ))}
                </select>
              </>
            )}
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="terminationType">
              Termination Type <span className="">*</span>
            </label>
            <input
              type="text"
              name="terminationType"
              value={addTermination?.terminationType}
              onChange={(e) =>
                setAddTermination({
                  ...addTermination,
                  terminationType: e.target.value,
                })
              }
              placeholder="Termination Type"
              required
              className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            />
          </section>
          <section className="grid grid-cols-2 gap-4 mb-2">
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="terminationDate">
                Termination Date <span className="">*</span>
              </label>
              <input
                type="date"
                name="terminationDate"
                value={addTermination?.termi_Date}
                onChange={(e) =>
                  setAddTermination({
                    ...addTermination,
                    termi_Date: e.target.value,
                  })
                }
                required
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="noticeDate">
                Notice Date <span className="">*</span>
              </label>
              <input
                type="date"
                name="noticeDate"
                value={addTermination?.notice_Date}
                onChange={(e) =>
                  setAddTermination({
                    ...addTermination,
                    notice_Date: e.target.value,
                  })
                }
                required
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </div>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="reason">
              Reason <span className="">*</span>
            </label>
            <textarea
              name="reason"
              value={addTermination?.reason}
              onChange={(e) => {
                setAddTermination({
                  ...addTermination,
                  reason: e.target.value,
                });
              }}
              id="reason"
              cols="10"
              rows="2"
              required
              className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 resize-y w-full`}
            ></textarea>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="reason">
              Signature upload <span className="">*</span>
            </label>
            {image !== null ? (
              <>
                <p className={`text-green-500 text-[14px]`}>
                  Signature Uploaded
                </p>
              </>
            ) : (
              <></>
            )}
            <div class="flex items-center justify-center w-full">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <span className="text-primaryBg">
                    <BsUpload />
                  </span>
                  <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                    Upload your signature
                  </p>
                </div>
                <input
                  accept="image/png, image/jpeg"
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  onChange={handleSignature}
                />
                <img
                  src={
                    addTermination?.signature === "string"
                      ? addTermination?.signature
                      : addTermination.signatureUrl
                  }
                  className="w-6 h-6 object-cover"
                  alt="signature"
                />
              </label>
            </div>
          </section>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={
                addTermination?.edit ? updateTermination : handleAddTermination
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal showModal={deleteModal} setShowModal={setDeleteModal}>
        <div className="w-[342px] text-center">
          <p className="mt-5 mb-8 text-[20px] capitalize">
            Are you sure, you want to delete this termination?{" "}
          </p>
          <Button
            intent="primary"
            mode={mode}
            onClick={() => deleteTermination(addTermination?.id)}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Termination;
