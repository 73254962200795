/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import { ContextThemeProvider } from "../../context/ThemeContext";
import Badge from "../../components/Badge";
import { useSnackbar } from "../../context/SnackbarContext";
import jwt_decode from "jwt-decode";
import Button from "../../components/Button";
import { useNavigate } from "react-router";

const PolicyUser = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const decodedToken = jwt_decode(token);
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const navigate = useNavigate();

  const getAllPolices = () => {
    try {
      axios
        .get(`${LOCALHOST_BASE_URL}/policies/get-all-policies`, {
          headers: {
            "api-key": apiKey,
          },
        })
        .then((response) => {
          if (response) {
            console.log("Here is the Data", response?.data?.getAll);
            setData(response?.data?.getAll);
          }
        })
        .catch((error) => {
          console.log("Here is the Error from then-catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  useEffect(() => {
    getAllPolices();
  }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="flex justify-between gap-4 items-center">
        <h1 className={`text-[32px] text-primaryText`}>Policies </h1>
      </div>

      {/* card */}
      <div className="grid grid-cols-3 gap-[26px] my-[54px]">
        {data?.reverse()?.map((d) => (
          <section
            className={`w-[342px] h-[230px] shadow-md rounded py-3 cardShadow ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <h2 className="text-primaryText px-4 font-semibold text-[20px] flex justify-between h-[60px]  pr-3 line-clamp-2">
              {d?.headline}
            </h2>
            <p className="h-[95px] text-[12px]  my-2 px-4 overflow-hidden text-primaryText">
              {/* {d?.body} */}
              <div
                className="h-[100px]"
                dangerouslySetInnerHTML={{ __html: d?.body }}
              ></div>
            </p>
            <div className="flex justify-center items-center gap-4">
              <Button
                intent="primary"
                mode={mode}
                onClick={() => navigate(`/viewUserPolicies/${d._id}`)}
              >
                View
              </Button>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default PolicyUser;
