/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsCalendar2, BsClock } from "react-icons/bs";
import { TbHierarchy3 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import { LOCALHOST_BASE_URL, apiKey } from "../utils/config";
import Avatar from "../components/Avatar";
import EmployeeInformation from "../components/employeeDetails/EmployeeInformation";
import EmployeeAttendance from "../components/employeeDetails/EmployeeAttendance";
import FinancialDetails from "../components/employeeDetails/FinancialDetails";
import EmployeeLeave from "../components/employeeDetails/EmployeeLeave";
import EmployeeDocument from "../components/employeeDetails/EmployeeDocument";
import { useSnackbar } from "../context/SnackbarContext";

const tabData = [
  {
    id: 1,
    tabName: "Employee Information",
  },
  {
    id: 2,
    tabName: "Attendance",
  },
  {
    id: 3,
    tabName: "Financial Details",
  },
  {
    id: 4,
    tabName: "Leave",
  },
  {
    id: 5,
    tabName: "Documents",
  },
];

const EmployeeDetails = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const { empId } = useParams();
  let [tab, setTab] = useState("Employee Information");
  const [employeeInfo, setEmployeeInfo] = useState();
  console.log("employee info", employeeInfo);

  const getEmployeeInfo = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${empId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setEmployeeInfo(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getEmployeeInfo("employeeDetails", employeeInfo);
  }, [empId]);

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Employee Information":
        return <EmployeeInformation employeeInfo={employeeInfo} />;
      case "Attendance":
        return <EmployeeAttendance />;
      case "Financial Details":
        return <FinancialDetails employeeInfo={employeeInfo} />;
      case "Leave":
        return <EmployeeLeave employeeInfo={employeeInfo} />;
      case "Documents":
        return <EmployeeDocument employeeInfo={employeeInfo} />;
      default:
    }
  };

  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const currentYear = new Date().getFullYear();

  const empWorkDetails = [
    {
      detailName: "Designation",
      detailBody: employeeInfo?.employee?.emp_designation?.des_name,
    },
    {
      detailName: "Employee type",
      detailBody: employeeInfo?.employee?.emp_type,
    },
    {
      detailName: "Mobile Number",
      detailBody: employeeInfo?.employee?.emp_phoneNo,
    },
    {
      detailName: "Email Address",
      detailBody: employeeInfo?.employee?.emp_email,
    },
    {
      detailName: "Date of Birth",
      detailBody: formatDate(employeeInfo?.employee?.emp_dob),
    },
  ];

  const employeeDetailCards = [
    {
      icon: <BsClock />,
      cardHeader: "Date of joining",
      cardBody: formatDate(employeeInfo?.employee?.emp_joingDate),
    },
    {
      icon: <TbHierarchy3 />,
      cardHeader: "Department",
      cardBody: employeeInfo?.employee?.emp_department?.dept_name,
    },
    {
      icon: <BsClock />,
      cardHeader: "Shift time",
      cardBody: (() => {
        const shiftStartTime =
          employeeInfo?.employee?.emp_shiftTime?.start_time;
        const shiftEndTime = employeeInfo?.employee?.emp_shiftTime?.end_time;

        if (shiftStartTime && shiftEndTime) {
          return `${shiftStartTime} - ${shiftEndTime}`;
        } else {
          return "Not specified";
        }
      })(),
    },
    {
      icon: <BsCalendar2 />,
      cardHeader: "Taken leave this year",
      cardBody: `${
        employeeInfo?.leaveData?.[currentYear]?.leaveCount
          ? employeeInfo?.leaveData?.[currentYear]?.leaveCount
          : "-"
      }`,
    },
  ];

  return (
    <div
      className={`h-full pl-[36px] py-[24px] pr-[50px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="rounded-lg shadow-xl p-[25px]">
        <Link
          to="/employees"
          className={`${
            organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
          }`}
        >
          <BsArrowLeft />
        </Link>
        <div className="mb-18[px] mt-[26px] flex gap-[31px]">
          <Avatar
            type="img"
            src={employeeInfo?.employee?.emp_profile}
            size="xxlg"
          />
          <section className="text-primaryText w-[90%]">
            <h1 className="mb-[34px]">{employeeInfo?.employee?.emp_name}</h1>
            <div className="flex justify-between w-full">
              {empWorkDetails?.map((d) => (
                <section className="">
                  <span className="text-[14px] font-[300]">
                    {d?.detailName}
                  </span>
                  <p>{d?.detailBody}</p>
                </section>
              ))}
            </div>
          </section>
        </div>

        <div className="grid grid-cols-4 mt-[18px] gap-4">
          {employeeDetailCards?.map((card) => (
            <section
              className={`flex items-center gap-4 p-[25px] rounded-lg text-primaryText h-[96px] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-primaryLight"
              }`}
            >
              <div
                className={`w-[41px] h-[41px] rounded-full flex justify-center items-center ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-secondaryDark"
                    : "bg-white"
                }`}
              >
                {card?.icon}
              </div>
              <div>
                <h2 className="mb-2">{card?.cardHeader}</h2>
                <p>{card?.cardBody}</p>
              </div>
            </section>
          ))}
        </div>
      </section>

      <div className="my-6">
        <section
          className={`grid grid-cols-5 mb-[26px] ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-primaryLight"
          }`}
        >
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`text-[14px] text-primaryText font-medium text-center py-2 rounded cursor-pointer transition-all ease ${
                    tab === i.tabName ? "bg-primaryBg text-white" : ""
                  }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <section className="">{getCorrectPage(tab)}</section>
      </div>
    </div>
  );
};

export default EmployeeDetails;
