/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { useSnackbar } from "../context/SnackbarContext";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";

const ViewLayoff = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const { layoffId } = useParams();
  console.log("layoffId", layoffId);

  const [layoffDetails, setLayoffDetails] = useState();
  console.log("layoffDetails", layoffDetails);

  // Helper function to handle errors and show a snackbar message
  const handleError = (error, errorMessage) => {
    if (error.response) {
      if (error.response.status === 404) {
        showSnackbar(`Resource not found. ${errorMessage}`, "red");
      } else {
        showSnackbar(`Something went wrong. ${errorMessage}`, "red");
      }
    } else {
      showSnackbar(`Something went wrong. ${errorMessage}`, "red");
    }
  };

  const getLayoffDetails = async () => {
    try {

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/layOff/get-layOff?id=${layoffId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setLayoffDetails(result?.data?.layOffGet);
    } catch (error) {
      handleError(error, "while fetching reimbursement details.");
    }
  };

  useEffect(() => {
    getLayoffDetails(layoffDetails);
  }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <Link
          to="/lay-off"
          className={`text-[32px] flex items-center gap-1 text-primaryText`}
        >
          <MdOutlineKeyboardBackspace /> View Layoff
        </Link>
        <Button intent="primary" mode={mode}>
          Download
        </Button>
      </section>
      <div
        className={`h-full pl-[36px] py-[29px] pr-[45px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-secondaryDark"
            : "bg-white"
        } `}
      >
        <div className="flex justify-center mt-[50px]">
          <section className="flex justify-center bg-white w-[595px] h-[842px] cardShadow">
            <div className="">
              <svg
                width="595"
                height="202"
                viewBox="0 0 595 202"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4.5V0H594.5V201.5C594.5 10 403 4.5 0 4.5Z"
                  fill="url(#paint0_linear_2161_6066)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2161_6066"
                    x1="0"
                    y1="201.496"
                    x2="594.5"
                    y2="201.496"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="var(--primaryBg)" />
                    <stop offset="1" stop-color="var(--primaryBg)" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="mt-[-100px] px-[62px]">
                <section className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold leading-4 uppercase text-[#808080]">
                    <p>{layoffDetails?.termi_Date}</p>
                    <p>Badden kherr</p>
                    <p>Design</p>
                    <p>UI/UX Designer</p>
                  </div>
                  <img
                    src="https://miratsfilestorage.s3.ap-south-1.amazonaws.com/onboardEmail/Group+376.png"
                    alt="logo"
                    className="w-[85px] h-[40px] object-cover"
                  />
                </section>

                <div className="mt-[10px] mb-[31px]">
                  <h2 className="text-[15px]">Layoff</h2>
                  <p className="w-9 h-[2px] bg-primaryBg mt-[10px]"></p>
                </div>

                <div className="text-[11px] text-[#222]">
                  <h3>Dear {layoffDetails?.employeeId?.emp_name},</h3>
                  <p className="my-3">
                    We regret to inform you that due to unforeseen
                    circumstances, we have had to make the difficult decision to
                    implement a layoff. As of [Layoff Date], your employment
                    with [Company Name] will come to an end.
                  </p>
                  <h3 className="font-medium">Details of Layoff:</h3>
                  <p>
                    A layoff was implemented in the {layoffDetails?.department}{" "}
                    department, affecting the{" "}
                    {layoffDetails?.employeeId?.emp_designation?.des_name}, with
                    the layoff type being {layoffDetails?.type} on{" "}
                    {layoffDetails?.layOffDate}, following the notice issued on{" "}
                    {layoffDetails?.noticeDate}, due to {layoffDetails?.reason}
                  </p>
                  <p className="mt-4">
                    We understand that this is a challenging time, and we want
                    to express our gratitude for your contributions to the
                    company. Your work as a{" "}
                    {layoffDetails?.employeeId?.emp_designation?.des_name} has
                    been invaluable.
                  </p>
                </div>

                <div className="mt-5">
                  <h3 className="mb-5">Signature:</h3>
                  <img
                    src={layoffDetails?.signature}
                    alt="signature"
                    className="w-[130px] h-[90px] object-contain"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewLayoff;
