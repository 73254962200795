/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../context/ThemeContext";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import Modal from "../components/Modal";
import { BsUpload } from "react-icons/bs";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const OrganisationSetting = () => {
  const [image, setImage] = useState(null);
  const { color, mode, organisation, orgData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [editSetting, setEditSetting] = useState(false);
  const [allOrganisation, setAllOrganisation] = useState({});

  console.log("allOrganisation", allOrganisation);

  // orgInfo
  const [orgInfo, setOrgInfo] = useState([]);
  const [updateData, setUpdateData] = useState({
    organisationName: allOrganisation?.organisationName || "",
    organisationEmail: allOrganisation?.organisationEmail || "",
    organisationWebSite: allOrganisation?.organisationWebSite || "",
  });
  console.log("updateData", updateData);
  console.log("orgData");

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  //GET ORGANISATION DATA:
  const getAllOrganisation = async () => {
    const url = `${LOCALHOST_BASE_URL}/organisation/getOne-organisation`;

    try {
      const response = await axios.get(url, {
        headers: {
          "api-key": apiKey,
        },
      });

      if (response.status === 200) {
        console.log("Here is the data", response.data?.result?.[0]);
        setAllOrganisation(response.data?.result?.[0]);
      } else if (response.status === 404) {
        // Handle 404 error, such as displaying an error message to the user
        console.error("Organisation not found");
        showSnackbar("Organisation not found. Please try again", "red");
      } else {
        // Handle other errors if needed
        console.error("An error occurred:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      // Handle any other errors that might occur during the request
      console.error("An error occurred:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  const handleChangeSetting = (name, value) => {
    setEditSetting(true);
    setUpdateData(allOrganisation);
    // setEditSetting({
    //   ...editSetting,
    //   [name]: value,
    // });
  };

  const handleUpload = async (url) => {
    const orgId = orgData?._id;
    try {
      // const url = URL.createObjectURL(image);
      setShowModal(!showModal);
      setAllOrganisation({
        ...allOrganisation,
        organisationLogo: url,
      });
      await axios
        .put(
          `${LOCALHOST_BASE_URL}/organisation/update-organisation?id=${orgData?._id}`,
          {
            organisationEmail: `${allOrganisation?.organisationEmail}`,
            organisationLogo: `${url}`,
            organisationName: `${allOrganisation.organisationName}`,
            organisationWebSite: `${allOrganisation.organisationWebSite}`,
          },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Here is the Response from Upload", response.data);
          getAllOrganisation();
        })
        .catch((error) => {
          console.log("Here is the Error from try-Catch", error);
          showSnackbar("Something went wrong 1. Please try again", "red");
        });
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  // Save org info
  const handleSaveInfo = async () => {
    try {
      const orgId = allOrganisation?._id;
      console.log("Here is the Data to update", updateData);

      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/organisation/update-organisation?id=${orgId}`,
        {
          organisationEmail:
            updateData?.organisationEmail ?? allOrganisation?.organisationEmail,
          organisationName:
            updateData?.organisationName ?? allOrganisation?.organisationName,
          organisationWebSite:
            updateData?.organisationWebSite ??
            allOrganisation?.organisationWebSite,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        console.log("Updated data:", response.data);
        getAllOrganisation();
      }
      editSetting(true);
    } catch (error) {
      console.error("Error occurred:", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  // org info
  const handleEditChange = (e) => {
    const updatedInfo = orgInfo?.map((info) => {
      if (info?.infoLabel === e.target.name) {
        return {
          ...info,
          infoValue: e.target.value,
        };
      }
      return info;
    });
    setOrgInfo(updatedInfo);
    setUpdateData(updatedInfo);
  };

  //handleimagechange
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handlemageupload = async () => {
    try {
      if (image) {
        const formdata = new FormData();
        formdata.append("file", image);
        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data.viewURl);
          handleUpload(data.viewURl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrganisation();
  }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="border rounded">
        <section className="bg-primaryLight flex justify-between items-center px-6 py-3">
          <h1 className="text-[20px] font-medium text-primaryText">
            General Settings
          </h1>
          {editSetting ? (
            ""
          ) : (
            <Button
              intent="primary"
              mode={mode}
              onClick={() => handleChangeSetting("editSetting", true)}
            >
              Edit
            </Button>
          )}
        </section>

        <div className="grid grid-cols-3 gap-4 px-6 py-8">
          <section className="flex flex-col gap-2">
            <label
              className={`text-[14px] text-primaryText mb-[6px]`}
              htmlFor="organisationName"
            >
              Organisation Name
            </label>
            {editSetting ? (
              <div>
                <input
                  name="organisationName"
                  type="text"
                  value={updateData?.organisationName}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      organisationName: e.target.value,
                    });
                  }}
                  className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "bg-transparent"
                      : ""
                  } `}
                />
              </div>
            ) : (
              <p className="font-[500] text-primaryText py-2 text-[14px] rounded">
                {allOrganisation?.organisationName}
              </p>
            )}
          </section>

          <section className="flex flex-col gap-2">
            <label
              className={`text-[14px] text-primaryText mb-[6px]`}
              htmlFor="organisationEmail"
              // htmlFor={info?.infoLabel}
            >
              {/* {info?.infoLabel} */}
              Organisation Email ID
            </label>
            {editSetting ? (
              <input
                value={updateData?.organisationEmail}
                onChange={(e) => {
                  setUpdateData((prevData) => ({
                    ...prevData,
                    organisationEmail: e.target.value,
                  }));
                }}
                // onChange={e=>setAllOrganisation(prev=>({...prev,[info.infoLabel?.split(" ")?.[0]?.toLowerCase()?.join("")]:e.target.info?.infoValue}))}
                // onChange={handleEditChange}
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] text-primaryText py-2 text-[14px] rounded">
                {allOrganisation?.organisationEmail}
              </p>
            )}
          </section>

          <section className="flex flex-col gap-2">
            <label className={`text-[14px] text-primaryText mb-[6px]`}>
              Organisation Website URL
            </label>
            {editSetting ? (
              <input
                name="organisationWebSite"
                value={updateData?.organisationWebSite}
                onChange={(e, name) => {
                  setUpdateData((prevData) => ({
                    ...prevData,
                    organisationWebSite: e.target.value,
                  }));
                }}
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            ) : (
              <p className="font-[500] text-primaryText py-2 text-[14px] rounded">
                {allOrganisation?.organisationWebSite}
              </p>
            )}
          </section>
        </div>

        <div className="px-6 py-8">
          <h1 className="text-primaryText">Organisation logo</h1>
          <section className="my-4">
            {allOrganisation?.organisationLogo ? (
              <>
                <img
                  className="w-[200px] h-[200px] object-contain"
                  src={allOrganisation?.organisationLogo}
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  src="https://signaturely.com/wp-content/uploads/2020/04/embellished-letters-signaturely.svg"
                  alt=""
                />
              </>
            )}
          </section>
          <Button intent="primary" mode={mode} onClick={openModal}>
            Upload
          </Button>
        </div>

        <div>
          <section className="bg-primaryLight flex justify-between items-center px-6 py-3">
            <h1 className="text-[20px] font-medium text-primaryText">Theme</h1>
          </section>
          <div className="px-6 py-8">
            <h1 className="text-primaryText">Selected Theme</h1>
            <section className="my-4">
              {organisation?.organisationTheme?.name === "dark" ? (
                <h1 className="text-primaryText text-[24px] font-medium">
                  {allOrganisation?.organisationTheme?.name}
                </h1>
              ) : (
                <div className="bg-primaryBg w-10 h-10 rounded-lg"></div>
              )}
            </section>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => navigate(`/plans`)}
            >
              Upgrade Your Plan
            </Button>
          </div>
        </div>
      </div>
      {editSetting ? (
        <section className="mt-8 flex justify-center items-center gap-4">
          <Button intent="secondary" mode={mode}>
            Clear
          </Button>
          <Button intent="primary" mode={mode} onClick={handleSaveInfo}>
            Save
          </Button>
        </section>
      ) : (
        ""
      )}
      {/* upload modal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="w-[361px]">
          <h2 className="mb-3 text-center">Organisation Logo</h2>
          <div class="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="text-primaryBg">
                  <BsUpload />
                </span>
                <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                  Upload Logo
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                // onChange={(e) => {
                //   console.log("Here from Upload", e.target.files[0]);
                //   setImage(e.target.files[0]);
                // }}
                onChange={handleImageChange}
              />
            </label>
          </div>
          <div className="">
            {image !== null ? (
              <>
                <p className={`auto text-green-500 text-[14px]`}>
                  Logo Uploaded
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <section className="mt-8 flex justify-center items-center gap-4">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => setImage(null)}
            >
              Clear
            </Button>
            <Button intent="primary" mode={mode} onClick={handlemageupload}>
              Save
            </Button>
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default OrganisationSetting;
