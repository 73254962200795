/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../context/ThemeContext";
import Button from "../components/Button";
import axios from "axios";
import { LOCALHOST_BASE_URL, apiKey, token } from "../utils/config";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import TablePagination from "../components/table/TablePagination";
import { BsFileEarmarkPdf, BsFillGridFill } from "react-icons/bs";
import { LuExternalLink } from "react-icons/lu";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import Avatar from "../components/Avatar";
import { PiListFill } from "react-icons/pi";
import { useNavigate, useParams } from "react-router";
import Badge from "../components/Badge";
import { Link } from "react-router-dom";
import { useSnackbar } from "../context/SnackbarContext";

const Reimbursement = () => {
  const { color, mode, organisation, desData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [designationSelect, setDesignationSelect] = useState();
  const [statusSelect, setStatusSelect] = useState();

  const { reimbursementId } = useParams();
  console.log("reimbursementId", reimbursementId);

  const [reimbursement, setReimbursement] = useState();
  console.log("reimbursement", reimbursement);

  const status = [
    {
      label: "Approved",
      value: "true",
    },
    {
      label: "Rejected",
      value: "false",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const filterData = reimbursement?.filter((blog) => {
    const departData = designationSelect
      ? blog?.emp_id?.emp_designation?.des_name === designationSelect
      : true;

    if (statusSelect === "pending") {
      return (
        (!blog?.status && blog.status !== false) || blog?.status === "pending"
      );
    } else if (statusSelect === "true") {
      return blog?.status === true;
    } else if (statusSelect === "false") {
      return blog?.status === false;
    }
    return departData;
  });

  console.log("filterData", filterData);
  // card list view
  const [tab, setTab] = useState(1);

  const tabData = [
    {
      id: 1,
      tabIcon: <PiListFill color={tab === 1 ? "white" : "black"} />,
    },
    {
      id: 2,
      tabIcon: <BsFillGridFill color={tab === 2 ? "white" : "black"} />,
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case 1:
        return (
          <ReimbursementTable
            reimbursement={filterData}
            mode={mode}
            formatDate={formatDate}
            statusReimbursement={statusReimbursement}
            deleteReimbursement={deleteReimbursement}
            paymentStatusReimbursement={paymentStatusReimbursement}
            navigate={navigate}
            organisation={organisation}
          />
        );
      case 2:
        return (
          <ReimbursementCard
            reimbursement={filterData}
            mode={mode}
            formatDate={formatDate}
            statusReimbursement={statusReimbursement}
            deleteReimbursement={deleteReimbursement}
            navigate={navigate}
            organisation={organisation}
          />
        );
      default:
    }
  };

  // get all reimbursement
  const getAllReimbursement = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/reimbursement/get-all-reimbursement`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      setReimbursement(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar("Reimbursement data not found", "red");
      } else {
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  // update reimbursement
  // status reimbursement
  const statusReimbursement = (id, status) => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/reimbursement/update-reimbursement?id=${id}`,
        {
          status: status,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllReimbursement();
        // showSnackbar(
        //   `"Reimbursement" ${status === true ? "Approved" : "Rejected"} ${status === "true" ? "green" : "red"}`
        // );
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again.", "red");
      });
  };

  const paymentStatusReimbursement = (id) => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/reimbursement/update-reimbursement?id=${id}`,
        {
          paymentStatus: "true",
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllReimbursement();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again.", "red");
      });
  };

  // delete reimbursement
  const deleteReimbursement = async (id) => {
    try {
      axios
        .delete(
          `${LOCALHOST_BASE_URL}/reimbursement/delete-reimbursement?id=${id}`,
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          getAllReimbursement();
          console.log("Reimbursement deleted", response);
        })
        .catch((err) => {
          console.log(err);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Error deleting reimbursement:", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  useEffect(() => {
    getAllReimbursement();
  }, []);

  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Reimbursement</h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="designation"
            id="designation"
            className={`py-2 px-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setDesignationSelect(e.target.value)}
            value={designationSelect ? designationSelect : ""}
          >
            <option value="designation" selected>
              Designation
            </option>
            {desData?.map((emp) => (
              <option key={emp?.value} value={emp?.des_name}>
                {emp?.des_name}
              </option>
            ))}
          </select>
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setStatusSelect(e.target.value)}
            value={statusSelect ? statusSelect : ""}
          >
            <option value="status" selected>
              Status
            </option>
            {status?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.label}
              </option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setDesignationSelect("");
              setStatusSelect("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <section className="flex flex-row-reverse items-center gap-4">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`px-2 py-2 rounded cursor-pointer ${
                    tab === i.id ? "bg-primaryBg" : ""
                  }`}
                  onClick={() => {
                    setTab(i.id);
                  }}
                >
                  {i.tabIcon}
                </h3>
              </>
            );
          })}
        </section>
      </div>
      <section>{getCorrectPage(tab)}</section>
    </div>
  );
};

const ReimbursementTable = ({
  reimbursement,
  mode,
  statusReimbursement,
  deleteReimbursement,
  paymentStatusReimbursement,
  navigate,
  organisation,
}) => {
  const [reimbursePageNumber, setReimbursePageNumber] = useState(1);
  const [reimburseRowsPerPage, setReimburseRowsPerPage] = useState(10);
  return (
    <div className="text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>
                <span className="pl-2">Employee Name</span>
              </Th>
              <Th>Designation</Th>
              <Th>Date</Th>
              <Th>Type</Th>
              <Th>Total</Th>
              <Th>Document</Th>
              <Th>Status</Th>
              <Th>Approval</Th>
              <Th>Action</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {reimbursement
              ?.reverse()
              ?.slice(
                (reimbursePageNumber - 1) * reimburseRowsPerPage,
                reimbursePageNumber * reimburseRowsPerPage
              )
              ?.map((re, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="pl-2 flex items-center gap-1">
                        <Avatar
                          type="img"
                          src={re?.emp_id?.emp_profile}
                          size="sm"
                        />
                        <span>{re?.emp_id?.emp_name}</span>
                      </div>
                    </Td>
                    <Td>{re?.emp_id?.emp_designation?.des_name}</Td>
                    <Td>{re?.date}</Td>
                    <Td>{re?.type_reimbursement}</Td>
                    <Td>{re?.totalAmount}</Td>
                    <Td>
                      <span
                        className="cursor-pointer hover:text-primaryBg border-b border-primaryBg"
                        onClick={() =>
                          navigate(`/view-reimbursement/${re?._id}`)
                        }
                      >
                        View
                      </span>
                    </Td>
                    <Td>
                      <div className="flex items-center gap-1">
                        <span>
                          {re?.status === false ? (
                            <AiOutlineClose color="#FF3838" />
                          ) : re?.status === true ? (
                            <AiOutlineCheck color="#08A758" />
                          ) : (
                            <TbClockExclamation color="#F9A000" />
                          )}
                        </span>
                        <span>
                          {re?.status === false
                            ? "Rejected"
                            : re?.status === true
                            ? "Approved"
                            : "Pending"}
                        </span>
                      </div>
                    </Td>
                    <Td>
                      {re?.status === true ? (
                        <div className="flex items-center justify-center gap-2">
                          <button disabled>
                            <AiOutlineCheckCircle color="#B0B0B0" size={23} />
                          </button>
                          <button
                            color="#ccc"
                            // onClick={() =>
                            //   statusReimbursement(re?._id, "false")
                            // }
                          >
                            <AiOutlineCloseCircle color="#ccc" size={23} />
                          </button>
                        </div>
                      ) : re?.status === false ? (
                        <div className="flex items-center justify-center gap-2">
                          <button
                            onClick={() => statusReimbursement(re?._id, "true")}
                          >
                            <AiOutlineCheckCircle color="green" size={23} />
                          </button>
                          <button disabled>
                            <AiOutlineCloseCircle color="#ccc" size={23} />
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => statusReimbursement(re?._id, "true")}
                          >
                            <AiOutlineCheckCircle color="green" size={23} />
                          </button>
                          <button
                            onClick={() =>
                              statusReimbursement(re?._id, "false")
                            }
                          >
                            <AiOutlineCloseCircle color="red" size={23} />
                          </button>
                        </div>
                      )}
                    </Td>
                    <Td>
                      <div className="flex items-center gap-4 ">
                        {re?.status === true ? (
                          <>
                            {/* <h2
                         className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                         onClick={() => deleteReimbursement(re?._id)}
                       >
                         <RiDeleteBin6Line color="#D20E0E" />
                       </h2> */}
                            <Button
                              intent="primary"
                              mode={mode}
                              onClick={() =>
                                paymentStatusReimbursement(re?._id)
                              }
                              disabled={re?.paymentStatus === true}
                            >
                              Payment Done
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={reimbursement?.length || 0}
          setRowsPerPage={setReimburseRowsPerPage}
          rowsPerPage={reimburseRowsPerPage}
          setPageNumber={setReimbursePageNumber}
          pageNumber={reimbursePageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const ReimbursementCard = ({
  reimbursement,
  mode,
  formatDate,
  statusReimbursement,
  deleteReimbursement,
  navigate,
  organisation,
}) => {
  return (
    <div className="grid grid-cols-4 gap-[26px]">
      {reimbursement?.reverse()?.map((re) => (
        <section
          className={`w-[270px] h-[327px] shadow-md rounded py-3 cardShadow ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="flex items-center justify-between gap-1 pl-4 pr-3 pb-3">
            <h2 className="text-primaryText text-[15px]">
              {re?.type_reimbursement}
            </h2>
            <section className="flex items-center gap-1">
              <Badge
                intent={
                  re?.status === false
                    ? "danger"
                    : re?.status === true
                    ? "success"
                    : "warning"
                }
                size="small"
                roundness="pill"
              >
                {re?.status === false
                  ? "Reject"
                  : re?.status === true
                  ? "Approve"
                  : "Pending"}
              </Badge>
              {/* <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                onClick={() => deleteReimbursement(re?._id)}
              >
                <RiDeleteBin6Line color="#D20E0E" />
              </h2> */}
            </section>
          </div>
          <div className="flex justify-between mt-2 mb-4 px-4">
            <section className="flex items-center gap-2">
              {re?.emp_id?.emp_profile ? (
                <Avatar type="img" src={re?.emp_id?.emp_profile} size="md" />
              ) : (
                <Avatar size="md" type="text">
                  {re?.emp_id?.emp_name?.slice(0, 1)}
                </Avatar>
              )}

              <div className="">
                <h4 className="text-primaryText text-[14px]">
                  {re?.emp_id?.emp_name}
                </h4>
                <h4 className="text-secondaryText text-[10px]">
                  {re?.emp_id?.emp_designation?.des_name}
                </h4>
              </div>
            </section>
          </div>
          <div
            className={`py-[14px] text-center ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-[#2A2E3D]"
                : "bg-primaryLight"
            }`}
          >
            <h2 className="text-primaryBg font-semibold mb-1">
              {re?.totalAmount}
            </h2>
            <h2 className="text-primaryText text-[14px]">
              {formatDate(re?.date)}
            </h2>
          </div>
          <div className="px-4 mt-3 mb-5 text-[12px]">
            <h3 className="text-secondaryText">Document:</h3>
            {/* <p className="h-[70px] text-[12px] text-primaryText font-medium">
              {l?.emp_leave?.reason}
            </p> */}
            <p
              onClick={() => navigate(`/view-reimbursement/${re?._id}`)}
              className="text-primaryBg flex items-center gap-1 mt-1 cursor-pointer"
            >
              View bill.Pdf <LuExternalLink />
            </p>
          </div>
          <div className="flex justify-center items-center gap-2">
            {re?.status === true ? (
              <div className="flex items-center gap-2">
                <Button
                  intent="secondary"
                  mode={mode}
                  onClick={() => statusReimbursement(re?._id, "false")}
                >
                  Reject
                </Button>
                <Button intent="primary" mode={mode} disabled>
                  Approve
                </Button>
              </div>
            ) : re?.status === false ? (
              <div className="flex items-center gap-2">
                <Button intent="secondary" mode={mode} disabled>
                  Reject
                </Button>
                <Button
                  intent="primary"
                  mode={mode}
                  onClick={() => statusReimbursement(re?._id, "true")}
                >
                  Approve
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Button
                  intent="secondary"
                  mode={mode}
                  onClick={() => statusReimbursement(re?._id, "false")}
                >
                  Reject
                </Button>
                <Button
                  intent="primary"
                  mode={mode}
                  onClick={() => statusReimbursement(re?._id, "true")}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Reimbursement;
