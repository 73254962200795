/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import { GoDownload } from "react-icons/go";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";
import { FiEdit2 } from "react-icons/fi";
import Modal from "../components/Modal";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { BsFillGridFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import { BsArrowLeft } from "react-icons/bs";
import { useSnackbar } from "../context/SnackbarContext";

const PayMentDetails = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const getTodayDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const monthName = months[today.getMonth()];
    const year = today.getFullYear();

    return `${year}-${monthName}`;
  };

  // Usage
  const todayDate = getTodayDate();
  console.log(todayDate); // Output: "2023-October"

  const [allPayroll, setAllPayrolls] = useState();
  const getAllPayrolls = async () => {

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/payRoll/get-all-payRoll?month=${todayDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAllPayrolls(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the 404 error here
        console.error("Payroll data not found");
        showSnackbar("Payroll data not found", "red");
      } else {
        // Handle any other errors that might occur during the request
        console.error("An error occurred:", error);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    }
  };

  useEffect(() => {
    if (todayDate) {
      getAllPayrolls();
    }
  }, [todayDate]);

  let [tab, setTab] = useState("fulltime");
  const tabHead = [
    {
      id: 1,
      tabName: "fulltime",
      tabLabel: "Full time employee",
    },
    {
      id: 2,
      tabName: "intern",
      tabLabel: "Intern Employee",
    },
    {
      id: 3,
      tabName: "probation",
      tabLabel: "Probation employee",
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "fulltime":
        return <FullTimeEmployee payRoll={allPayroll} />;
      case "intern":
        return <InternEmployee payRoll={allPayroll} />;
      case "probation":
        return <ProbationEmployee payRoll={allPayroll} />;
      default:
    }
  };

  const [payrollPageNumber, setPayrolPageNumber] = useState(1);
  const [payrollRowsPerPage, setPayrollRowsPerPage] = useState(10);

  const openAcceptPayroll = (id) => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/payRoll/update-payRoll?id=${id}`,
        { status: "true" },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllPayrolls();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  const openRejectPayroll = (id) => {

    axios
      .put(
        `${LOCALHOST_BASE_URL}/payRoll/update-payRoll?id=${id}`,
        { status: "false" },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllPayrolls();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  // Delete payroll
  const deletePayroll = async (id) => {
    try {
      await axios.delete(
        `${LOCALHOST_BASE_URL}/payRoll/delete-payRoll?id=${id}`,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getAllPayrolls();
      alert("Payroll deleted successfully");
    } catch (error) {
      console.log("Error deleting payroll:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  console.log("allPayroll", allPayroll);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/pay-roll"
        className={`text-[32px] flex items-center gap-[11px] ${
          mode === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
        }`}
      >
        <BsArrowLeft />
        Payment Details
      </Link>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="department"
            id="department"
            className={`py-2 text-[12px] px-2 mx-2 w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option
              value="department"
              selected
              disabled
              className="px-2 mx-2 test"
            >
              select month
            </option>
          </select>
          <select
            name="department"
            id="department"
            className={`py-2 text-[12px] px-2 mx-2 w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="department" selected disabled>
              select year
            </option>
          </select>
          <button className={`text-primaryBg border-b border-primaryBg`}>
            Clear Filter
          </button>
        </section>
        <div className="flex  gap-3">
          <Button intent="primary" mode={mode}>
            Download Excel Sheet
          </Button>
        </div>
      </div>
      <div>
        <section className="flex gap-5 items-center border-b">
          {tabHead.map((i) => {
            return (
              <>
                <h3
                  className={`pb-2 text-[14px] cursor-pointer ${
                    tab === i.tabName
                      ? "text-primaryBg border-b border-primaryBg"
                      : "text-primaryText"
                  }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabLabel}
                </h3>
              </>
            );
          })}
        </section>
        <section className="my-[33px]">{getCorrectPage(tab)}</section>
      </div>
    </div>
  );
};

const FullTimeEmployee = ({ payRoll }) => {
  console.log("payRoll", payRoll);

  const [newData, setNewData] = useState([]);
  console.log("newData", newData);

  useEffect(() => {
    // Filter the data where emp_type is "fulltime"
    const filteredData = payRoll?.filter(
      (item) => item?.emp_id?.emp_type === "fulltime"
    );
    setNewData(filteredData);
  }, [payRoll]);

  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);
  return (
    <div className="text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>Beneficiary Name</Th>
              <Th>Bank Name (User Bank)</Th>
              <Th>IFSC</Th>
              <Th>Account No</Th>
              <Th>Gross Salary(₹)</Th>
              <Th>ESI</Th>
              <Th>Net Payable</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData
              // ?.reverse()
              ?.slice(
                (ftPageNumber - 1) * ftRowPerPage,
                ftPageNumber * ftRowPerPage
              )
              ?.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2">
                        <section>
                          {data?.emp_id?.emp_profile ? (
                            <Avatar
                              type="img"
                              src={data?.emp_id?.emp_profile}
                              size="md"
                            />
                          ) : (
                            <Avatar size="md" type="text">
                              MD
                            </Avatar>
                          )}
                        </section>

                        <Link
                          to="/view-payslip"
                          className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                        >
                          {data?.emp_id?.emp_name}
                        </Link>
                      </div>
                    </Td>
                    <Td>{data?.emp_id?.financial?.emp_bankName}</Td>
                    <Td>{data?.emp_id?.financial?.emp_ifsc}</Td>
                    <Td>{data?.emp_id?.financial?.emp_accountNumber}</Td>
                    <Td>{data?.gross_Salary}</Td>
                    <Td>{data?.esi ? data?.esi : "-"}</Td>
                    <Td>{data?.netPayable}</Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={newData?.length || 0}
          setRowsPerPage={setFtRowPerPage}
          rowsPerPage={ftRowPerPage}
          setPageNumber={setFtPageNumber}
          pageNumber={ftPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const InternEmployee = ({ payRoll }) => {
  console.log("payRoll", payRoll);

  const [newData, setNewData] = useState([]);
  console.log("newData", newData);

  useEffect(() => {
    // Filter the data where emp_type is "intern"
    const filteredData = payRoll?.filter(
      (item) => item?.emp_id?.emp_type === "intern"
    );
    setNewData(filteredData);
  }, [payRoll]);

  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);
  return (
    <div className="text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>Beneficiary Name</Th>
              <Th>Bank Name (User Bank)</Th>
              <Th>IFSC</Th>
              <Th>Account No</Th>
              <Th>Gross Salary(₹)</Th>
              <Th>ESI</Th>
              <Th>Net Payable</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData
              // ?.reverse()
              ?.slice(
                (ftPageNumber - 1) * ftRowPerPage,
                ftPageNumber * ftRowPerPage
              )
              ?.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2">
                        <section>
                          {data?.emp_id?.emp_profile ? (
                            <Avatar
                              type="img"
                              src={data?.emp_id?.emp_profile}
                              size="md"
                            />
                          ) : (
                            <Avatar size="md" type="text">
                              MD
                            </Avatar>
                          )}
                        </section>

                        <Link
                          to="/view-payslip"
                          className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                        >
                          {data?.emp_id?.emp_name}
                        </Link>
                      </div>
                    </Td>
                    <Td>{data?.emp_id?.financial?.emp_bankName}</Td>
                    <Td>{data?.emp_id?.financial?.emp_ifsc}</Td>
                    <Td>{data?.emp_id?.financial?.emp_accountNumber}</Td>
                    <Td>{data?.gross_Salary}</Td>
                    <Td>{data?.esi ? data?.esi : "-"}</Td>
                    <Td>{data?.netPayable}</Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={newData?.length || 0}
          setRowsPerPage={setFtRowPerPage}
          rowsPerPage={ftRowPerPage}
          setPageNumber={setFtPageNumber}
          pageNumber={ftPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const ProbationEmployee = ({ payRoll }) => {
  console.log("payRoll", payRoll);

  const [newData, setNewData] = useState([]);
  console.log("newData", newData);

  useEffect(() => {
    // Filter the data where emp_type is "probation"
    const filteredData = payRoll?.filter(
      (item) => item?.emp_id?.emp_type === "probation"
    );
    setNewData(filteredData);
  }, [payRoll]);

  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);
  return (
    <div className="text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>Beneficiary Name</Th>
              <Th>Bank Name (User Bank)</Th>
              <Th>IFSC</Th>
              <Th>Account No</Th>
              <Th>Gross Salary(₹)</Th>
              <Th>ESI</Th>
              <Th>Net Payable</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData
              // ?.reverse()
              ?.slice(
                (ftPageNumber - 1) * ftRowPerPage,
                ftPageNumber * ftRowPerPage
              )
              ?.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2">
                        <section>
                          {data?.emp_id?.emp_profile ? (
                            <Avatar
                              type="img"
                              src={data?.emp_id?.emp_profile}
                              size="md"
                            />
                          ) : (
                            <Avatar size="md" type="text">
                              MD
                            </Avatar>
                          )}
                        </section>

                        <Link
                          to="/view-payslip"
                          className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                        >
                          {data?.emp_id?.emp_name}
                        </Link>
                      </div>
                    </Td>
                    <Td>{data?.emp_id?.financial?.emp_bankName}</Td>
                    <Td>{data?.emp_id?.financial?.emp_ifsc}</Td>
                    <Td>{data?.emp_id?.financial?.emp_accountNumber}</Td>
                    <Td>{data?.gross_Salary}</Td>
                    <Td>{data?.esi ? data?.esi : "-"}</Td>
                    <Td>{data?.netPayable}</Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={newData?.length || 0}
          setRowsPerPage={setFtRowPerPage}
          rowsPerPage={ftRowPerPage}
          setPageNumber={setFtPageNumber}
          pageNumber={ftPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

export default PayMentDetails;
