/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import { ContextThemeProvider } from "../context/ThemeContext";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import TablePagination from "../components/table/TablePagination";
import axios, { all } from "axios";
import { LOCALHOST_BASE_URL , apiKey ,token} from "../utils/config";
import Modal from "../components/Modal";
import Select from "react-select";
import { useSnackbar } from "../context/SnackbarContext";
import Avatar from "../components/Avatar";
import { Link } from "react-router-dom";

const Benefits = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [assignBenefitModal, setAssignBenefitModal] = useState(false);
  const openAssignBenefitModal = (id, benefitHeading, status, data) => {
    console.log(data);
    const benefitToEdit = benefits?.find((b) => b?._id === id);
    if (status) {
      // Set initial values for update here
      setAssignBenefitModal(true);
      setAssignBenefitData({
        ...assignBenefitData,
        ...data,
        benefitId: id,
        benefitHeading: benefitHeading,
        status: true, // Update status if necessary
        // Set other values as needed
      });
    } else {
      // Set initial values for add here
      setAssignBenefitModal(true);
      setAssignBenefitData({
        ...assignBenefitData,
        ...data,
        benefitId: id,
        benefitHeading: benefitHeading,
        status: false, // Update status if necessary
        // Set other values as needed
      });
    }
  };

  const [benefitsTab, setBenefitsTab] = useState("Created Benefits");

  const [assignBenefitData, setAssignBenefitData] = useState({});
  console.log("assignBenefitData", assignBenefitData);

  console.log("status", assignBenefitData?.status);
  const [selectedOption, setSelectedOption] = useState(null);
  // console.log("selectedOption", selectedOption);

  //   get all benefits
  const [benefits, setBenefits] = useState();

  const getAllBenefits = async () => {
  
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/benefit/get-all-benefit`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setBenefits(result?.data);
      } else {
        console.log("Error fetching benefits");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // delete benefits
  const deleteBenefits = async (id) => {
    try {
      const response = await axios.delete(
        `${LOCALHOST_BASE_URL}/benefit/delete-benefits?id=${id}`,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`, // Add the Bearer token to the header
          },
        }
      );

      if (response.status === 200) {
        getAllBenefits();
        console.log("Benefit deleted");
      }
    } catch (error) {
      showSnackbar(`Designation is not deleted`, "red");
      console.error("Error deleting benefit:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // add assign benefit
  const handleAddAssignBenefit = (id) => {

    axios
      .put(
        `${LOCALHOST_BASE_URL}/benefit/assign-benefit?id=${id}`,
        {
          ...assignBenefitData,
          emp_id: [],
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`, // Add the Bearer token to the header
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Benefit assigned");
        showSnackbar("Benefit Assigned.", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something is wrong, please try again.", "red");
      });
  };

  // update assign benefit
  const handleUpdateBenefit = (id) => {

    let newAssignBenefitData = assignBenefitData?.assigneBenefits?.filter(
      (data) => !data?.emp_name
    );
    console.log(newAssignBenefitData);
    axios
      .put(
        `${LOCALHOST_BASE_URL}/benefit/assign-benefit?id=${id}`,
        {
          emp_id: assignBenefitData?.emp_id,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        setAssignBenefitModal(false);
        getAllBenefits();
        console.log("Benefit updated");
        showSnackbar("Benefit Updated.", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something is wrong, please try again.", "red");
      });
  };

  useEffect(() => {
    getAllBenefits();
  }, []);

  const handleAssignAsset = (selectedOptions, e) => {
    console.log("currentEmployeId", selectedOptions);
    const selectedEmployeeIds = selectedOptions?.map((option) => option?.value);

    if (e.name === "select-employee") {
      // Extract selected employee IDs

      console.log(selectedEmployeeIds);
      setAssignBenefitData((prev) => ({
        ...prev,
        emp_id: selectedEmployeeIds,
      }));
    } else if (e.name === "select-all-employee") {
      if (e.checked) {
        console.log(
          "employeeee",
          filteredEmployees?.find((fdata) => fdata?._id === selectedEmployeeIds)
        );
        const allEmployeeIds = filteredEmployees.map((emp) => emp._id);
        setAssignBenefitData((prev) => ({
          ...prev,
          emp_id: allEmployeeIds,
          assigneBenefits: [
            ...prev.assigneBenefits,
            ...filteredEmployees?.find(
              (fdata) => fdata?._id === selectedEmployeeIds
            ),
          ],
        }));
      } else {
        setAssignBenefitData((prev) => ({
          ...prev,
          emp_id: [],
        }));
      }
    } else {
      // For other fields (e.g., shift_name, start_time, etc.), update as usual
      setAssignBenefitData((prev) => ({
        ...prev,
        [e.name]: e.target.value,
      }));
    }
  };

  const handleAssignAsset2 = (selectedOptions, act) => {
    let e = act;
    console.log(selectedOptions, act, e);
    const currentEmployeeId = act?.option?.value;
    console.log("currentEmployeeId", currentEmployeeId);

    console.log("currentEmployeId", selectedOptions);
    const selectedEmployeeIds = selectedOptions?.map((option) => option?.value);
    if (e.action !== "remove-value") {
      if (e.name === "select-employee") {
        // Extract selected employee IDs

        console.log(selectedEmployeeIds);
        console.log(
          "employeeee",
          filteredEmployees?.find((fdata) => fdata?._id === selectedEmployeeIds)
        );
        setAssignBenefitData((prev) => ({
          ...prev,
          emp_id: selectedEmployeeIds,
          assigneBenefits: [
            ...prev.assigneBenefits,
            filteredEmployees?.find(
              (fdata) => fdata?._id === currentEmployeeId
            ),
          ],
        }));
      } else if (e.name === "select-all-employee") {
        if (e.checked) {
          console.log(
            "employeeee",
            filteredEmployees?.find(
              (fdata) => fdata?._id === selectedEmployeeIds
            )
          );
          const allEmployeeIds = filteredEmployees.map((emp) => emp._id);
          setAssignBenefitData((prev) => ({
            ...prev,
            emp_id: allEmployeeIds,
            assigneBenefits: [
              ...prev.assigneBenefits,
              ...filteredEmployees?.find(
                (fdata) => fdata?._id === currentEmployeeId
              ),
            ],
          }));
        } else {
          setAssignBenefitData((prev) => ({
            ...prev,
            emp_id: [],
          }));
        }
      } else {
        // For other fields (e.g., shift_name, start_time, etc.), update as usual
        setAssignBenefitData((prev) => ({
          ...prev,
          [e.name]: e.target.value,
        }));
      }
    }
  };

  // Function to handle the "Select All Employees" checkbox change
  const handleSelectAllEmployeesChange = (e) => {
    if (e.target.checked) {
      const allEmployeeIds = filteredEmployees.map((emp) => emp._id);
      setAssignBenefitData({
        ...assignBenefitData,
        emp_id: allEmployeeIds,
      });
    } else {
      setAssignBenefitData({
        ...assignBenefitData,
        emp_id: [],
      });
    }
  };

  // handle individual checkbox changes for employees
  const handleEmployeeCheckboxChange = (employeeId) => {
    const updatedEmpIds = [...assignBenefitData.emp_id];
    if (updatedEmpIds.includes(employeeId)) {
      updatedEmpIds.splice(updatedEmpIds.indexOf(employeeId), 1);
    } else {
      updatedEmpIds.push(employeeId);
    }
    setAssignBenefitData({
      ...assignBenefitData,
      emp_id: updatedEmpIds,
    });
  };

  const filteredEmployees =
    deptData?.find((dept) => dept?.dept_name === assignBenefitData?.dept)
      ?.employee || [];

  console.log("filteredEmployees", filteredEmployees);

  const benefitsTabData = [
    {
      id: 1,
      tabName: "Created Benefits",
    },
    {
      id: 2,
      tabName: "Assigned Benefits",
    },
  ];

  const getCorrectPage = (benefitsTab) => {
    switch (benefitsTab) {
      case "Created Benefits":
        return (
          <CreatedBenefits
            mode={mode}
            benefits={benefits}
            navigate={navigate}
            deleteBenefits={deleteBenefits}
            openAssignBenefitModal={openAssignBenefitModal}
            assignBenefitModal={assignBenefitModal}
            allDepartment={deptData}
            filteredEmployees={filteredEmployees}
            organisation={organisation}
            handleAssignAsset={handleAssignAsset}
            setAssignBenefitData={setAssignBenefitData}
            assignBenefitData={assignBenefitData}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleSelectAllEmployeesChange={handleSelectAllEmployeesChange}
            handleAddAssignBenefit={handleAddAssignBenefit}
          />
        );
      case "Assigned Benefits":
        return (
          <AssignBenefit
            mode={mode}
            benefits={benefits}
            navigate={navigate}
            deleteBenefits={deleteBenefits}
            openAssignBenefitModal={openAssignBenefitModal}
            assignBenefitModal={assignBenefitModal}
            allDepartment={deptData}
            filteredEmployees={filteredEmployees}
            organisation={organisation}
            handleAssignAsset={handleAssignAsset}
            setAssignBenefitData={setAssignBenefitData}
            assignBenefitData={assignBenefitData}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleSelectAllEmployeesChange={handleSelectAllEmployeesChange}
            handleAddAssignBenefit={handleAddAssignBenefit}
          />
        );
      default:
    }
  };

  console.log(assignBenefitData);

  console.log(assignBenefitData?.edit);

  const removeAddedEmpFromBenefit = (teamId, empId) => {

    try {
      axios
        .put(
          `${LOCALHOST_BASE_URL}/benefit/remove-assign-benefit?id=${teamId}&emp_id=${empId}`,
          null,
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log("removeAddedEmpFromTeam", response.data);
          }
        })
        .catch((error) => {
          showSnackbar(`Something went wrong, please try again`, "red");
          console.log("Here is the Error from then-Catch", error);
        });
    } catch (error) {
      console.log("Here is the try-Catch", error);
    }
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="flex justify-between gap-4 items-center">
        <h1 className={`text-[32px] text-primaryText`}>Benefits</h1>
        <Button
          intent="primary"
          mode={mode}
          onClick={() => navigate("/create-benefits")}
        >
          Create New Benefits
        </Button>
      </div>
      {/* assign benefit modal */}
      <Modal
        showModal={assignBenefitModal}
        setShowModal={setAssignBenefitModal}
      >
        <div className="w-[638px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center mb-5">
            {assignBenefitData?.edit ? "Edit" : "Add Benefits"}
          </h1>

          <div className="pb-4 border-b">
            <div className="grid grid-cols-2 gap-5">
              <section className="flex flex-col gap-[6px] mb-2">
                <label htmlFor="dept">Select Department</label>
                <select
                  name="dept"
                  id="dept"
                  value={assignBenefitData?.dept}
                  required
                  onChange={(e) =>
                    setAssignBenefitData({
                      ...assignBenefitData,
                      dept: e.target.value,
                    })
                  }
                  // onChange={handleAssignAsset}
                  className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                >
                  <option value="dept" selected disabled>
                    Select Department
                  </option>
                  {deptData?.map((dept) => (
                    <option value={dept?.dept_name}>{dept?.dept_name}</option>
                  ))}
                </select>
              </section>
              <section className="flex flex-col gap-[6px] mb-2">
                <label htmlFor="dept">Select Employee</label>
                <Select
                  isMulti
                  // value={assignBenefitData?.emp_id}
                  onChange={(selectedOptions, action) =>
                    handleAssignAsset2(selectedOptions, action)
                  }
                  options={filteredEmployees?.map((emp) => ({
                    value: emp?._id,
                    label: emp?.emp_name,
                  }))}
                  name="select-employee"
                />
              </section>
            </div>
            <section className="flex flex-col gap-[6px] justify-start items-start mb-2">
              <label htmlFor="dept">Select All Employee</label>
              <input
                type="checkbox"
                name="select-all-employees"
                onChange={handleSelectAllEmployeesChange}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500`}
              />
            </section>
          </div>

          {/* selected employees */}
          <div className="mt-5">
            <h1 className="text-[20px] font-medium text-[#333] text-center mb-5">
              Selected Employees
            </h1>
            {/* <div className="grid grid-cols-4 gap-1">
              {assignBenefitData?.status
                ? assignBenefitData?.assigneBenefits?.map((data) => {
                    return (
                      <>
                        <section
                          key={data?._id}
                          className="flex gap-2 items-center whitespace-nowrap"
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              console.log(
                                data?.emp_id?._id,
                                data?.emp_id?.emp_name
                              );
                              if (e.target.checked) {
                                setAssignBenefitData((prev) => ({
                                  ...prev,
                                  assigneBenefits: [
                                    ...prev?.assigneBenefits,
                                    data?.emp_id?._id,
                                  ],
                                }));
                              } else {
                                setAssignBenefitData((prev) => ({
                                  ...prev,
                                  assigneBenefits:
                                    prev?.assigneBenefits?.filter((emp) => {
                                      return (
                                        emp?.emp_id?._id !== data?.emp_id?._id
                                      );
                                    }),
                                }));
                              }
                            }}
                            checked={assignBenefitData?.assigneBenefits?.some(
                              (d) => {
                                return d?.emp_id?._id === data?.emp_id?._id;
                              }
                            )}
                          />
                          <span>
                            {data?.emp_name || data?.emp_id?.emp_name}
                          </span>
                        </section>
                      </>
                    );
                  })
                : filteredEmployees
                    ?.filter((employee) =>
                      assignBenefitData?.emp_id?.includes(employee?._id)
                    )
                    .map((employee) => (
                      <section
                        key={employee?._id}
                        className="flex gap-2 items-center whitespace-nowrap"
                      >
                        <input type="checkbox" checked />
                        <span>{employee?.emp_name}</span>
                      </section>
                    ))}
            </div> */}

            <table className="w-full border text-left">
              <thead>
                <tr className="bg-[#F8F9FF] text-primaryTextLight text-[14px] font-[500]">
                  <th className="p-2">Employee name</th>
                  <th>Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assignBenefitData?.assigneBenefits?.map((data) => {
                  return (
                    <>
                      <tr>
                        <td className="flex gap-2 px-2 py-2">
                          {" "}
                          {data?.emp_id?.emp_profile ? (
                            <Avatar
                              type="img"
                              src={data?.emp_id?.emp_profile}
                              size="md"
                            />
                          ) : (
                            <Avatar size="md" type="text">
                              {data?.emp_id?.emp_name?.split(" ")[0][0] +
                                data?.emp_id?.emp_name?.split(" ")[1][0]}
                            </Avatar>
                          )}
                          {data?.emp_id?.emp_name}
                        </td>
                        <td>{data?.emp_id?.emp_designation?.des_name}</td>
                        <td>
                          <div className="flex gap-3 items-center">
                            <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out">
                              <RiDeleteBin6Line
                                color="#D20E0E"
                                onClick={(e) =>
                                  removeAddedEmpFromBenefit(
                                    assignBenefitData?.benefitId,
                                    data?.emp_id?._id
                                  )
                                }
                              />
                            </h2>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <section className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              // onClick={() =>
              //   handleAddAssignBenefit(assignBenefitData?.benefitId)
              // }
              // onClick={() => {
              //   handleAddAssignBenefit(assignBenefitData?.benefitId);
              //   setAssignBenefitData({
              //     // ...assignBenefitData,
              //     edit: true,
              //   });
              // }}
              onClick={() => handleUpdateBenefit(assignBenefitData?.benefitId)}
            >
              Assign
            </Button>
          </section>
        </div>
      </Modal>
      <div>
        <section className="my-[28px] flex gap-6 border-b">
          {benefitsTabData.map((i) => {
            return (
              <>
                <h3
                  className={`cursor-pointer pb-2 transition-all ease ${
                    benefitsTab === i.tabName
                      ? "text-primaryBg border-b-2 border-primaryBg"
                      : "text-primaryText"
                  }`}
                  onClick={() => {
                    setBenefitsTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <section>{getCorrectPage(benefitsTab)}</section>
      </div>
    </div>
  );
};

const CreatedBenefits = ({
  mode,
  benefits,
  navigate,
  deleteBenefits,
  openAssignBenefitModal,
  assignBenefitModal,
  setAssignBenefitModal,
  allDepartment,
  filteredEmployees,
  organisation,
  handleAssignAsset,
  setAssignBenefitData,
  assignBenefitData,
  selectedOption,
  setSelectedOption,
  handleSelectAllEmployeesChange,
  handleAddAssignBenefit,
}) => {
  // const employeeOptions = filteredEmployees?.map((emp) => ({
  //   value: emp?._id,
  //   label: emp?.emp_name,
  // }));

  // const [selectedEmployee, setSelectedEmployee] = useState(null);

  // const handleChange = (selectedOption) => {
  //   setSelectedEmployee(selectedOption);
  // };

  const getEmployeeNamesFromIds = (employees, empIds) => {
    return empIds?.map((empId) => {
      const employee = employees?.find((emp) => emp?._id === empId);
      return employee ? employee.emp_name : "Employee Not Found";
    });
  };

  const empNames = getEmployeeNamesFromIds(
    filteredEmployees,
    assignBenefitData?.emp_id
  );
  // console.log("empNames", empNames);

  // check if an employee ID is included in assignBenefitData.emp_id
  const isSelected = (employeeId) =>
    assignBenefitData.emp_id?.includes(employeeId);

  return (
    <>
      <div className="grid grid-cols-3 gap-[26px] my-[54px]">
        {benefits?.map((d) => (
          <section
            className={`w-[342px] h-[200px] shadow-md rounded py-3 cardShadow ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <div className="flex justify-between items-center pr-3">
              <h2 className="text-primaryText px-4 font-semibold text-[20px]">
                {d?.benefit_heading}
              </h2>
              <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                onClick={() => navigate(`/create-benefits/${d?._id}`)}
              >
                <FiEdit2 color="var(--primaryText)" />
              </h2>
            </div>
            <p className="h-[80px] text-[12px] my-2 px-4 text-primaryText line-clamp-2">
              {d?.benefit_description}
            </p>
            <div className="flex justify-center items-center gap-4">
              <Button
                onClick={() =>
                  openAssignBenefitModal(d?._id, d?.benefit_heading, false, d)
                }
              >
                Assign
              </Button>
              <Button
                intent="secondary"
                mode={mode}
                onClick={() => navigate(`/view-benefit/${d?._id}`)}
              >
                View
              </Button>
              {/* <Button
                intent="primary"
                mode={mode}
                onClick={() => deleteBenefits(d?._id)}
              >
                Delete
              </Button> */}
            </div>
          </section>
        ))}
      </div>
    </>
  );
};

const AssignBenefit = ({
  mode,
  organisation,
  openAssignBenefitModal,
  assignBenefitModal,
  setAssignBenefitModal,
  benefits,
  setBenefits,
  assignBenefitData,
  handleAddAssignBenefit,
  deleteBenefits,
}) => {
  return (
    <>
      <div className="text-primaryText grid grid-cols-4 gap-4">
        {benefits?.map((b) => (
          <section
            className={`w-[248px] h-[124px] px-[23px] py-[19px] rounded flex flex-col justify-between  ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-primaryLight"
            }`}
          >
            <div className="flex justify-between items-center">
              <h3 className="line-clamp-1">{b?.benefit_heading}</h3>
              <div className="flex gap-1 items-center">
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                  onClick={() =>
                    openAssignBenefitModal(b?._id, b?.benefit_heading, true, b)
                  }
                >
                  <FiEdit2 />
                </h2>
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                  onClick={() => deleteBenefits(b?._id)}
                >
                  <RiDeleteBin6Line />
                </h2>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <section className="flex">
                {b?.assigneBenefits?.map((assign) => (
                  <h1 className="ml-[-0.2em]">
                    <Avatar
                      type="img"
                      src={assign?.emp_id?.emp_profile}
                      size="sm"
                    />
                  </h1>
                ))}
              </section>
              <Link
                to={`/view-benefit/${b?._id}`}
                className="text-primaryBg border-b border-primaryBg"
              >
                View Benefit
              </Link>
            </div>
          </section>
        ))}
      </div>
    </>
  );
};

export default Benefits;
