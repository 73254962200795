/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { LOCALHOST_BASE_URL, token, apiKey } from "../utils/config";
import jwt_decode from "jwt-decode";
export const ContextThemeProvider = createContext();

const colors = [
  "neon-blue",
  "indigo",
  "rose",
  "violet",
  "darkblue",
  "brown",
  "slateblue",
  "darkbrown",
  "charcoal",
];
const modes = ["light", "dark"];

const ThemeContext = ({ children }) => {
  const [theme, setTheme] = useState();
  const [changeColor, setChangeColor] = useState(false);

  const [color, setColor] = useState();
  const [mode, setMode] = useState(modes[0]);

  // get all themes
  const getAllThemes = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/theme/get-All-Theme`
      );
      setTheme(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const [teamData, setTeamData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [desData, setDesData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [error, setError] = useState(null);

  const getRequiredData = async () => {
    try {
      // Set the headers with the API key
      const headers = {
        "api-key": apiKey,
      };

      const departmentsResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/departments/get-all-departments`,
        { headers }
      );
      const designationsResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/designations/get-all-designations`,
        { headers }
      );
      const teamResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/team/get-all-team`,
        { headers }
      );
      const shiftResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/shift/get-all-shift`,
        { headers }
      );

      const orgResponse = await axios.get(
        `${LOCALHOST_BASE_URL}/organisation/getOne-organisation`,
        { headers }
      );

      // Check for successful responses and set the data
      if (departmentsResponse?.status === 200) {
        setDeptData(departmentsResponse?.data);
      } else {
        setError("Error fetching departments");
      }

      if (designationsResponse?.status === 200) {
        setDesData(designationsResponse?.data);
      } else {
        setError("Error fetching designations");
      }

      if (teamResponse?.status === 200) {
        setTeamData(teamResponse?.data);
      } else {
        setError("Error fetching teams");
      }

      if (shiftResponse?.status === 200) {
        setShiftData(shiftResponse?.data);
      } else {
        setError("Error fetching shift");
      }

      console.log("Theme Context", orgResponse?.data?.result?.[0]);
      if (orgResponse?.status === 200) {
        setOrgData(orgResponse?.data?.result?.[0]);
      } else {
        setError("Error fetching shift");
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    }
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    // This effect runs when `token` state changes
    if (token) {
      try {
        const decoded = jwt_decode(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Error decoding the token: ", error);
        // Handle the error appropriately, e.g., by clearing the token or redirecting to login
      }
    }
  }, [token]);

  const [organisation, setOrganisation] = useState();
  const getOrganisation = async () => {
    // const id = "6500414ba8cd139df986127a";
    try {
      // Set the headers with the API key
      const headers = {
        "api-key": apiKey,
      };
      const result1 = await axios.get(
        // `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${decodedToken?.id}`,
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${decodedToken?.id}`,
        { headers }
      );
      const theme = result1?.data?.employee?.organisationTheme;
      if (!theme) {
        const result = await axios.get(
          `${LOCALHOST_BASE_URL}/organisation/getOne-organisation`,
          { headers }
        );
        setOrganisation(result?.data?.result?.[0]);
      } else {
        setOrganisation(result1?.data?.employee);
      }
      // setColor(result?.data?.result?.[0]?.themeColor)
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    // This effect runs when `decodedToken` state changes
    if (decodedToken) {
      getAllThemes();
      getOrganisation();
      getRequiredData();
    }
  }, [decodedToken]);

  return (
    <ContextThemeProvider.Provider
      value={{
        color,
        setColor,
        mode,
        setMode,
        modes,
        theme,
        setTheme,
        organisation,
        setOrganisation,
        changeColor,
        setChangeColor,
        getOrganisation,
        deptData,
        desData,
        teamData,
        shiftData,
        orgData,
      }}
    >
      {children}
    </ContextThemeProvider.Provider>
  );
};

export default ThemeContext;
