/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { GrGroup } from "react-icons/gr";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Badge from "../../components/Badge";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { TbClockExclamation } from "react-icons/tb";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import UserNav from "./UserNav";
import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "../../context/SnackbarContext";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { FiClock } from "react-icons/fi";
import Modal from "../../components/Modal";
import workingMonth from "../../assets/Userside/Whait mode/Worked this month.svg";
import workingMonthdark from "../../assets/Userside/black mode/Worked this month.svg";
import leaveMonth from "../../assets/Userside/Whait mode/Leave this month.svg";
import leaveMonthdark from "../../assets/Userside/black mode/Leave this month.svg";
import leaveYear from "../../assets/Userside/Whait mode/Taken leave this year.svg";
import leaveYeardark from "../../assets/Userside/black mode/Taken leave this year.svg";
import joiningDate from "../../assets/Userside/Whait mode/Joining Date.svg";
import joiningDatedark from "../../assets/Userside/black mode/Joining Date.svg";
import { getGreetMsg } from "../../utils/utils";
import { LOCALHOST_BASE_URL, apiKey, token } from "../../utils/config";

const UserDashboard = () => {
  const { color, mode, organisation, orgData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  
 // Declare decodedToken as a state variable
 const [decodedToken, setDecodedToken] = useState(null);


  
 console.log("tokenpeoples", token);
 const isTestTokenUndefined = token === undefined;
 console.log("isTestTokenUndefined", isTestTokenUndefined);
 if (isTestTokenUndefined === true) {
   console.log("Test token is undefined. Returning true.");
   
 } else {
   console.log("Test token is defined. Returning false.");
 }

 useEffect(() => {
   try {
     const decoded = jwt_decode(token);
     // Set the decoded token to the state
     setDecodedToken(decoded);
   } catch (error) {
     console.error("Error decoding token:", error.message);
     // Handle the error, e.g., redirect to login page or show an error message
   }
 }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

 // Now you can use decodedToken anywhere within the component
 console.log(decodedToken);



  const [todaysDate, setTodaysDate] = useState();

  // get date in 14 Oct 2023 format
  function formatDate1(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  // function to get todays date
  const getTodayDate = () => {
    const today = new Date();
    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  // holiday date
  const holidayDate = (timestamp) => {
    if (!timestamp) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    return (
      <div className="text-center">
        <p>{day}</p>
        <p>{month}</p>
      </div>
    );
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return ""; // or return a placeholder if you prefer
    }
    const time = new Date(timeString);
    return time.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  useEffect(() => {
    getTodayDate();
  });

  // get holidays
  const [holidays, setHoliday] = useState();
  // console.log("userHolidays", userHolidays);

  // get user leave
  const [userLeave, setUserLeave] = useState();
  // console.log("userLeave", userLeave);

  // get user attendance
  const [userAttendance, setUserAttendance] = useState();

  // get birthdays and work anniversary
  const [birthdaysAndWorkAnnDash, setBirthdaysAndWorkAnnDash] = useState();

  // new joining
  const [newJoining, setNewJoining] = useState();

  const getNewJoining = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetter/get-offerLetter/get_emp_joiningDate`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setNewJoining(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const getUserAttendance = async () => {
    // Your API key

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/getAttendanceByUser?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      // console.log("result: ", result);
      setUserAttendance(result?.data);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  const lastEntry = userAttendance?.[userAttendance?.length - 1];

  const getHoliday = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/holiday/get-all-holiday`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        const sortData = result.data.sort(
          (a, b) =>
            new Date(b?.holiday_day)?.getTime() -
            new Date(a?.holiday_day)?.getTime()
        );
        setHoliday(sortData);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  const getUserLeave = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getLeaveByEmployee?id=6500414ba8cd139df986127a`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setUserLeave(result?.data?.leaveRecords);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result?.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  // punch in
  const handlePunchIn = () => {
    // Your API key

    const requestBody = {
      emp_id: decodedToken?.id,
    };

    axios
      .post(`${LOCALHOST_BASE_URL}/attendance/punchIn`, requestBody, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Punch In Successful");
        getUserAttendance();
        showSnackbar("Punch In Successful", "green");
      })
      .catch((error) => {
        console.log("error", error);
        showSnackbar("Punch In Unsuccessful", "red");
      });
  };

  // punch out
  const handlePunchOut = () => {
    // Assuming you need to send emp_id in the request body
    const requestBody = {
      // date: new Date().toISOString().split('T')[0], // Today's date in 'YYYY-MM-DD' format
      attendanceId: lastEntry?._id,
      emp_id: decodedToken?.id,
    };

    console.log("requestBody", requestBody);

    axios
      .put(`${LOCALHOST_BASE_URL}/attendance/punchOut`, requestBody, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Punch Out Successful");
        getUserAttendance();
        showSnackbar("Punch Out Successful", "green");
      })
      .catch((error) => {
        console.log("error", error);
        showSnackbar("Punch Out Unsuccessful", "red");
      });
  };

  // get birthdays and work anniversary
  const getBirthdaysAndWorkAnniversary = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getBirthdaysAndAnniversaries?data=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setBirthdaysAndWorkAnnDash(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getHoliday();
    getUserLeave();
    getUserAttendance();
    getBirthdaysAndWorkAnniversary();
    getNewJoining();
  }, []);

  const dashboardCards = [
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? workingMonthdark
          : workingMonth,
      cardHeader: "Worked this month",
      cardBody: 1000,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? leaveMonthdark
          : leaveMonth,
      cardHeader: "Leave this month",
      cardBody: 1000,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? leaveYeardark
          : leaveYear,
      cardHeader: "Taken leave this year",
      cardBody: 1000,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? joiningDatedark
          : joiningDate,
      cardHeader: "Joining Date",
      cardBody: 1000,
    },
  ];

  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  useEffect(() => {
    if (lastEntry?.punch_in) {
      const punchInTime = new Date(lastEntry?.punch_in);
      const currentTime = new Date();

      const timeDifference = currentTime - punchInTime;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setElapsedTime(`${hours} : ${minutes} : ${seconds} hrs`);
    }
  }, [lastEntry?.punch_in]);

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-primaryLight"
      } `}
    >
      <section className={`flex justify-between items-center`}>
        <h1 className={`text-[32px]`}>
          <span
            className={`font-[500] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            {getGreetMsg()}’
          </span>{" "}
          <span className="text-primaryBg font-[600]">
            {decodedToken?.emp_name}
          </span>
        </h1>
        <div className="flex items-center gap-4">
          {lastEntry?.punch_out ? (
            <Button intent="secondary" mode={mode} onClick={handlePunchIn}>
              <section className="flex items-center gap-3">
                <FiClock size={20} />
                <div className="text-left">
                  <h4 className="text-[12px] font-semibold">Check In</h4>
                  <span>{lastEntry?.production_time} hrs</span>
                </div>
              </section>
            </Button>
          ) : (
            <Button intent="primary" mode={mode} onClick={handlePunchOut}>
              <section className="flex items-center gap-3">
                <FiClock size={20} />
                <div className="text-left">
                  <h4 className="text-[12px] font-semibold">Check Out</h4>
                  <span>{elapsedTime} hrs</span>
                </div>
              </section>
            </Button>
          )}
        </div>
      </section>

      <div className="py-[34px] grid grid-cols-4 gap-[45px]">
        {dashboardCards?.map((card) => (
          <section
            className={`py-[20px] px-4 rounded-lg flex gap-5 ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            } `}
          >
            <p
              className={`${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-primaryLight"
              } w-[56px] h-[56px] flex justify-center items-center rounded-full bg-primaryLight`}
            >
              <img src={card?.cardIcon} alt="" />
            </p>
            <div>
              <span className={`text-[14px] text-[#A3AED0]`}>
                {card?.cardHeader}
              </span>
              <h1
                className={`mt-2 text-[24px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-[#2B3674]"
                } `}
              >
                {card?.cardBody}
              </h1>
            </div>
          </section>
        ))}
      </div>

      <div className="flex gap-4 mb-[57px]">
        <section
          className={`w-[65%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center pr-[31px]`}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Attendance
            </span>
            <Link
              to="/attendance"
              className={`text-[12px] flex items-center  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryBg"
              }`}
            >
              <span>View All</span>
              <MdKeyboardArrowRight />
            </Link>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
            <table className="border-collapse text-left w-full text-[14px]">
              <thead
                className={`h-[60px] font-medium sticky top-0 ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-[#A3AED0] bg-primaryDark"
                    : "text-[#A3AED0] bg-white"
                } `}
              >
                <tr>
                  <th>Date</th>
                  <th>Check in</th>
                  <th>Check out</th>
                  <th>Duration</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userAttendance?.map((report) => (
                  <tr
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    } `}
                  >
                    <td className="py-2">{report?.date}</td>
                    <td>{formatTime(report?.punch_in)}</td>
                    <td>{formatTime(report?.punch_out)}</td>
                    <td>{report?.production_time}</td>
                    <td>
                      <div className="flex">
                        <Badge
                          intent={
                            report?.punch_in_status === "On time"
                              ? "success"
                              : "warning"
                          }
                          size="medium"
                          roundness="pill"
                        >
                          {report?.punch_in_status}
                        </Badge>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        <section
          className={`w-[35%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`mb-4 pr-[31px] `}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Holidays
            </span>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[250px] pr-[31px]">
            {holidays?.map((day) => (
              <section
                className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <div
                  className={`w-[50px] h-[50px] rounded-full flex items-center justify-center flex-col text-[14px] font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "bg-secondaryDark text-primaryTextDark"
                      : "bg-primaryLight text-primaryBg"
                  }`}
                >
                  {holidayDate(day?.holiday_day)}
                </div>
                <p
                  className={`font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#2B3674]"
                  }`}
                >
                  {day?.holiday_name}
                </p>
              </section>
            ))}
          </div>
        </section>
      </div>

      <div className="flex gap-4">
        <section
          className={`w-[35%] h-[345px] pl-[23px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center mb-4 pr-[23px] `}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Birthdays / Work Anniversary
            </span>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[250px] pr-[23px]">
            {birthdaysAndWorkAnnDash?.map((ann) => (
              <section
                className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                {ann?.emp_profile ? (
                  <Avatar type="img" src={ann?.emp_profile} size="lg" />
                ) : (
                  <Avatar size="lg" type="text">
                    {ann?.emp_name.slice(0, 1)}
                  </Avatar>
                )}
                <div>
                  <h3
                    className={`font-[700] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    {ann?.emp_name}
                  </h3>
                  <h3
                    className={`font-[500] text-[12px] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#A3AED0]"
                    }`}
                  >
                    {ann?.workAnnouncementType} {ann?.birthdayAnnouncementType}
                  </h3>
                </div>
              </section>
            ))}
          </div>
        </section>
        <section
          className={`w-[65%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center pr-[31px]`}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Leave
            </span>
            <Link
              to="/leave"
              className={`text-[12px] flex items-center  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryBg"
              }`}
            >
              <span>View All</span>
              <MdKeyboardArrowRight />
            </Link>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
            <table className="border-collapse text-left w-full text-[14px]">
              <thead
                className={`h-[60px] font-medium ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-[#A3AED0]"
                    : "text-[#A3AED0]"
                } `}
              >
                <tr>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userLeave?.map((l) => (
                  <tr
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    } `}
                  >
                    <td className="py-2">
                      {formatDate1(l?.from_date)} - {formatDate1(l?.to_date)}
                    </td>
                    <td>{l?.duration} day</td>
                    <td>
                      <span className="capitalize">
                        {l?.leave_type?.leave_type}
                      </span>
                    </td>
                    <td>
                      <p className="capitalize flex items-center gap-1">
                        <span>
                          {l?.leave_status === "approved" ? (
                            <AiOutlineCheck color="#08A758" />
                          ) : l?.leave_status === "reject" ? (
                            <AiOutlineClose color="#FF3838" />
                          ) : (
                            <TbClockExclamation color="#F9A000" />
                          )}
                        </span>
                        {l?.leave_status === "approved" ? (
                          <p>Approved</p>
                        ) : l?.leave_status === "reject" ? (
                          <p>Reject</p>
                        ) : (
                          <p>Pending</p>
                        )}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <section
        className={`w-[35%] h-[345px] pl-[23px] py-[19px] rounded-lg mt-[33px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-primaryDark"
            : "bg-white"
        }`}
      >
        <h1 className={`flex justify-between items-center mb-4 pr-[23px] `}>
          <span
            className={`text-[24px] font-[700] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-[#2B3674]"
            }`}
          >
            New joining
          </span>
        </h1>
        <div className="scrollContainer overflow-y-auto h-[250px] pr-[23px]">
          {newJoining?.map((n) => (
            <section
              className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
              }`}
            >
              <Avatar size="lg" type="text">
                {n?.emp_name?.slice(0, 1)}
              </Avatar>
              <div>
                <h3
                  className={`font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#2B3674]"
                  }`}
                >
                  {n?.emp_name}
                </h3>
                <h3
                  className={`font-[500] text-[12px] line-clamp-1 ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#A3AED0]"
                  }`}
                >
                  {n?.designation?.des_name}
                </h3>
              </div>
            </section>
          ))}
        </div>
      </section>
    </div>
  );
};

export default UserDashboard;
