/* eslint-disable no-unused-vars */
import { useContext, useState } from "react";
import Button from "../../components/Button";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { BsUpload } from "react-icons/bs";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey , token} from "../../utils/config";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useSnackbar } from "../../context/SnackbarContext";

const resignationReasons = [
  "Looking for career growth.",
  "Career change.",
  "Better compensation.",
  "Health reasons.",
  "Organisational restructuring.",
  "Unhealthy Work Environment",
  "Lack of Growth",
  "Further Education",
  "Other",
];

const ResignationReasons = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const decodedToken = jwt_decode(token);

  console.log("decodedToken", decodedToken);

  const [resignData, setResignData] = useState({
    serveNoticePeriod:true
  });
  console.log("resignData", resignData);

  const [image, setImage] = useState(null);

  const [multiResponse, setMultiResponse] = useState([]);
  console.log("multiResponse", multiResponse);

  // add resignation
  const handleAddResignation = () => {
    axios
      .post(
        `${LOCALHOST_BASE_URL}/resignation/add-resignation`,
        {
          resignation_date: "2023-11-26",
          notice_date: "2023-12-26",
          check1: multiResponse?.check1,
          check2: multiResponse?.check2,
          check3: multiResponse?.check3,
          check4: multiResponse?.check4,
          check5: multiResponse?.check5,
          check6: multiResponse?.check6,
          check7: multiResponse?.check7,
          check8: multiResponse?.check8,
          check9: multiResponse?.check9,
          question1: resignData?.question1,
          question2: resignData?.question2,
          question3: resignData?.question3,
          question4: resignData?.question4,
          question5: resignData?.question5,
          question6: resignData?.question6,
          question7: resignData?.question7,
          question8: resignData?.question8,
          question9: resignData?.question9,
          // signature: ,
          reason: resignData?.reason,
          emp_id: decodedToken?.id,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Resignation added");
        showSnackbar("Details Updated.", "green");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] text-primaryText ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] mb-[32px]`}>
        Are you sure you want to resign a company?
      </h1>
      <div>
        <section className="flex flex-col gap-[6px] mb-[15px]">
          <label htmlFor="reason" className="text-[20px] font-semibold">
            Why are you resigning from this job?
          </label>
          <textarea
            name="description"
            id="description"
            cols="5"
            rows="3"
            value={resignData?.reason}
            onChange={(e) =>
              setResignData({
                ...resignData,
                reason: e.target.value,
              })
            }
            className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 resize-y w-[602px]`}
          ></textarea>
        </section>

        <section className="w-[602px] mb-5 text-primaryText">
          <h1 className="text-[20px] font-medium mb-4">
            Reason for leaving (required)
          </h1>
          <div className="">
            {resignationReasons?.map((option, index) => (
              <div
                key={index}
                className={`flex justify-between items-center border-b py-3  ${
                  multiResponse?.[`check${index + 1}`] ? "active" : ""
                }`}
              >
                <label className="font-semibold" htmlFor={index}>
                  {option}
                </label>
                <input
                  type="checkbox"
                  id={index}
                  name={`check${index + 1}`}
                  value={option}
                  checked={multiResponse?.[`check${index + 1}`]}
                  onChange={(e) => {
                    const checkboxKey = `check${index + 1}`;
                    setMultiResponse((prev) => ({
                      ...prev,
                      [checkboxKey]: e.target.checked,
                    }));
                  }}
                />
              </div>
            ))}
          </div>
        </section>

        {multiResponse?.check9 === true ? (
          <section className="flex flex-col gap-[6px] mb-10">
            <label htmlFor="reason" className="text-[20px] font-semibold">
              Other
            </label>
            <textarea
              name="description"
              id="description"
              cols="5"
              rows="3"
              className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 resize-y w-[602px]`}
            ></textarea>
          </section>
        ) : (
          ""
        )}

        <div className="my-5 ">
          <h1 className="text-[24px] font-medium text-primaryText mb-5">
            Apply for Resignation
          </h1>
          <section className="grid grid-cols-2 gap-x-[40px] gap-y-5">
            {/* q1 */}
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Resign Date
              </p>
              <input
                type="date"
                name="question1"
                value={resignData?.question1}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question1: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Serve Notice Period <span className="text-red-500">*</span>
                {/* if no then show last working date otherwise show notice date */}
              </p>
              <section className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <input
                    type="radio"
                    id="yes"
                    name="serveNoticePeriod"
                    value="yes"
                    // checked={resignData?.serveNoticePeriod === true}
                    defaultChecked
                    onChange={() =>
                      setResignData({ ...resignData, serveNoticePeriod: true })
                    }
                    className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "bg-transparent"
                        : ""
                    } `}
                  />
                  <label for="yes">Yes</label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="radio"
                    name="serveNoticePeriod"
                    id="no"
                    value="no"
                    checked={resignData.serveNoticePeriod === false}
                    onChange={() =>
                      setResignData({ ...resignData, serveNoticePeriod: false })
                    }
                    className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "bg-transparent"
                        : ""
                    } `}
                  />
                  <label for="no">No</label>
                </div>
              </section>
              {/* <input
                name="question2"
                value={resignData?.question2}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question2: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              /> */}
            </div>

            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                {resignData?.serveNoticePeriod === true
                  ? "Notice Period Date"
                  : " Last working date"}
              </p>
              <input
                type="date"
                name="notice_date"
                value={resignData?.notice_date}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    notice_date: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>

            {/* q4 */}
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Why are you resigning from this job?{" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="question4"
                value={resignData?.question4}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question4: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Do you think management gave you enough opportunities to air
                your views? <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="question5"
                value={resignData?.question5}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question5: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Did you have any goals when you joined the company?{" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="question6"
                value={resignData?.question6}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question6: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                What would you need to change for you to consider returning to
                this job? <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="question7"
                value={resignData?.question7}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question7: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                If you were to change anything here, what would it be?{" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="question8"
                value={resignData?.question8}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question8: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Are there any Reasons to Not Give One month Notice period?
                (answer only if you're not serving notice period)
              </p>
              <input
                type="text"
                name="question9"
                value={resignData?.question9}
                onChange={(e) =>
                  setResignData({
                    ...resignData,
                    question9: e.target.value,
                  })
                }
                className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-transparent"
                    : ""
                } `}
              />
            </div>
            <div>
              <p className="text-primaryText mb-[2px] font-[500]">
                Signature Upload * <span className="text-red-500">*</span>
              </p>
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <span className="text-primaryBg">
                      <BsUpload />
                    </span>
                    <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                      Upload your signature
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                    name="question10"
                    onChange={(e) => {
                      console.log("Here", e.target.files[0]);
                      setImage(e.target.files[0]);
                    }}
                  />
                  <img
                    // src={
                    //   addTermination?.signature === "string"
                    //     ? addTermination?.signature
                    //     : addTermination.signatureUrl
                    // }
                    src=""
                    className="w-6 h-6 object-cover"
                    alt="signature"
                  />
                </label>
              </div>
            </div>
          </section>
        </div>

        <section className="flex justify-center items-center gap-5">
          <Button intent="secondary" mode={mode}>
            Cancel
          </Button>
          <Button intent="primary" mode={mode} onClick={handleAddResignation}>
            Add Resignation
          </Button>
        </section>
      </div>
    </div>
  );
};

export default ResignationReasons;
