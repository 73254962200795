/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BsUpload } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";
import { useNavigate } from "react-router";

const Layoffs = () => {
  const { color, mode, organisation, desData, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const [selectedDesignation, setSelectedDesignation] = useState("");

  //   add layoff modal
  const [addLayoffModal, setAddLayoffModal] = useState(false);
  const openAddLayoffModal = () => {
    setAddLayoffModal((prev) => !prev);
    setAddLayoffs({});
  };

  //   add layoffs
  const [addLayoffs, setAddLayoffs] = useState({});
  console.log("addLayoffs", addLayoffs);

  //   get all layoffs
  const [layOffs, setLayOffs] = useState();
  console.log("layOffs", layOffs);
  const getAllLayoffs = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/layOff/get-all-layOff`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setLayOffs(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  const [layoffImage, setLayoffImage] = useState(null);
  console.log("layoffImage", layoffImage);

  const handleLayoffSignature = (e) => {
    const file = e.target.files[0];
    setLayoffImage(file);

    console.log("file", file);
  };

  const handleLayoffSignatureUpload = async () => {
    try {
      if (layoffImage) {
        const formData = new FormData();
        formData.append("file", layoffImage);

        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`File upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data?.viewURl);
        return data?.viewURl;
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      throw error;
    }
  };

  //   add layofs
  const handleAddLayoffs = async () => {
  
    const signature = await handleLayoffSignatureUpload();

    axios
      .post(
        `${LOCALHOST_BASE_URL}/layOff/add-LayOff`,
        {
          department: addLayoffs?.department,
          employeeId: addLayoffs?.employeeId,
          type: addLayoffs?.type,
          layOffDate: addLayoffs?.layOffDate,
          noticeDate: addLayoffs?.noticeDate,
          reason: addLayoffs?.reason,
          signature: signature,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("layOff added");
        getAllLayoffs();
        setAddLayoffs({});
        setAddLayoffModal(false);
      })
      .catch((error) => {
        console.log(error);
        setAddLayoffs({});
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  // edit layoff
  const updateLayoffs = async () => {
  


    const signature = await handleLayoffSignatureUpload();

    axios
      .put(
        `${LOCALHOST_BASE_URL}/layOff/update-layOff?id=${addLayoffs?._id}`,
        {
          department: addLayoffs?.department,
          employeeId: addLayoffs?.employeeId,
          type: addLayoffs?.type,
          layOffDate: addLayoffs?.layOffDate,
          noticeDate: addLayoffs?.noticeDate,
          reason: addLayoffs?.reason,
          signature: signature,                          
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("layoff updated");
        getAllLayoffs();
        setAddLayoffs({});
        setAddLayoffModal(false);
      })
      .catch((error) => {
        console.log(error);
        setAddLayoffs({});
        setAddLayoffModal(false);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  //  delete layoff
  const deleteLayoffs = async (id) => {
  


    console.log("delete id", id);
    try {
      await axios.delete(
        `${LOCALHOST_BASE_URL}/layOff/delete-layOff?id=${id}`,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getAllLayoffs();
    } catch (error) {
      console.error("Error deleting layoff:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getAllLayoffs();
  }, []);

  const filteredEmployees =
    deptData?.find((dept) => dept?._id === addLayoffs?.department)?.employee ||
    [];

  const filterData = layOffs?.getAll?.filter((blog) => {
    const designationData = selectedDesignation
      ? blog?.employeeId?.emp_designation?.des_name === selectedDesignation
      : true;
    return designationData;
  });

  // console.log("filteredEmployees", filteredEmployees);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Lay Offs</h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="designation"
            id="designation"
            value={selectedDesignation ? selectedDesignation : ""}
            onChange={(e) => setSelectedDesignation(e.target.value)}
            className={`py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="designation" selected>
              Designation
            </option>
            {desData?.map((des) => (
              <option value={des?.des_name}>{des?.des_name}</option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setSelectedDesignation("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <Button intent="primary" mode={mode} onClick={openAddLayoffModal}>
          + Add Lay offs
        </Button>
      </div>

      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <span className="pl-2">Employee Name</span>
                </Th>
                <Th>Designation</Th>
                <Th>Type</Th>
                <Th>Notice Date</Th>
                <Th>Lay offs Date</Th>
                <Th>Reason</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData
                ?.reverse()
                ?.slice(
                  (pageNumber - 1) * rowsPerPage,
                  pageNumber * rowsPerPage
                )
                ?.map((l, i) => {
                  return (
                    <TableRow key={i}>
                      {/* {console.log("l", l)} */}
                      <Td>
                        <div
                          className="flex items-center gap-1 cursor-pointer"
                          onClick={() => navigate(`/view-layoffs/${l?._id}`)}
                        >
                          {l?.employeeId?.emp_profile ? (
                            <Avatar
                              type="img"
                              src={l?.employeeId?.emp_profile}
                              size="sm"
                            />
                          ) : (
                            <Avatar size="sm" type="text">
                              {l?.employeeId?.emp_name?.slice(0, 1)}
                            </Avatar>
                          )}
                          <span className="border-b border-primaryBg w-fit">
                            {l?.employeeId?.emp_name}
                          </span>
                        </div>
                      </Td>
                      <Td>{l?.employeeId?.emp_designation?.des_name}</Td>
                      <Td>{l?.type}</Td>
                      <Td>{l?.noticeDate}</Td>
                      <Td>{l?.layOffDate}</Td>
                      <Td>{l?.reason}</Td>
                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                            onClick={() => {
                              setAddLayoffModal(true);
                              setAddLayoffs({
                                ...l,
                                edit: true,
                              });
                            }}
                          >
                            <FiEdit2 />
                          </h2>
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                            onClick={() => deleteLayoffs(l?._id)}
                          >
                            <RiDeleteBin6Line />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={layOffs?.getAll?.length || 0}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </TableWithPagination>
      </div>

      {/* add layoff modal */}
      <Modal showModal={addLayoffModal} setShowModal={setAddLayoffModal}>
        <div className="w-[384px]">
          <h1 className="text-primaryTextLight text-[20px] mb-4 font-medium text-center">
            {addLayoffs?.edit ? "Edit" : "Add"} Lay Offs
          </h1>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Select Department</label>
            <select
              name="dept"
              id="dept"
              required
              value={addLayoffs?.department}
              onChange={(e) =>
                setAddLayoffs({
                  ...addLayoffs,
                  department: e.target.value,
                })
              }
              className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            >
              <option value="dept" selected disabled>
                Select Department
              </option>
              {deptData?.map((dept) => (
                <option value={dept?._id}>{dept?.dept_name}</option>
              ))}
            </select>
          </section>

          {addLayoffs?.edit ? (
            <section className="flex flex-col gap-[6px] mb-2">
              <h3>Selected Employee</h3>
              <h4
                className={`px-2 text-black font-[500] py-[6px] text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                {addLayoffs?.employeeId?.emp_name}
              </h4>
            </section>
          ) : (
            <section className="flex flex-col gap-[6px] mb-2">
              <label htmlFor="dept">Select Employee</label>
              <select
                name="emp"
                id="emp"
                required
                value={addLayoffs?.employeeId}
                onChange={(e) =>
                  setAddLayoffs({
                    ...addLayoffs,
                    employeeId: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="emp" disabled selected>
                  Select Employee
                </option>
                {filteredEmployees?.map((emp) => (
                  <option key={emp?._id} value={emp?._id}>
                    {emp?.emp_name}
                  </option>
                ))}
              </select>
            </section>
          )}

          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="terminationType">
              Type <span className="">*</span>
            </label>
            <input
              type="text"
              name="terminationType"
              placeholder="Layoff Type"
              value={addLayoffs?.type}
              onChange={(e) =>
                setAddLayoffs({
                  ...addLayoffs,
                  type: e.target.value,
                })
              }
              required
              className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            />
          </section>
          <section className="grid grid-cols-2 gap-4 mb-2">
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="terminationDate">
                Lay offs Date <span className="">*</span>
              </label>
              <input
                type="date"
                name="terminationDate"
                value={addLayoffs?.layOffDate}
                onChange={(e) =>
                  setAddLayoffs({
                    ...addLayoffs,
                    layOffDate: e.target.value,
                  })
                }
                required
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label htmlFor="noticeDate">
                Notice Date <span className="">*</span>
              </label>
              <input
                type="date"
                name="noticeDate"
                required
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                value={addLayoffs?.noticeDate}
                onChange={(e) =>
                  setAddLayoffs({
                    ...addLayoffs,
                    noticeDate: e.target.value,
                  })
                }
              />
            </div>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="reason">
              Reason <span className="">*</span>
            </label>
            <textarea
              name="reason"
              id="reason"
              cols="10"
              rows="2"
              value={addLayoffs?.reason}
              onChange={(e) => {
                setAddLayoffs({
                  ...addLayoffs,
                  reason: e.target.value,
                });
              }}
              required
              className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 resize-y w-full`}
            ></textarea>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="reason">
              Signature upload <span className="">*</span>
            </label>
            <div class="flex items-center justify-center w-full">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <span className="text-primaryBg">
                    <BsUpload />
                  </span>
                  <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                    Upload your signature
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  // onChange={(e) =>
                  //   setAddLayoffs({
                  //     ...addLayoffs,
                  //     signature: e.target.files[0],
                  //     signatureUrl: URL.createObjectURL(e.target.files[0]),
                  //   })
                  // }
                  onChange={handleLayoffSignature}
                />
                <img className="w-6 h-6 object-cover" alt="signature" />
              </label>
            </div>
          </section>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={addLayoffs?.edit ? updateLayoffs : handleAddLayoffs}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Layoffs;
