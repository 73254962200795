/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Badge from "../components/Badge";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const Resignation = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const status = [
    {
      label: "Approve",
      value: "true",
    },
    {
      label: "Reject",
      value: "false",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const [resignation, setResignation] = useState(); //All resignation
  const [departmentSelect, setDepartmentSelect] = useState();
  const [statusSelect, setStatusSelect] = useState();
  console.log("resignation", resignation);

  //   approve reject modal
  const [statusModal, setStatusModal] = useState(false);
  const openStatusModal = (status, id) => {
    setUpdateStatus(status); // Set the updateStatus value when opening the modal
    setStatusModal(true);
    setResignationId(id);
    getAllResignation();
  };

  //   update status modal

  //  get all resignations
  const getAllResignation = async () => {

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/resignation/get-all-resignation`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setResignation(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar("Resignation data not found", "red");
      } else {
        console.error("An error occurred:", error);
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  useEffect(() => {
    getAllResignation();
  }, []);

  //   update resignation
  const [updateStatus, setUpdateStatus] = useState("");
  const [resignationId, setResignationId] = useState();
  const updateResignation = async () => {

    axios
      .put(
        `${LOCALHOST_BASE_URL}/resignation/update-resignation?id=${resignationId}`,
        { status: updateStatus },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          getAllResignation();
          setStatusModal(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showSnackbar("Resignation not found", "red");
        } else {
          console.log("Here is the Error from Then-Catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        }
      });
  };

  const deleteResignation = async (id) => {


    axios
      .delete(`${LOCALHOST_BASE_URL}/resignation/delete-resignation?id=${id}`, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          getAllResignation();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showSnackbar("Resignation not found", "red");
        } else {
          console.log("Here is the Error from Then-Catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        }
      });
  };

  const filterData = resignation?.resig?.filter((blog) => {
    const autherData = departmentSelect
      ? blog?.emp_id?.emp_designation?.des_name === departmentSelect
      : true;
    if (statusSelect === "pending") {
      return !blog?.status || blog?.status === "pending";
    } else if (statusSelect === "true") {
      return blog?.status === true;
    } else if (statusSelect === "false") {
      return blog?.status === false;
    }
    return autherData;
  });

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Resignation</h1>
      <section className="flex gap-4 items-center mt-[39px] mb-[43px]">
        <select
          name="dept"
          id="dept"
          className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect : ""}
        >
          <option value="dept" selected>
            Department
          </option>
          {deptData?.map((emp) => (
            <option key={emp?.value} value={emp?.value}>
              {emp?.dept_name}
            </option>
          ))}
        </select>
        <select
          name="status"
          id="status"
          className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          onChange={(e) => setStatusSelect(e.target.value)}
          value={statusSelect ? statusSelect : ""}
        >
          <option value="status" selected>
            Status
          </option>
          {status?.map((emp) => (
            <option key={emp?.value} value={emp?.value}>
              {emp?.label}
            </option>
          ))}
        </select>
        <button
          className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect("");
            setStatusSelect("");
          }}
        >
          Clear Filter
        </button>
      </section>

      <div className="grid grid-cols-3 gap-4">
        {filterData?.map((res) => (
          //   <Link to={`/view-resignation/${res?._id}`}>
          <section
            className={`h-[220px] px-[21px] py-[15px] rounded cardShadow text-primaryText ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <div className="flex justify-between items-center">
              <h3 className="text-[18px] font-medium">
                {res?.emp_id?.emp_name}
              </h3>
              <Badge
                intent={
                  res?.status === true
                    ? "success"
                    : res?.status === false
                    ? "danger"
                    : "warning"
                }
                size="medium"
                roundness="pill"
              >
                {console.log("res?.status", res?.status)}
                {res?.status === true
                  ? "Approved"
                  : res?.status === false
                  ? "Rejected"
                  : "Pending"}
              </Badge>
            </div>
            <p className="my-[10px] font-[300] text-[14px]">
              {res?.emp_id?.emp_designation?.des_name}
            </p>
            <p className="text-[12px]">
              <strong className="font-medium">Date: </strong>
              {res?.resignation_date}
            </p>
            <p className="text-[12px] mt-[10px] mb-[14px]">
              <strong className="font-medium">Reason: </strong>
              {/* {res?.reason} */}
              Our Employee Code of Conduct company policy outlines our
              expectations regarding......
            </p>
            <div className="flex justify-center items-center gap-4">
              {res?.status === true ? (
                <>
                  <Button intent="secondary" mode={mode} disabled>
                    Reject
                  </Button>
                  <Button intent="primary" mode={mode} disabled>
                    Approve
                  </Button>
                </>
              ) : res?.status === false ? (
                <>
                  <Button
                    intent="secondary"
                    mode={mode}
                    size="small"
                    onClick={() => deleteResignation(res?._id)}
                  >
                    Delete
                  </Button>
                  <Button intent="primary" mode={mode} disabled>
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    intent="secondary"
                    mode={mode}
                    size="small"
                    onClick={() => openStatusModal("false", res?._id)}
                  >
                    Reject
                  </Button>
                  <Button
                    intent="primary"
                    mode={mode}
                    onClick={() => openStatusModal("true", res?._id)}
                  >
                    Approve
                  </Button>
                </>
              )}
            </div>
          </section>
          //   </Link>
        ))}
      </div>

      {/* edit modal */}
      <Modal showModal={statusModal} setShowModal={setStatusModal}>
        {updateStatus === "true" ? (
          <div className="w-[342px] text-center">
            <p className="mt-5 mb-8 text-[20px] capitalize">
              Are you sure, you want to Approved this Resignation?
            </p>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => updateResignation()}
            >
              Confim
            </Button>
          </div>
        ) : (
          <div className="w-[342px] text-center">
            <p className="mt-5 mb-8 text-[20px] capitalize">
              Are you sure, you want to Reject this Resignation?{" "}
            </p>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => updateResignation()}
            >
              Confim
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Resignation;
