/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { BsFillGridFill } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { PiListFill } from "react-icons/pi";
import { TbClockExclamation } from "react-icons/tb";
import Avatar from "../../components/Avatar";
import Badge from "../../components/Badge";
import Button from "../../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../../components/table/Table";
import TablePagination from "../../components/table/TablePagination";
import { useSnackbar } from "../../context/SnackbarContext";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import jwt_decode from "jwt-decode";
import Modal from "../../components/Modal";

const UserLeave = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const decodedToken = jwt_decode(token);
  // console.log("decodedToken", decodedToken);

  // get user leave
  const [userLeave, setUserLeave] = useState();
  // console.log("userLeave", userLeave)

  // get all leaves
  const [allLeaves, setAllLeaves] = useState();
  // console.log("allLeaves", allLeaves);

  const [addRequestLeaveData, setAddRequestLeaveData] = useState({
    edit: false,
  });
  console.log("addRequestLeaveData", addRequestLeaveData);

  const [addRequestLeaveModal, setAddRequestLeaveModal] = useState(false);
  const openAddRequestLeaveModal = () => {
    setAddRequestLeaveModal((prev) => !prev);
    setAddRequestLeaveData({});
  };

  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const [selectedStatus, setSelectedStatus] = useState("all");

  const status = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Approve",
      value: "approved",
    },
    {
      label: "Reject",
      value: "reject",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const filteredLeaves =
    selectedStatus === "all"
      ? userLeave?.leaveRecords
      : userLeave?.leaveRecords?.filter(
          (l) => l?.leave_status === selectedStatus
        );

  console.log("filteredLeaves", filteredLeaves);

  const [tab, setTab] = useState(1);

  const tabData = [
    {
      id: 1,
      tabIcon: <PiListFill color={tab === 1 ? "white" : "black"} />,
    },
    {
      id: 2,
      tabIcon: <BsFillGridFill color={tab === 2 ? "white" : "black"} />,
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case 1:
        return (
          <UserLeaveTable
            userLeave={userLeave?.leaveRecords}
            formatDate={formatDate}
            decodedToken={decodedToken}
            mode={mode}
            organisation={organisation}
            openAddRequestLeaveModal={openAddRequestLeaveModal}
            filteredLeaves={filteredLeaves}
            setAddRequestLeaveData={setAddRequestLeaveData}
            setAddRequestLeaveModal={setAddRequestLeaveModal}
          />
        );
      case 2:
        return (
          <UserLeaveCards
            userLeave={userLeave?.leaveRecords}
            formatDate={formatDate}
            decodedToken={decodedToken}
            mode={mode}
            organisation={organisation}
            filteredLeaves={filteredLeaves}
            setAddRequestLeaveData={setAddRequestLeaveData}
            setAddRequestLeaveModal={setAddRequestLeaveModal}
          />
        );
      default:
    }
  };

  // get user leave
  const getUserLeaves = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getLeaveByEmployee?id=6500414ba8cd139df986127a`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      if (result?.status === 200) {
        setUserLeave(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result?.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      console.error("Error fetching leaves:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  // add leave request
  const handleAddLeaveRequest = () => {
    const requestData = {
      employeeId: decodedToken?.id,
      leaveData: {
        from_date: addRequestLeaveData?.from_date,
        to_date: addRequestLeaveData?.to_date,
        reason: addRequestLeaveData?.reason,
        leave_type: addRequestLeaveData?.leave_type,
      },
    };

    console.log("requestData", requestData);

    axios
      .post(`${LOCALHOST_BASE_URL}/employee/addnewLeave`, requestData, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Leave Request Added");
        getUserLeaves();
        setAddRequestLeaveData({});
        setAddRequestLeaveModal(false);
        showSnackbar("Leave request added successfully", "green");
      })
      .catch((error) => {
        console.log("error", error);
        setAddRequestLeaveData({});
        showSnackbar("Leave request not added", "red");
      });
  };

  // get all leave
  const getAllLeaves = async () => {
    try {
      const result = await axios?.get(
        `${LOCALHOST_BASE_URL}/leaveType/get-all-leaveType`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setAllLeaves(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result?.status
        );
        // Show a snackbar with an error message
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // update leaves
  const updateLeaves = (leaveId) => {
    console?.log("leaveId", leaveId);

    axios
      ?.put(
        `${LOCALHOST_BASE_URL}/employee/updateLeave?employeeId=${decodedToken?.id}&leaveId=${leaveId}`,
        addRequestLeaveData,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        setAddRequestLeaveModal();
        getAllLeaves();
        showSnackbar("Leaves Updated Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  useEffect(() => {
    getUserLeaves();
    getAllLeaves();
  }, []);

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <h1 className={`text-[32px] text-primaryText`}>Leave</h1>
        <Button intent="primary" mode={mode} onClick={openAddRequestLeaveModal}>
          Request For Leave
        </Button>
      </section>

      <div className="mt-[35px] grid grid-cols-4 gap-4">
        {userLeave?.leaveCounts?.map((leave) => (
          <section
            className={`px-[20px] py-2 w-[188px] h-[97px] rounded-lg text-primaryText ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-primaryLight"
            } `}
          >
            <h2 className="text-primaryBg font-medium mb-3">
              {leave?.leave_name}
            </h2>
            <div className="text-[24px] font-[700]">
              {leave?.taken_Leave} / {leave?.total_annualLeave}
            </div>
          </section>
        ))}
      </div>

      <div className="my-[35px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="status"
            id="status"
            onChange={(e) => setSelectedStatus(e.target.value)}
            value={selectedStatus}
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.result?.[0]?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="status" selected disabled>
              Status
            </option>
            {status?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.label}
              </option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => setSelectedStatus("all")}
          >
            Clear Filter
          </button>
        </section>
        <section className="flex flex-row-reverse items-center gap-4">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`px-2 py-2 rounded cursor-pointer ${
                    tab === i.id ? "bg-primaryBg" : ""
                  }`}
                  onClick={() => {
                    setTab(i.id);
                  }}
                >
                  {i.tabIcon}
                </h3>
              </>
            );
          })}
        </section>
      </div>

      <div>{getCorrectPage(tab)}</div>

      {/* add request leave modal */}
      <Modal
        showModal={addRequestLeaveModal}
        setShowModal={setAddRequestLeaveModal}
      >
        <div className="w-[340px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            {addRequestLeaveData?.edit
              ? "Edit Request For Leave"
              : "Request For Leave"}
          </h1>

          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="leaveType">
                Leave Type <span className="">*</span>
              </label>
              <select
                name="leave_type"
                id="leave_type"
                value={addRequestLeaveData?.leave_type}
                onChange={(e) =>
                  setAddRequestLeaveData({
                    ...addRequestLeaveData,
                    leave_type: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="leaveType" selected disabled>
                  Select Leave Type
                </option>
                {allLeaves?.map((leave) => (
                  <option value={leave?._id}>{leave?.leave_name}</option>
                ))}
              </select>
            </section>
            <section className="grid grid-cols-2 gap-4 mb-5">
              <div className="flex flex-col gap-[6px]">
                <label htmlFor="from_date">
                  Leave From <span className="">*</span>
                </label>
                <input
                  type="date"
                  name="from_date"
                  value={addRequestLeaveData?.from_date}
                  onChange={(e) =>
                    setAddRequestLeaveData({
                      ...addRequestLeaveData,
                      from_date: e.target.value,
                    })
                  }
                  required
                  className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                />
              </div>
              <div className="flex flex-col gap-[6px]">
                <label htmlFor="to_date">
                  Leave To <span className="">*</span>
                </label>
                <input
                  type="date"
                  name="to_date"
                  value={addRequestLeaveData?.to_date}
                  onChange={(e) =>
                    setAddRequestLeaveData({
                      ...addRequestLeaveData,
                      to_date: e.target.value,
                    })
                  }
                  required
                  className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                />
              </div>
            </section>
            <section className="flex flex-col gap-[6px] mb-2">
              <label htmlFor="reason">
                Reason <span className="">*</span>
              </label>
              <textarea
                name="reason"
                value={addRequestLeaveData?.reason}
                onChange={(e) => {
                  setAddRequestLeaveData({
                    ...addRequestLeaveData,
                    reason: e.target.value,
                  });
                }}
                id="reason"
                cols="10"
                rows="3"
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 resize-y w-full`}
              ></textarea>
            </section>
          </div>

          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              // onClick={
              //   addRequestLeaveData?.edit ? updateLeaves : handleAddLeaveRequest
              // }
              onClick={() =>
                addRequestLeaveData?.edit
                  ? updateLeaves(addRequestLeaveData.leaveId)
                  : handleAddLeaveRequest
              }

              // onClick={handleAddLeaveRequest}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const UserLeaveTable = ({
  userLeave,
  formatDate,
  openAddRequestLeaveModal,
  filteredLeaves,
  setAddRequestLeaveModal,
  setAddRequestLeaveData,
}) => {
  const { showSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <div className="overflow-auto text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>
                <p className="pl-2">Date</p>
              </Th>
              <Th>Duration</Th>
              <Th>Type</Th>
              <Th>Reason</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeaves
              ?.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage)
              ?.reverse()
              ?.map((l, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <p className="pl-2">
                        {formatDate(l?.from_date)} - {formatDate(l?.to_date)}
                      </p>
                    </Td>
                    <Td>{l?.duration} day </Td>
                    <Td>{l?.leave_type?.leave_type}</Td>
                    <Td>
                      <p className="w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {l?.reason}
                      </p>
                    </Td>
                    <Td>
                      <div className="flex">
                        <Badge
                          intent={
                            l?.leave_status === "approved"
                              ? "success"
                              : l?.leave_status === "reject"
                              ? "danger"
                              : "warning"
                          }
                          size="medium"
                          roundness="pill"
                        >
                          {l?.leave_status === "approved" ? (
                            <p className="flex items-center gap-1 capitalize">
                              <AiOutlineCheck color="#08A758" />
                              {l?.leave_status}
                            </p>
                          ) : l?.leave_status === "pending" ? (
                            <p className="flex items-center gap-1 capitalize">
                              <TbClockExclamation color="#F9A000" />
                              {l?.leave_status}
                            </p>
                          ) : l?.leave_status === "reject" ? (
                            <p className="flex items-center gap-1 capitalize">
                              <AiOutlineClose color="#FF3838" />
                              {l?.leave_status}
                            </p>
                          ) : (
                            ""
                          )}
                        </Badge>
                      </div>
                    </Td>
                    <Td>
                      {l?.leave_status === "pending" ? (
                        <h2
                          className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                          onClick={() => {
                            setAddRequestLeaveModal(true);
                            setAddRequestLeaveData({
                              edit: true,
                              leaveId: l?._id,
                              ...l,
                            });
                          }}
                        >
                          <FiEdit2 color="var(--primaryText)" />
                        </h2>
                      ) : (
                        ""
                      )}
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={userLeave?.length || 0}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const UserLeaveCards = ({
  userLeave,
  formatDate,
  mode,
  organisation,
  decodedToken,
  filteredLeaves,
  setAddRequestLeaveData,
  setAddRequestLeaveModal,
}) => {
  return (
    <div className="grid grid-cols-4 gap-[26px] text-primaryText">
      {filteredLeaves?.reverse()?.map((l) => (
        <section
          key={l?._id}
          className={`w-[261px] h-[300px] shadow-md rounded py-3 cardShadow ${
            organisation?.result?.[0]?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center text-primaryText">
            <h2 className="font-medium">{l?.leave_type?.leave_name}</h2>
            <Badge
              intent={
                l?.leave_status === "approved"
                  ? "success"
                  : l?.leave_status === "pending"
                  ? "warning"
                  : l?.leave_status === "rejected"
                  ? "danger"
                  : ""
              }
              size="small"
              roundness="pill"
            >
              <span className="capitalize">{l?.leave_status}</span>
            </Badge>
          </div>
          <div className="flex justify-between items-start mt-3 mb-3 px-4 text-primaryText h-[50px]">
            <section className="flex items-center gap-2">
              {decodedToken?.emp_profile ? (
                <Avatar type="img" src={decodedToken?.emp_profile} size="md" />
              ) : (
                <Avatar size="md" type="text">
                  {decodedToken?.emp_name?.slice(0, 1)}
                </Avatar>
              )}

              <div className="">
                <h4 className="text-[14px]">{decodedToken?.emp_name} on</h4>
                <h4 className="text-[10px]">{l?.date}</h4>
              </div>
            </section>
            <section>
              <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                onClick={() => {
                  setAddRequestLeaveModal(true);
                  setAddRequestLeaveData({
                    edit: true,
                    leaveId: l?._id,
                    ...l,
                  });
                }}
              >
                <FiEdit2 />
              </h2>
            </section>
          </div>
          <div
            className={`py-[14px] text-center text-primaryText ${
              organisation?.result?.[0]?.organisationTheme?.name === "dark"
                ? "bg-[#2A2E3D]"
                : "bg-primaryLight"
            }`}
          >
            <h2 className="text-primaryBg font-semibold mb-1">
              {l?.duration} Day
            </h2>
            <h2 className="text-primaryText text-[14px]">
              {formatDate(l?.from_date)}
              {" - "}
              {formatDate(l?.to_date)}
            </h2>
          </div>
          <div className="px-4 mt-2">
            <h3 className="text-secondaryText">Reason:</h3>
            <p className="h-[70px] text-[12px] text-primaryText font-medium line-clamp-3">
              {l?.reason}
            </p>
          </div>
          {/* <div className="flex justify-center items-center gap-2 test">
            {l?.emp_leave?.leave_status === "pending" ? (
              <>
                <Button intent="secondary" mode={mode}>
                  Reject
                </Button>
                <Button intent="primary" mode={mode}>
                  Approve
                </Button>
              </>
            ) : l?.emp_leave?.leave_status === "rejected" ? (
              <>
                <Button intent="secondary" mode={mode}>
                  Delete
                </Button>
                <Button intent="primary" mode={mode}>
                  Approve
                </Button>
              </>
            ) : l?.emp_leave?.leave_status === "approved" ? (
              <>
                <Button intent="secondary" mode={mode}>
                  Delete
                </Button>
                <Button intent="primary" mode={mode}>
                  Reject
                </Button>
              </>
            ) : (
              ""
            )}
          </div> */}
        </section>
      ))}
    </div>
  );
};

export default UserLeave;
