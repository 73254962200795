/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  TableActionPopUp,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { FiEdit2 } from "react-icons/fi";
// eslint-disable-next-line no-unused-vars
import Modal from "../components/Modal";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
// eslint-disable-next-line no-unused-vars
import { BsFillGridFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import payrollLight from "../assets/PayRoll.svg";
import payrollDark from "../assets/PayrollBlack.svg";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { BiCaretDown } from "react-icons/bi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useSnackbar } from "../context/SnackbarContext";

const collapseData = [
  {
    id: 101,
    country: "India",
    surveyNo: "SN # 1000522",
    countries: [
      {
        id: 111,
        level: "Gold",
        countryName: "japan",
        allocation: 328,
        avgCpi: 13,
        totalBudget: 900,
        allocationStatus: "Approved",
        tg: [
          {
            level: "Silver",
            targetGroupName: "tg1",
          },
          {
            level: "Silver",
            targetGroupName: "tg2",
          },
        ],
      },
    ],
  },
  {
    id: 102,
    country: "India",
    surveyNo: "SN # 1000522",
    countries: [
      {
        id: 122,
        level: "Gold",
        countryName: "japan",
        allocation: 328,
        avgCpi: 13,
        totalBudget: 900,
        allocationStatus: "Approved",
        tg: [
          {
            level: "Silver",
            targetGroupName: "tg1",
          },
          {
            level: "Silver",
            targetGroupName: "tg2",
          },
        ],
      },
    ],
  },
];

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.toLocaleString("default", { month: "long" });

const yearOptions = Array.from({ length: currentYear - 2018 + 1 }, (_, index) =>
  (currentYear - index).toString()
);
const monthOptions = Array.from({ length: 12 }, (_, index) => {
  const date = new Date(0, index);
  return date.toLocaleString("default", { month: "long" });
});

const PayRoll = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const [selectedId, setSelectedId] = useState([]);
  const [open, setOpen] = useState([]);

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const [allPayroll, setAllPayrolls] = useState();
  const getAllPayrolls = async () => {
    const combinedValue = `${selectedYear}-${selectedMonth}`;

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/payRoll/get-all-payRoll?month=${
          combinedValue !== "-" ? combinedValue : todayDate
        }`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 404) {
        showSnackbar(`Pay Roll not found in selected month and year`, "red");
      } else {
        setAllPayrolls(result.data);
        showSnackbar(`Pay Roll found in selected month and year`, "green");
      }
    } catch (error) {
      // Handle any other errors
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getAllPayrolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth]);

  const redirectToUpdatePayroll = (id) => {
    navigate(`/create-pay-roll?payrollId=${id}`);
  };

  const redirectToPaymentDetails = (id) => {
    navigate(`/paymentDetails`); // Redirect using plain JavaScript
  };

  const getTodayDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const monthName = months[today.getMonth()];
    const year = today.getFullYear();

    return `${year}-${monthName}`;
  };

  // Usage
  const todayDate = getTodayDate();

  const [payrollPageNumber, setPayrolPageNumber] = useState(1);
  const [payrollRowsPerPage, setPayrollRowsPerPage] = useState(10);

  const openAcceptPayroll = (id) => {
    axios
      ?.put(
        `${LOCALHOST_BASE_URL}/payRoll/update-payRoll?id=${id}`,
        { status: "true" },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllPayrolls();
        showSnackbar(`PayRoll Accept SuccessFully`, "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(`Something went wrong, please try again`, "red");
      });
  };

  const openRejectPayroll = (id) => {

    axios
      ?.put(
        `${LOCALHOST_BASE_URL}/payRoll/update-payRoll?id=${id}`,
        { status: "false" },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getAllPayrolls();
        showSnackbar(`PayRoll Reject SuccessFully`, "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(`Something went wrong, please try again`, "red");
      });
  };

  const deletePayroll = async (ids) => {

    try {
      for (const id of ids) {
        await axios.delete(
          `${LOCALHOST_BASE_URL}/payRoll/delete-payRoll?id=${id}`,
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      getAllPayrolls();
      showSnackbar(`Payrolls deleted successfully`, "green");
    } catch (error) {
      showSnackbar(`Something went wrong, please try again`, "red");
      console.log("error", error);
    }
  };

  let transferToPaySlip;

  if (Array.isArray(allPayroll)) {
    // Check if all statuses are true
    const allStatusTrue = allPayroll.every(
      (item) => item && item.status === true
    );

    // Create a new array with the updated transferToPaySlip field
    const updatedPayroll = allPayroll.map((item) => ({
      ...item,
      transferToPaySlip: allStatusTrue,
    }));

    transferToPaySlip = allStatusTrue; // Assign to the global variable
  } else {
    console.log("allPayroll is not an array or is undefined.");
  }

  const transferToPaySlipButton = async (ids) => {

    try {
      for (const id of ids) {
        await axios.post(
          `${LOCALHOST_BASE_URL}/payslip/add-payslip`,
          { paySlip_id: id },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      getAllPayrolls();
      showSnackbar(`Payslip is created successfully`, "green");
    } catch (error) {
      showSnackbar(
        `Something went wrong with Payslip, please try again`,
        "red"
      );
      console.log("error", error);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const headerCheckboxRef = useRef();
  function getCheckedRowIds() {
    return selectedRows
      .filter((row) => row._id) // Filter out rows without _id
      .map((row) => row._id);
  }
  const checkedRowIds = getCheckedRowIds();

  // console.log("selectedPayRollID",checkedRowIds);
  // console.log(selectedRows?.forEach(data=>data?._id));

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1
        className={`text-[32px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "text-primaryTextDark"
            : "text-primaryTextLight"
        }`}
      >
        Payroll
      </h1>
      {!allPayroll ? (
        <div className="h-[600px] w-full flex justify-center flex-col items-center">
          <img
            src={`${
              organisation?.organisationTheme?.name === "dark"
                ? payrollDark
                : payrollLight
            }`}
            className="w-[280px] h-[180px]"
          />
          <p
            className={`text-[24px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            Please click the "Create " button to retrieve your
          </p>
          <p
            className={`text-[24px] mb-4 ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            payroll information.
          </p>
          <Button
            intent="primary"
            mode={mode}
            onClick={() => navigate(`/create-pay-roll`)}
          >
            Create Payroll
          </Button>
        </div>
      ) : (
        <>
          <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
            <section className="flex gap-4 items-center">
              <select
                name="month"
                id="month"
                className={`text-center py-2 text-[12px] w-[92px] rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="month" selected>
                  Month
                </option>
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="year"
                id="year"
                className={`text-center py-2 text-[12px] w-[92px] rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="year" selected>
                  Year
                </option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
                {/* {generateYearOptions()} */}
              </select>
              <button className={`text-primaryBg border-b border-primaryBg`}>
                Clear Filter
              </button>
            </section>
            <div className="flex  gap-3">
              <Button
                intent="secondary"
                mode={mode}
                onClick={() => navigate(`/create-pay-roll`)}
              >
                Create Payroll
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={redirectToPaymentDetails}
              >
                Payment Details
              </Button>
            </div>
          </div>
          <div
            className={`text-left w-full mx-1 my-10 ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryText"
                : "border border-[#EEF4FB]"
            }`}
          >
            <TableWithPagination>
              <TableActionPopUp selectedRows={selectedRows}>
                {selectedRows?.length === 1 ? (
                  <div className="flex gap-2">
                    <Button
                      intent="primary"
                      mode={mode}
                      onClick={() => deletePayroll(checkedRowIds)}
                    >
                      Delete
                    </Button>
                    <Button
                      intent="primary"
                      mode={mode}
                      onClick={() =>
                        redirectToUpdatePayroll(checkedRowIds?.[0])
                      }
                    >
                      Update Payroll
                    </Button>
                    {transferToPaySlip && (
                      <Button
                        intent="primary"
                        mode={mode}
                        onClick={() => transferToPaySlipButton(checkedRowIds)}
                      >
                        Transfer To PaySlip
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <Button
                      intent="primary"
                      mode={mode}
                      onClick={() => deletePayroll(checkedRowIds)}
                    >
                      Delete
                    </Button>
                    {transferToPaySlip && (
                      <Button
                        intent="primary"
                        mode={mode}
                        onClick={() => transferToPaySlipButton(checkedRowIds)}
                      >
                        Transfer To PaySlip
                      </Button>
                    )}
                  </div>
                )}
              </TableActionPopUp>
              <table className="w-full text-left text-primaryText">
                <thead
                  className={`h-[40px] text-primaryText font-medium ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "bg-primaryDark"
                      : "bg-primaryLight"
                  }`}
                >
                  <tr className="text-[12px] font-medium">
                    <th className="pl-1 py-2">
                      <input
                        type="checkbox"
                        ref={headerCheckboxRef}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedRows(allPayroll);
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                        value
                        checked={selectedRows?.length === allPayroll?.length}
                      />
                    </th>
                    <th>Employee Name</th>
                    <th>Gross Salary</th>
                    <th>Basic Salary</th>
                    <th>HRA</th>
                    <th>Conveyance</th>
                    <th>Statutory Bonus</th>
                    <th>Other Allowance</th>
                    <th>Overtime</th>
                    <th>Incentive</th>
                    <th>Arrears</th>
                    <th>Wifi/Mobile Recharge</th>
                    <th>Status</th>
                    <th>Approval</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allPayroll
                    ?.slice(
                      (payrollPageNumber - 1) * payrollRowsPerPage,
                      payrollPageNumber * payrollRowsPerPage
                    )
                    ?.map((data) => {
                      let suppId = data?._id;
                      return (
                        <>
                          <tr className="text-[14px] border-b border-[#EEF4FB]">
                            <td
                              className={`py-[10px] px-1 primaryText font-[400] bg-transparent  text-[14px]`}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  selectedRows?.filter(
                                    (r) => r._id === data._id
                                  )[0]
                                }
                                onChange={(e) => {
                                  if (e?.target?.checked) {
                                    setSelectedRows((prev) => [...prev, data]);
                                  } else {
                                    // setSelectedRows((prev) => [...prev, data]);
                                    setSelectedRows((prev) =>
                                      prev.filter((d) => d !== data)
                                    );
                                  }

                                  // Update the checked state of the header checkbox accordingly.
                                  headerCheckboxRef.current.checked =
                                    selectedRows?.length === allPayroll?.length;
                                }}
                              />
                            </td>
                            <Td>{data?.emp_id?.emp_name}</Td>
                            <Td>{data?.gross_Salary}</Td>
                            <Td>
                              {data?.basic_Salary ? data.basic_Salary : "-"}
                            </Td>
                            <Td>{data?.hra ? data?.hra : "-"}</Td>
                            <Td>{data?.convyance ? data?.convyance : "-"}</Td>
                            <Td>
                              {data?.statutory_bonus
                                ? data?.statutory_bonus
                                : "-"}
                            </Td>
                            <Td>
                              {data?.other_allowance
                                ? data?.other_allowance
                                : "-"}
                            </Td>
                            <Td>{data?.overTime ? data?.overTime : "-"}</Td>
                            <Td>{data?.incentive ? data?.incentive : "-"}</Td>
                            <Td>{data?.arrears ? data?.arrears : "-"}</Td>
                            <Td>
                              {data?.wifi_mobile_recharge
                                ? data?.wifi_mobile_recharge
                                : "-"}
                            </Td>
                            <Td>
                              {data?.status === true ? (
                                <p className="flex items-center gap-1 capitalize">
                                  <AiOutlineCheck color="#08A758" />
                                  Approved
                                </p>
                              ) : data?.status === false ? (
                                <p className="flex items-center gap-1 capitalize">
                                  <AiOutlineClose color="#FF3838" />
                                  Reject
                                </p>
                              ) : (
                                <p className="flex items-center gap-1 capitalize">
                                  <TbClockExclamation color="#F9A000" />
                                  Pending
                                </p>
                              )}
                            </Td>
                            <Td>
                              {data?.status === true ? (
                                <div className="flex gap-2">
                                  <AiOutlineCheckCircle
                                    color="#B0B0B0"
                                    cursor="not-allowed"
                                  />
                                  <AiOutlineCloseCircle
                                    color="#B0B0B0"
                                    cursor="not-allowed"
                                  />
                                </div>
                              ) : data?.status === false ? (
                                <div className="flex gap-2">
                                  <AiOutlineCheckCircle
                                    onClick={() => openAcceptPayroll(data?._id)}
                                    color="#08A758"
                                    cursor="pointer"
                                  />
                                  <AiOutlineCloseCircle
                                    onClick={() => openRejectPayroll(data?._id)}
                                    color="#B0B0B0"
                                    cursor="not-allowed"
                                  />
                                </div>
                              ) : (
                                <div className="flex gap-2">
                                  <AiOutlineCheckCircle
                                    onClick={() => openAcceptPayroll(data?._id)}
                                    color="#08A758"
                                    cursor="pointer"
                                  />
                                  <AiOutlineCloseCircle
                                    onClick={() => openRejectPayroll(data?._id)}
                                    color="#DB2C2C"
                                    cursor="pointer"
                                  />
                                </div>
                              )}
                            </Td>
                            <td
                              onClick={() => {
                                if (!open.includes(suppId)) {
                                  setOpen((prevArr) => [...prevArr, suppId]);
                                } else {
                                  setOpen((prevArr) => {
                                    return prevArr.filter(
                                      (id) => id !== suppId
                                    );
                                  });
                                }
                              }}
                            >
                              <div className="flex items-center cursor-pointer">
                                <MdOutlineKeyboardArrowDown size={20} />
                                {/* {open ? (
                              <MdOutlineKeyboardArrowUp />
                            ) : (
                              <MdOutlineKeyboardArrowDown />
                            )} */}
                              </div>
                            </td>
                          </tr>

                          {open.includes(suppId) && (
                            <>
                              {data?.deductions?.map((deduction) => {
                                let deductionID = deduction?.id;
                                if (open.includes(deductionID)) {
                                  return (
                                    <>
                                      <tr>
                                        <span className="px-2 pt-3 text-[14px]">
                                          Deductions
                                        </span>
                                      </tr>
                                      <tr className="w-full text-[14px] border-b border-[#EEF4FB]">
                                        <td className="px-2">
                                          <div>
                                            <h3 className="py-2 font-medium">
                                              ESI
                                            </h3>
                                            <span>{deduction?.esi}</span>
                                          </div>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            PF
                                          </h3>
                                          <span>{deduction?.pf}</span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            Mediclaim Gratuity
                                          </h3>
                                          <span>
                                            {deduction?.mediclaim_gratuity}
                                          </span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            Half Day
                                          </h3>
                                          <span>{deduction?.half_day}</span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            Unpaid Leave
                                          </h3>
                                          <span>{deduction?.unpaid_leave}</span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            Late Login
                                          </h3>
                                          <span>{deduction?.late_login}</span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            TDS
                                          </h3>
                                          <span>{deduction?.tds}</span>
                                        </td>
                                        <td className="px-2">
                                          <h3 className="py-2 font-medium">
                                            Other Deduction
                                          </h3>
                                          <span>
                                            {deduction?.other_deductions}
                                          </span>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                } else {
                                  return <>no data</>;
                                }
                              })}
                            </>
                          )}
                        </>
                      );
                    })}
                </tbody>
              </table>
              <TablePagination
                totalDataCnt={collapseData?.length || 0}
                setRowsPerPage={setPayrolPageNumber}
                rowsPerPage={payrollRowsPerPage}
                setPageNumber={setPayrolPageNumber}
                pageNumber={payrollPageNumber}
              />
            </TableWithPagination>
          </div>
        </>
      )}

      {/* expandable table */}
    </div>
  );
};

export default PayRoll;
