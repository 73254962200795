import React from "react";
import { cva } from "class-variance-authority";

const BadgeVariants = cva(
  // badge base style
  "font-[400] h-[22px] flex justify-center items-center",
  {
    variants: {
      //    badge colors
      intent: {
        success: "bg-success text-successText",
        warning: "bg-warning text-warningText",
        danger: "bg-danger text-dangerText",
      },
      //    badge sizes
      size: {
        small: ["text-[10px]", "py-1", "px-3"],
        medium: ["text-[12px]", "py-2", "px-3"],
        large: ["text-[14px]", "py-2", "px-3"],
      },
      //    badge roundness
      roundness: {
        round: "rounded",
        pill: "rounded-full",
      },
    },
  }
);

export default function Badge({ children, intent, size, roundness }) {
  return (
    <span className={BadgeVariants({ intent, size, roundness })}>
      {children}
    </span>
  );
}
