/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { ContextThemeProvider } from "../context/ThemeContext";
import logo from "../assets/logo.svg";
import { RiNotification2Fill } from "react-icons/ri";
import {
  AiFillPlusCircle,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { TbClockExclamation } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { BsGrid1X2 } from "react-icons/bs";
import { GrGroup } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";
import Badge from "../components/Badge";
import Modal from "../components/Modal";
import { LOCALHOST_BASE_URL, token, apiKey } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";
import employees from "../assets/Admin side/whait mode/Total Employees.svg";
import employeesdark from "../assets/Admin side/black mode/Total Employees.svg";
import presentEmp from "../assets/Admin side/whait mode/Present Employees.svg";
import presentEmpdark from "../assets/Admin side/black mode/Present Employees.svg";
import empOnLeave from "../assets/Admin side/whait mode/Employees on Leave.svg";
import empOnLeavedark from "../assets/Admin side/black mode/Employees on Leave.svg";
import totalDept from "../assets/Admin side/whait mode/Total Departments.svg";
import totalDeptdark from "../assets/Admin side/black mode/Total Departments.svg";
import jwt_decode from "jwt-decode";
import { getGreetMsg } from "../utils/utils";

const Dashboard = () => {
  const { color, mode, organisation, orgData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const decodedToken = jwt_decode(token);

  const navigate = useNavigate();
  const [dailyAttendance, setDailyAttendance] = useState();
  const [holidays, setHoliday] = useState();
  const [leave, setLeave] = useState();
  const [anniversary, setAnniversary] = useState();
  const [todaysDate, setTodaysDate] = useState();

  const getTodayDate = () => {
    const today = new Date();

    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  // get dashboard cards data
  const [dashboardCardData, setDashboardCardData] = useState();
  console.log("dashboardCardData", dashboardCardData);

  const [holidayData, setHolidayData] = useState({
    holiday_name: "",
    holiday_day: "",
  });

  const [announcementData, setAnnouncementData] = useState({
    date: "",
    title: "",
  });

  // add holidays
  const [addHolidays, setAddHolidays] = useState(false);
  const openHolidaysModal = () => {
    setAddHolidays((prev) => !prev);
  };

  // add announcements
  const [addAnnouncements, setAddAnnouncements] = useState(false);
  const openAnnouncementModal = () => {
    setAddAnnouncements((prev) => !prev);
  };

  // new joining
  const [newJoining, setNewJoining] = useState();

  const dashboardCards = [
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? employeesdark
          : employees,
      cardHeader: "Total Employees",
      cardBody: dashboardCardData?.employees,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? presentEmpdark
          : presentEmp,
      cardHeader: "Present Employees",
      cardBody: dashboardCardData?.attendances,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? empOnLeavedark
          : empOnLeave,
      cardHeader: "Employees on Leave",
      cardBody: dashboardCardData?.leaveCount,
    },
    {
      cardIcon:
        organisation?.organisationTheme?.name === "dark"
          ? totalDeptdark
          : totalDept,
      cardHeader: "Total Departments",
      cardBody: dashboardCardData?.departments,
    },
  ];

  const getNewJoining = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetter/get-offerLetter/get_emp_joiningDate`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setNewJoining(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const getDailyAttendance = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/getDailyAttendance?date=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setDailyAttendance(result.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  const getHoliday = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/holiday/get-all-holiday`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        const sortData = result.data.sort(
          (a, b) =>
            new Date(b?.holiday_day)?.getTime() -
            new Date(a?.holiday_day)?.getTime()
        );
        setHoliday(sortData);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  const getLeave = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/leave/getAllLeave`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setLeave(result.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  const getAnniversary = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/getBirthdaysAnniversaries?data=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setAnniversary(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  const getDashboardCardData = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getAdminDashborad?month=2023-10-13`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setDashboardCardData(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result?.status
        );
        showSnackbar("Something is wrong. Please try again", "red");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      } else {
        console.error("An error occurred while fetching data:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      }
    }
  };

  useEffect(() => {
    if (todaysDate) {
      getDailyAttendance();
      getHoliday();
      getLeave();
      getAnniversary();
      // getAllBirthdaysAndWorkAnniversary();
    }
  }, [todaysDate]);

  useEffect(() => {
    getTodayDate();
    getDashboardCardData();
    getNewJoining();
  }, []);

  const formatTime = (timestamp) => {
    if (!timestamp || timestamp === "-") {
      return "-";
    }

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format
    };
    const formattedTime = new Date(timestamp).toLocaleTimeString([], options);
    return formattedTime;
  };
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const formatDate1 = (timestamp) => {
    if (!timestamp) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    return (
      <div className="text-center">
        <p>{day}</p>
        <p>{month}</p>
      </div>
    );
  };

  const handleholidaySave = () => {
    axios
      .post(`${LOCALHOST_BASE_URL}/holiday/add-holiday`, holidayData, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`, // Include the Bearer token
        },
      })
      .then((response) => {
        showSnackbar("", "green");
        console.log("Holiday added successfully");
        setAddHolidays(false);
        getHoliday();
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        if (error.response && error.response.status === 404) {
          console.error("Error: Resource not found");
          showSnackbar("Wait for Some time and try again", "red");
        } else {
          console.error("Error adding holiday:", error);
          showSnackbar("Something is wrong. Please try again", "red");
        }
      });
  };

  const handleAnnouncementSave = () => {
    axios
      .post(
        `${LOCALHOST_BASE_URL}/announcement/add-announcement`,
        announcementData,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`, // Include the Bearer token
          },
        }
      )
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log("Announcement added successfully");
        // Close the modal or perform other actions as needed
        setAddAnnouncements(false);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        if (error.response && error.response.status === 404) {
          console.error("Error: Resource not found");
          showSnackbar("Wait for Some time and try again", "red");
        } else {
          console.error("Error adding announcement:", error);
          showSnackbar("Something is wrong. Please try again", "red");
        }
      });
  };

  const deleteHoliday = (holidayId) => {
    axios
      .delete(`${LOCALHOST_BASE_URL}/holiday/deleteHoliday?id=${holidayId}`, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`, // Include the Bearer token
        },
      })
      .then((response) => {
        if (response.status === 200) {
          showSnackbar("Holiday Deleted Successfully", "green");
          console.log("Holiday deleted successfully");
          getHoliday();
        } else {
          // Handle error, e.g., show an error message
          console.error("Error deleting holiday");
          showSnackbar("Error deleting holiday. Please try again", "red");
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error deleting holiday:", error);
        showSnackbar("Something is wrong. Please try again", "red");
      });
  };

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-primaryLight"
      } `}
    >
      <section className={`flex justify-between items-center`}>
        <h1 className={`text-[32px]`}>
          {" "}
          <span
            className={`font-[500] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            {getGreetMsg()}’
          </span>{" "}
          <span className="text-primaryBg font-[600]">
            {decodedToken?.emp_name}
          </span>
        </h1>
        <div className="flex items-center gap-4">
          <Button
            intent="primary"
            mode={mode}
            onClick={() => navigate(`/register-employee`)}
          >
            Register Employee
          </Button>
          <Button
            intent="secondary"
            mode={mode}
            onClick={openAnnouncementModal}
          >
            Add Announcement
          </Button>
        </div>
      </section>

      <div className="py-[34px] grid grid-cols-4 gap-[45px]">
        {dashboardCards?.map((card) => (
          <section
            className={`py-[20px] px-4 rounded-lg flex gap-5 ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            } `}
          >
            <p
              className={`${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-primaryLight"
              } w-[56px] h-[56px] flex justify-center items-center rounded-full bg-primaryLight `}
            >
              <img src={card?.cardIcon} alt="" />
            </p>
            <div>
              <span className={`text-[14px] text-[#A3AED0]`}>
                {card?.cardHeader}
              </span>
              <h1
                className={`mt-2 text-[24px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-[#2B3674]"
                } `}
              >
                {card?.cardBody}
              </h1>
            </div>
          </section>
        ))}
      </div>

      <div className="flex gap-4 mb-[57px]">
        <section
          className={`w-[65%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center pr-[31px]`}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Daily Report
            </span>
            <Link
              to="/daily-report"
              className={`text-[12px] flex items-center  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryBg"
              }`}
            >
              <span>View All</span>
              <MdKeyboardArrowRight />
            </Link>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
            <table className="border-collapse text-left w-full text-[14px]">
              <thead
                className={`h-[60px] font-medium ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-[#A3AED0]"
                    : "text-[#A3AED0]"
                } `}
              >
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Check in</th>
                  <th>Check out</th>
                  <th>Duration</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dailyAttendance?.map((report) => (
                  <tr
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    } `}
                  >
                    <td className="py-2">{report?.employeeName}</td>
                    <td>{formatDate(report?.date)}</td>
                    <td>{formatTime(report?.punchIn)}</td>
                    <td>{formatTime(report?.punchOut)}</td>
                    <td>
                      {report?.productionTime ? report?.productionTime : "-"}
                    </td>
                    <td>
                      <Badge
                        intent={
                          report?.punchInStatus === "On time"
                            ? "success"
                            : "warning"
                        }
                        size="medium"
                        roundness="pill"
                      >
                        {report?.punchInStatus}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        <section
          className={`w-[35%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center mb-4 pr-[31px] `}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Holidays : ({holidays?.length})
            </span>
            <div
              className={`p-2 rounded cursor-pointer hover:bg-gray-100 ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-secondaryDark"
                  : "bg-primaryLight"
              } `}
              onClick={openHolidaysModal}
            >
              <AiFillPlusCircle color="var(--primaryBg)" />
            </div>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[250px] pr-[31px]">
            {holidays?.map((day) => (
              <section
                className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <div
                  className={`w-[50px] h-[50px] rounded-full flex items-center justify-center flex-col text-[14px] font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "bg-secondaryDark text-primaryTextDark"
                      : "bg-primaryLight text-primaryBg"
                  }`}
                >
                  {formatDate1(day?.holiday_day)}
                </div>
                <p
                  className={`font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#2B3674]"
                  }`}
                >
                  {day?.holiday_name}
                </p>
                <RiDeleteBin6Line
                  color="red"
                  onClick={() => deleteHoliday(day?._id)}
                />
              </section>
            ))}
          </div>
        </section>
      </div>

      {/* add holidays modal */}
      <Modal showModal={addHolidays} setShowModal={setAddHolidays}>
        <div className="w-[347px]">
          <h1 className="text-center text-[20px] font-medium mb-6">
            Add Holidays
          </h1>
          <div className="flex flex-col gap-[6px] mb-4">
            <label htmlFor="holidayName" className="text-primaryTextLight">
              Holiday Name
            </label>
            <input
              type="text"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="holidayName"
              value={holidayData.holiday_name}
              onChange={(e) =>
                setHolidayData({ ...holidayData, holiday_name: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-[6px] mb-6">
            <label htmlFor="holidayName">Date</label>
            <input
              type="date"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="holidayDate"
              value={holidayData.holiday_day}
              onChange={(e) =>
                setHolidayData({ ...holidayData, holiday_day: e.target.value })
              }
            />
          </div>
          <div className="text-center">
            <Button
              intent="primary"
              size="large"
              mode={mode}
              onClick={handleholidaySave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* add announcements modal */}
      <Modal showModal={addAnnouncements} setShowModal={setAddAnnouncements}>
        <div className="w-[347px]">
          <h1 className="text-center text-[20px] font-medium mb-6">
            Add Announcement
          </h1>
          <div className="flex flex-col gap-[6px] mb-4">
            <label htmlFor="holidayName" className="text-primaryTextLight">
              Title
            </label>
            <input
              type="text"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementTitle"
              value={announcementData.title}
              onChange={(e) =>
                setAnnouncementData({
                  ...announcementData,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col gap-[6px] mb-6">
            <label htmlFor="holidayDate">Date</label>
            <input
              type="date"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementDate"
              value={announcementData.date}
              onChange={(e) =>
                setAnnouncementData({
                  ...announcementData,
                  date: e.target.value,
                })
              }
            />
          </div>
          <div className="text-center">
            <Button
              intent="primary"
              size="large"
              mode={mode}
              onClick={handleAnnouncementSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <div className="flex gap-4">
        <section
          className={`w-[35%] h-[345px] pl-[23px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center mb-4 pr-[23px] `}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Birthdays / Work Anniversary
            </span>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[250px] pr-[23px]">
            {anniversary?.map((ann) => (
              <section
                className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                {ann?.employeeProfileUrl ? (
                  <Avatar type="img" src={ann?.employeeProfileUrl} size="lg" />
                ) : (
                  <Avatar size="lg" type="text">
                    MD
                  </Avatar>
                )}

                <Avatar
                  type="img"
                  src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                  size="lg"
                />
                <div>
                  <h3
                    className={`font-[700] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    {ann?.emp_name}
                  </h3>
                  <h3
                    className={`font-[500] text-[12px] ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#A3AED0]"
                    }`}
                  >
                    {ann?.occasion}
                  </h3>
                </div>
              </section>
            ))}
          </div>
        </section>
        <section
          className={`w-[65%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <h1 className={`flex justify-between items-center pr-[31px]`}>
            <span
              className={`text-[24px] font-[700] ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-[#2B3674]"
              }`}
            >
              Leave
            </span>
            <Link
              to="/leave"
              className={`text-[12px] flex items-center  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryBg"
              }`}
            >
              <span>View All</span>
              <MdKeyboardArrowRight />
            </Link>
          </h1>
          <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
            <table className="border-collapse text-left w-full text-[14px]">
              <thead
                className={`h-[60px] font-medium ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-[#A3AED0]"
                    : "text-[#A3AED0]"
                } `}
              >
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {leave?.map((l) => (
                  <tr
                    className={`font-medium ${
                      organisation?.organisationTheme?.name === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    } `}
                  >
                    <td className="py-2">{l?.emp_name}</td>
                    <td>
                      {formatDate(l?.emp_leave?.from_date)} -{" "}
                      {formatDate(l?.emp_leave?.to_date)}
                    </td>
                    <td>{l?.emp_leave?.duration}day</td>
                    <td>
                      <span className="capitalize">
                        {l?.emp_leave?.leave_type}
                      </span>
                    </td>
                    <td>
                      <p className="capitalize flex items-center gap-1">
                        <span>
                          {l?.emp_leave?.leave_status === "approved" ? (
                            <AiOutlineCheck color="#08A758" />
                          ) : l?.emp_leave?.leave_status === "reject" ? (
                            <AiOutlineClose color="#FF3838" />
                          ) : (
                            <TbClockExclamation color="#F9A000" />
                          )}
                        </span>
                        {l?.emp_leave?.leave_status === "approved" ? (
                          <p>Approved</p>
                        ) : l?.emp_leave?.leave_status === "reject" ? (
                          <p>Reject</p>
                        ) : (
                          <p>Pending</p>
                        )}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <section
        className={`w-[35%] h-[345px] pl-[23px] py-[19px] rounded-lg mt-[33px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-primaryDark"
            : "bg-white"
        }`}
      >
        <h1 className={`flex justify-between items-center mb-4 pr-[23px] `}>
          <span
            className={`text-[24px] font-[700] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-[#2B3674]"
            }`}
          >
            New joining
          </span>
        </h1>
        <div className="scrollContainer overflow-y-auto h-[250px] pr-[23px]">
          {newJoining?.map((n) => (
            <section
              className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
              }`}
            >
              <Avatar size="lg" type="text">
                {n?.emp_name?.slice(0, 1)}
              </Avatar>
              <div>
                <h3
                  className={`font-[700] ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#2B3674]"
                  }`}
                >
                  {n?.emp_name}
                </h3>
                <h3
                  className={`font-[500] text-[12px] line-clamp-1 ${
                    organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-[#A3AED0]"
                  }`}
                >
                  {n?.designation?.des_name}
                </h3>
              </div>
            </section>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
