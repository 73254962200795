/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";


const Announcements = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  //   get all announcements
  const [announcements, setAnnouncements] = useState();
  //   console.log("announcements", announcements);

  //   add announcements
  const [addAnnouncement, setAddAnnouncement] = useState({});
  console.log("addAnnouncement", addAnnouncement);

  //   add announcements modal
  const [addAnnouncementsModal, setAddAnnouncementsModal] = useState(false);
  const openAddAnnouncementsDetails = () => {
    setAddAnnouncementsModal((prev) => !prev);
    setAddAnnouncement({});
  };

  //   get all announcements
  const getAllAnnouncements = async () => {
    try {
      const headers = {
        'api-key': apiKey,
      };
  
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/announcement/get-all-announcement`,
        { headers }
      );
      setAnnouncements(result?.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };
  

  //   add announcements
  const handleAddAnnouncements = () => {
  
      
  
    const headers = {
      'api-key': apiKey,
      'Authorization': `Bearer ${token}`
    };
  
    axios
      .post(
        `${LOCALHOST_BASE_URL}/announcement/add-announcement`,
        addAnnouncement,
        { headers }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Announcement added");
        setAddAnnouncementsModal(false);
        getAllAnnouncements();
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.status === 404) {
          showSnackbar("Data not found", "red");
        } else {
          showSnackbar("Something is wrong, please try again.", "red");
        }
      });
  };
  

  //   edit announcements
  const updateAnnouncements = () => {
  
      
  
    const headers = {
      'api-key': apiKey,
      'Authorization': `Bearer ${token}`
    };
  
    axios
      ?.put(
        `${LOCALHOST_BASE_URL}/announcement/update-announcement?id=${addAnnouncement?._id}`,
        addAnnouncement,
        { headers }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Announcement updated");
        getAllAnnouncements();
        setAddAnnouncement({});
        setAddAnnouncementsModal(false);
      })
      .catch((error) => {
        console.log(error);
        setAddAnnouncement({});
        setAddAnnouncementsModal(false);
  
        if (error.response?.status === 404) {
          showSnackbar("Data not found", "red");
        } else {
          showSnackbar("Something is wrong, please try again.", "red");
        }
      });
  };
  

  // delete announcements
  const deleteAnnouncements = async (id, title) => {
    console.log(id, title);
  
      
  
    const headers = {
      'api-key': apiKey,
      'Authorization': `Bearer ${token}`
    };
  
    try {
      await axios.delete(
        `${LOCALHOST_BASE_URL}/announcement/delete-announcement?id=${id}`,
        { headers }
      );
      getAllAnnouncements();
    } catch (error) {
      console.error("Error deleting announcement:", error);
  
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };
  

  useEffect(() => {
    getAllAnnouncements();
  }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center mb-[44px]">
        <h1 className={`text-[32px] text-primaryText`}>Announcement</h1>
        <Button
          intent="primary"
          mode={mode}
          onClick={openAddAnnouncementsDetails}
        >
          + Add Announcement
        </Button>
      </section>
      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <span className="pl-2">Announcement Name</span>
                </Th>
                <Th>Date</Th>
                <Th>Description</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                // ?.reverse()
                ?.map((ann, i) => {
                  return (
                    <TableRow key={i}>
                      {console.log("ann", ann)}
                      <Td>
                        <span className="pl-2">{ann?.title}</span>
                      </Td>
                      <Td>{ann?.date}</Td>
                      <Td>{ann?.description}</Td>
                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                            onClick={() => {
                              setAddAnnouncementsModal(true);
                              setAddAnnouncement({
                                ...addAnnouncement,
                                ...ann,
                                edit: true,
                              });
                            }}
                          >
                            <FiEdit2 />
                          </h2>
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                            onClick={() =>
                              deleteAnnouncements(ann?._id, ann?.title)
                            }
                          >
                            <RiDeleteBin6Line />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={announcements?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>

      {/* add announcement modal */}
      <Modal
        showModal={addAnnouncementsModal}
        setShowModal={setAddAnnouncementsModal}
      >
        <div className="w-[384px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            {addAnnouncement?.edit ? "Edit" : "Add New"} Announcement
          </h1>
          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="annname">
                Announcement Name <span className="">*</span>
              </label>
              <input
                type="text"
                placeholder="Add Announcement"
                value={addAnnouncement?.title}
                onChange={(e) => {
                  setAddAnnouncement({
                    ...addAnnouncement,
                    title: e.target.value,
                  });
                }}
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="anndate">
                Date <span className="">*</span>
              </label>
              <input
                type="date"
                value={addAnnouncement?.date}
                onChange={(e) => {
                  setAddAnnouncement({
                    ...addAnnouncement,
                    date: e.target.value,
                  });
                }}
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="anndesc">
                Description <span className="">*</span>
              </label>
              <textarea
                name="description"
                id="description"
                value={addAnnouncement?.description}
                onChange={(e) => {
                  setAddAnnouncement({
                    ...addAnnouncement,
                    description: e.target.value,
                  });
                }}
                cols="5"
                rows="3"
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full resize-y`}
              ></textarea>
            </section>
          </div>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={
                addAnnouncement?.edit
                  ? updateAnnouncements
                  : handleAddAnnouncements
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Announcements;
