/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey, token} from "../utils/config";
import Badge from "../components/Badge";
import Modal from "../components/Modal";
import { useSnackbar } from "../context/SnackbarContext";
import { Link } from "react-router-dom";

const Agreements = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  let [tab, setTab] = useState("Agreements");
  // get all agreements
  const [agreements, setAgreements] = useState([]);
  // const [allDepartments, setAllDepartments] = useState();
  // console.log("alldepts", deptData);

  const [assignAssetData, setAssignAssetData] = useState({});
  // console.log("assignAssetData", assignAssetData);

  const [assignEmployeeData, setAssignEmployeeData] = useState([]);
  // console.log("assignEmployeeData", assignEmployeeData);

  // const handleAssignAgreementChange = (e, name, index) => {
  //   const { value } = e.target;
  //   if (name === "dept" || name === "emp_id" || name === "givenDate") {
  //     setAssignAssetData((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));
  //   } else {
  //     const updatedAssignEmployeeData = assignAssetData.assignEmployeeData
  //       ? [...assignAssetData.assignEmployeeData]
  //       : [];
  //     if (updatedAssignEmployeeData[index]) {
  //       updatedAssignEmployeeData[index] = {
  //         ...updatedAssignEmployeeData[index],
  //         [name]: value,
  //       };
  //     } else {
  //       updatedAssignEmployeeData[index] = {
  //         [name]: value,
  //       };
  //     }
  //     setAssignAssetData((prev) => ({
  //       ...prev,
  //       assignEmployeeData: updatedAssignEmployeeData,
  //     }));
  //   }
  // };

  // new onchange
  const handleAssignAgreementChange = (e, name, index) => {
    const { value } = e.target;
    if (name === "dept" || name === "emp_id" || name === "givenDate") {
      setAssignAssetData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      const updatedAssignEmployeeData =
        assignAssetData.assignEmployeeData || [];
      if (updatedAssignEmployeeData[index]) {
        updatedAssignEmployeeData[index] = {
          ...updatedAssignEmployeeData[index],
          [name]: value,
        };
      } else {
        updatedAssignEmployeeData[index] = {
          [name]: value,
        };
      }
      setAssignAssetData((prev) => ({
        ...prev,
        assignEmployeeData: updatedAssignEmployeeData,
      }));
    }
  };

  const handleAssignAgreementChangeNew = (e, name) => {
    setAssignAssetData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const [assignModal, setAssignModal] = useState(false);
  const [tempVariableData, setTempVariableData] = useState();
  console.log("tempVariableData", tempVariableData);

  const [selectedEmpId, setSelectedEmpId] = useState(null);
  console.log("selectedEmpId", selectedEmpId);

  const openAssignModal = (id) => {
    setTempVariableData(agreements?.result?.find((agr) => agr?._id === id));
    // const clickedCardData = agreements?.result?.find((agr) => agr?._id === id);
    setAssignModal((prev) => !prev);
  };

  // assigned agreement card view modal
  const [assignedEmployeeModal, setAssignedEmployeeModal] = useState(false);
  const openAssignedEmployeeModal = (id) => {
    setTempVariableData(agreements?.result?.find((agr) => agr?._id === id));
    setAssignedEmployeeModal((prev) => !prev);
  };

  // edit assigned agreement card modal
  const [editAssignedEmployeeModal, setEditAssignedEmployeeModal] =
    useState(false);
  const openEditAssignedEmployeeModal = (id, empId) => {
    setSelectedEmpId(empId);
    setEditAssignedEmployeeModal((prev) => !prev);
  };

  // console.log("Clicked Card Data:", tempVariableData);
  // console.log("agreements", agreements);

  const tabData = [
    {
      id: 1,
      tabName: "Agreements",
    },
    {
      id: 2,
      tabName: "Assigned Asset",
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Agreements":
        return (
          <AgreementsDetails
            agreements={agreements}
            formatDate={formatDate}
            organisation={organisation}
            deptData={deptData}
            filteredEmployees={filteredEmployees}
            assignAssetData={assignAssetData}
            setAssignAssetData={setAssignAssetData}
            assignModal={assignModal}
            setAssignModal={setAssignModal}
            openAssignModal={openAssignModal}
            tempVariableData={tempVariableData}
            handleAssignAgreementChange={handleAssignAgreementChange}
            handleAddAssignAgreement={handleAddAssignAgreement}
            // clickedCardData={clickedCardData}
          />
        );
      case "Assigned Asset":
        return (
          <AssignAsset
            agreements={agreements}
            formatDate={formatDate}
            organisation={organisation}
            deptData={deptData}
            filteredEmployees={filteredEmployees}
            assignAssetData={assignAssetData}
            setAssignAssetData={setAssignAssetData}
            assignModal={assignModal}
            setAssignModal={setAssignModal}
            openAssignModal={openAssignModal}
            openAssignedEmployeeModal={openAssignedEmployeeModal}
            assignedEmployeeModal={assignedEmployeeModal}
            setAssignedEmployeeModal={setAssignedEmployeeModal}
            tempVariableData={tempVariableData}
            editAssignedEmployeeModal={editAssignedEmployeeModal}
            setEditAssignedEmployeeModal={setEditAssignedEmployeeModal}
            openEditAssignedEmployeeModal={openEditAssignedEmployeeModal}
            selectedEmpId={selectedEmpId}
            handleAssignAgreementChange={handleAssignAgreementChange}
            showSnackbar={showSnackbar}
            getAllAgreements={getAllAgreements}
          />
        );
      default:
    }
  };

  // get all agreements
  const getAllAgreements = async () => {
    try {
      const headers = {
        "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/get-All-agreement`,
        { headers }
      );
      console.log(result.data);
      setAgreements(result?.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };

  // add assign agreement
  const handleAddAssignAgreement = (id) => {
  
  

    axios
      .put(
        `${LOCALHOST_BASE_URL}/agreement/assign-agreement?id=${tempVariableData?._id}`,
        {
          ...assignAssetData,
          assignEmployee: assignAssetData?.assignEmployee
            ? [
                ...assignAssetData?.assignEmployee,
                {
                  emp_data: assignAssetData?.assignEmployeeData,
                  emp_id: assignAssetData?.emp_id,
                },
              ]
            : [
                {
                  emp_data: assignAssetData?.assignEmployeeData,
                  emp_id: assignAssetData?.emp_id,
                },
              ],
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Agreement Assigned");
        showSnackbar("Agreement Assigned.", "green");
        setAssignModal(false);
        getAllAgreements();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something is wrong, please try again.", "red");
      });
  };

  // localhost:7065/api/v1/peoples/agreement/updateAssign-agreement?id=652cd2324a18157f6c462bc8&assignEmployeeId=6544bd6c5fc60e0d4eefb99d

  const filteredEmployees =
    deptData?.find((dept) => dept?.dept_name === assignAssetData?.dept)
      ?.employee || [];

  console.log("filteredEmployees", filteredEmployees);

  useEffect(() => {
    getAllAgreements();
  }, []);

  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  console.log("idsss", tempVariableData?._id, selectedEmpId?._id);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <h1 className={`text-[32px] text-primaryText`}>
          Agreements and Assign Asset
        </h1>
        <Button
          intent="primary"
          mode={mode}
          onClick={() => navigate(`/create-agreement`)}
        >
          Create New Agreement
        </Button>
      </section>
      <div>
        <section className="flex gap-5 items-center border-b mt-[51px] mb-[47px]">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`pb-2 text-[14px] cursor-pointer ${
                    tab === i.tabName
                      ? "text-primaryBg border-b border-primaryBg"
                      : "text-primaryText"
                  }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <section className="my-[33px]">{getCorrectPage(tab)}</section>
      </div>

      {/* add assign employee view modal */}
      <Modal showModal={assignModal} setShowModal={setAssignModal}>
        <div className="w-[573px] max-h-[600px] overflow-y-scroll">
          <p className="text-[20px] text-primaryText text-center mb-[14px] font-medium capitalize">
            Assign {tempVariableData?.assetHeading}
          </p>
          {console.log("tempVariableData", tempVariableData)}
          <div className="grid grid-cols-2 gap-5">
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="dept">Select Department</label>
              <select
                name="dept"
                id="dept"
                required
                onChange={(e) => handleAssignAgreementChange(e, "dept")}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="dept" selected disabled>
                  Select Department
                </option>
                {deptData?.map((dept) => (
                  <option value={dept?.dept_name}>{dept?.dept_name}</option>
                ))}
              </select>
            </section>
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="dept">Select Employee</label>
              <select
                name="emp"
                id="emp"
                required
                // onChange={(e) =>
                //   setAssignAssetData({
                //     ...assignAssetData,
                //     emp_id: e.target.value,
                //   })
                // }
                onChange={(e) => handleAssignAgreementChange(e, "emp_id")}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="emp" disabled selected>
                  Select Employee
                </option>
                {filteredEmployees?.map((emp) => (
                  <option value={emp?._id}>{emp?.emp_name}</option>
                ))}
              </select>
            </section>
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="number">
                Given Date <span className="">*</span>
              </label>
              <input
                type="date"
                name="givenDate"
                onChange={(e) => handleAssignAgreementChange(e, "givenDate")}
                required
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
          </div>

          {/* <h2>{tempVariableData?.assetHeading}</h2> */}
          <div className="grid grid-cols-2 gap-5 mt-5">
            {tempVariableData?.variableTempBody?.map((temp, index) => (
              <section className="flex flex-col gap-[6px] mb-2">
                <label htmlFor={temp?.variableName} className="capitalize">
                  {temp?.variableName}
                </label>
                <input
                  type="text"
                  name={temp?.variableName}
                  // value={temp?.variableValue}
                  onChange={(e) =>
                    handleAssignAgreementChange(e, temp?.variableName, index)
                  }
                  placeholder={temp?.variableName}
                  required
                  className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
                />
              </section>
            ))}
          </div>

          <section className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={() => handleAddAssignAgreement()}
            >
              Assign
            </Button>
          </section>
        </div>
      </Modal>
    </div>
  );
};

const AgreementsDetails = ({
  agreements,
  mode,
  formatDate,
  organisation,
  deptData,
  filteredEmployees,
  assignAssetData,
  setAssignAssetData,
  assignModal,
  setAssignModal,
  openAssignModal,
  clickedCardData,
  tempVariableData,
  handleAssignAgreementChange,
  handleAddAssignAgreement,
  openAssignedEmployeeModal,
}) => {
  // console.log("AgreementsDetails", agreements);
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-4 gap-4">
      {agreements?.result?.map((agr) => (
        <section className="h-[200px] rounded border shadow-lg px-4 py-2">
          {/* {console.log("agreements?.result", agr)} */}
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-primaryBg capitalize font-semibold">
              {agr?.assetHeading}
            </h1>
            <div className="flex gap-1 items-center">
              <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                onClick={() => navigate(`/create-agreement/${agr?._id}`)}
              >
                <FiEdit2 color="var(--primaryText)" />
              </h2>
              {/* <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
              >
                <RiDeleteBin6Line color="var(--primaryText)" />
              </h2> */}
            </div>
          </div>
          <p className="h-[100px] text-primaryText line-clamp-1">
            {agr?.assetSummary}
          </p>
          <div className="flex justify-center items-center gap-4">
            <Button
              intent="primary"
              mode={mode}
              onClick={() => navigate(`/view-agreement/${agr?._id}`)}
            >
              View
            </Button>
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => openAssignModal(agr?._id, agr?.assetHeading)}
            >
              Assign
            </Button>
          </div>
        </section>
      ))}
    </div>
  );
};

const AssignAsset = ({
  agreements,
  mode,
  formatDate,
  organisation,
  openAssignedEmployeeModal,
  assignedEmployeeModal,
  setAssignedEmployeeModal,
  tempVariableData,
  openEditAssignedEmployeeModal,
  editAssignedEmployeeModal,
  setEditAssignedEmployeeModal,
  selectedEmpId,
  handleAssignAgreementChange,
  handleAssignAgreementChangeNew,
  showSnackbar,
  setAssignModal,
  getAllAgreements,
  assignAssetData,
}) => {
  // update assign asset

  const [editAssignedEmployeeModal2, setEditAssignedEmployeeModal2] =
    useState(false);
  const [oneAgreement, setOneAgreement] = useState([]);
  const [selectedEmpAgreement, setSelectedEmpAgreement] = useState([]);

  const openEditAssignedEmployeeModal2 = async (id, empId) => {
    setEditAssignedEmployeeModal2((prev) => !prev);
    console.log("Edit Assigned Employee Modified", id, empId);
    try {
      const headers = {
        "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/get-agreement?id=${id}`,
        { headers }
      );
      console.log(result);
      setOneAgreement(result?.data);
      // Find the matching object in the assignEmployee array
      const matchingEmployee = result.data.assignEmployee.find(
        (employee) => employee._id === empId
      );
      if (matchingEmployee) {
        // If a matching object is found, set it into selectedEmpAgreement
        setSelectedEmpAgreement(matchingEmployee);
      } else {
        // Handle the case where no matching object is found
        showSnackbar("Employee not found in the agreement", "red");
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };

  console.log("line 598 ", oneAgreement);
  console.log("line 612 ", selectedEmpAgreement);

  const handleInputChange = (index, key, value) => {
    const updatedEmpData = [...selectedEmpAgreement.emp_data];
    updatedEmpData[index][key] = value;

    setSelectedEmpAgreement({
      ...selectedEmpAgreement,
      emp_data: updatedEmpData,
    });
  };

  console.log("line 624 ", selectedEmpAgreement?.emp_data);

  const handleUpdateAssign = (id, assignEmployeeId) => {
  
  
    const body = selectedEmpAgreement?.emp_data;

    console.log("line 590", body);
    console.log("line 590", selectedEmpAgreement?.id, assignEmployeeId);
    console.log("line 592", id);

    axios
      .put(
        `${LOCALHOST_BASE_URL}/agreement/updateAssign-agreement?id=${id}&assignEmployeeId=${assignEmployeeId}`,
        {
          emp_data: body,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Agreement Updated");
        showSnackbar("Agreement Updated.", "green");
        setAssignModal(false);
        getAllAgreements();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something is wrong, please try again.", "red");
      });
  };

  return (
    <>
      {console.log("agreements", agreements?.result)}

      <div className="my-[32px]">
        <section className="flex gap-4 items-center">
          <select
            name="dept"
            id="dept"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[122px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="dept" selected disabled>
              Department
            </option>
          </select>
          <select
            name="month"
            id="month"
            className={`py-2 px-2 text-[12px] rounded outline-none  focus:border-primaryBg w-[92px]  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="month" selected disabled>
              Month
            </option>
          </select>
          <select
            name="year"
            id="year"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="year" selected disabled>
              Year
            </option>
          </select>
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="status" selected disabled>
              Status
            </option>
          </select>
          <button className={`text-primaryBg border-b border-primaryBg`}>
            Clear Filter
          </button>
        </section>
        {/* cards */}
        <div className="grid grid-cols-4 gap-[26px] mt-8">
          {agreements?.result?.reverse()?.map((res) => (
            <section
              className={`w-[261px] h-[300px] shadow-md rounded cardShadow text-primaryText ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
              }`}
            >
              <div
                className={`py-[14px] text-center h-[80px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "bg-[#2A2E3D]"
                    : "bg-primaryLight"
                }`}
              >
                <h2 className="text-primaryBg font-semibold mb-1">
                  Type of Asset
                </h2>
                <span>{res?.typeofAsset}</span>
              </div>
              <div className="px-4">
                <span className="text-primaryText text-[12px] font-semibold">
                  Asset details :
                </span>
                <p className="h-[50px] mb-2 line-clamp-2 text-[12px]">
                  {res?.assetSummary}
                </p>
              </div>

              <div className="px-4 mb-5 h-[70px]">
                <p className="text-primaryText text-[12px] font-semibold">
                  Assigned Employees :
                </p>
                {res?.assignEmployee?.map((emp) => (
                  <>
                  <span className="text-[12px] text-primaryBg mr-1 capitalize">
                    {emp?.emp_id?.emp_name}
                  </span>
                  </>
                ))}
              </div>
              <section className="text-center">
                <Button
                  intent="secondary"
                  mode={mode}
                  onClick={() =>
                    openAssignedEmployeeModal(res?._id, res?.assetHeading)
                  }
                >
                  View
                </Button>
              </section>
            </section>
          ))}
        </div>

        {/* view and update assigned employee modal */}
        <Modal
          showModal={assignedEmployeeModal}
          setShowModal={setAssignedEmployeeModal}
          tempVariableData={tempVariableData}
        >
          <div className="w-[700px]">
            <h1 className="text-center text-[20px] font-medium mb-4 capitalize">
              View {tempVariableData?.typeofAsset}
            </h1>

            <table className="w-full border text-left text-[14px]">
              <thead className="bg-primaryLight h-[40px]">
                <tr>
                  <th className="pl-2 py-1">Name</th>
                  <th>Designation</th>
                  <th>Status</th>
                  <th>View Agreement</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tempVariableData?.assignEmployee?.map((emp) => (
                  <tr>
                    {console.log("emp", emp)}
                    <td className="pl-2 py-2">
                      {console.log("emp", emp?._id)}
                      <div className="flex gap-1 items-center">
                        <Avatar
                          type="img"
                          src={emp?.emp_id?.emp_profile}
                          size="md"
                        />
                        <span>{emp?.emp_id?.emp_name}</span>
                      </div>
                    </td>
                    <td>{emp?.emp_id?.emp_designation?.des_name}</td>
                    <td>{emp?.status === true ? "Accepted" : "Rejected"}</td>
                    <td>
                      <Link
                        to={`/view-emp-agreement/${tempVariableData?._id}/${emp?._id}`}
                        className="border-b border-primaryBg"
                      >
                        View
                      </Link>
                    </td>
                    <td>
                      <div className="flex gap-3 items-center">
                        {emp?.status === true ? (
                          ""
                        ) : (
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                            onClick={() =>
                              openEditAssignedEmployeeModal2(
                                tempVariableData?._id,
                                emp?._id
                              )
                            }
                          >
                            <FiEdit2 />
                          </h2>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>

        {/* edit assign employee modal */}
        <Modal
          showModal={editAssignedEmployeeModal2}
          setShowModal={setEditAssignedEmployeeModal2}
          tempVariableData={tempVariableData}
          selectedEmpId={selectedEmpId}
        >
          <>
            <h1 className="text-[20px] font-medium mb-4 text-center">
              Update Asset Variables
            </h1>
            <div className="test w-[600px] grid grid-cols-2 gap-1">
              {selectedEmpAgreement?.emp_data?.map((data, index) => {
                return Object.entries(data).map(([key, value]) => (
                  <section className="flex flex-col gap-[6px] mb-2" key={index}>
                    <label htmlFor={key} className="capitalize">
                      {key}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={(e) => {
                        handleInputChange(index, key, e.target.value);
                      }}
                      required
                      className="px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full"
                    />
                  </section>
                ));
              })}
            </div>
            <section className="mt-5 text-center">
              <Button
                intent="primary"
                mode={mode}
                onClick={() =>
                  handleUpdateAssign(
                    tempVariableData?._id,
                    selectedEmpAgreement?._id
                  )
                }
              >
                Update
              </Button>
            </section>
          </>
        </Modal>
      </div>
    </>
  );
};

export default Agreements;
