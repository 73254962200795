/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import computerLogin from "../assets/computerLogin-rafiki.svg";
import logo from "../assets/logo.svg";
import FormsPana from "../assets/Forms-pana.svg";
import ForgotPasswordImg from "../assets/Forgot-password-pana.svg";
import ConfirmPassword from "../assets/ConfirmPassword.svg";
import PasswordRecoverySuccessful from "../assets/PasswordRecoverySuccessful.svg";
import login from "../assets/login.svg";
import { BsUpload } from "react-icons/bs";
import { useSnackbar } from "../context/SnackbarContext";

import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { ContextThemeProvider } from "../context/ContextData";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";

const VerifyCation = () => {
  const navigate = useNavigate();
  const [forgotPasswordData, setForgotPasswordData] = useState({});
  const { showSnackbar } = useSnackbar();

  const handleEmailSent = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(
        `${LOCALHOST_BASE_URL}/employee/sendVerifyEmail`,
        forgotPasswordData,
        {
          headers: {
            'api-key': apiKey,
            'Authorization': `Bearer ${token}`,
          }
        }
      );
  
      navigate(`/dashboard`);
      showSnackbar(
        `User Verification link is sent to the Registered Email Address`,
        "green"
      );
    } catch (error) {
      console.error(error);
  
      if (error.response) {
        if (error.response.status === 404) {
          showSnackbar(
            `Wait for Some time and try again.`,
            "red"
          );
        } else {
          showSnackbar(
            `Enter your valid Registered Email Address`,
            "red"
          );
          console.log(error?.response?.data);
        }
      } else {
        showSnackbar(
          `Something went wrong. Please try again.`,
          "red"
        );
      }
    }
  };
  
  

   return (
    <>
    <header
      className={`pl-[26px] pr-[50px]  mt-[25px] ml-7`}
    >
      <div className="flex justify-between items-center">
        <section className="flex items-center gap-4 py-[20px]">
          <img src={logo} alt="logo" />
          <h1
            className={``}
          >
            People’s Console
          </h1>
        </section>
      </div>
    </header>
     <div className="grid grid-cols-2 px-[50px] py-[50px] justify-center mt-15">
        <div className="w-[454px] p-[30px] signupShadow rounded-lg">
          <h1 className="font-[500] text-[32px]">Forgot password</h1>
          <p className="text-[12px] text-[#999] mb-[24px]">
            Enter your email for the verification process, we will send code to your email
          </p>
          <form action="" onSubmit={handleEmailSent}>
            <section className="flex flex-col gap-2 mb-4">
              <label htmlFor="email" className="font-[500]">
                Email address <sup className="text-[#ff4949]">*</sup>
              </label>
              <input
                type="email"
                name="email"
                placeholder="name@compagny.com"
                required
                className="border border-[#e2d2e5] px-4 py-2 outline-none rounded focus:border-[#5860F8]"
                onChange={(e) =>
                  setForgotPasswordData({
                    ...forgotPasswordData,
                    emp_email: e.target.value,
                  })
                }
              />
            </section>
            <button
              type="submit"
              className="my-[24px] bg-[#5860F8] px-4 py-2 w-full font-[500] text-[#fff] rounded hover:bg-[#5860F8]"
            >
              Continue
            </button>
            {/* </Link> */}
          </form>
        </div>
        <div className="h-[300px]">
          <img src={ForgotPasswordImg} alt="" />
        </div>
        </div>
    </>
  );
};

export default VerifyCation;
