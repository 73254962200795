/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router";
import { ContextThemeProvider } from "../../context/ThemeContext";
import axios, { all } from "axios";
import { LOCALHOST_BASE_URL , token ,apiKey} from "../../utils/config";
import { useSnackbar } from "../../context/SnackbarContext";
import jwt_decode from "jwt-decode";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const UserBenefits = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const decodedToken = jwt_decode(token);

  const [benefits, setUserBenefits] = useState();

  const getUserBenefits = async () => {
  
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/benefit/getBenefitByEmpId?empId=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setUserBenefits(result?.data);
      } else {
        console.log("Error fetching benefits");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  useEffect(() => {
    getUserBenefits();
  }, []);

  console.log("Successfully fetched", benefits);

  return (
    <div className={`pl-[36px] py-[29px] pr-[45px] h-[100vh] ${
      organisation?.organisationTheme?.name === "dark"
        ? "bg-secondaryDark"
        : "bg-primaryLight"
    } `}>
      <section className={`flex justify-between items-center mb-5 `}>
        <h1 className={`text-[34px]`}>
          {" "}
          <span
            className={`font-[500] ${
              organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
            }`}
          >
            Benefits
          </span>
        </h1>
        <div className="flex items-center gap-4"></div>
      </section>
      <div className="grid grid-cols-3 gap-[26px] my-[54px]">
        {benefits?.map((d) => (
          <section
            className={`w-[342px] h-[222px] shadow-md rounded py-3 cardShadow ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <div className="flex justify-between items-center pr-3">
              <h2 className="text-primaryText px-4 font-semibold text-[20px]">
                {d?.benefit_heading}
              </h2>
            </div>
            <p className="h-[110px] text-[12px] my-2 px-4 text-primaryText line-clamp-2">
              <div
                className="text-primaryText"
                dangerouslySetInnerHTML={{
                  __html: d?.benefitBody,
                }}
              ></div>
            </p>
            <div className="flex justify-center items-center gap-4">
              <Button
                intent="primary"
                mode={mode}
                onClick={() => navigate(`/user-benefit/${d?._id}`)}
              >
                <p className="flex items-center gap-2">
                  View Benefits <HiOutlineArrowNarrowRight size={20} />
                </p>
              </Button>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default UserBenefits;
