/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , token , apiKey } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const Designation = () => {
  const { showSnackbar } = useSnackbar();
  const { color, mode, organisation } = useContext(ContextThemeProvider);

  const [designation, setDesignation] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [addDesignation, setAddDesignation] = useState({});
  console.log("addDesignation", addDesignation);
  // add designation modal
  const [addDesigntionModal, setAddDesignationModal] = useState(false);
  const openAddDesignationDetails = () => {
    setAddDesignationModal((prev) => !prev);
    setAddDesignation({});
  };

  //GET ALL DESIGNATIONS:
  const getDesignation = async () => {
    try {
   
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/designations/get-all-designations`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setDesignation(result.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        // Show a snackbar with an error message
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error fetching designations:", error);
      // Show a snackbar with an error message
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  // add designation
  const handleAddDesignation = () => {
 


    axios
      .post(
        `${LOCALHOST_BASE_URL}/designations/add-designation`,
        addDesignation,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Designation added");
        setAddDesignationModal(false);
        getDesignation();
        setAddDesignation({});
        showSnackbar("Designation Added Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        setAddDesignation({});
        showSnackbar("Designation not Added", "red");
      });
  };

  // edit designation
  const UpdateDesignation = () => {
 


    axios
      .put(
        `${LOCALHOST_BASE_URL}/designations/update-designation?id=${addDesignation?._id}`,
        addDesignation,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Designation updated");
        getDesignation();
        setAddDesignation({});
        setAddDesignationModal(false);
        showSnackbar("Designation Updated Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        setAddDesignation({});
        setAddDesignationModal(false);
        showSnackbar("Designation is Not Updated", "red");
      });
  };

  // delete designation
  const deleteDesignation = async (id) => {
 

    try {
      await axios.delete(
        `${LOCALHOST_BASE_URL}/designations/delete-designation?id=${id}`,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("delete id", id);
      getDesignation();
      showSnackbar("Designation Deleted Successfully", "green");
    } catch (error) {
      showSnackbar("Designation is Not Deleted", "red");
      console.error("Error deleting designation:", error);
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Designation</h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="designation"
            id="designation"
            value={selectedDesignation ? selectedDesignation : ""}
            onChange={(e) => setSelectedDesignation(e.target.value)}
            className={`py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="designation" selected disabled>
              Designation
            </option>
            {designation?.map((des) => (
              <option value={des?.des_name}>{des?.des_name}</option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => setSelectedDesignation("")}
          >
            Clear Filter
          </button>
        </section>
        <Button
          intent="primary"
          mode={mode}
          onClick={openAddDesignationDetails}
        >
          Add Designation
        </Button>
      </div>
      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th><p className="pl-2">Sr No.</p></Th>
                <Th>Designation</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {(selectedDesignation
                ? designation
                    ?.reverse()
                    ?.filter((des) => des.des_name === selectedDesignation)
                : designation
              )
                // ?.reverse()
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                ?.map((des, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>
                        <h2 className="pl-2">{i + 1}</h2>
                      </Td>
                      <Td>{des?.des_name}</Td>
                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                            onClick={() => {
                              setAddDesignationModal(true);
                              setAddDesignation({
                                ...des,
                                edit: true,
                              });
                            }}
                          >
                            <FiEdit2 />
                          </h2>
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                            onClick={() => {
                              deleteDesignation(des?._id);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={designation?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>

      {/* add designation modal */}
      <Modal
        showModal={addDesigntionModal}
        setShowModal={setAddDesignationModal}
      >
        <div className="w-[340px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            {addDesignation?.edit ? "Edit" : "Add"} Designation Name
          </h1>

          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">
                Designation Name <span className="">*</span>
              </label>
              <input
                type="text"
                placeholder="Add Designation"
                value={addDesignation?.des_name}
                onChange={(e) =>
                  setAddDesignation({
                    ...addDesignation,
                    des_name: e.target.value,
                  })
                }
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
          </div>

          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={
                addDesignation?.edit ? UpdateDesignation : handleAddDesignation
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Designation;
