import { getCookie } from "@mirats/mirats-auth";
// local use
// export const LOCALHOST_BASE_URL = "https://apiservice.peoples.atomostech.com/atomos/v1/peoples";

//Deploy Use
let base_url;
if (window.location.hostname === "peoples.miratsgroup.com") {
  base_url =
    "https://apiservice.peoples.miratsgroup.com/miratsgroup/v1/peoples";
} else if (window.location.hostname === "peoples.miratsinsights.com") {
  base_url =
    "https://apiservice.peoples.miratsinsights.com/miratsinsights/v1/peoples";
} else if (window.location.hostname === "peoples.lambdaresearch.in") {
  base_url =
    "https://apiservice.peoples.lambdaresearch.in/lambdaresearch/v1/peoples";
} else if (window.location.hostname === "peoples.atomostech.com") {
  base_url = "https://apiservice.peoples.atomostech.com/atomos/v1/peoples";
} else {
  // Default base URL if none of the conditions match
  base_url = "https://apiservice.peoples.atomostech.com/atomos/v1/peoples";
}

export const LOCALHOST_BASE_URL = base_url;

export const token = getCookie('tokenpeoples');
// export const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YWRmZTNlOWI1NTEyZWE4OGUxMjgwMCIsImVtcF9uYW1lIjoiVmFyc2hhIEphZGhhdiIsImVtcF9wcm9maWxlIjoiaHR0cHM6Ly9zdG9yYWdlLm1pcmF0c29uZXNlcnZpY2VzLmNvbS9hcGkvZmlsZS9kb3dubG9hZC9taXJhdHNmaWxlc3RvcmFnZS9QZW9wbGVzLXBvcnRhbC8zNy8zLkpQRUciLCJlbXBfZGVzaWduYXRpb24iOiJTb2Z0d2FyZSBEZXZlbG9wZXIgRW5naW5lZXIgKEJhY2tlbmQpIiwiZW1wX2RlcGFydG1lbnQiOiJTb2Z0d2FyZSBTZXJ2aWNlcyIsInJvbGUiOiJhZG1pbiIsImFjY2VzcyI6eyJfaWQiOiI2NWFlMGI1YTgzZDM3N2ZlM2IyZGY1NWEiLCJlbXBfaWQiOiI2NWFkZmUzZTliNTUxMmVhODhlMTI4MDAiLCJhY2Nlc3NMZXZlbCI6IkFkbWluIiwiZGFzaGJvYXJkIjp0cnVlLCJkYWlseVJlcG9ydCI6dHJ1ZSwiYXR0ZW5kYW5jZSI6dHJ1ZSwibGVhdmUiOnRydWUsInBheXJvbGwiOnRydWUsInJlaW1idXJzZW1lbnQiOnRydWUsIm9yZ2FuaXphdGlvbiI6dHJ1ZSwib2ZmZXJsYXR0ZXIiOnRydWUsInBvbGljeSI6dHJ1ZSwicGF5c2xpcHMiOnRydWUsImRlcGFydG1lbnQiOnRydWUsImRlc2lnbmF0aW9uIjp0cnVlLCJ0ZWFtIjp0cnVlLCJzaGlmdFNjaGVkdWxlIjp0cnVlLCJhZ3JlZW1lbnRzIjp0cnVlLCJiZW5lZml0cyI6dHJ1ZSwidGVybWluYXRpb24iOnRydWUsInJlc2lnbmF0aW9uIjp0cnVlLCJsYXlPZmYiOnRydWUsIm1lbWJlciI6dHJ1ZSwiYW5ub3VuY2VtZW50Ijp0cnVlLCJsZWF2ZUN1c3RvbWlzYXRpb24iOnRydWUsInBheVNsaXBDdXN0b21pc2F0aW9uIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDI0LTAxLTIyVDA2OjI5OjQ2Ljc5M1oiLCJ1cGRhdGVkQXQiOiIyMDI0LTAxLTIyVDA2OjI5OjQ2Ljc5M1oiLCJfX3YiOjB9LCJpYXQiOjE3MDYwODE0NDh9.Jt3Wcu59vwOdJIMnRQQzGd-AURG1ln3E7yGel4AAMcg"

export const apiKey = "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=";
