/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { LOCALHOST_BASE_URL, apiKey, token } from "../../utils/config";
import { ContextThemeProvider } from "../../context/ThemeContext";
import logo from "../../assets/logo.svg";
import { HiBell } from "react-icons/hi";
import Avatar from "../../components/Avatar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import SidebarMenu from "../../components/SidebarMenu";
import dashIcon from "../../assets/sidebarIcons/whitemodeicon/Dashboard.svg";
import dashIconDark from "../../assets/sidebarIcons/blackmodeicon/Dashboard.svg";
import attendanceIcon from "../../assets/sidebarIcons/whitemodeicon/Attendance.svg";
import attendanceIconDark from "../../assets/sidebarIcons/blackmodeicon/Attendance.svg";
import timesheetIcon from "../../assets/sidebarIcons/whitemodeicon/Time Sheet.svg";
import timesheetIcondark from "../../assets/sidebarIcons/blackmodeicon/Time Sheet.svg";
import leaveIcon from "../../assets/sidebarIcons/whitemodeicon/Leave.svg";
import leaveIconDark from "../../assets/sidebarIcons/blackmodeicon/Leave.svg";
import payslipIcon from "../../assets/sidebarIcons/whitemodeicon/Payslips.svg";
import payslipIcondark from "../../assets/sidebarIcons/blackmodeicon/Payslips.svg";
import reimbursementIcon from "../../assets/sidebarIcons/whitemodeicon/Reimbursement.svg";
import reimbursementIcondark from "../../assets/sidebarIcons/blackmodeicon/Reimbursement.svg";
import assetsIcon from "../../assets/sidebarIcons/whitemodeicon/Assets.svg";
import assetsIcondark from "../../assets/sidebarIcons/blackmodeicon/Assets.svg";
import benefitsIcon from "../../assets/sidebarIcons/whitemodeicon/Benefits.svg";
import benefitsIcondark from "../../assets/sidebarIcons/blackmodeicon/Benefits.svg";
import policiesIcon from "../../assets/sidebarIcons/whitemodeicon/Policies.svg";
import policiesIcondark from "../../assets/sidebarIcons/blackmodeicon/Policies.svg";
import resigIcon from "../../assets/sidebarIcons/whitemodeicon/Resignation.svg";
import resigIcondark from "../../assets/sidebarIcons/blackmodeicon/Resignation.svg";
import themeIcon from "../../assets/sidebarIcons/whitemodeicon/Theme.svg";
import themeIcondark from "../../assets/sidebarIcons/blackmodeicon/Theme.svg";
import employeeIcon from "../../assets/sidebarIcons/whitemodeicon/Employees.svg";
import employeesIconDark from "../../assets/sidebarIcons/blackmodeicon/Employees.svg";
import settingIcon from "../../assets/sidebarIcons/whitemodeicon/Setting.svg";
import settingIcondark from "../../assets/sidebarIcons/blackmodeicon/Setting.svg";
import Modal from "../../components/Modal";
import { FaUserCircle } from "react-icons/fa";
import { BiLinkExternal } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { logOut, getAuth, getCookie } from "@mirats/mirats-auth";

// import {gasUser } from "../../context/Context";
const UserNav = ({ children }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [gasUser, setGasUser] = useState();

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionSelect = () => {
    setDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const getUser = async () => {
    let user = await getAuth();
    setGasUser(user.currentUser);
  };

  useEffect(() => {
    getUser();
  }, []);

  
  // console.log("gasUser", gasUser?.access);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // const { showSnackbar } = useSnackbar();
  const { color, mode, organisation, changeColor, setChangeColor, orgData } =
    useContext(ContextThemeProvider);
  console.log("dash", mode);

  const navigate = useNavigate();
  const decodedToken = jwt_decode(token);
  console.log("decodedToken", decodedToken);

  const access = decodedToken?.access;

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const currentURL = window.location.href;

  const [announcementModal, setAnnouncementModal] = useState(false);
  const openAnnouncementModal = () => {
    setAnnouncementModal((prev) => !prev);
  };

  // profile popup
  const [profilePopup, setProfilePopup] = useState(false);
  const handleProfilePopup = () => {
    setProfilePopup((prev) => !prev);
  };

  const onClickOutsideListener = () => {
    setProfilePopup((prev) => !prev);
    document.removeEventListener("click", onClickOutsideListener);
  };
  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  const handleLogout = () => {
    logOut();
    deleteCookie("tokenpeoples");
  };

  const sidebarData = [
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? dashIconDark
          : dashIcon,
      name: "Dashboard",
      link: "/",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? attendanceIconDark
          : attendanceIcon,
      name: "Attendance",
      link: "/user-attendance",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? timesheetIcondark
          : timesheetIcon,
      name: "OverTime",
      link: "/over-time",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? leaveIconDark
          : leaveIcon,
      name: "Leave",
      link: "/user-leave",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? payslipIcondark
          : payslipIcon,
      name: "Payslips",
      link: "/user-pay-slip",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? assetsIcondark
          : assetsIcon,
      name: "Assets",
      link: "/user-assets",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? policiesIcondark
          : policiesIcon,
      name: "Policies",
      link: "/user-policies",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? policiesIcondark
          : policiesIcon,
      name: "Documents",
      link: "/documents",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? benefitsIcondark
          : benefitsIcon,
      name: "Benefits",
      link: "/user-benefits",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? reimbursementIcondark
          : reimbursementIcon,
      name: "Reimbursement",
      link: "/user-reimbursement",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? resigIcondark
          : resigIcon,
      name: "Resignation",
      link: "/user-resignation",
    },
    {
      icon:
        organisation?.organisationTheme?.name === "dark"
          ? settingIcondark
          : settingIcon,
      name: "Setting",
      link: "/user-setting",
      subRoutes: [
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? themeIcondark
              : themeIcon,
          name: "Theme",
          link: "/user-theme",
          disable: access?.organization,
        },
        {
          icon:
            organisation?.organisationTheme?.name === "dark"
              ? employeesIconDark
              : employeeIcon,
          name: "User Details",
          link: "/user-details",
          disable: access?.organization,
        },
      ],
    },
  ];

  const [announcement, setAnnouncement] = useState();
  const [currentMonthAnnouncements, setCurrentMonthAnnouncements] = useState(
    []
  );

  const [todaysDate, setTodaysDate] = useState();

  // function to get todays date
  const getTodayDate = () => {
    const today = new Date();
    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  // birthday work anniversary modal
  const [birthdaysAndWorkAnnModal, setBirthdaysAndWorkAnnModal] =
    useState(false);
  const openBirthdaysAndWorkAnnModal = () => {
    setBirthdaysAndWorkAnnModal((prev) => !prev);
  };

  // get birthdays and work anniversary
  const [birthdaysAndWorkAnn, setBirthdaysAndWorkAnn] = useState();

  console.log("announcement", announcement);
  console.log("currentMonthAnnouncements", currentMonthAnnouncements);

  const [oneEmp, setOneEmp] = useState();
  console.log("oneEmp", oneEmp);

  const getAnnouncement = async () => {
    const url = `${LOCALHOST_BASE_URL}/announcement/get-all-announcement`;

    try {
      const response = await axios.get(url, {
        headers: {
          "api-key": apiKey,
        },
      });

      if (response.status === 200) {
        setAnnouncement(response.data);
        // Filter announcements for the current month
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
        const filteredAnnouncements = response.data.filter((ann) => {
          const announcementDate = new Date(ann.date);
          return announcementDate.getMonth() + 1 === currentMonth;
        });

        setCurrentMonthAnnouncements(filteredAnnouncements);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          response.status
        );
        // showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      // showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  // get birthdays and work anniversary
  const getBirthdaysAndWorkAnniversary = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getBirthdaysAndAnniversaries?data=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setBirthdaysAndWorkAnn(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // get one employee
  const getOneEmployee = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setOneEmp(result?.data?.employee);
    } catch (error) {
      console.error("error", error);
    }
  };

  // https://www.peoplesapp.miratsapiservices.com/atomos/v1/peoples/employee/get-one-employee?id=6500414ba8cd139df986127a

  useEffect(() => {
    getAnnouncement();
    getBirthdaysAndWorkAnniversary();
    getOneEmployee();
  }, []);

  useEffect(() => {
    if (birthdaysAndWorkAnn?.length > 0) {
      console.log("Opening modal");
      openBirthdaysAndWorkAnnModal();
    }
  }, [birthdaysAndWorkAnn]);

  console.log(organisation?.themeColor);
  console.log(organisation?.organisationTheme?.name);
  const accessSize = gasUser?.access?.length || 0;

  return (
    <div
      className={[
        "",
        organisation?.themeColor && `theme-${organisation?.themeColor}`,
        mode && `theme-${organisation?.organisationTheme?.name}`,
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <header
        className={`pl-[26px] pr-[50px] border-b border-borderPrimary fixed top-0 z-10000 w-full ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-primaryDark"
            : "bg-white"
        } `}
      >
        <div className="flex justify-between items-center">
          <section className="flex items-center gap-4 py-[20px]">
            <img src={logo} alt="logo" />
            <h1
              className={`${
                organisation?.organisationTheme?.name === "dark"
                  ? "text-primaryTextDark"
                  : "text-primaryTextLight"
              }`}
            >
              People's Console
            </h1>
          </section>
          <section className="flex items-center h-[100%]">
            {accessSize > 1 ? (
              <div className="relative inline-block mx-4" ref={dropdownRef}>
                <button
                  id="dropdownHoverButton"
                  className="border text-black bg-white-700 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  type="button"
                  onClick={handleDropdownToggle}
                >
                  Select Service to use
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {isDropdownVisible && (
                  <div
                    id="dropdownHover"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 mt-2 absolute top-full"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 "
                      aria-labelledby="dropdownHoverButton"
                    >
                      {gasUser?.access.map((accessItem) => (
                        <li key={accessItem._id}>
                          <a
                            href={accessItem.service.serviceURL}
                            className="block px-4 py-2 hover:bg-gray-100"
                            onClick={() => handleOptionSelect()}
                          >
                            {accessItem.service.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <p
              className="mr-4 flex cursor-pointer"
              onClick={openAnnouncementModal}
            >
              <span className="">
                <HiBell size={25} color="var(--primaryBg)" />
              </span>
              <h3 className="w-[15px] h-[15px] bg-[#EC634F] text-white rounded-full ml-[-0.8em] mt-[-0.3em] text-[12px] font-semibold flex justify-center items-center">
                {currentMonthAnnouncements?.length}
              </h3>
            </p>
            <div className="flex items-center gap-4 pl-8 py-2 border-l border-borderPrimary">
              <section
                className={`${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                <p className={`font-medium`}>{decodedToken?.emp_name}</p>
                <span className="text-[14px]">
                  {decodedToken?.emp_designation}
                </span>
              </section>
              <div className="relative z-[1000]">
                <section>
                  {decodedToken?.emp_profile ? (
                    <Avatar
                      type="img"
                      src={oneEmp?.emp_profile}
                      size="lg"
                      onClick={handleProfilePopup}
                    />
                  ) : (
                    <Avatar size="md" type="text">
                      {decodedToken?.emp_name?.slice(0, 1)}
                    </Avatar>
                  )}
                </section>
                {profilePopup === true ? (
                  <section
                    className="absolute right-0 bg-white w-[170px] shadow-sm rounded-lg"
                    onMouseLeave={() => {
                      document.addEventListener(
                        "click",
                        onClickOutsideListener
                      );
                    }}
                  >
                    <Link
                      to="/user-details"
                      className="flex items-center gap-3 py-2 px-3 border-b hover:bg-primaryLight"
                    >
                      <Avatar
                        type="img"
                        src={decodedToken?.emp_profile}
                        size="sm"
                      />
                      <span>My Account</span>
                    </Link>
                    {decodedToken?.role === "admin" ? (
                      <Link
                        to="/dashboard"
                        className="flex items-center gap-3 py-2 px-3 border-b hover:bg-primaryLight"
                      >
                        <span>
                          <BiLinkExternal />
                        </span>
                        <span>Go to console</span>
                      </Link>
                    ) : (
                      ""
                    )}

                    <h3
                      className="flex items-center gap-3 py-2 px-3 border-b cursor-pointer hover:bg-primaryLight"
                      onClick={() => handleLogout()}
                    >
                      <span>
                        <MdLogout />
                      </span>{" "}
                      <span>Log out</span>
                    </h3>
                  </section>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </header>

      <Modal showModal={announcementModal} setShowModal={setAnnouncementModal}>
        <div className="w-[388px] h-[400px] max-h-[470px] scrollContainer overflow-y-auto pr-2">
          <h1 className="text-[20px] font-medium text-center mb-4">
            Announcements
          </h1>
          {currentMonthAnnouncements?.map((ann) => (
            <section className="shadow-sm px-4 py-3 mb-4 rounded-lg">
              <div className="flex justify-between items-center text-[12px] mb-1">
                <h3 className="text-primaryBg">{ann?.title}</h3>
                <span>{ann?.date}</span>
              </div>
              <p className="text-[12px]">{ann?.description}</p>
            </section>
          ))}
        </div>
      </Modal>

      {/* birthday anni modal */}

      {/* modal */}
      <Modal
        showModal={birthdaysAndWorkAnnModal}
        setShowModal={setBirthdaysAndWorkAnnModal}
      >
        {birthdaysAndWorkAnn?.length === 0 ? (
          <h1 className="p-5 text-[25px]">No record of todays date</h1>
        ) : (
          <>
            <div
              className={`rounded-lg py-[27px] px-[20px] ${
                birthdaysAndWorkAnn?.length === 4
                  ? "w-[790px]"
                  : birthdaysAndWorkAnn?.length === 1
                  ? "w-[492px] flex flex-col justify-center items-center"
                  : "max-[790px] w-[790px]"
              } `}
            >
              <h1 className="text-[#333] font-[700] text-[18px] mb-[32px] text-center">
                Today's Birthdays / Work Anniversary
              </h1>
              <div
                className={`rounded-lg py-[27px] px-[20px] flex gap-4 justify-center ${
                  birthdaysAndWorkAnn?.length === 4
                    ? "w-[790px]"
                    : birthdaysAndWorkAnn?.length === 1
                    ? "w-[492px] flex flex-col justify-center items-center"
                    : birthdaysAndWorkAnn?.length === 2
                    ? "w-[492px]"
                    : "max-[790px] w-[790px]"
                } `}
              >
                {birthdaysAndWorkAnn?.map((ann) => (
                  <section
                    className={`pt-2 w-[167px] bg h-[211px] bg-no-repeat bg-center flex flex-col items-center rounded-lg cardShadow`}
                  >
                    <div className="p-1 rounded-full border-dotted border-2 border-[#4A3AFF]">
                      <img
                        src={ann?.emp_profile}
                        alt=""
                        className="w-[78px] h-[78px] object-cover rounded-full"
                      />
                    </div>
                    <h1
                      title={ann?.emp_name}
                      className="font-[700] mt-1 text-center text-ellipsis whitespace-nowrap overflow-hidden w-[150px]"
                    >
                      {ann?.emp_name}
                    </h1>
                    <p className="text-[#3D4EFE] font-semibold text-[12px] my-[7px]">
                      {ann?.announcementType}
                    </p>
                    <p className="text-[#3D4EFE] text-[14px] font-semibold capitalize">
                      - {orgData?.organisationName} Team
                    </p>
                  </section>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>

      <div className="flex sidebarContainer mt-[65px]">
        <section
          className={`w-[230px] h-[100vh] border-r shadow-sm pb-20 whitespace-nowrap fixed overflow-scroll ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark text-white"
              : "bg-white"
          }`}
        >
          {sidebarData?.map((data, ind) => {
            if (data?.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  data={data}
                  isOpen={isOpen}
                  className="link"
                  activeClassName="active"
                />
              );
            }

            return (
              <NavLink
                to={data?.link}
                className={({ isActive }) =>
                  isActive ? "active bg-primaryBg" : "text-primaryText"
                }
              >
                <section className="flex items-center gap-4 pl-[32px] py-[13px] hover:bg-primaryLight hover:text-primaryBg">
                  <img src={data?.icon} alt="" className="" />
                  <h1>{data?.name}</h1>
                  {data?.disable && <h1>{data.disable}</h1>}{" "}
                  {/* Conditionally display the disable property */}
                </section>
              </NavLink>
            );
          })}
        </section>

        <section className={`w-full h-full ml-[230px]`}>{children}</section>
      </div>
    </div>
  );
};

export default UserNav;
