/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL, token, apiKey } from "../utils/config";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "../components/Modal";
import { useSnackbar } from "../context/SnackbarContext";

const Department = () => {
  const { showSnackbar } = useSnackbar();
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);

  const [department, setDepartments] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();

  const getDepartments = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/departments/get-all-departments`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setDepartments(result.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        // Show a snackbar with an error message
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    } catch (error) {
      // Handle other errors, such as network issues
      console.error("Error fetching departments:", error);
      // Show a snackbar with an error message
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  // get all employees
  const [getAllEmployees, setGetAllEmployees] = useState();
  // console.log("getAllEmployees", getAllEmployees);

  const getAllEmployeesFunc = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-all-employees`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result?.status === 200) {
        setGetAllEmployees(result?.data);
      } else if (result?.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something is wrong. Please try again", "red");
    }
  };

  // add department name and head
  const [addDepartment, setAddDepartment] = useState({
    dept_name: "",
    dept_head: "",
  });

  console.log("addDepartment", addDepartment);
  const handleAddDepartment = () => {
    axios
      .post(`${LOCALHOST_BASE_URL}/departments/add-department`, addDepartment, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("dept added");
        setAddDepartmentModal(false);
        getDepartments();
        showSnackbar(`New Department is created successfully`, "green");
      })
      .catch((error) => {
        showSnackbar(`New Department is not created`, "red");
        console.log(error);
      });
  };

  // update department
  const UpdateDepartment = () => {
    axios
      ?.put(
        `${LOCALHOST_BASE_URL}/departments/update-department?id=${addDepartment?._id}`,
        addDepartment,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("dept updated", response);
        getDepartments();
        setEditDepartmentModal(false);
        showSnackbar(`Department is updated successfully`, "green");
      })
      .catch((error) => {
        showSnackbar(`Department is not updated`, "red");
        console.log(error);
      });
  };

  // delete department
  const deleteDepartment = async (id) => {
    console.log("delete id", id);
    try {
      (async () => {
        try {
          await axios.delete(
            `${LOCALHOST_BASE_URL}/departments/delete-department?id=${id}`,
            {
              headers: {
                "api-key": apiKey,
                Authorization: `Bearer ${token}`,
              },
            }
          );
          getDepartments();
          showSnackbar(`Department is deleted successfully`, "green");
          console.log("dept deleted");
        } catch (error) {
          showSnackbar(`Department is not deleted`, "red");
          console.log("error", error);
        }
      })();
    } catch (error) {
      console.error("Error preparing to delete department:", error);
    }
  };

  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  // teamname modal
  const [selectedDeptId, setSelectedDeptId] = useState(null);
  const [teamName, setTeamName] = useState(false);
  const openTeamName = (deptId) => {
    setSelectedDeptId(deptId);
    setTeamName((prev) => !prev);
  };

  // add dept modal
  const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  const openAddDepartment = () => {
    setAddDepartmentModal((prev) => !prev);
  };

  // edit dept modal
  const [editDepartmentModal, setEditDepartmentModal] = useState(false);
  const openEditDepartmentDetails = (departmentId) => {
    const departmentToEdit = department?.find(
      (dept) => dept._id === departmentId
    );

    console.log("departmentToEdit", departmentToEdit);

    if (departmentToEdit) {
      setAddDepartment({
        dept_name: departmentToEdit.dept_name,
        dept_head: departmentToEdit.dept_head._id,
      });
      setEditDepartmentModal((prev) => !prev);
    }
  };

  useEffect(() => {
    getDepartments();
    getAllEmployeesFunc();
  }, []);

  const filterData = department?.filter((blog) => {
    const autherData = departmentSelect
      ? blog?.dept_name === departmentSelect
      : true;
    // const categoryData = categorySelect
    //   ? blog?.category === categorySelect
    //   : true;
    // return categoryData && autherData;
    return autherData;
  });

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1
        className={`text-[32px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "text-primaryTextDark"
            : "text-primaryTextLight"
        }`}
      >
        Departments
      </h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="department"
            id="department"
            className={`py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            value={departmentSelect ? departmentSelect : ""}
            onChange={(e) => setDepartmentSelect(e.target.value)}
          >
            <option value="department" selected>
              Department
            </option>
            {deptData?.map((emp) => (
              <option key={emp?.value} value={emp?.dept_name}>
                {emp?.dept_name}
              </option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setDepartmentSelect("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <Button intent="primary" mode={mode} onClick={openAddDepartment}>
          Add Departments
        </Button>
      </div>
      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <p className="pl-2">Sr No.</p>
                </Th>
                <Th>Department</Th>
                <Th>Department Head</Th>
                <Th>Team Name</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData
                ?.reverse()
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                // ?.reverse()
                ?.map((dept, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>
                        <h2 className="pl-2">{i + 1}</h2>
                      </Td>
                      <Td>{dept?.dept_name}</Td>
                      <Td>{dept?.dept_head?.emp_name}</Td>
                      <Td>
                        <div
                          className="flex gap-1 items-center border-b-2 border-[#1765dc] w-fit cursor-pointer"
                          onClick={() => openTeamName(dept?._id)}
                        >
                          {dept?.team?.map((t, i) => (
                            <span key={i}>
                              {t?.team_name}
                              {i < dept.team.length - 1 ? "/" : null}
                            </span>
                          ))}
                        </div>
                      </Td>

                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                            // onClick={() => openEditDepartmentDetails(dept?._id)}
                            onClick={() => {
                              openEditDepartmentDetails(dept?._id);
                              setAddDepartment({
                                ...dept,
                              });
                            }}
                          >
                            <FiEdit2 />
                          </h2>
                          <h2
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                            onClick={() => deleteDepartment(dept?._id)}
                          >
                            <RiDeleteBin6Line />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={department?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>

      {/* team names modal */}
      <Modal showModal={teamName} setShowModal={setTeamName}>
        <div className="w-[446px]">
          {department
            ?.filter((dep) => dep._id === selectedDeptId)
            .map((dep) => (
              <>
                <h2 className="mb-4 text-center text-primaryTextLight font-medium text-[20px]">
                  {dep?.dept_name}
                </h2>
                <section className="">
                  <h2 className="py-2 text-center bg-[#F8F9FF]">Teams</h2>
                  <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
                    <h2 className="flex flex-col px-[53px] text-primaryTextLight text-[14px]">
                      {dep?.team?.map((t) => (
                        <span className="py-[10px]">{t?.team_name}</span>
                      ))}
                    </h2>
                  </div>
                </section>
              </>
            ))}
        </div>
      </Modal>

      {/* add department modal */}
      <Modal
        showModal={addDepartmentModal}
        setShowModal={setAddDepartmentModal}
      >
        <div className="w-[340px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Add Department
          </h1>

          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">Department Name</label>
              <input
                type="text"
                placeholder="Add Department"
                value={addDepartment?.dept_name}
                onChange={(e) =>
                  setAddDepartment({
                    ...addDepartment,
                    dept_name: e.target.value,
                  })
                }
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="depthead">Department Head</label>
              <select
                name="depthead"
                id="depthead"
                value={addDepartment?.dept_head}
                required
                onChange={(e) =>
                  setAddDepartment({
                    ...addDepartment,
                    dept_head: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="depthead" disabled>
                  Select Department Head
                </option>
                {getAllEmployees
                  ?.filter((emp) => emp?._id !== department?.dept_head?._id) // Filter out employees whose IDs match the department head's ID
                  .map((emp) => (
                    <option key={emp?._id} value={emp?._id}>
                      {emp?.emp_name}
                    </option>
                  ))}
              </select>
            </section>
          </div>

          <div className="text-center mt-8">
            <Button intent="primary" mode={mode} onClick={handleAddDepartment}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* edit department modal */}
      <Modal
        showModal={editDepartmentModal}
        setShowModal={setEditDepartmentModal}
      >
        <div className="w-[340px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Edit Department
          </h1>

          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">Department Name</label>
              <input
                type="text"
                placeholder="Add Department"
                value={addDepartment?.dept_name}
                onChange={(e) =>
                  setAddDepartment({
                    ...addDepartment,
                    dept_name: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="depthead">Department Head</label>
              <select
                name="depthead"
                id="depthead"
                value={addDepartment?.dept_head}
                onChange={(e) =>
                  setAddDepartment({
                    ...addDepartment,
                    dept_head: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="depthead" selected disabled>
                  Select Department Head
                </option>
                {getAllEmployees
                  ?.filter((emp) => emp?._id !== department?.dept_head?._id) // Filter out employees whose IDs match the department head's ID
                  .map((emp) => (
                    <option key={emp?._id} value={emp?._id}>
                      {emp?.emp_name}
                    </option>
                  ))}
              </select>
            </section>
          </div>

          <div className="text-center mt-8">
            <Button intent="primary" mode={mode} onClick={UpdateDepartment}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Department;
