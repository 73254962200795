/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import { ContextThemeProvider } from "../context/ThemeContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";
import Button from "../components/Button";
import { useSnackbar } from "../context/SnackbarContext";


const CreateOfferrLetter = () => {
  const { color, mode, organisation, deptData, desData, teamData, shiftData } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const { tempId, offerrLetterId } = useParams();

  const navigate = useNavigate();

  console.log("tempId", tempId);

  const [tempData, setTempData] = useState();

  const getOfferrLetterData = async () => {
    try {
    

      // Define your headers with the API key
      const headers = {
        'api-key': apiKey,
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetterTemp/get-offerLetterTemp?id=${tempId}`,
        { headers }
      );

      if (result?.status === 200) {
        setTempData(result?.data);
      } else {
        console.error("Data not found");
      }
    } catch (error) {
      console.error("Data not found");
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };


  const [error, setError] = useState(null);

  const getOfferLetter = async () => {
  

    try {
      const result = await axios.get(`${LOCALHOST_BASE_URL}/offerLetter/get-offerLetter?id=${offerrLetterId}`, {
        headers: {
          'api-key': apiKey,
        },
      });
      setofferLetterData(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };



  // Render your components using teamData, deptData, and desData


  useEffect(() => {
    getOfferrLetterData();
  }, []);


  // start
  const [countryStateCity, setCountryStateCity] = useState({
    countries: [],
    state: [],
    cities: [],
  });

  const headers = new Headers();
  headers.append(
    "X-CSCAPI-KEY",
    "aGJKbVB4TXZwVVgzeDEzNXJId3hCeEx6MnM0UmhlUVJkTmR2NndEMw=="
  );
  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };
  //get countries:
  const getCountries = () => {
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCountryStateCity((prev) => ({ ...prev, countries: result }));
      })
      .catch((error) => console.log("error", error));
  };





  useEffect(() => {
    getCountries();
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("");

  const [selectedWorkCountry, setSelectedWorkCountry] = useState("");

  const [offerLetterData, setofferLetterData] = useState();

  const handleCountryChange = (event) => {
    console.log("line 107", event);
    const selectedCountry = event?.target?.value;
    const newCountryValue = selectedCountry || offerLetterData?.emp_country;
    setSelectedCountry(newCountryValue);
    setofferLetterData({
      ...offerLetterData,
      'emp_country': newCountryValue
    });
    // Fetch states based on the selected country
  };
  //end

  const handleWorkCountryChange = (event) => {
    console.log("line 107", event);
    const selectedCountry = event?.target?.value;
    const newCountryValue1 = selectedCountry || offerLetterData?.country;
    setSelectedWorkCountry(newCountryValue1);
    setofferLetterData({
      ...offerLetterData,
      'country': newCountryValue1
    });
  };
  const workingdays = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

  const [multiResponse, setMultiResponse] = useState([]);

  console.log("line 38 ", multiResponse);

  const handleInputChange = (fieldName, newValue) => {
    // Create a new object that includes all properties of offerLetterData
    // and update 'fieldName' and 'emp_type' with the provided values
    setofferLetterData({
      ...offerLetterData,
      [fieldName]: newValue,
      'emp_type': tempData?.employmentType,
      'offerLetter_Id': tempId,
      'working_schedule': multiResponse
    });
  };



  const createOfferLetter = () => {
  
    const headers = {
      'api-key': apiKey,
      Authorization: `Bearer ${token}`,
    };

    if (!offerrLetterId) {
      axios
        .post(`${LOCALHOST_BASE_URL}/offerLetter/add-offerLetter`, offerLetterData, {
          headers,
        })
        .then((response) => {
          console.log("Response data:", response.data);
          navigate('/offer-letter');
        })
        .catch((error) => {
          console.error(error);

          if (error.response && error.response.status === 404) {
            // Handle 404 error
            console.error("Resource not found");
            showSnackbar("Wait for Some time and try again.", "red");
          } else {
            // Handle other errors
            console.error("Something went wrong");
            showSnackbar("Something went wrong. Please try again.", "red");
          }
        })
        .finally(() => {
          // Code to run regardless of success or failure
          // This can include cleanup tasks or UI updates
        });
    } else {
      const { _id, ...newOfferLetterData } = offerLetterData;
      axios
        .put(`${LOCALHOST_BASE_URL}/offerLetter/update-offerLetter?id=${offerrLetterId}`, newOfferLetterData, {
          headers,
        })
        .then((response) => {
          console.log("Response data:", response.data);
          navigate('/offer-letter');
        })
        .catch((error) => {
          console.error(error);

          if (error.response && error.response.status === 404) {
            // Handle 404 error
            console.error("Resource not found");
            showSnackbar("Wait for Some time and try again.", "red");
          } else {
            // Handle other errors
            console.error("Something went wrong");
            showSnackbar("Something went wrong. Please try again.", "red");
          }
        })
        .finally(() => {
          // Code to run regardless of success or failure
          // This can include cleanup tasks or UI updates
        });
    }
  };





  useEffect(() => {
    if (offerrLetterId) {
      getOfferLetter();
    }
  }, [offerrLetterId]);

  useEffect(() => {
    if (offerrLetterId) {
      handleCountryChange(offerLetterData?.emp_country);
      handleWorkCountryChange(offerLetterData?.country)
    }
  }, [offerrLetterId]);

  console.log("countryStateCity", countryStateCity);

  const handleSectionChange = (e) => {
    // Your logic for handling section change
    console.log("Section changed:", e);
  };


  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${organisation?.organisationTheme?.name === "dark"
        ? "bg-secondaryDark"
        : "bg-white"
        } `}
    >
      <Link
        to="/offer-letter"
        className={`text-[32px] flex items-center gap-[11px] ${organisation?.organisationTheme?.name === "dark"
          ? "text-primaryTextDark"
          : "text-primaryTextLight"
          }`}
      >
        <BsArrowLeft />
        {
          tempData?.employmentType === "fulltime"
            ? "Full Time Offer letter"
            : tempData?.employmentType === "intern"
              ? "Intern Offer letter"
              : tempData?.employmentType === "probation"
                ? "Probation Offer letter"
                : "Offer letter" // Default text if employmentType is not one of the specified values
        }
      </Link>

      <div
        className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark text-primaryTextDark"
          : "bg-primaryLight text-primaryTextLight"
          }`}
      >
        Personal Details
      </div>
      <section className="grid grid-cols-4 gap-5">

        <div>
          {offerLetterData && (
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="empName"
                className={`text-[14px] ${organisation?.organisationTheme?.name === 'dark'
                    ? 'text-primaryTextDark'
                    : 'text-primaryTextLight'
                  }`}
              >
                Offer Letter Status
              </label>
              <input
                type="checkbox"
                className={`text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark"
                  : "border border-primaryTextLight text-primaryTextLight"
                  }  `}
                onChange={(e) => handleInputChange('status', e.target.checked)}
                checked={offerLetterData?.status || false}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="empName"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Employee Name
          </label>
          <input
            type="text"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              } `}
            required
            value={offerLetterData?.emp_name ? offerLetterData?.emp_name : ""}
            disabled={offerrLetterId ? true : false}
            onChange={(e) => handleInputChange('emp_name', e.target.value)}
          />
        </div>

        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="phoneNo"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Phone No
          </label>
          <input
            type="number"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_phoneNo ? offerLetterData?.emp_phoneNo : ""}
            onChange={(e) => handleInputChange('emp_phoneNo', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="emailId"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Email Id
          </label>
          <input
            type="email"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_email ? offerLetterData?.emp_email : ""}
            onChange={(e) => handleInputChange('emp_email', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="dob"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Date of Birth
          </label>
          <input
            type="date"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_dob ? offerLetterData?.emp_dob : ""}
            onChange={(e) => handleInputChange('emp_dob', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="gender"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Gender
          </label>
          <select
            name="gender"
            id="gender"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_gender ? offerLetterData?.emp_gender : ""}
            onChange={(e) => handleInputChange('emp_gender', e.target.value)}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="country"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Country
          </label>
          <select
            name="country"
            id="country"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={handleCountryChange}
            value={
              offerrLetterId
                ? offerLetterData?.emp_country
                  ? offerLetterData.emp_country
                  : selectedCountry
                : selectedCountry
            }
            required
          >
            <option value="country" selected disabled>
              Select Country
            </option>
            {countryStateCity?.countries?.map((data) => {
              return (
                <option key={data?.name} value={data?.iso2}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="state"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            State
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_state ? offerLetterData?.emp_state : ""}
            onChange={(e) => handleInputChange('emp_state', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="city"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            City
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.emp_city ? offerLetterData?.emp_city : ""}
            onChange={(e) => handleInputChange('emp_city', e.target.value)}
          />
        </div>
      </section>
      <div
        className={`px-[33px] py-[22px] my-[32px] text-[20px] rounded ${organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark text-primaryTextDark"
          : "bg-primaryLight text-primaryTextLight"
          }`}
      >
        Work Details
      </div>
      <section className="grid grid-cols-4 gap-5">
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="dept"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Department
          </label>
          <select
            name="dept"
            id="dept"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={(e) => handleInputChange('department', e.target.value)}
            value={offerLetterData?.department ? offerLetterData?.department : ""}
          >
            <option value="dept" selected disabled>
              Select Department
            </option>
            {deptData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.dept_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="designation"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Designation
          </label>
          <select
            name="designation"
            id="designation"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            value={offerLetterData?.designation ? offerLetterData?.designation : ""}
            onChange={(e) => handleInputChange('designation', e.target.value)}
          >
            <option value="designation" selected disabled>
              Select Designation
            </option>
            {desData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.des_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="teamName"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Team Name
          </label>
          <select
            name="teamName"
            id="teamName"
            className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            value={offerLetterData?.team_name ? offerLetterData?.team_name : ""}
            onChange={(e) => handleInputChange('team_name', e.target.value)}
          >
            <option value="teamName" selected disabled>
              Select Team Name
            </option>
            {teamData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.team_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="workType"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Reports to
          </label>
          <input
            type="text"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.reports_to ? offerLetterData?.reports_to : ""}
            onChange={(e) => handleInputChange('reports_to', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="dOfJoining"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Date of Joining
          </label>
          <input
            type="date"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            value={offerLetterData?.joining_date ? offerLetterData?.joining_date : ""}
            onChange={(e) => handleInputChange('joining_date', e.target.value)}
          />
        </div>

        {
          tempData?.employmentType === "intern"
            ? (
              <>
                <div className="flex flex-col gap-[6px]">
                  <label
                    htmlFor="start_date"
                    className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-primaryTextLight"
                      }`}
                  >
                    Start Date
                  </label>
                  <input
                    type="date"
                    className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                      ? "border border-primaryTextDark text-primaryTextDark"
                      : "border border-primaryTextLight text-primaryTextLight"
                      }  `}
                    onChange={(e) => handleInputChange('start_date', e.target.value)}
                    value={offerLetterData?.start_date ? offerLetterData?.start_date : ""}
                  />
                </div>
                <div className="flex flex-col gap-[6px]">
                  <label
                    htmlFor="end_date"
                    className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
                      ? "text-primaryTextDark"
                      : "text-primaryTextLight"
                      }`}
                  >
                    End Date
                  </label>
                  <input
                    type="date"
                    className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                      ? "border border-primaryTextDark text-primaryTextDark"
                      : "border border-primaryTextLight text-primaryTextLight"
                      }  `}
                    onChange={(e) => handleInputChange('end_date', e.target.value)}
                    value={offerLetterData?.end_date ? offerLetterData?.end_date : ""}
                  />
                </div>
              </>
            ) : ("")}

        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="shiftTime"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Shift Time
          </label>
          <select
            name="shiftTime"
            id="shiftTime"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={(e) => handleInputChange('shift_time', e.target.value)}
            value={offerLetterData?.shift_time ? offerLetterData?.shift_time : ""}
          >
            <option value="shiftTime" selected disabled>
              Select Shift Time
            </option>
            {shiftData?.map((emp) => (
              <option key={emp?._id} value={emp?._id}>
                {emp?.shift_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <h2
            htmlFor="workingDays"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Working Days
          </h2>
          <section>
            {workingdays?.map((day, index) => (
              <div className="multiSelect days m-1" key={index}>
                <label>
                  <input
                    type="checkbox"
                    id={index}
                    name={day}
                    value={day}
                    checked={offerLetterData?.working_schedule?.includes(day)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setMultiResponse((prev) => [...prev, e.target.value]);
                      } else {
                        setMultiResponse((prev) =>
                          prev?.filter((value) => value !== e.target.value)
                        );
                      }

                      setofferLetterData((prevData) => {
                        const updatedSchedule = e.target.checked
                          ? [...(prevData?.working_schedule || []), e.target.value]
                          : (prevData?.working_schedule || []).filter(
                            (value) => value !== e.target.value
                          );

                        return {
                          ...prevData,
                          working_schedule: updatedSchedule,
                        };
                      });
                    }}
                  />
                  <span>{day}</span>
                </label>
              </div>
            ))}
          </section>


        </div>
        {
          tempData?.employmentType === "probation"
            ? (
              <div className="flex flex-col gap-[6px] mb-4">
                <label
                  htmlFor="monthsWorked"
                  className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                    }`}
                >
                  Months Worked
                </label>
                <input
                  type="text"
                  className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                    }  `}
                  required
                  onChange={(e) => handleInputChange('months_worked', e.target.value)}
                  value={offerLetterData?.months_worked ? offerLetterData?.months_worked : ""}
                />
              </div>
            ) : ("")
        }

        {
          tempData?.employmentType === "fulltime"
            ? (
              <div className="flex flex-col gap-[6px] mb-4">
                <label
                  htmlFor="grossSalary"
                  className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                    }`}
                >
                  Gross Salary
                </label>
                <input
                  type="text"
                  className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                    }  `}
                  required
                  onChange={(e) => handleInputChange('gross_salary', parseFloat(e.target.value))}
                  value={offerLetterData?.gross_salary ? offerLetterData?.gross_salary : ""}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-[6px] mb-4">
                <label
                  htmlFor="stipend"
                  className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                    }`}
                >
                  Stipend
                </label>
                <input
                  type="text"
                  className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                    }  `}
                  value={offerLetterData?.stipend ? offerLetterData?.stipend : ""}
                  required
                  onChange={(e) => handleInputChange('stipend', parseFloat(e.target.value))}
                />
              </div>
            )}

        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="grossSalary"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Notice Period
          </label>
          <input
            type="text"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.notice_period ? offerLetterData?.notice_period : ""}
            onChange={(e) => handleInputChange('notice_period', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="country"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Country
          </label>
          <select
            name="country"
            id="country"
            className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={handleWorkCountryChange}
            value={
              offerrLetterId
                ? offerLetterData?.country
                  ? offerLetterData?.country
                  : selectedCountry
                : selectedCountry
            }
            required
          >
            <option value="country" selected disabled>
              Select Country
            </option>
            {countryStateCity?.countries?.map((data) => {
              return (
                <option key={data?.name} value={data?.iso2}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="state"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            State
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={(e) => handleInputChange('state', e.target.value)}
            value={offerLetterData?.state ? offerLetterData?.state : ""}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="city"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            City
          </label>
          <input
            type="state"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={(e) => handleInputChange('city', e.target.value)}
            value={offerLetterData?.city ? offerLetterData?.city : ""}
          />
        </div>
        <div className="flex flex-col gap-[6px] mb-4">
          <label
            htmlFor="pfAmount"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            PF Amount
          </label>
          <input
            type="text"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.pf_amount ? offerLetterData?.pf_amount : ""}
            onChange={(e) => handleInputChange('pf_amount', parseFloat(e.target.value))}
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="dOfSign"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Date of Sign
          </label>
          <input
            type="date"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            value={offerLetterData?.dateOfSign ? offerLetterData?.dateOfSign : ""}
            onChange={(e) => handleInputChange('dateOfSign', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-[6px] justify-start items-start">
          <label
            htmlFor="esic"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            ESIC
          </label>
          <input
            type="checkbox"
            className={`text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            onChange={(e) => handleInputChange('esic', e.target.checked)}
            checked={offerLetterData?.esic || false}
          />

        </div>
        <div className="flex flex-col gap-[6px] justify-start items-start">
          <label
            htmlFor="esic"
            className={`text-[14px] ${organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
              }`}
          >
            Mediclaim
          </label>
          <input
            type="number"
            className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark"
              : "border border-primaryTextLight text-primaryTextLight"
              }  `}
            required
            value={offerLetterData?.mediclaim ? offerLetterData?.mediclaim : ""}
            onChange={(e) => handleInputChange('mediclaim', parseFloat(e.target.value))}
          />
        </div>
      </section>
      <div className="flex flex-col gap-[6px] justify-center items-center">
        <Button
          intent="primary"
          mode={mode}
          onClick={() => createOfferLetter()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreateOfferrLetter;
