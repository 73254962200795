/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , token , apiKey} from "../utils/config";
import { FiEdit2 } from "react-icons/fi";
// eslint-disable-next-line no-unused-vars
import Modal from "../components/Modal";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
// eslint-disable-next-line no-unused-vars
import { BsFillGridFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import payrollLight from "../assets/PayRoll.svg";
import payrollDark from "../assets/PayrollBlack.svg";
import Avatar from "../components/Avatar";
import { useSnackbar } from "../context/SnackbarContext";

const Member = () => {
  // eslint-disable-next-line no-unused-vars
  const { showSnackbar } = useSnackbar();
  const { color, mode, organisation } = useContext(ContextThemeProvider);

  const [allMember, setAllMembers] = useState();
  const getAllMembers = async () => {
    try {
      // Set the headers with the API key
      const headers = {
        "api-key":   apiKey,
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/member/get-all-members`,
        { headers }
      );

      if (result?.data?.success === false) {
        // Handle the case where there's an error message
        console.log(result?.data?.message); // or display an error message to the user
      } else {
        // Set the payroll data
        setAllMembers(result?.data?.getAll);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        showSnackbar(`Resource not found, please try again.`, "red");
      } else {
        // Handle any other errors
        console.error(error);
        showSnackbar(`Something went wrong, please try again.`, "red");
      }
    }
  };

  const [memberPageNumber, setMemberPageNumber] = useState(1);
  const [memberRowsPerPage, setMemberRowsPerPage] = useState(10);

  console.log("allMember", allMember);

  const [addMember, setAddMember] = useState({});

  const [addMemberModal, setAddMemberModal] = useState(false);
  const openAddMemberModal = () => {
    setAddMemberModal((prev) => !prev);
    setAddMember({});
  };
  const [updateMemberModal, setUpdateMemberModal] = useState(false);
  const openUpdateMemberModal = async (id) => {
    setUpdateMemberModal((prev) => !prev);
    try {
      // Set the headers with the API key
      const headers = {
        "api-key":   apiKey,
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/member/get-member?id=${id}`,
        { headers }
      );

      if (result?.data?.success === false) {
        // Handle the case where there's an error message
        console.log(result?.data?.message); // or display an error message to the user
        showSnackbar(`Something is wrong, please try again.`, "red");
      } else {
        // Set the payroll data
        setAddMember(result?.data?.getMember);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        showSnackbar(`Resource not found, please try again.`, "red");
      } else {
        // Handle any other errors
        console.error(error);
        showSnackbar(`Something went wrong, please try again.`, "red");
      }
    }
  };

  const [allDepartment, setAllDepartments] = useState();
  // get all department
  const getAllDepartment = async () => {
    try {
      // Set the headers with the API key
      const headers = {
        "api-key":   apiKey,
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/departments/get-all-departments`,
        { headers }
      );

      if (result?.data?.success === false) {
        // Handle the case where there's an error message
        console.log(result?.data?.message); // or display an error message to the user
        showSnackbar(`Something is wrong, please try again.`, "red");
      } else {
        setAllDepartments(result?.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        showSnackbar(`Resource not found, please try again.`, "red");
      } else {
        // Handle any other errors
        console.error(error);
        showSnackbar(`Something went wrong, please try again.`, "red");
      }
    }
  };

  const filteredEmployees =
    allDepartment?.find((dept) => dept?.dept_name === addMember?.dept)
      ?.employee || [];

  console.log("filteredEmployees", filteredEmployees);

  useEffect(() => {
    getAllMembers();
    getAllDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const services = [
    {
      label: "Dashboard",
      value: "dashboard",
    },
    {
      label: "DailyReport",
      value: "dailyReport",
    },
    {
      label: "Attendance",
      value: "attendance",
    },
    {
      label: "Leave",
      value: "leave",
    },
    {
      label: "Payroll",
      value: "payroll",
    },
    {
      label: "Reimbursement",
      value: "reimbursement",
    },
    {
      label: "Organization",
      value: "organization",
    },
    {
      label: "Offerlatter",
      value: "offerlatter",
    },
    {
      label: "Policy",
      value: "policy",
    },
    {
      label: "Payslips",
      value: "payslips",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Designation",
      value: "designation",
    },
    {
      label: "Team",
      value: "team",
    },
    {
      label: "ShiftSchedule",
      value: "shiftSchedule",
    },
    {
      label: "Agreements",
      value: "agreements",
    },
    {
      label: "Benefits",
      value: "benefits",
    },
    {
      label: "Termination",
      value: "termination",
    },
    {
      label: "Resignation",
      value: "resignation",
    },
    {
      label: "LayOff",
      value: "layOff",
    },
    {
      label: "Member",
      value: "member",
    },
    {
      label: "Announcement",
      value: "announcement",
    },
    {
      label: "Leave Customisation",
      value: "leaveCustomisation",
    },
    {
      label: "PaySlip Customisation",
      value: "paySlipCustomisation",
    },
  ];

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setAddMember((prevAddMember) => ({
      ...prevAddMember,
      [id]: checked ? "true" : "false",
    }));
  };

  const handleAddMember = () => {
    // Create a new object with the desired structure
    const { dept, ...restAddMember } = addMember;

    console.log("addMember", restAddMember);
    const headers = {
      "api-key":   apiKey,
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${LOCALHOST_BASE_URL}/member/add-member`, restAddMember, {
        headers,
      })
      .then((response) => {
        setAddMemberModal(false);
        getAllMembers();
        setAddMember({});
        showSnackbar(`Member Added Successfully`, "green");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showSnackbar(`Resource not found, please try again.`, "red");
        } else {
          console.log(error);
          showSnackbar(`Something went wrong, please try again.`, "red");
        }
        setAddMemberModal(false);
        setAddMember({});
      });
  };

  const deleteMember = (id) => {
    const headers = {
      "api-key":   apiKey,
      Authorization: `Bearer ${token}`,
    };

    axios
      .delete(`${LOCALHOST_BASE_URL}/member/delete-member?id=${id}`, {
        headers,
      })
      .then((response) => {
        getAllMembers();
        showSnackbar(`Member Deleted Successfully`, "green");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showSnackbar(`Resource not found, please try again.`, "red");
        } else {
          console.log(error);
          showSnackbar(`Something went wrong, please try again.`, "red");
        }
        setAddMember({});
      });
  };

  const handleUpdateMember = (id) => {
    // Create a new object with the desired structure
    const { emp_id, ...restAddMember } = addMember;

    const headers = {
      "api-key":   apiKey,
      Authorization: `Bearer ${token}`,
    };

    axios
      .put(
        `${LOCALHOST_BASE_URL}/member/update-member?id=${id}`,
        restAddMember,
        { headers }
      )
      .then((response) => {
        setAddMemberModal(false);
        getAllMembers();
        // setAddMember({});
        showSnackbar(`Member Updated Successfully`, "green");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showSnackbar(`Resource not found, please try again.`, "red");
        } else {
          console.log(error);
          showSnackbar(`Something went wrong, please try again.`, "red");
        }
        setAddMemberModal(false);
        // setAddMember({});
      });
  };

  console.log("line 206", filteredEmployees);

  const isAddMemberEmpty =
    !addMember || !addMember.accessLevel || !addMember.emp_id;
  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="grid grid-cols-2 w-full">
        <h1
          className={`text-[32px] ${
            organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
          }`}
        >
          Members
        </h1>
        <div className="flex justify-end">
          <Button intent="primary" mode={mode} onClick={openAddMemberModal}>
            + Add Members
          </Button>
        </div>
      </div>

      {!allMember ? (
        <div className="h-[600px] w-full flex justify-center flex-col items-center">
          <img
            src={`${
              organisation?.organisationTheme?.name === "dark"
                ? payrollDark
                : payrollLight
            }`}
            className="w-[280px] h-[180px]"
          />
          <p
            className={`text-[24px] ${
              mode === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
            }`}
          >
            Please click the "Create " button to retrieve your
          </p>
          <p
            className={`text-[24px] mb-4 ${
              mode === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
            }`}
          >
            payroll information.
          </p>
          <Button intent="primary" mode={mode} onClick={openAddMemberModal}>
            + Add Members
          </Button>
        </div>
      ) : (
        <>
          <div className="text-primaryText my-[25px] w-[95%] overflow-scroll">
            <TableWithPagination>
              <Table>
                <TableHead>
                  <TableRow>
                    <Th>Employee Name</Th>
                    <Th>Access Level</Th>
                    <Th>Dashboard</Th>
                    <Th>DailyReport</Th>
                    <Th>Attendance</Th>
                    <Th>Leave</Th>
                    <Th>Payroll</Th>
                    <Th>Reimbursement</Th>
                    <Th>Organization</Th>
                    <Th>Offerlatter</Th>
                    <Th>Policy</Th>
                    <Th>Payslips</Th>
                    <Th>Department</Th>
                    <Th>Designation</Th>
                    <Th>Team</Th>
                    <Th>ShiftSchedule</Th>
                    <Th>Agreements</Th>
                    <Th>Benefits</Th>
                    <Th>Termination</Th>
                    <Th>Resignation</Th>
                    <Th>Lay Off</Th>
                    <Th>Member</Th>
                    <Th>Announcement</Th>
                    <Th>Leave Customisation</Th>
                    <Th>Payslip Customisation</Th>
                    <Th>Action</Th>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allMember
                    ?.slice(
                      (memberPageNumber - 1) * memberRowsPerPage,
                      memberPageNumber * memberRowsPerPage
                    )
                    // ?.reverse()
                    ?.map((dept, i) => {
                      return (
                        <TableRow>
                          <Td>
                            <div className="flex items-center gap-2">
                              {dept?.emp_id?.emp_profile ? (
                                <Avatar
                                  type="img"
                                  src={dept?.emp_id?.emp_profile}
                                  size="md"
                                />
                              ) : (
                                <Avatar size="md" type="text">
                                  {dept?.emp_id?.emp_name?.slice(0, 1)}
                                </Avatar>
                              )}
                              {dept?.emp_id?.emp_name}
                            </div>
                          </Td>
                          <Td>{dept?.accessLevel}</Td>
                          <Td>
                            {dept?.dashboard ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.dailyReport ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.attendance ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.leave ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.payroll ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.reimbursement ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.organization ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.offerlatter ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.policy ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.payslips ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.department ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.designation ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.team ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.shiftSchedule ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.agreements ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.benefits ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.termination ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.resignation ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.layOff ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.member ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.announcement ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.leaveCustomisation ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>
                          <Td>
                            {dept?.paySlipCustomisation ? (
                              <input type="checkbox" checked={true} />
                            ) : (
                              <input type="checkbox" checked={false} />
                            )}
                          </Td>

                          <Td>
                            <div className="flex gap-3 items-center">
                              <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out">
                                <FiEdit2
                                  onClick={() =>
                                    openUpdateMemberModal(dept._id)
                                  }
                                />
                              </h2>
                              <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out">
                                <RiDeleteBin6Line
                                  color="#D20E0E"
                                  onClick={() => deleteMember(dept._id)}
                                />
                              </h2>
                            </div>
                          </Td>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                totalDataCnt={allMember?.length || 0}
                setRowsPerPage={setMemberPageNumber}
                rowsPerPage={memberRowsPerPage}
                setPageNumber={setMemberPageNumber}
                pageNumber={memberPageNumber}
              />
            </TableWithPagination>
          </div>
        </>
      )}

      {/* addMemberModal */}
      <Modal showModal={addMemberModal} setShowModal={setAddMemberModal}>
        <div className="w-[484px]">
          <h1 className="text-center mb-[14px] text-[20px] font-medium">
            Add Member
          </h1>
          <section className="flex flex-col gap-[6px] mb-2">
            <>
              <label htmlFor="dept">Select Department</label>
              <select
                name="dept"
                id="dept"
                required
                onChange={(e) =>
                  setAddMember({
                    ...addMember,
                    dept: e.target.value,
                  })
                }
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="dept" selected disabled>
                  Select Department
                </option>
                {allDepartment?.map((dept) => (
                  <option value={dept?.dept_name}>{dept?.dept_name}</option>
                ))}
              </select>
            </>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Select Employee</label>
            <select
              name="emp"
              id="emp"
              required
              onChange={(e) =>
                setAddMember({
                  ...addMember,
                  emp_id: e.target.value,
                })
              }
              className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            >
              <option value="emp" disabled selected>
                Select Employee
              </option>
              {filteredEmployees?.map((emp) => (
                <option value={emp?._id}>{emp?.emp_name}</option>
              ))}
            </select>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Access level</label>
            <select
              name="emp"
              id="emp"
              required
              onChange={(e) =>
                setAddMember({
                  ...addMember,
                  accessLevel: e.target.value,
                })
              }
              className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            >
              <option value="emp" disabled selected>
                Access level
              </option>
              <option value="Super Admin">Super Admin</option>
              <option value="Admin">Admin</option>
            </select>
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Members Role Accessibility</label>
            <div className="grid grid-cols-3 gap-2">
              {services?.map((service, i) => (
                <div key={service.value} className="flex gap-2">
                  <input
                    type="checkbox"
                    id={service.value}
                    name={service.value}
                    checked={
                      addMember?.accessLevel === "Super Admin" ||
                      (addMember[service.value] === "true" &&
                        service.value !== "emp_id" &&
                        service.value !== "accessLevel")
                    }
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={service.value}>{service.label}</label>
                </div>
              ))}
            </div>
          </section>

          <div className="text-center mt-8">
            {!isAddMemberEmpty ? (
              <Button intent="primary" mode={mode} onClick={handleAddMember}>
                Save
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>

      {/* Update MemberModal */}
      <Modal showModal={updateMemberModal} setShowModal={setUpdateMemberModal}>
        <div className="w-[484px]">
          <h1 className="text-center mb-[14px] text-[20px] font-medium">
            Update Member
          </h1>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Selected Employee</label>
            <input
              type="email"
              name="email"
              placeholder=""
              required
              disabled
              className="border border-[#e2d2e5] px-4 py-2 outline-none rounded focus:border-[#5860F8]"
              value={addMember?.emp_id?.emp_name}
            />
          </section>
          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Access level</label>
            <select
              name="emp"
              id="emp"
              required
              value={addMember?.accessLevel}
              onChange={(e) => {
                const newAccessLevel = e.target.value;
                const updatedMember = {
                  ...addMember,
                  accessLevel: newAccessLevel,
                };

                // If accessLevel is "Super Admin," set all checkboxes to true
                if (newAccessLevel === "Super Admin") {
                  services.forEach((service) => {
                    updatedMember[service.value] = true;
                  });
                }

                setAddMember(updatedMember);
              }}
              className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
            >
              <option value="" disabled>
                Access level
              </option>
              <option value="Super Admin">Super Admin</option>
              <option value="Admin">Admin</option>
            </select>
          </section>

          <section className="flex flex-col gap-[6px] mb-2">
            <label htmlFor="dept">Members Role Accessibility</label>
            <div className="grid grid-cols-3 gap-2">
              {services?.map((service, i) => (
                <div key={service.value} className="flex gap-2">
                  <input
                    type="checkbox"
                    id={service.value}
                    name={service.value}
                    checked={addMember[service.value]}
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      const updatedMember = {
                        ...addMember,
                        [service.value]: newValue,
                      };
                      setAddMember(updatedMember);
                    }}
                  />
                  <label htmlFor={service.value}>{service.label}</label>
                </div>
              ))}
            </div>
          </section>

          <div className="text-center mt-8">
            {!isAddMemberEmpty ? (
              <Button
                intent="primary"
                mode={mode}
                onClick={() => handleUpdateMember(addMember?._id)}
              >
                Save
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Member;
