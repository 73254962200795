/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// localhost:7065/api/v1/peoples/agreement/getAgreementByEmpId?empId=6500414ba8cd139df986127a

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useSnackbar } from "../../context/SnackbarContext";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL ,token , apiKey} from "../../utils/config";
import jwt_decode from "jwt-decode";

const UserAssets = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const decodedToken = jwt_decode(token);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [assets, setAssets] = useState();

  const [tempAssetData, setTempAssetData] = useState();
  // console.log("tempAssetData", tempAssetData);

  // true
  const [statusModal, setStatusModal] = useState(false);
  const handleStatusModal = (id) => {
    setTempAssetData(assets?.find((a) => a?._id === id));
    setStatusModal((prev) => !prev);
  };

  // false
  const [statusModalFalse, setStatusModalFalse] = useState(false);
  const handleStatusModalFalse = (id) => {
    setTempAssetData(assets?.find((a) => a?._id === id));
    setStatusModalFalse((prev) => !prev);
  };

  const getAssets = async () => {
    try {
    
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/getAgreementByEmpId?empId=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAssets(result?.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // update status
  const handleStatusUpdate = (id, objectId, status) => {
  
  
    const data = {
      emp_data: tempAssetData?.assignEmployee?.[0]?.emp_data,
      status: status,
    };

    console.log("id, objectId", id, objectId);
    console.log("data", data);

    axios
      .put(
        `${LOCALHOST_BASE_URL}/agreement/updateAssign-agreement?id=${id}&assignEmployeeId=${objectId}`,
        data,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Agreement Updated");
        showSnackbar("Asset Status Updated.", "green");
        setStatusModal(false);
        setStatusModalFalse(false);
        getAssets();
      })
      .catch((error) => {
        console.error("error", error);
        showSnackbar("Something went wrong, please try again.", "red");
      });
  };

  useEffect(() => {
    getAssets();
  }, []);

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] h-[100vh] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText mb-[32px]`}>Assets</h1>
      <div className="grid grid-cols-4 gap-[26px]">
        {assets?.map((asset) => (
          <section
            className={`w-[257px] h-[210px] shadow-md rounded-lg p-4 cardShadow text-primaryText ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <div className="">
              <h2 className="text-[20px] font-semibold">
                {asset?.assetHeading}
              </h2>
              <p className="font-medium my-2 text-[12px]">
                Given Date :<span className="font-[400]"> {asset?.date}</span>
              </p>
              <section>
                <span className="text-[11px] font-medium">
                  Asset Details :{" "}
                </span>
                <p className="h-[50px] line-clamp-2 text-[11px]">
                  {asset?.assetSummary}
                </p>
              </section>

              {asset?.assignEmployee?.[0]?.status === true ? (
                <section className="flex gap-4">
                  <Button
                    intent="secondary"
                    mode={mode}
                    onClick={() =>
                      navigate(
                        `/view-user-asset/${asset?._id}/${asset?.assignEmployee?.[0]?._id}`
                      )
                    }
                  >
                    View
                  </Button>
                  <Button
                    disabled
                    intent="primary"
                    mode={mode}
                    onClick={() => handleStatusModalFalse(asset?._id)}
                  >
                    Reject
                  </Button>
                </section>
              ) : asset?.assignEmployee?.[0]?.status === false ? (
                <section className="flex gap-4">
                  <Button
                    disabled
                    intent="secondary"
                    mode={mode}
                    onClick={() => handleStatusModal(asset?._id)}
                  >
                    Accept
                  </Button>
                  <Button
                    disabled
                    intent="primary"
                    mode={mode}
                    onClick={() => handleStatusModalFalse(asset?._id)}
                  >
                    Reject
                  </Button>
                </section>
              ) : (
                <section className="flex gap-4">
                  <Button
                    intent="secondary"
                    mode={mode}
                    onClick={() => handleStatusModal(asset?._id)}
                  >
                    Accept
                  </Button>
                  <Button
                    intent="primary"
                    mode={mode}
                    onClick={() => handleStatusModalFalse(asset?._id)}
                  >
                    Reject
                  </Button>
                </section>
              )}
            </div>
          </section>
        ))}
      </div>

      {/* status modal true */}
      <Modal showModal={statusModal} setShowModal={setStatusModal}>
        <div className="w-[342px] p-5 text-center">
          <h1 className="text-[14px] font-medium px-5 capitalize">
            Are you sure, you want to Accept This agreement?
          </h1>
          <section className="flex items-center justify-center gap-4 mt-4">
            <button
              onClick={() => setStatusModal(false)}
              className="py-[6px] px-[30px] border border-primaryBg rounded text-primaryBg text-[14px]"
            >
              Cancel
            </button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() =>
                handleStatusUpdate(
                  tempAssetData?._id,
                  tempAssetData?.assignEmployee?.[0]?._id,
                  "true"
                )
              }
            >
              Accept
            </Button>
          </section>
        </div>
      </Modal>

      {/* status modal false */}
      <Modal showModal={statusModalFalse} setShowModal={setStatusModalFalse}>
        <div className="w-[342px] p-5 text-center">
          <h1 className="text-[14px] font-medium px-5 capitalize">
            Are you sure, you want to reject This agreement?
          </h1>
          <section className="flex items-center justify-center gap-4 mt-4">
            <button
              onClick={() => setStatusModalFalse(false)}
              className="py-[6px] px-[30px] border border-primaryBg rounded text-primaryBg text-[14px]"
            >
              Cancel
            </button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() =>
                handleStatusUpdate(
                  tempAssetData?._id,
                  tempAssetData?.assignEmployee?.[0]?._id,
                  "false"
                )
              }
            >
              Reject
            </Button>
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default UserAssets;
