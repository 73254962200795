/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey, token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const CreateAgreement = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { agreementId } = useParams();

  // get one agreement
  const [oneAgreement, setOneAgreement] = useState();
  console.log("oneAgreement", oneAgreement);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [agreementData, setAgreementData] = useState({
    variableTempBody: [
      {
        variableName: "",
        variableValue: "",
      },
    ],
    assetHeading: "",
    date: "",
    typeofAsset: "",
    companyName: "",
    companyLocation: "",
    assetSummary: "",
    assetBody: "",
  });

  console.log("agreementData", agreementData);

  const [variables, setVariables] = useState([
    {
      variableName: "",
      variableValue: "",
    },
  ]);

  // const handleChange = (e, i) => {
  //   const { name, value } = e.target;
  //   const data = [...agreementData?.variableTempBody];
  //   data[i][name] = value;
  //   setAgreementData((prev) => ({ ...prev, variableTempBody: data }));
  // };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const data = [...agreementData?.variableTempBody];
    data[i][name] = value;
    const formattedValue = value.toLowerCase().replace(/\s+/g, "_");
    data[i].variableValue = `%%${formattedValue}%%`; // Update variableValue to match variableName
    setAgreementData((prev) => ({ ...prev, variableTempBody: data }));
  };

  const handleOnChange = (name, value) => {
    setAgreementData((prev) => ({ ...prev, [name]: value }));
  };

  const addVariables = () => {
    setAgreementData((prev) => ({
      ...prev,
      variableTempBody: [
        ...prev?.variableTempBody,
        { variableName: "", variableValue: "" },
      ],
    }));
  };

  const deleteVariables = (i) => {
    const deleteData = agreementData?.variableTempBody?.filter(
      (data, index) => {
        return index !== i;
      }
    );
    setAgreementData((prev) => ({ ...prev, variableTempBody: deleteData }));
  };

  //   add agreement
  const handleAddAgreement = async () => {
    axios
      .post(
        `${LOCALHOST_BASE_URL}/agreement/add-agreement`,
        {
          ...variables,
          assetHeading: agreementData?.assetHeading,
          date: agreementData?.date,
          typeofAsset: agreementData?.typeofAsset,
          companyName: agreementData?.companyName,
          companyLocation: agreementData?.companyLocation,
          assetSummary: agreementData?.assetSummary,
          assetBody: agreementData?.assetBody,
          variableTempBody: agreementData?.variableTempBody,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`, // Add the Bearer token to the header
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Agreement added");
        navigate(`/agreements`);
      })
      .catch((error) => {
        console.log("error", error);
        showSnackbar("Something is wrong, please try again.", "red");
      });
  };

  // get one agreement
  const getOneAgreement = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/get-agreement?id=${agreementId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      if (result.status === 200) {
        setAgreementData(result?.data);
      } else {
        console.log("Error fetching one agreement");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error("Error fetching one agreement:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  console.log("Here is the data from agreementdata", agreementData);
  console.log("Here is the data one agreement", oneAgreement);

  // update agreement
  const handleUpdateAgreement = async () => {

  
    try {
      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/agreement/update-agreement?id=${agreementId}`,
        {
          ...agreementData,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("response data", response?.data);
        console.log("agreement updated");
        navigate(`/agreements`);
      } else {
        console.log("Error updating agreement");
        showSnackbar("Something is wrong, please try again.", "red");
      }
    } catch (error) {
      console.error("Error updating agreement:", error);
      showSnackbar("Something is wrong, please try again.", "red");
    }
  };

  // add pages
  // const [pages, setPages] = useState([]);
  // const [activePage, setActivePage] = useState(0);
  // console.log("activePage", activePage);

  // const addNewPage = () => {
  //   const newPage = {
  //     assetHeading: agreementData?.assetHeading,
  //     date: agreementData?.date,
  //     typeofAsset: agreementData?.typeofAsset,
  //     companyName: agreementData?.companyName,
  //     companyLocation: agreementData?.companyLocation,
  //     assetSummary: agreementData?.assetSummary,
  //     assetBody: agreementData?.assetBody,
  //     variableTempBody: agreementData?.variableTempBody,
  //   };
  //   setPages([...pages, newPage]);
  // };

  // const deletePage = (index) => {
  //   const updatedPages = [...pages];
  //   updatedPages.splice(index, 1);
  //   setPages(updatedPages);
  // };

  // useEffect(() => {
  //   const initialPage = {
  //     variableTempBody: [
  //       {
  //         variableName: "",
  //         variableValue: "",
  //       },
  //     ],
  //     assetHeading: "",
  //     date: "",
  //     typeofAsset: "",
  //     companyName: "",
  //     companyLocation: "",
  //     assetSummary: "",
  //     assetBody: "",
  //   };
  //   setPages([initialPage]);
  // }, []);

  useEffect(() => {
    if (agreementId) {
      getOneAgreement();
    }
  }, [agreementId]);

  const submitFunct = () => {
    if (agreementId) {
      handleUpdateAgreement();
    } else {
      handleAddAgreement();
    }
  };
  console.log("agreementData?.assetBody", agreementData?.assetBody);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/agreements"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px]"
      >
        <MdOutlineKeyboardBackspace />
        {agreementId ? "Edit" : "New"} Agreement Layout
      </Link>
      <div className="flex gap-4">
        {/* <section className="w-[60%] h-[842px] bg-white shadow-sm">
          <div className="px-[66px] py-[26px]">
            <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
              <span>{agreementData?.companyName}</span>
              <span>Date : {formatDate(agreementData?.date)}</span>
            </h1>
            <h2 className="text-[#333]">{agreementData?.assetHeading}</h2>
            <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
            <div
              dangerouslySetInnerHTML={{ __html: agreementData?.assetBody }}
            ></div>
          </div>
        </section> */}
        <div className="w-[60%]">
          <section className="w-[100%] h-[842px] bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              {/* {pages.slice(activePage, activePage + 1).map((page, index) => ( */}
              <div>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{agreementData.companyName}</span>
                  <span>Date : {formatDate(agreementData.date)}</span>
                </h1>
                <h2 className="text-[#333]">{agreementData.assetHeading}</h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: agreementData?.assetBody,
                  }}
                ></div>
              </div>
              {/* ))} */}
            </div>
          </section>

          {/* Pagination Component */}
          {/* <section className="flex gap-3 items-center">
            {pages.map((_, index) => (
              <button
                className={`p-2 ${
                  activePage === index ? "bg-red-400" : "bg-blue-400"
                }`}
                key={index}
                onClick={() => setActivePage(index)}
              >
                {index + 1}
              </button>
            ))}
          </section> */}
        </div>
        <section className="w-[40%] h-[945px]">
          {/* <div className="flex gap-4 mb-4">
            <Button intent="primary" mode={mode} onClick={addNewPage}>
              + Add Page
            </Button>
            <Button intent="secondary" onClick={() => deletePage(index)}>
             - Delete Page
            </Button>
          </div> */}
          <section className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="assetHeading"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Asset Heading
              </label>
              <input
                type="text"
                name="assetHeading"
                value={agreementData?.assetHeading}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("assetHeading", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="date"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Date
              </label>
              <input
                type="date"
                name="date"
                value={agreementData?.date}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("date", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px] mb-4">
              <label
                htmlFor="typeofAsset"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Type Of Asset
              </label>
              <select
                name="typeofAsset"
                id="typeofAsset"
                value={agreementData?.typeofAsset}
                className={`h-[36px] py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("typeofAsset", e.target.value);
                }}
              >
                <option value="typeofAsset" selected disabled>
                  Select Asset
                </option>
                <option value="laptop">Laptop</option>
                <option value="mobile">Mobile</option>
                <option value="tablet">Tablet</option>
              </select>
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="companyName"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                value={agreementData?.companyName}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("companyName", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="companyLocation"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Company Location
              </label>
              <input
                type="text"
                name="companyLocation"
                value={agreementData?.companyLocation}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("companyLocation", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="assetSummary"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Asset Summary
              </label>
              <input
                type="text"
                name="assetSummary"
                value={agreementData?.assetSummary}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("assetSummary", e.target.value);
                }}
              />
            </div>
          </section>
          <section className="my-4">
            <h2 className="text-[14px] font-semibold text-primaryText mb-2">
              Use this variable in template Body
            </h2>
            <h2 className="text-[14px] font-semibold text-primaryText">
              eg - Use Variable Name as First Name and while using variables in editor 
            </h2>
            <h2 className="text-[14px] font-semibold text-primaryText">Use it as %%first_name%% eg - Hello, %%first_name%%</h2>
            <div className="my-4">
              <section className="grid grid-cols-2 gap-4 mb-1 text-primaryText text-[14px] bg-primaryLight py-1">
                <h3 className="px-4">Variable Name</h3>
                <h3 className="px-4">Variable</h3>
              </section>
              <section className="">
                {agreementData?.variableTempBody?.map((item, i) => {
                  return (
                    <div key={i}>
                      <section className="grid grid-cols-2 gap-4">
                        <input
                          id="variableName"
                          placeholder="variable name"
                          name="variableName"
                          value={item?.variableName}
                          onChange={(e) => handleChange(e, i)}
                          className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg`}
                        />
                        <input
                          id="variableValue"
                          placeholder="variable value"
                          name="variableValue"
                          value={`%%${item?.variableName
                            .toLowerCase()
                            .replace(/\s/g, "_")}%%`}
                          readOnly
                          // onChange={(e) => handleChange(e, i)}
                          className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg`}
                        />
                      </section>
                      <div className="flex justify-between items-center my-1">
                        <p
                          className={`w-[21px] h-[21px] shadow-sm rounded-full flex justify-center items-center ${
                            organisation?.organisationTheme?.name === "dark"
                              ? "bg-primaryDark"
                              : "bg-white"
                          }`}
                        >
                          <AiOutlinePlus
                            color="var(--primaryText)"
                            style={{ cursor: "pointer" }}
                            onClick={addVariables}
                          />
                        </p>
                        {agreementData?.variableTempBody?.length !== 1 && (
                          <p
                            className={`w-[21px] h-[21px] shadow-sm rounded-full flex justify-center items-center ${
                              organisation?.organisationTheme?.name === "dark"
                                ? "bg-primaryDark"
                                : "bg-white"
                            }`}
                          >
                            <AiOutlineMinus
                              color="var(--primaryText)"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteVariables(i)}
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>

            <div className="">
              <SunEditor
                showToolbar={true}
                value={agreementData?.assetBody}
                onChange={(content) =>
                  setAgreementData((prevData) => ({
                    ...prevData,
                    assetBody: content,
                  }))
                }
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      // "table",
                      "image",
                      "preview",
                      "link",
                    ],
                  ],
                }}
                setContents={agreementData?.assetBody}
                height="200px"
              />
            </div>
          </section>
        </section>
      </div>
      <div className="flex items-center justify-center mt-8 gap-5">
        <Button intent="primary" mode={mode} onClick={submitFunct}>
          Save
        </Button>
        <Button intent="secondary" mode={mode}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default CreateAgreement;
