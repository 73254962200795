/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import Select from "react-select";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSnackbar } from "../context/SnackbarContext";
import { GrView } from "react-icons/gr";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ShiftAndSchedule = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [shifts, setShifts] = useState([]);
  // console.log("shifts", shifts);
  const [allEmployees, setAllEmployees] = useState([]);
  // console.log("allEmployees", allEmployees);
  const [selectedOption, setSelectedOption] = useState(null);

  const [addShiftData, setAddShiftData] = useState({ emp_id: [] });

  const handleShiftData = (selectedOptions, e) => {
    if (e.name === "select-employee") {
      // Extract selected employee IDs
      // console.log("=======================", selectedOptions)
      const selectedEmployeeIds = selectedOptions?.map(
        (option) => option?.value
      );
        console.log("-----------", selectedEmployeeIds)
      setAddShiftData((prev) => ({
        ...prev,
        emp_id: selectedEmployeeIds,
      }));
    } else {
      // For other fields (e.g., shift_name, start_time, etc.), update as usual
      setAddShiftData((prev) => ({
        ...prev,
        [e.name]: e.target.value,
      }));
    }
  };
  const handleShiftDataRemain = (name, value) => {
    console.log("Here is the Name, ", name);
    console.log("Here is the Value, ", value);
    setAddShiftData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //   add shift modal
  const [addShiftModal, setAddShiftModal] = useState(false);
  const openAddShiftModal = () => {
    setAddShiftModal((prev) => !prev);
    setSelectedOption(null);
  };

  //   employee list modal
  const [empListModal, setEmpListModal] = useState(false);
  const [modalShiftData, setModalShiftdata] = useState(null);
  const openEmpListModal = (shiftId) => {
    const modalShiftDataById = shifts.find((shift) => shift._id === shiftId);
    setModalShiftdata(modalShiftDataById);
    setEmpListModal((prev) => !prev);
    console.log("modal shift data", modalShiftData);
  };

  // get all shifts
  const getAllShifts = async () => {

    try {
      const result = await axios.get(`${LOCALHOST_BASE_URL}/shift/get-all-shift`, {
        headers: {
          'api-key': apiKey,
        },
      });
      console.log("Here are all the shifts data", result?.data);
      setShifts(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar("Shifts not found", "red");
      } else {
        // Handle any other errors
        console.error("An error occurred:", error);
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  // add shift
  const handleAddShift = () => {

    axios
      .post(`${LOCALHOST_BASE_URL}/shift/add-shift`, {
        ...addShiftData,
        // emp_id: selectedOption?.map((data) => data?.value),
        emp_id: addShiftData?.emp_id,
        shift_name: addShiftData?.shift_name,
        start_time: addShiftData?.start_time,
        end_time: addShiftData?.end_time,
        breaktime: addShiftData?.breaktime,
      },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("Shift added");
        getAllShifts();
        setAddShiftData({ emp_id: [] });
        setAddShiftModal(false);
        console.log("After add - emp_id:", addShiftData.emp_id);
      })
      .catch((error) => {
        console.log(error);
        setAddShiftData({});
      });
  };

  // update shift
  const handleUpdateShift = async () => {


    axios
      .put(
        `${LOCALHOST_BASE_URL}/shift/update-shift?id=${addShiftData?._id}`,
        addShiftData,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response?.data);
        console.log("Shift updated");
        getAllShifts();
        setAddShiftData({});
        setAddShiftModal(false);
        console.log("After save - emp_id:", addShiftData.emp_id);
      })
      .catch((error) => {
        console.log(error);
        setAddShiftData({});
        setAddShiftModal(false);
      });
  };

  //   get all employees
  const getAllEmployees = async () => {

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-all-employees`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAllEmployees(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar(`Employees not found`, "red");
      } else {
        // Handle any other errors
        console.error("An error occurred:", error);
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  // delete shift
  const deleteShift = async (id) => {

    try {
      await axios?.delete(`${LOCALHOST_BASE_URL}/shift/delete-shift?id=${id}`, {
        headers: {
          "api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      });
      getAllShifts();
      console.log("shift deleted");
    } catch (error) {
      console.log("error", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  const removeAddedEmpFromTeam = (shiftId, empId) => {

    try {
      axios
        .put(
          `${LOCALHOST_BASE_URL}/shift/removeAddedEmp-shift?shiftId=${shiftId}&empId=${empId}`,
          null, // Pass an empty request body to avoid errors
          {
            headers: {
              "api-key": apiKey,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            handleUpdateModal(shiftId);
            getAllShifts();
            setAddShiftModal(true);
          }
        })
        .catch((error) => {
          console.log("Here is the Error from Try-Catch", error);
          showSnackbar(`Something went wrong. Please try again.`, "red");
        });
    } catch (error) {
      console.log("Here is the Error: ", error);
    }
  };

  const handleUpdateModal = (shiftId) => {

    setAddShiftModal(true);
    try {
      axios
        .get(`${LOCALHOST_BASE_URL}/shift/get-one-shift?shiftId=${shiftId}`, {
          headers: {
            "api-key": apiKey,
          },
        })
        .then((response) => {
          if (response.data) {
            console.log("Here is the Data", response?.data?.shifts);
            setAddShiftData({
              edit: true,
              ...response?.data?.shifts,
            });
          }
        })
        .catch((error) => {
          console.log("Here is the Error", error);
          showSnackbar(`Something went wrong. Please try again.`, "red");
        });
    } catch (error) {
      console.log("Here is the Error from try-catch", error);
    }
  };

  useEffect(() => {
    getAllShifts();
    getAllEmployees();
  }, []);

  console.log("allEmployees line 194", allEmployees);
  console.log("addShiftData line 195", addShiftData);
  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1
        className={`text-[32px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "text-primaryTextDark"
            : "text-primaryTextLight"
        }`}
      >
        Shift & Schedule
      </h1>
      <div className="my-[30px] mb-[34px] flex justify-between gap-4 items-center">
        <Button intent="primary" mode={mode} onClick={openAddShiftModal}>
          + Add Shift Time
        </Button>
      </div>

      <div
        className="grid grid-cols-4 gap-4"
      >
        {shifts?.map((shift) => (
          <section
            key={shift?._id}
            className={`w-[270px] h-[177px] py-4 px-5 rounded transition-all ease-in hover:shadow-cardHover ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-[#F7F7FF]"
            }`}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-medium text-primaryText">
                {shift?.shift_name}
              </h3>
              <div className="flex gap-1 items-center">
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#dce8fb] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                  onClick={() => openEmpListModal(shift._id)}
                >
                  <MdOutlineRemoveRedEye color="#1765dc" />
                </h2>
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                  onClick={(e) => {
                    handleUpdateModal(shift._id);
                    setAddShiftData({
                      ...shift,
                      edit: true,
                    });
                  }}
                >
                  <FiEdit2 color="#389927" />
                </h2>
                <h2
                  className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                  onClick={() => deleteShift(shift?._id)}
                >
                  <RiDeleteBin6Line color="#D20E0E" />
                </h2>
              </div>
            </div>
            <div className="flex justify-between items-center mt-[23px] mb-[11px]">
              <section className="text-primaryText">
                <span className="text-[12px] font-[300]">Shift Time</span>
                <h4 className="text-[14px] font-medium">
                  {shift?.start_time} To {shift?.end_time}
                </h4>
              </section>
              <section className="text-primaryText">
                <span className="text-[12px] font-[300]">Break Time</span>
                <h4 className="text-[14px] font-medium">{shift?.breaktime}</h4>
              </section>
            </div>
            <section className="text-primaryText">
              <span className="text-[12px] font-[300]">Employee</span>
              <div className="text-[14px] font-medium flex">
                {shift?.emp_id?.map((emp) => (
                  <>
                    <h1 className="ml-[-0.2em]">
                      {emp?.emp_profile ? (
                        <Avatar type="img" src={emp?.emp_profile} size="sm" />
                      ) : (
                        <Avatar size="md" type="text">
                          {emp?.emp_name?.slice(0, 1)}
                        </Avatar>
                      )}
                    </h1>
                  </>
                ))}
              </div>
            </section>
          </section>
        ))}
      </div>

      {/* add shift modal */}
      <Modal showModal={addShiftModal} setShowModal={setAddShiftModal}>
        <div className="w-[638px] h-[662px] overflow-auto">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            {addShiftData?.edit ? (
              <>Update {addShiftData?.shift_name}</>
            ) : (
              <>Add Shift & Schedule</>
            )}
          </h1>
          <div className="mt-[24px] mb-[36px] grid grid-cols-2 gap-x-4 border-b pb-3">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">
                Select Employee <span className="">*</span>
              </label>
              <Select
                isMulti
                onChange={(selectedOptions, action) =>
                   handleShiftData(selectedOptions, action)
                }
                options={allEmployees.map((emp) => ({
                  value: emp?._id,
                  label: emp?.emp_name,
                }))}
                name="select-employee"
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">
                Shift Name <span className="">*</span>
              </label>
              <input
                type="text"
                placeholder="Add Shift"
                name="shift_name"
                value={addShiftData?.shift_name}
                onChange={(e) =>
                  handleShiftDataRemain(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="start_time">
                Start Time <span className="">*</span>
              </label>
              <input
                type="time"
                value={addShiftData?.start_time}
                name="start_time"
                onChange={(e) =>
                  handleShiftDataRemain(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="end_time">
                End Time <span className="">*</span>
              </label>
              <input
                type="time"
                value={addShiftData?.end_time}
                name="end_time"
                onChange={(e) =>
                  handleShiftDataRemain(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="breaktime">
                Breaktime (In minutes) <span className="">*</span>
              </label>
              <input
                type="text"
                name="breaktime"
                value={addShiftData?.breaktime}
                // onChange={handleShiftData}
                onChange={(e) =>
                  handleShiftDataRemain(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
                className={`px-2 h-[36px] text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
          </div>
          <h1 className="text-primaryTextLight text-[20px] mb-7 font-medium text-center">
            Selected Employees
          </h1>
          <div className="flex flex-wrap items-start justify-start gap-x-2 h-[200px] overflow-auto">
            <table className="w-full border text-left">
              <thead>
                <tr className="bg-[#F8F9FF] text-primaryTextLight text-[14px] font-[500]">
                  <th className="p-2">Employee name</th>
                  <th>Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {addShiftData?.emp_id?.map((team) => (
                  <>
                    {console.log()}
                    <tr>
                      <td className="flex gap-2 px-2 py-2">
                        {" "}
                        {team?.emp_profile ? (
                          <Avatar
                            type="img"
                            src={team?.emp_profile}
                            size="md"
                          />
                        ) : (
                          <Avatar size="md" type="text">
                            {team?.emp_name?.split(" ")[0][0] +
                              team?.emp_name?.split(" ")[1][0]}
                          </Avatar>
                        )}
                        {team?.emp_name}
                      </td>
                      <td>{team?.emp_designation?.des_name}</td>
                      <td>
                        {" "}
                        <div className="flex gap-3 items-center">
                          <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out">
                            <RiDeleteBin6Line
                              color="#D20E0E"
                              onClick={(e) =>
                                removeAddedEmpFromTeam(
                                  addShiftData?._id,
                                  team?._id
                                )
                              }
                            />
                          </h2>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={addShiftData?.edit ? handleUpdateShift : handleAddShift}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* list of employees in shift modal */}
      <Modal showModal={empListModal} setShowModal={setEmpListModal}>
        <div className="w-[446px] h-[435px]">
          <h1 className="text-[20px] font-medium text-center">
            {modalShiftData?.shift_name}
          </h1>
          <section className="my-6 flex justify-between items-center text-[14px]">
            <p>
              Shift Time: {modalShiftData?.start_time} TO{" "}
              {modalShiftData?.end_time}
            </p>
            <p>Break Time: {modalShiftData?.breaktime}</p>
          </section>
          <div className="h-[350px] overflow-auto">
            <table className="w-full border-collapse text-left text-[14px]">
              <thead className="bg-primaryLight h-[40px] sticky top-0">
                <th className="text-[#333] font-medium px-3">Employee Name</th>
                <th className="text-[#333] font-medium px-3">Designation</th>
              </thead>
              <tbody className="">
                {modalShiftData?.emp_id?.map((emp) => (
                  <tr>
                    <td className="text-[#333] px-3 py-1">
                      <div className="flex items-center gap-2">
                        {emp?.emp_profile ? (
                          <Avatar type="img" src={emp?.emp_profile} size="sm" />
                        ) : (
                          <Avatar size="md" type="text">
                            {emp?.emp_name?.slice(0, 1)}
                          </Avatar>
                        )}
                        <span className="capitalize">{emp?.emp_name}</span>
                      </div>
                    </td>
                    <td className="text-[#333] px-3 py-1">
                      {emp?.emp_designation?.des_name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShiftAndSchedule;
