/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import Button from "../components/Button";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const ViewAgreement = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const { agreementId } = useParams();

  const [agreementDetails, setAgreementDetails] = useState();
  console.log("agreementDetails", agreementDetails);

  const getAgreementDetails = async () => {
    try {

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/get-agreement?id=${agreementId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAgreementDetails(result?.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          showSnackbar(`Wait for Some time and try again.`, "red");
        } else {
          showSnackbar(`Something went wrong. Please try again.`, "red");
        }
      } else {
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  useEffect(() => {
    getAgreementDetails(agreementDetails);
  }, [agreementId]);

  function replacePlaceholders(assetBody, variableTempBody) {
    let modifiedAssetBody = assetBody;

    variableTempBody?.forEach((variable) => {
      const placeholder = `%%${variable?.variableName}%%`;
      modifiedAssetBody = modifiedAssetBody?.replace(
        new RegExp(placeholder, "g"),
        variable?.variableValue
      );
    });

    return modifiedAssetBody;
  }

  const modifiedAssetBody = replacePlaceholders(
    agreementDetails?.assetBody,
    agreementDetails?.variableTempBody
  );

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/agreements"
        className={`text-[32px] flex items-center gap-1 text-primaryText`}
      >
        <MdOutlineKeyboardBackspace /> Assets
      </Link>

      <section className="h-[1488px] w-full my-5 p-[66px] bg-white shadow-sm">
        <h1>{agreementDetails?.assetHeading}</h1>
        <p className="w-[100px] h-1 bg-[#3D4EFE] my-[60px]"></p>
        {agreementDetails?.assignEmployee?.map((emp) => (
          <h2>Hello, {emp?.emp_id?.emp_name}</h2>
        ))}
        <div
          className="my-4"
          dangerouslySetInnerHTML={{ __html: modifiedAssetBody }}
        ></div>
      </section>
    </div>
  );
};

export default ViewAgreement;
