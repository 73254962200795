/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";
import Avatar from "../components/Avatar";

const ViewTermination = () => {
  const { color, mode, organisation, orgData } = useContext(ContextThemeProvider);
  console.log("orgData", orgData)
  const { showSnackbar } = useSnackbar();

  const { terminationId } = useParams();

  // get one termination
  const [terminationDetails, setTerminationDetails] = useState();
  console.log("terminationDetails", terminationDetails?.result?.signature);

  // Helper function to handle errors and show a snackbar message
  const handleError = (error, errorMessage) => {
    if (error.response) {
      if (error.response.status === 404) {
        showSnackbar(`Resource not found. ${errorMessage}`, "red");
      } else {
        showSnackbar(`Something went wrong. ${errorMessage}`, "red");
      }
    } else {
      showSnackbar(`Something went wrong. ${errorMessage}`, "red");
    }
  };

  const getTerminationDetails = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/termination/get-termination?id=${terminationId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setTerminationDetails(result?.data);
    } catch (error) {
      handleError(error, "while fetching termination details.");
    }
  };

  useEffect(() => {
    getTerminationDetails(terminationDetails);
  }, [terminationId]);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <Link
          to="/termination"
          className={`text-[32px] flex items-center gap-1 text-primaryText`}
        >
          <MdOutlineKeyboardBackspace /> View Termination
        </Link>
        <Button intent="primary" mode={mode}>
          Download
        </Button>
      </section>

      <div className="flex justify-center mt-[50px]">
        <section className="flex justify-center bg-white w-[595px] h-[842px] cardShadow">
          <div className="">
            <svg
              width="595"
              height="202"
              viewBox="0 0 595 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.5V0H594.5V201.5C594.5 10 403 4.5 0 4.5Z"
                fill="url(#paint0_linear_2161_6066)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2161_6066"
                  x1="0"
                  y1="201.496"
                  x2="594.5"
                  y2="201.496"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="var(--primaryBg)" />
                  <stop offset="1" stop-color="var(--primaryBg)" />
                </linearGradient>
              </defs>
            </svg>

            <div className="mt-[-100px] px-[62px]">
              <section className="flex justify-between items-center">
                <div className="text-[14px] font-semibold leading-4 uppercase text-[#808080]">
                  <p>{terminationDetails?.result?.termi_Date}</p>
                  <p>Badden kherr</p>
                  <p>Design</p>
                  <p>UI/UX Designer</p>
                </div>
                <img
                  src="https://miratsfilestorage.s3.ap-south-1.amazonaws.com/onboardEmail/Group+376.png"
                  alt="logo"
                  className="w-[85px] h-[40px] object-cover"
                />
              </section>

              <div className="mt-[10px] mb-[31px]">
                <h2 className="text-[15px]">Termination</h2>
                <p className="w-9 h-[2px] bg-primaryBg mt-[10px]"></p>
              </div>

              <div className="text-[#222] text-[11px]">
                <h3>Dear {terminationDetails?.result?.emp_id?.emp_name},</h3>
                <p className="my-5">
                  We regret to inform you that your employment with {orgData?.organisationName} is terminated from{" "}
                  {terminationDetails?.result?.notice_Date}. This termination is
                  the reason of:{" "}
                  <span className="capitalize">
                    {terminationDetails?.result?.reason}
                  </span>
                </p>
                <p>
                  Your Full and Final settlement would be done as per the
                  company's policies. If you are eligible for any paid leaves,
                  they will be identified and paid according to the
                  organizational regulations. You can contact us anytime in case
                  of any queries.
                </p>
              </div>

              <div className="mt-5">
                <h3 className="mb-5">Signature:</h3>
                <img src={terminationDetails?.result?.signature} alt="signature" className="w-[130px] h-[90px] object-contain" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewTermination;
