/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ContextThemeProvider } from "../../context/ThemeContext";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey} from "../../utils/config";
import { useSnackbar } from "../../context/SnackbarContext";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const ViewUserAsset = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const { agreementId, selectedEmpAgreementId } = useParams();


  const [empAgreementDetails, setEmpAgreementDetails] = useState();
  console.log("empAgreementDetails", empAgreementDetails);

  const selectedEmployeeData = empAgreementDetails?.assignEmployee?.find(
    (emp) => emp?._id === selectedEmpAgreementId
  );

  console.log("selectedEmployeeData", selectedEmployeeData);

  function replacePlaceholders(assetBody, variableTempBody, selectedEmployeeData) {
    let modifiedAssetBody = assetBody;
  
    variableTempBody?.forEach((variable) => {
      const placeholder = `%%${variable?.variableName}%%`;
      const empData = selectedEmployeeData?.emp_data[0];
      
      console.log("empData", empData)
  
      if (empData[variable?.variableName]) {
        let processedValue = empData[variable?.variableName];
        if (processedValue.startsWith("%%") && processedValue.endsWith("%%")) {
          processedValue = processedValue.slice(2, -2);
        }
  
        modifiedAssetBody = modifiedAssetBody.replace(
          new RegExp(placeholder, "g"),
          processedValue
        );
      }
    });
  
    return modifiedAssetBody;
  }
  
  const modifiedAssetBody = replacePlaceholders(
    empAgreementDetails?.assetBody,
    empAgreementDetails?.variableTempBody,
    selectedEmployeeData
  );

  console.log("modifiedAssetBody", modifiedAssetBody);

  const getEmpAgreementDetails = async () => {
    try {
      // Set your API key
  

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/agreement/get-agreement?id=${agreementId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setEmpAgreementDetails(result?.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          showSnackbar(`Wait for Some time and try again.`, "red");
        } else {
          showSnackbar(`Something went wrong. Please try again.`, "red");
        }
      } else {
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  useEffect(() => {
    getEmpAgreementDetails(empAgreementDetails);
  }, [agreementId]);


  const parsedAssetBody = empAgreementDetails?.assetBody;

  // Extract variable names and their corresponding values
  const variableMap = {};
  empAgreementDetails?.variableTempBody.forEach((variable) => {
    variableMap[variable.variableName] = variable.variableValue;
  });

  // Replace variables with values
  let agreementContent = parsedAssetBody;

  Object.keys(variableMap).forEach((variableName) => {
    const variableValue = selectedEmployeeData.emp_data.find(
      (data) => data[variableName]
    );
    if (variableValue) {
      agreementContent = agreementContent.replace(
        variableMap[variableName],
        variableValue[variableName]
      );
    }
  });


  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/user-assets"
        className={`text-[32px] flex items-center gap-1 text-primaryText`}
      >
        <MdOutlineKeyboardBackspace /> Assets
      </Link>
      <section className="h-[1488px] w-full my-5 p-[66px] bg-white shadow-sm">
        <h1 className="capitalize text-center text-[32px] font-[700]">
          {empAgreementDetails?.assetHeading}
        </h1>
        <p className="bg-[#3D4EFE] w-[100px] h-[5px] my-[60px]"></p>
        <h2 className="text-[18px]">
          Hello, {selectedEmployeeData?.emp_id?.emp_name}
        </h2>
        {/* <div
          className="my-4"
          dangerouslySetInnerHTML={{ __html: empAgreementDetails?.assetBody }}
        ></div> */}
        <div
          className="my-4"
          dangerouslySetInnerHTML={{
            __html: agreementContent,
          }}
        ></div>
      </section>
    </div>
  );
};

export default ViewUserAsset;
