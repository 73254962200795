/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";

const Table = ({ children }) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  return (
    <div
      className={`text-left w-full rounded ${
        organisation?.organisationTheme?.name === "dark"
          ? "border border-[#313648]"
          : "border border-[#EEF4FB]"
      }`}
    >
      <table className={`text-left w-full`}>{children}</table>
    </div>
  );
};
export const TableWithPagination = ({ children }) => {
  return <div className="TableWithPaginationContainer">{children}</div>;
};

export const TableHead = ({ children }) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  return (
    <thead
      className={`h-[40px] text-primaryText font-medium ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark"
          : "bg-primaryLight"
      }`}
    >
      {children}
    </thead>
  );
};

export const TableBody = ({ children }) => {
  return <tbody className="">{children}</tbody>;
};

export const TableRow = ({ children }) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  return (
    <tr
      className={`${
        organisation?.organisationTheme?.name === "dark"
        ? "border-b border-[#313648]"
        : "border-b border-[#EEF4FB]"
      }`}
    >
      {children}
    </tr>
  );
};

export const Th = ({ children }) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  return (
    <th className={`primaryText font-medium px-1 text-[14px]`}>{children}</th>
  );
};

export const Td = ({ children }) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  return (
    <td
      className={`py-[10px] px-1 primaryText font-[400] bg-transparent  text-[14px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-primaryDark"
          : "bg-primaryLight"
      }`}
    >
      {children}
    </td>
  );
};

export const TableActionPopUp = ({ selectedRows, children }) => {
  return (
    <>
      {selectedRows.length ? (
        <div className="TableActionPopUpContainer flex gap-3 pl-2 py-2">
          {selectedRows.length} Item selected
          {children}
        </div>
      ) : null}
    </>
  );
};

export default Table;
