// LoadingSpinner.jsx

import React from 'react';
import { Link } from 'react-router-dom';

const EmployeeNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-blue-500 border-opacity-25 h-12 w-12 mb-4"></div>
      <p className="text-sm text-gray-600 mb-2">Loading...</p>
      <Link to="/" className="text-sm text-blue-500 font-semibold">
        Sorry You Don't Have Access
      </Link>
    </div>
  );
};

export default EmployeeNotFound;
