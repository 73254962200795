//GETTING CURRENT TIME AND PRINTING GREET MESSAGE:
export const getGreetMsg = () => {
  const nowtime = new Date().getHours();
  if (nowtime >= 5 && nowtime < 12) {
    return "Morning";
  } else if (nowtime >= 12 && nowtime <= 16) {
    return "Afternoon";
  } else if (nowtime > 16 && nowtime < 23) {
    return "Good Evening";
  } else {
    return "Night";
  }
};
