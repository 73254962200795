/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import jwt_decode from "jwt-decode";
import { LOCALHOST_BASE_URL,apiKey, token } from "../../utils/config";
import Button from "../Button";

const EmployeeInformation = ({ employeeInfo, getEmployeeInfo }) => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const decodedToken = jwt_decode(token);

  console.log("decodedToken", decodedToken);
  const { empId } = useParams();

  console.log("employeeInfo", employeeInfo?.employee);

  // shift data
  const [allShifts, setAllShifts] = useState([]);
  // console.log("allShifts", allShifts)

  // designation data
  const [allDesignation, setAllDesignation] = useState([]);
  console.log("allDesignation", allDesignation);

  const getAllDesignation = async () => {
    try {
      const result = await axios?.get(
        `${LOCALHOST_BASE_URL}/designations/get-all-designations`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setAllDesignation(result?.data);
      } else {
        console.error(result?.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  const getAllShifts = async () => {
    try {
      const result = await axios?.get(
        `${LOCALHOST_BASE_URL}/shift/get-all-shift`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setAllShifts(result?.data);
      } else {
        console.error(result?.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmpDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // onchange to update working days
  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (type === "checkbox") {
  //     // Handle checkbox input
  //     setEmpDetails((prevDetails) => {
  //       const updatedWorkdays = prevDetails?.employee?.emp_workdays || {};
  //       updatedWorkdays[name] = checked;

  //       // Update emp_workSchedule based on the checked checkboxes
  //       const updatedWorkSchedule = workingdays.filter(
  //         (day) => updatedWorkdays[day]
  //       );
  //       return {
  //         ...prevDetails,
  //         employee: {
  //           ...prevDetails.employee,
  //           emp_workdays: updatedWorkdays,
  //           emp_workSchedule: updatedWorkSchedule,
  //         },
  //       };
  //     });
  //   } else {
  //     // Handle other input types (text, number, etc.)
  //     setEmpDetails((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value,
  //     }));
  //   }
  // };

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   // Handle checkboxes for emp_workSchedule
  //   if (type === "checkbox" && name === "emp_workSchedule") {
  //     setEmpDetails((prevDetails) => {
  //       const updatedWorkSchedule = [...prevDetails?.emp_workSchedule];

  //       console.log("updatedWorkSchedule", updatedWorkSchedule);

  //       if (checked) {
  //         // Add the day if checked
  //         updatedWorkSchedule.push(value);
  //       } else {
  //         // Remove the day if unchecked
  //         const index = updatedWorkSchedule.indexOf(value);
  //         if (index !== -1) {
  //           updatedWorkSchedule.splice(index, 1);
  //         }
  //       }

  //       return {
  //         ...prevDetails,
  //         emp_workSchedule: updatedWorkSchedule,
  //       };
  //     });
  //   } else {
  //     // Handle other input changes
  //     setEmpDetails((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value,
  //     }));
  //   }
  // };

  // get employee details
  const initialEmployeeDetails =
    employeeInfo && employeeInfo.employee ? employeeInfo.employee : {};
  const [empDetails, setEmpDetails] = useState(initialEmployeeDetails);
  console.log("empDetails", empDetails);
  const updateEmpDetails = async () => {

    try {
      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/employee/update-OtherEmployee?id=${decodedToken?.id}`,
        { ...empDetails, emp_workSchedule: empWorkSchedule },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response?.data);
      showSnackbar("Details Updated.", "green");
      getEmployeeInfo();
    } catch (error) {
      console.error("error", error);
      showSnackbar("Something went wrong, please try again.", "red");
    }
  };

  useEffect(() => {
    setEmpDetails((prevDetails) => ({
      ...prevDetails,
      ...(employeeInfo?.employee || {}),
    }));
    getAllShifts();
    getAllDesignation();
  }, [employeeInfo]);

  const workingdays = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

  const [multiResponse, setMultiResponse] = useState([]);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [empWorkSchedule, setEmpWorkSchedule] = useState(
    empDetails?.emp_workSchedule || []
  );

  console.log("empWorkSchedule", empWorkSchedule);

  const handleWorkScheduleChange = (e) => {
    const { value } = e.target;

    setEmpWorkSchedule((prevSchedule) => {
      if (prevSchedule.includes(value)) {
        // Remove the day if it's already selected
        return prevSchedule.filter((day) => day !== value);
      } else {
        // Add the day if it's not selected
        return [...prevSchedule, value];
      }
    });
  };

  return (
    <div
      className={`px-[72px] py-[33px] cardShadow rounded-lg ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark border border-white rounded-lg"
          : "bg-white"
      }`}
    >
      <section className="grid grid-cols-3 gap-6 items-center">
        {/* emp name */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>
            Employee Name
          </p>
          <input
            disabled
            name="emp_name"
            value={empDetails?.emp_name || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* dob */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>
            Date of Birth
          </p>
          <input
            disabled
            name="emp_dob"
            value={formatDate(empDetails?.emp_dob) || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* email */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Email</p>
          <input
            disabled
            name="emp_email"
            value={empDetails?.emp_email || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* contact */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Contact No</p>
          <input
            disabled
            name="emp_phoneNo"
            value={empDetails?.emp_phoneNo || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* alt contact no */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>
            Alternative Contact No
          </p>
          <input
            disabled
            name="emp_alternativePhoneNo"
            value={empDetails?.emp_alternativePhoneNo || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* gender */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Gender</p>
          <input
            disabled
            name="emp_gender"
            value={empDetails?.emp_gender || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* blood group */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Blood Group</p>
          <input
            name="emp_bloodGroup"
            value={empDetails?.emp_bloodGroup || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* country */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Country</p>
          <input
            disabled
            name="emp_country"
            value={empDetails?.emp_country || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* state */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>State</p>
          <input
            disabled
            name="emp_state"
            value={empDetails?.emp_state || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* city */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>City</p>
          <input
            disabled
            name="emp_city"
            value={empDetails?.emp_city || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* address */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Address</p>
          <input
            disabled
            name="emp_address"
            value={empDetails?.emp_address || ""}
            onChange={handleInputChange}
            className={`px-2 font-[500] text-primaryText py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            } `}
          />
        </div>
        {/* emp status */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Status</p>
          <select
            name="emp_status"
            value={empDetails?.emp_status || ""}
            onChange={handleInputChange}
            className={`px-2 text-primaryText font-[500] py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            }`}
          >
            <option value="" selected disabled>
              Select Status
            </option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        {/* department */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Department</p>
          <select
            name="emp_department"
            value={empDetails?.emp_department?.dept_name || ""}
            onChange={handleInputChange}
            className={`px-2 text-primaryText font-[500] py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            }`}
          >
            <option value="" selected disabled>
              Select Department
            </option>
            {deptData?.map((data) => (
              <option value={data?._id}>{data?.dept_name}</option>
            ))}
          </select>
        </div>
        {/* designation */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Designation</p>
          <select
            name="emp_designation"
            value={empDetails?.emp_designation || ""}
            onChange={handleInputChange}
            className={`px-2 text-primaryText font-[500] py-2 text-[14px] rounded outline-none h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            }`}
          >
            <option value="" selected disabled>
              Select Designation
            </option>
            {allDesignation?.map((data) => (
              <option value={data?._id}>{data?.des_name}</option>
            ))}
          </select>
        </div>
        {/* shift time */}
        <div className="w-full">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>Shift Time</p>
          <select
            name="emp_shiftTime"
            value={empDetails?.emp_shiftTime || ""}
            onChange={handleInputChange}
            className={`px-2 text-primaryText font-[500] py-2 text-[14px] rounded outline-none  h-[40px] border border-primaryText focus:border-blue-500 w-full ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-transparent"
                : ""
            }`}
          >
            {allShifts?.map((data) => (
              <option value={data?._id}>{data?.shift_name}</option>
            ))}
          </select>
        </div>
        {/* working days */}
        <div className="w-[600px]">
          <p className={`text-[14px] text-primaryText mb-[6px]`}>
            Working Days
          </p>
          {workingdays?.map((day, index) => (
            <div className="multiSelect days" key={index}>
              {/* {console.log("day",employeeInfo.employee?.emp_workSchedule?.includes(day))} */}
              <label>
                <input
                  type="checkbox"
                  name="emp_workSchedule"
                  value={day}
                  checked={employeeInfo?.employee?.emp_workSchedule?.includes(
                    day
                  )}
                  // checked={employeeInfo?.emp_workSchedule(day)}
                  onChange={handleWorkScheduleChange}
                />
                <span className="capitalize">{day}</span>
              </label>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center pt-10">
        <Button intent="primary" mode={mode} onClick={updateEmpDetails}>
          Save
        </Button>
      </section>
    </div>
  );
};

export default EmployeeInformation;
