/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { BsCalendar3 } from "react-icons/bs";
import { PiClockCountdownBold } from "react-icons/pi";
import Badge from "../../components/Badge";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useSnackbar } from "../../context/SnackbarContext";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../../components/table/Table";
import TablePagination from "../../components/table/TablePagination";
import jwt_decode from "jwt-decode";

const filterDataByDate = (data, year, month) => {
  // Make sure monthSelect is a number before converting to string
  const formattedMonth = String(month).padStart(2, "0"); // Ensure month is in 'MM' format
  return data.filter((item) => {
    const [itemYear, itemMonth] = item.date.split("-");
    return itemYear === String(year) && itemMonth === formattedMonth;
  });
};

const getCurrentYearAndMonth = () => {
  const now = new Date();
  return {
    currentYear: now.getFullYear(),
    currentMonth: now.getMonth() + 1, // GetMonth returns 0-11, add 1 for 1-12
  };
};

const AttendanceUser = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);

  const decodedToken = jwt_decode(token);
  const { showSnackbar } = useSnackbar();
  const [attendance, setAttendance] = useState();
  const selectcurrentYear = new Date().getFullYear();
  const startYear = 2010;
  const year = [];

  for (let i = startYear; i <= selectcurrentYear; i++) {
    year.push(i);
  }
  const { currentYear, currentMonth } = getCurrentYearAndMonth();
  const [yearSelect, setYearSelect] = useState(currentYear);
  const [monthSelect, setMonthSelect] = useState(currentMonth);
  const [filteredattendanceData, setFilteredattendanceData] = useState([]);

  const months = [
    { value: "01", leable: "Jan" },
    { value: "02", leable: "Feb" },
    { value: "03", leable: "Mar" },
    { value: "04", leable: "Apr" },
    { value: "05", leable: "May" },
    { value: "06", leable: "Jun" },
    { value: "07", leable: "Jul" },
    { value: "08", leable: "Aug" },
    { value: "09", leable: "Sep" },
    { value: "10", leable: "Oct" },
    { value: "11", leable: "Nov" },
    { value: "12", leable: "Dec" },
  ];

  const formatTime = (timeString) => {
    if (!timeString) {
      return ""; // or return a placeholder if you prefer
    }
    const time = new Date(timeString);
    return time.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const getattendances = async () => {
    console.log("Inside the getattendances");
    const apiKey = "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw="; // Your API key

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/getAttendanceByUser?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      console.log("result: ", result);
      setAttendance(result?.data);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  useEffect(() => {
    getattendances();
  }, []);
  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const lastEntry = attendance?.[attendance?.length - 1];

  const headerCards = [
    {
      cardIcon: <BsCalendar3 size={25} color="var(--primaryBg)" />,
      cardHeader: "Date",
      cardBody: formatDate(lastEntry?.date),
    },
    {
      cardIcon: <AiOutlineClockCircle size={25} color="var(--primaryBg)" />,
      cardHeader: "Check In",
      cardBody: formatTime(lastEntry?.punch_in),
    },
    {
      cardIcon: <AiOutlineClockCircle size={25} color="var(--primaryBg)" />,
      cardHeader: "Check out ",
      cardBody: formatTime(lastEntry?.punch_out),
    },
    {
      cardIcon: <PiClockCountdownBold size={25} color="var(--primaryBg)" />,
      cardHeader: "Duration",
      cardBody: lastEntry?.production_time,
    },
  ];

  const getDayOfWeek = (dateString) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  console.log("yearSelect", yearSelect);
  console.log("monthSelect", monthSelect);

  useEffect(() => {
    if (attendance && Array.isArray(attendance)) {
      const formattedMonth = String(monthSelect).padStart(2, "0");
      const filteredData = attendance.filter((item) => {
        const [itemYear, itemMonth] = item.date.split("-");
        return itemYear === String(yearSelect) && itemMonth === formattedMonth;
      });
      setFilteredattendanceData(filteredData);
    }
  }, [attendance, yearSelect, monthSelect]);

  console.log("attendance", attendance);
  console.log("filteredattendanceData", filteredattendanceData);

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className={`flex justify-between items-center`}>
        <h1 className={`text-[32px] text-primaryText`}> Attendance</h1>
        <div className="flex items-center gap-4"></div>
      </section>

      <div className={`py-[34px] grid grid-cols-4 gap-[45px] `}>
        {headerCards?.map((card) => (
          <section
            className={`py-[20px] px-4 rounded-lg flex gap-5 ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-primaryLight"
            } `}
          >
            <span
              className={`${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
              } w-[56px] h-[56px] flex justify-center items-center rounded-full`}
            >
              {card?.cardIcon}
            </span>
            <div>
              <span className={`text-[14px] text-[#A3AED0]`}>
                {card?.cardHeader}
              </span>
              <h1
                className={`mt-2 text-[24px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-[#2B3674]"
                } `}
              >
                {card?.cardBody}
              </h1>
            </div>
          </section>
        ))}
      </div>
      <div className="my-[32px] flex gap-4 items-center">
        <select
          name="month"
          id="month"
          className={`py-2 px-2 w-[92px] text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          value={monthSelect ? monthSelect : ""}
          onChange={(e) => setMonthSelect(e.target.value)}
        >
          <option value="month" selected>
            Month
          </option>
          {months?.map((emp) => (
            <option key={emp?._id} value={emp?.value}>
              {emp?.leable}
            </option>
          ))}
        </select>
        <select
          name="year"
          id="year"
          className={`py-2 px-2 w-[92px] text-[12px] rounded outline-none focus:border-primaryBg ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          value={yearSelect ? yearSelect : ""}
          onChange={(e) => setYearSelect(e.target.value)}
        >
          <option value="year" selected>
            Year
          </option>
          {year?.map((emp) => (
            <option key={emp} value={emp}>
              {emp}
            </option>
          ))}
        </select>
        <button
          className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setYearSelect(currentYear);
            setMonthSelect(currentMonth);
          }}
        >
          Clear Filter
        </button>
      </div>

      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>Date</Th>
                <Th>Check in</Th>
                <Th>Check Out</Th>
                <Th>Duration</Th>
                <Th>Check In Status</Th>
                <Th>Status</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredattendanceData
                ?.reverse()
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                // ?.reverse()
                ?.map((dept, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>{dept?.date}</Td>
                      <Td>{formatTime(dept?.punch_in)}</Td>
                      <Td>{formatTime(dept?.punch_out)}</Td>
                      <Td>{dept?.production_time}</Td>
                      <td>
                        <div className="flex">
                          <Badge
                            intent={
                              dept?.punch_in_status === "On time"
                                ? "success"
                                : "warning"
                            }
                            size="medium"
                            roundness="pill"
                          >
                            {dept?.punch_in_status}
                          </Badge>
                        </div>
                      </td>
                      <td>
                        <div className="flex">
                          <Badge
                            intent={
                              dept?.status === "Present" ? "success" : "warning"
                            }
                            size="medium"
                            roundness="pill"
                          >
                            {dept?.status ? dept?.status : "-"}
                          </Badge>
                        </div>
                      </td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={attendance?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>
    </div>
  );
};

export default AttendanceUser;
