/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Badge from "../components/Badge";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const Timesheet = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const [departmentSelect, setDepartmentSelect] = useState();
  const [timesheet, setTimesheet] = useState();
  const [todaysDate, setTodaysDate] = useState();
  const { showSnackbar } = useSnackbar();

  const getTodayDate = () => {
    const today = new Date();
    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    getTodayDate();
  });
  // console.log("timesheet", timesheet);

  const [timeSheetPageNumber, setTimeSheetPageNumber] = useState(1);
  const [tsRowsPerPage, setTsRowsPerPage] = useState(10);

  const getTimesheet = async () => {

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/getDailyAttendance?date=${todaysDate}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      if (result?.status === 404) {
        showSnackbar(`Data not found for Today`, "red");
      } else {
        setTimesheet(result?.data);
      }
    } catch (error) {
      showSnackbar(`Data not found for Today`, "red");
    }
  };

  useEffect(() => {
    getTimesheet();
  }, []);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  // Function to extract time from a datetime string
  function extractTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  const filterData = timesheet?.filter((blog) => {
    const departData = departmentSelect
      ? blog?.employeeDepartment === departmentSelect
      : true;
    return departData;
  });

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] mb-[46px] text-primaryText`}>Timesheet</h1>

      <div className="my-[32px] flex gap-4 items-center">
        <select
          name="dept"
          id="dept"
          className={`px-2 text-primaryText font-[500] py-2 text-[12px] rounded outline-none border border-primaryText focus:border-blue-500  ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-transparent"
              : ""
          }`}
          onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect : ""}
        >
          <option value="dept" selected disabled>
            Select Department
          </option>
          {deptData?.map((emp) => (
            <option key={emp?.value} value={emp?.value}>
              {emp?.dept_name}
            </option>
          ))}
        </select>
        <button
          className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect("");
          }}
        >
          Clear Filter
        </button>
      </div>

      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th><p className="pl-2">Employee Name</p></Th>
              <Th>Department</Th>
              <Th>Date</Th>
              <Th>Shift Time</Th>
              <Th>Check In</Th>
              <Th>Check Out</Th>
              <Th>Duration</Th>
              <Th>Status</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheet
              ?.reverse()
              ?.slice(
                (timeSheetPageNumber - 1) * tsRowsPerPage,
                timeSheetPageNumber * tsRowsPerPage
              )
              .map((t, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <Link to={`/timesheet-details/${t?.employeeId}`}>
                        <div className="flex items-center gap-2 pl-2">
                          {t?.employeeProfileUrl ? (
                            <Avatar
                              type="img"
                              src={t?.employeeProfileUrl}
                              size="md"
                            />
                          ) : (
                            <Avatar size="md" type="text">
                              {t.employeeName?.slice(0, 1)}
                            </Avatar>
                          )}
                          <div className="">
                            <h4 className="text-primaryText text-[14px]">
                              {t?.employeeName}
                            </h4>
                            <h4 className="text-primaryText text-[10px]">
                              {t?.employeeDesignation}
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </Td>
                    <Td>{t?.employeeDepartment}</Td>
                    <Td>{formatDate(t?.date)}</Td>
                    <Td>{t?.employeeShift}</Td>
                    <Td>
                      <span className="uppercase">
                        {extractTime(t?.punchIn)}
                      </span>
                    </Td>
                    <Td>
                      <span className="uppercase">
                        {extractTime(t?.punchOut)}
                      </span>
                    </Td>
                    <Td>{t?.productionTime}</Td>
                    <Td>
                      <span className="flex">
                        <Badge
                          intent={
                            t?.punchInStatus === "On time"
                              ? "success"
                              : "warning"
                          }
                          size="medium"
                          roundness="pill"
                        >
                          {t?.punchInStatus}
                        </Badge>
                      </span>
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={timesheet?.length || 0}
          setRowsPerPage={setTsRowsPerPage}
          rowsPerPage={tsRowsPerPage}
          setPageNumber={setTimeSheetPageNumber}
          pageNumber={timeSheetPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

export default Timesheet;
