/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL, apiKey, token } from "../utils/config";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "../components/Modal";
import Select from "react-select";
import { useSnackbar } from "../context/SnackbarContext";

const Team = () => {
  const { showSnackbar } = useSnackbar();
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const [team, setteams] = useState();
  console.log("team", team);
  const [selectedOption, setSelectedOption] = useState(null);
  const [allDepartments, setAllDepartments] = useState();
  console.log("allDepartments", allDepartments);
  const [filters, setFilters] = useState({});

  const getteams = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/team/get-all-team`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setteams(result?.data);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  const getAllDepartments = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/departments/get-all-departments`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setAllDepartments(result?.data);
      console.log("getAllDepartments", result?.data);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  const [allDepartmentEmployee, setAllDepartmentEmployee] = useState({});

  // get all employees
  const [getAllEmployees, setGetAllEmployees] = useState();
  // console.log("getAllEmployees", getAllEmployees);

  const getAllEmployeesFunc = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/team/employeesWithoutTeam`,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetAllEmployees(result?.data);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      if (error.response && error.response.status === 404) {
        showSnackbar(`Resource not found. Please check the URL.`, "red");
      } else {
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  // add team name and head
  const [addteam, setAddteam] = useState({});
  console.log("addteam", addteam);

  const handleAddteam = () => {
    try {
      axios
        .post(
          `${LOCALHOST_BASE_URL}/team/add-team`,
          {
            team_leader: addteam?.team_leader,
            team_name: addteam?.team_name,
            departmentId: addteam?.departmentId,
          },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Response data:", response.data);
          console.log("team added");
          setAddteamModal(false);
          showSnackbar(`New Team is created Successfully`, "green");
          getteams();
        });
    } catch (error) {
      showSnackbar(`New Team is not created`, "red");
      console.log(error);
    }
  };

  // update team
  const Updateteam = () => {
    const temp = [...addteam?.team_member, ...newAddedEmpList];
    axios
      .put(
        `${LOCALHOST_BASE_URL}/team/update-team?id=${addteam.id}`,
        {
          dept: addteam?.dept,
          id: addteam?.id,
          team_leader: addteam?.team_leader,
          team_name: addteam?.team_name,
          team_member: temp,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        console.log("team updated");
        setEditteamModal(false);
        showSnackbar(`Team is updated`, "green");
        getteams();
      })
      .catch((error) => {
        showSnackbar(`Team is not updated`, "red");
        console.log(error);
      });
  };

  useEffect(() => {
    getteams();
    getAllDepartments();
    getAllEmployeesFunc();
  }, []);

  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  // teamname modal
  const [selectedDeptId, setSelectedDeptId] = useState(null);
  const [teamName, setTeamName] = useState(false);

  // add dept modal
  const [addteamModal, setAddteamModal] = useState(false);
  const openAddteam = () => {
    setAddteamModal((prev) => !prev);
  };

  // edit dept modal
  const [editteamModal, setEditteamModal] = useState(false);
  const openEditteamDetails = (teamId) => {
    const teamToEdit = team.find((dept) => dept._id === teamId);
    console.log("line 126------>>>>", teamToEdit);
    // console.log("line 126", teamToEdit?.team_member);
    if (teamToEdit) {
      setAddteam({
        id: teamId,
        team_name: teamToEdit?.team_name,
        team_leader: teamToEdit?.team_leader?._id,
        team_member: teamToEdit?.team_member,
      });
      setEditteamModal((prev) => !prev);
    }
  };

  console.log("line 183", allDepartmentEmployee);

  // delete team
  const deleteTeam = async (id) => {
    try {
      axios
        .delete(`${LOCALHOST_BASE_URL}/team/delete-team?id=${id}`, {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response) {
            showSnackbar(`Team is deleted successfully`, "green");
            getteams();
            console.log("dept deleted");
          }
        })
        .catch((error) => {
          showSnackbar(`Team is not deleted`, "red");
          console.log("Here is the Error", error);
        });
    } catch (error) {
      showSnackbar(`Team is not deleted`, "red");
      console.log("error", error);
    }
  };

  const removeAddedEmpFromTeam = (teamId, empId) => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/team/removeAddedEmp-team?teamId=${teamId}&empId=${empId}`,
        null,
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          console.log("removeAddedEmpFromTeam", response);
          getteams();
          openEditteamDetails(teamId);
        }
      })
      .catch((error) => {
        showSnackbar(`Something went wrong, please try again`, "red");
        console.log("Here is the Error from then-Catch", error);
      });
  };

  const filteredEmployees =
    deptData?.find((dept) => dept?.dept_name === addteam?.dept)?.employee || [];

  console.log("filteredEmployees", filteredEmployees);

  const [newAddedEmpList, setNewAddedEmpList] = useState([]);

  let currentEmployeeId;
  const handleTeamEmp = (selectedOptions, act) => {
    let e = act;
    console.log(selectedOptions, act, e);
    currentEmployeeId = act?.option?.value;
    console.log("currentEmployeeId", currentEmployeeId);

    console.log("currentEmployeId", selectedOptions);
    const selectedEmployeeIds = selectedOptions?.map((option) => option?.value);
    if (e.action !== "remove-value") {
      if (e.name === "select-employee") {
        // Extract selected employee IDs

        console.log(selectedEmployeeIds);
        console.log(
          "employeeee",
          filteredEmployees?.find((fdata) => fdata?._id === selectedEmployeeIds)
        );
        setNewAddedEmpList(selectedEmployeeIds);
      } else if (e.name === "select-all-employee") {
        if (e.checked) {
          console.log(
            "employeeee",
            filteredEmployees?.find(
              (fdata) => fdata?._id === selectedEmployeeIds
            )
          );
          const allEmployeeIds = filteredEmployees.map((emp) => emp._id);
          setAddteam((prev) => ({
            ...prev,
            emp_id: allEmployeeIds,
            team_member: [
              ...prev.team_member,
              ...filteredEmployees?.find(
                (fdata) => fdata?._id === currentEmployeeId
              ),
            ],
          }));
        } else {
          setAddteam((prev) => ({
            ...prev,
            emp_id: [],
          }));
        }
      } else {
        // For other fields (e.g., shift_name, start_time, etc.), update as usual
        setAddteam((prev) => ({
          ...prev,
          [e.name]: e.target.value,
        }));
      }
    }
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="mb-[34px] flex justify-between gap-4 items-center">
        <h1
          className={`text-[32px] text-primaryText ${
            organisation?.organisationTheme?.name === "dark"
              ? "text-primaryTextDark"
              : "text-primaryTextLight"
          }`}
        >
          Team
        </h1>
        <Button intent="primary" mode={mode} onClick={openAddteam}>
          Add Team
        </Button>
      </div>

      <div className="text-primaryText">
        <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
          <section className="flex gap-4 items-center">
            <select
              name="designation"
              id="designation"
              className={`py-2 px-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
                organisation?.organisationTheme?.name === "dark"
                  ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                  : "border border-primaryTextLight text-primaryTextLight"
              }  `}
              onChange={(e) => {
                setFilters({ team: e.target.value });
              }}
              value={filters?.team || ""}
            >
              <option value="designation" selected>
                Teams
              </option>
              {team?.map((emp) => (
                <option key={emp?._id} value={emp?._id}>
                  {emp?.team_name}
                </option>
              ))}
            </select>

            <button
              className={`text-primaryBg border-b border-primaryBg`}
              onClick={() => {
                setFilters({});
              }}
            >
              Clear Filter
            </button>
          </section>
        </div>
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <p className="pl-2">Team</p>
                </Th>
                <Th>Team lead</Th>
                <Th>Employee</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {team
                ?.filter((data) =>
                  !filters?.team ? data : data?._id === filters?.team
                )
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                ?.map((dept, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>
                        <p className="pl-2">{dept?.team_name}</p>
                      </Td>
                      <Td>{dept?.team_leader?.emp_name} </Td>
                      <Td>
                        {console.log("team", team)}
                        <div className="text-[14px] font-medium flex">
                          {dept?.team_member?.map((emp) => (
                            <>
                              {emp?.emp_profile ? (
                                <h1 className="ml-[-0.2em]">
                                  <Avatar
                                    type="img"
                                    src={emp?.emp_profile}
                                    size="sm"
                                  />
                                </h1>
                              ) : (
                                <Avatar size="sm" type="text">
                                  {emp?.emp_name?.slice(0, 1)}
                                </Avatar>
                              )}
                            </>
                          ))}
                        </div>
                      </Td>
                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out">
                            <FiEdit2
                              onClick={() => openEditteamDetails(dept._id)}
                            />
                          </h2>
                          <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out">
                            <RiDeleteBin6Line
                              color="#D20E0E"
                              onClick={() => deleteTeam(dept._id)}
                            />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={team?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>

      {/* team names modal */}
      <Modal showModal={teamName} setShowModal={setTeamName}>
        <div className="w-[446px]">
          {team
            ?.filter((dep) => dep._id === selectedDeptId)
            .map((dep) => (
              <>
                <h2 className="mb-4 text-center text-primaryTextLight font-medium text-[20px]">
                  {dep?.dept_name}
                </h2>
                <section className="">
                  <h2 className="py-2 text-center bg-[#F8F9FF]">Teams</h2>
                  <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
                    <h2 className="flex flex-col px-[53px] text-primaryTextLight text-[14px]">
                      {dep?.team?.map((t) => (
                        <span className="py-[10px]">{t?.team_name}</span>
                      ))}
                    </h2>
                  </div>
                </section>
              </>
            ))}
        </div>
      </Modal>

      {/* add team modal */}
      <Modal showModal={addteamModal} setShowModal={setAddteamModal}>
        <div className="w-[340px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Add team
          </h1>

          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">Team Name</label>
              <input
                type="text"
                placeholder="Add team"
                onChange={(e) => {
                  console.log("team Name---", e.target.value);
                  setAddteam({
                    ...addteam,
                    team_name: e.target.value,
                  });
                }}
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="depthead">Team Head</label>
              <select
                name="depthead"
                id="depthead"
                required
                onChange={(e) => {
                  console.log("team Head---", e.target.value);
                  setAddteam({
                    ...addteam,
                    team_leader: e.target.value,
                  });
                }}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="depthead" disabled>
                  Select team Head
                </option>
                {getAllEmployees
                  ?.filter((emp) => emp?._id !== team?.dept_head?._id) // Filter out employees whose IDs match the team head's ID
                  .map((emp) => (
                    <option key={emp?._id} value={emp?._id}>
                      {emp?.emp_name}
                    </option>
                  ))}
              </select>
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">Department Name</label>
              <select
                name="depthead"
                id="depthead"
                // value={addteam?.dept_name}
                value={addteam?.departmentId}
                onChange={(e) => {
                  console.log("team Head---", e.target.value);
                  setAddteam({
                    ...addteam,
                    departmentId: e.target.value,
                  });
                }}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="" selected disabled>
                  Select Department
                </option>
                {allDepartments?.map((emp) => (
                  <option key={emp?._id} value={emp?._id}>
                    {emp?.dept_name}
                  </option>
                ))}
              </select>
            </section>
          </div>

          <div className="text-center mt-8">
            <Button intent="primary" mode={mode} onClick={handleAddteam}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* edit team modal */}
      <Modal showModal={editteamModal} setShowModal={setEditteamModal}>
        <div className="w-[640px] h-[500px] overflow-auto">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Edit Team
          </h1>

          <div className="mt-[24px] mb-[8px] grid grid-cols-2 gap-4">
            <section className="flex flex-col gap-[6px] mb-2">
              <label htmlFor="dept">Select Department</label>
              <select
                name="dept"
                id="dept"
                // value={allDepartments?.dept}
                required
                onChange={(e) =>
                  setAddteam({
                    ...addteam,
                    dept: e.target.value,
                  })
                }
                // onChange={handleAssignAsset}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="dept" selected disabled>
                  Select Department
                </option>
                {deptData?.map((dept) => (
                  <option value={dept?.dept_name}>{dept?.dept_name}</option>
                ))}
              </select>
            </section>
            {/* <section className="flex flex-col gap-[6px] mb-2">
                <label htmlFor="dept">Select Employee</label>
                <Select
                  isMulti
                  // value={assignBenefitData?.emp_id}
                  onChange={(selectedOptions, action) =>
                    handleTeamEmp(selectedOptions, action)
                  }
                  options={filteredEmployees?.map((emp) => ({
                    value: emp?._id,
                    label: emp?.emp_name,
                  }))}
                  name="select-employee"
                />
              </section> */}
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="depthead">Team Name</label>
              <input
                type="text"
                placeholder="Add team"
                value={addteam?.team_name}
                onChange={(e) => {
                  console.log("Here is the Team Name", addteam);
                  setAddteam({
                    ...addteam,
                    team_name: e.target.value,
                  });
                }}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="deptname">Team Lead</label>
              <select
                value={addteam?.team_leader}
                name="depthead"
                id="depthead"
                onChange={(e) => {
                  console.log("Here is the Team Lead", addteam);
                  setAddteam({
                    ...addteam,
                    team_leader: e.target.value,
                  });
                }}
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              >
                <option value="" disabled>
                  Select Employee
                </option>
                {allDepartments
                  // ?.filter(
                  //   (dep) => dep._id === `${allDepartmentEmployee.departmentId}`
                  // )
                  ?.map((emp) => (
                    <>
                      {/* {console.log("Emp=====", emp.employee)} */}
                      {/* <option value={emp?.employee?._id}> */}
                      {emp?.employee?.map((name) => (
                        <option value={name?._id}>{name.emp_name}</option>
                      ))}
                      {/* </option>  */}
                    </>
                  ))}
              </select>
            </section>
            <section className="flex flex-col gap-[6px]">
              <label htmlFor="depthead">Select Team Member</label>
              <Select
                isMulti
                // value={assignBenefitData?.emp_id}
                onChange={(selectedOptions, action) =>
                  handleTeamEmp(selectedOptions, action)
                }
                options={filteredEmployees?.map((emp) => ({
                  value: emp?._id,
                  label: emp?.emp_name,
                }))}
                name="select-employee"
              />
              {/* <Select
                isMulti
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={allDepartments
                  // ?.filter(
                  //   (dep) => dep._id === `${allDepartmentEmployee.departmentId}`
                  // )
                  ?.map((dep) => (
                    <>
                      {dep?.employee?.map((emp) => (
                        <>
                          <option key={emp._id} value={emp._id}>
                            {emp?.emp_name}
                          </option>
                        </>
                      ))}
                    </>
                  ))}
              /> */}
            </section>
          </div>

          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Added employees
          </h1>
          <table className="w-full border text-left">
            <thead>
              <tr className="bg-[#F8F9FF] text-primaryTextLight text-[14px] font-[500]">
                <th className="p-2">Employee name</th>
                <th>Department Head</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {addteam?.team_member?.map((team) => (
                <>
                  {console.log()}
                  <tr>
                    <td className="flex gap-2 px-2 py-2">
                      {" "}
                      {team?.emp_profile ? (
                        <Avatar type="img" src={team?.emp_profile} size="md" />
                      ) : (
                        <Avatar size="md" type="text">
                          {team?.emp_name?.split(" ")[0][0] +
                            team?.emp_name?.split(" ")[1][0]}
                        </Avatar>
                      )}
                      {team?.emp_name}
                    </td>
                    <td>{team?.emp_department?.dept_name}</td>
                    <td>{team?.emp_designation?.des_name}</td>
                    <td>
                      {" "}
                      <div className="flex gap-3 items-center">
                        <h2 className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out">
                          <RiDeleteBin6Line
                            color="#D20E0E"
                            onClick={(e) => {
                              console.log("Here is the Code inside");
                              removeAddedEmpFromTeam(addteam?.id, team?._id);
                            }}
                          />
                          {/* team?._id */}
                        </h2>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={(e) => {
                Updateteam();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Team;
