import React, { useContext } from "react";
import { cva } from "class-variance-authority";
import { ContextThemeProvider } from "../context/ThemeContext";

const ButtonVariants = cva(
  "transition-colors duration-150 font-[500] text-[12px] px-[32px] py-[8px]",
  {
    variants: {
      mode: {
        dark: {
          primary: "bg-primaryBg text-white",
          secondary: "border border-primaryBg bg-white text-primaryBg",
        },
        // light: "bg-primary",
      },
      intent: {
        primary:
          "bg-primaryBg text-white disabled:bg-[#E0E0E0] disabled:text-gray-500 disabled:cursor-not-allowed",
        secondary:
          "border border-primaryBg bg-white text-primaryBg disabled:bg-[#E0E0E0] disabled:text-gray-500 disabled:border-none disabled:cursor-not-allowed",
        default: "bg-primaryBg text-white",
      },

      size: {
        small: ["text-[12px]", "py-1", "px-4"],
        medium: ["text-[12px]", "py-2", "px-4"],
        large: ["text-[12px]", "py-2", "px-4", "w-full"],
      },
      roundness: {
        square: "rounded-none",
        round: "rounded",
        pill: "rounded-full",
      },
    },
    defaultVariants: {
      intent: "default",
      mode: "light", // Set a default mode value
      size: "medium",
      roundness: "round",
    },
  }
);

export default function Button({
  intent,
  mode,
  size,
  roundness,
  children,
  onClick,
  ...restProps
}) {
  const { color, organisation } = useContext(ContextThemeProvider);
  // console.log("color", color);

  return (
    <>
      {organisation?.organisationTheme?.name === "dark" &&
      intent === "secondary" ? (
        <button
          {...restProps}
          className={`font-[500] text-[12px] px-[32px] py-[8px] rounded border ${
            intent === "primary"
              ? "border-primaryBg text-primaryBg"
              : "text-white border-white"
          }`}
          onClick={(e) => onClick(e)}
        >
          {children}
        </button>
      ) : (
        <button
          {...restProps}
          className={ButtonVariants(
            {
              mode,
              color,
              intent:
                organisation?.organisationTheme?.name === "dark"
                  ? "default"
                  : intent,
            },
            roundness,
            size
          )}
          onClick={(e) => onClick(e)}
        >
          {children}
        </button>
      )}
    </>
  );
}
