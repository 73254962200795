/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BsArrowLeft, BsCalendar2, BsClock } from "react-icons/bs";
import { TbHierarchy3 } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL } from "../utils/config";
import Avatar from "./Avatar";
import Badge from "./Badge";
import { useSnackbar } from "../context/SnackbarContext";

const TimesheetEmpDetails = () => {
  const { employeeId } = useParams();
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const [empDetails, setEmpDetails] = useState();
  console.log("emp", empDetails);
  const getEmpDetails = async () => {
    try {
      const headers = {
        'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw='
      };
  
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${employeeId}`,
        { headers }
      );
      setEmpDetails(result?.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };
  

  // yearly attendance
  const [timesheetAttendance, setTimesheetAttendance] = useState();
  console.log("timesheetAttendance", timesheetAttendance);

  const getTimesheetAttendance = async () => {
    try {
      const headers = {
        'api-key': 'wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw='
      };
  
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/get-AttendanceByEmployee?id=${employeeId}`,
        { headers }
      );
      setTimesheetAttendance(result?.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      if (error.response?.status === 404) {
        showSnackbar("Data not found", "red");
      } else {
        showSnackbar("Something is wrong, please try again.", "red");
      }
    }
  };
  

  useEffect(() => {
    getEmpDetails();
    getTimesheetAttendance();
  }, [employeeId]);

  // convert date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  // get current year
  const currentYear = new Date().getFullYear();

  const empWorkDetails = [
    {
      detailName: "Designation",
      detailBody: empDetails?.employee?.emp_designation?.des_name,
    },
    {
      detailName: "Employee type",
      detailBody: empDetails?.employee?.emp_type,
    },
    {
      detailName: "Mobile Number",
      detailBody: empDetails?.employee?.emp_phoneNo,
    },
    {
      detailName: "Email Address",
      detailBody: empDetails?.employee?.emp_email,
    },
    {
      detailName: "Date of Birth",
      detailBody: formatDate(empDetails?.employee?.emp_dob),
    },
  ];

  const employeeDetailCards = [
    {
      icon: <BsClock />,
      cardHeader: "Date of joining",
      cardBody: formatDate(empDetails?.employee?.emp_joingDate),
    },
    {
      icon: <TbHierarchy3 />,
      cardHeader: "Department",
      cardBody: empDetails?.employee?.emp_department?.dept_name,
    },
    {
      icon: <BsClock />,
      cardHeader: "Shift time",
      cardBody: (() => {
        const shiftStartTime = empDetails?.employee?.emp_shiftTime?.start_time;
        const shiftEndTime = empDetails?.employee?.emp_shiftTime?.end_time;

        if (shiftStartTime && shiftEndTime) {
          return `${shiftStartTime} - ${shiftEndTime}`;
        } else {
          return "Not specified";
        }
      })(),
    },
    {
      icon: <BsCalendar2 />,
      cardHeader: "Taken leave this year",
      // cardBody: "-",
      cardBody: `${
        empDetails?.leaveData?.[currentYear]?.leaveCount
          ? empDetails?.leaveData?.[currentYear]?.leaveCount
          : "-"
      }`,
    },
  ];

  // Function to extract time from a datetime string
  function extractTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  return (
    <div
      className={`h-full pl-[36px] py-[24px] pr-[50px] ${
        organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
      } `}
    >
      <section className="rounded-lg cardShadow p-[25px]">
        <Link
          to="/timesheet"
          className={`${
            organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
          }`}
        >
          <BsArrowLeft />
        </Link>
        <div className="mb-18[px] mt-[26px] flex gap-[31px]">
          <Avatar
            type="img"
            src={empDetails?.employee?.emp_profile}
            size="xxlg"
          />
          <section className="text-primaryText">
            <h1 className="mb-5 text-[30px] text-primaryText font-medium">
              {empDetails?.employee?.emp_name}
            </h1>
            <div className="grid grid-cols-5 gap-4">
              {empWorkDetails?.map((d) => (
                <section>
                  <span className="text-[14px] font-[300]">
                    {d?.detailName}
                  </span>
                  <p>{d?.detailBody}</p>
                </section>
              ))}
            </div>
          </section>
        </div>

        <div className="grid grid-cols-4 mt-[18px] gap-4">
          {employeeDetailCards?.map((card) => (
            <section
              className={`flex items-center gap-4 p-[25px] rounded-lg text-primaryText h-[96px] ${
                organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-primaryLight"
              }`}
            >
              <div
                className={`w-[41px] h-[41px] rounded-full flex justify-center items-center ${
                  organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
                }`}
              >
                {card?.icon}
              </div>
              <div>
                <h2 className="mb-2">{card?.cardHeader}</h2>
                <p>{card?.cardBody}</p>
              </div>
            </section>
          ))}
        </div>
      </section>

      <section
        className={`mt-4 p-[25px] rounded-lg cardShadow ${
          organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
        } `}
      >
        <div className="mb-[25px] flex justify-between items-center">
          <h1 className="text-primaryText font-medium text-[26px]">
            Attendance History
          </h1>
          <select
            name="month"
            id="month"
            className={`px-2 w-[118px] text-primaryText font-[500] py-2 text-[12px] rounded outline-none border border-primaryText focus:border-blue-500  ${
              organisation?.organisationTheme
?.name === "dark" ? "bg-transparent" : ""
            }`}
          >
            <option value="month" selected disabled>
              Select Month
            </option>
          </select>
        </div>
        <div>
          {timesheetAttendance?.attendance &&
            Object.entries(timesheetAttendance?.attendance)
              ?.filter(([year, _]) => year === currentYear.toString())
              ?.map(([year, month]) => (
                <div key={year} className="grid grid-cols-4 gap-4">
                  {Object?.entries(month)?.map(([month, records]) =>
                    records?.map((rec) => (
                      <section
                        key={rec?.date}
                        className={`h-[116px] bg-[#F7F7FF] p-[15px] text-primaryText rounded-lg ${
                          organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-[F7F7FF]"
                        }`}
                      >
                        <div className="flex justify-between items-center mb-5">
                          <section className="flex items-center gap-2">
                            <BsCalendar2 size={13} />
                            <span className="font-medium text-[14px]">
                              {rec?.date}
                            </span>
                          </section>
                          <Badge
                            intent={
                              rec?.punch_in_status === "On time"
                                ? "success"
                                : "warning"
                            }
                            size="medium"
                            roundness="pill"
                          >
                            {rec?.punch_in_status}
                          </Badge>
                        </div>

                        <div className="flex items-center justify-between">
                          <section>
                            <h4 className="font-[300] text-[12px]">
                              Check In Time
                            </h4>
                            <h3 className="font-medium text-[14px] uppercase">
                              {extractTime(rec?.punch_in)}
                            </h3>
                          </section>
                          <section>
                            <h4 className="font-[300] text-[12px]">
                              Check Out Time
                            </h4>
                            <h3 className="font-medium text-[14px] uppercase">
                              {extractTime(rec?.punch_out)}
                            </h3>
                          </section>
                        </div>
                      </section>
                    ))
                  )}
                </div>
              ))}
        </div>
      </section>
    </div>
  );
};

export default TimesheetEmpDetails;
