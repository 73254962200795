/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const TablePagination = ({
  totalDataCnt,
  itemsPerPageArray,
  setRowsPerPage,
  rowsPerPage,
  setPageNumber,
  pageNumber,
}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [searchParams, setSearchParams] = useSearchParams();

  const { color, mode, organisation } = useContext(ContextThemeProvider);

  // useEffect(() => {
  //   const selectedSize = params?.size
  //     ? parseInt(params.size)
  //     : itemsPerPageArray && itemsPerPageArray.length > 0
  //     ? parseInt(itemsPerPageArray[0].value)
  //     : 10;

  //   const calculatedPage = Math.ceil(parseInt(totalDataCnt) / selectedSize);

  //   setSearchParams(() => ({
  //     ...params,
  //     size: selectedSize.toString(),
  //     page: parseInt(params.page) <= calculatedPage ? params.page : 1,
  //   }));
  // }, [params.size, params.page]);

  useEffect(() => {
    const selectedSize = params?.size
      ? parseInt(params.size)
      : itemsPerPageArray && itemsPerPageArray.length > 0
      ? parseInt(itemsPerPageArray[0].value)
      : 10;

    const calculatedPage = Math.ceil(parseInt(totalDataCnt) / selectedSize);

    setSearchParams(() => ({
      ...params,
      // size: selectedSize.toString(),
      size: rowsPerPage,
      page: parseInt(params.page) <= calculatedPage ? params.page : 1,
    }));
  }, [params.size, params.page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalDataCnt / params.size)) {
      setSearchParams(() => ({
        ...params,
        page: newPage.toString(),
      }));
      setPageNumber(newPage); // Update pageNumber state
    }
  };

  return (
    <div className="TablePaginationContainer w-full">
      <div className="ItemsPerPageContainer">
        <div className="ItemsPerPageLeftContainer">
          {/* <small>Items per page</small> */}
          {/* <select
            value={rowsPerPage} // Use rowsPerPage here, not params.size
            onChange={(e) => {
              setSearchParams(() => ({
                ...params,
                size: e.target.value,
                page: 1, // Set page number to 1 when changing page size
              }));
              setRowsPerPage(parseInt(e.target.value)); // Update rowsPerPage state
            }}
          >
            {itemsPerPageArray?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select> */}
        </div>
        {/* <div className="ItemsPerPageRightContainer test">
          <small>
            {params.page * params.size - params.size} -{" "}
            {Math.min(params.page * params.size, totalDataCnt)} of{" "}
            {totalDataCnt} items
          </small>
        </div> */}
      </div>
      <div
        className={`h-[40px] primaryText font-medium flex justify-between items-center px-[23px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "bg-primaryDark"
            : "bg-primaryLight"
        }`}
      >
        <section>
          <button
            className={`primaryText font-medium text-[14px] flex items-center gap-1 `}
            onClick={() => handlePageChange(pageNumber - 1)}
            style={{
              color: pageNumber > 1 ? "var(--primaryBg)" : "gray",
              cursor: pageNumber > 1 ? "pointer" : "not-allowed",
            }}
          >
            <BsArrowLeft /> Prev
          </button>
        </section>
        <section className="flex gap-1">
          {Array.from(
            { length: Math.ceil(totalDataCnt / params.size) },
            (_, i) => (
              <>
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`px-3 py-1 rounded w-fit ${
                    i + 1 === pageNumber ||
                    organisation?.organisationTheme?.name === "dark"
                      ? "bg-primaryBg text-white"
                      : "bg-white"
                  }`}
                >
                  {i + 1}
                </button>
              </>
            )
          )}
        </section>
        {/* <small>of {Math.ceil(totalDataCnt / params.size)} pages</small> */}

        <button
          className={`primaryText font-medium text-[14px] flex items-center gap-1 `}
          onClick={() => handlePageChange(pageNumber + 1)}
          style={{
            color:
              pageNumber < Math.ceil(totalDataCnt / params.size)
                ? "var(--primaryBg)"
                : "gray",
            cursor:
              pageNumber < Math.ceil(totalDataCnt / params.size)
                ? "pointer"
                : "not-allowed",
          }}
        >
          Next <BsArrowRight />
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
