/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
/* eslint-disable no-unused-vars */
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const CreatePolicies = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { policyId } = useParams();
  console.log("policyId", policyId);

  // get one agreement
  const [onePolicy, setOnePolicy] = useState();
  console.log("onePolicy", onePolicy);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [policyData, setPolicyData] = useState({
    headline: "",
    date: "",
    body: "",
  });

  console.log("policyData", policyData);

  const handleOnChange = (name, value) => {
    console.log("Here is the date", value);
    setPolicyData((prev) => ({ ...prev, [name]: value }));
    // setOnePolicy((prev) => ({ ...prev, [name]: value }))
  };

  //   add Policy
  const handleAddPolicy = async () => {

    const headers = {
      "api-key": apiKey,
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${LOCALHOST_BASE_URL}/policies/add-policies`,
        {
          headline: policyData?.headline,
          date: policyData?.date,
          body: policyData?.body,
        },
        {
          headers,
        }
      );

      console.log("Response data:", response.data);
      console.log("Policy added");
      navigate(`/policies`);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  // get one Policy
  const getSinglePolicy = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/policies/get-policies?id=${policyId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      console.log(result);
      if (result) {
        setPolicyData(result?.data?.getPolicies);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  // update Policy
  const handleUpdatePolicy = async () => {
  

    try {
      console.log("Here is the Data from Update", policyData);
      const response = await axios.put(
        `${LOCALHOST_BASE_URL}/policies/update-policies?id=${policyId}`,
        {
          ...policyData,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response data", response?.data);
      console.log("policy updated");
      navigate(`/policies`);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  const addUpdatePolicy = () => {
    if (policyId) {
      handleUpdatePolicy();
    } else {
      handleAddPolicy();
    }
  };


  useEffect(() => {
    if (policyId) {
      getSinglePolicy();
    }
  }, [policyId]);

  console.log("policyData?.body--->", policyData);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/policies"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px]"
      >
        <MdOutlineKeyboardBackspace />
        {policyId ? "Edit" : "New"} Policies Layout
      </Link>
      <div className="flex gap-4">
        <div className="w-[60%]">
          <section className="w-[100%] h-[842px] bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              <div>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>{policyData.companyName}</span>
                  <span>Date : {formatDate(policyData.date)}</span>
                </h1>
                <h2 className="text-[#333]">{policyData.headline}</h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{ __html: policyData?.body }}
                ></div>
              </div>
            </div>
          </section>

        </div>
        <section className="w-[40%] h-[945px]">
          <section className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="headline"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Policies Heading
              </label>
              <input
                type="text"
                name="headline"
                value={policyData?.headline}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("headline", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <label
                htmlFor="date"
                className={`text-[14px] ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-primaryTextLight"
                }`}
              >
                Date
              </label>
              <input
                type="date"
                name="date"
                value={policyData?.date}
                className={`px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
                onChange={(e) => {
                  handleOnChange("date", e.target.value);
                }}
              />
            </div>
          </section>
          <section className="my-4">
            <div className="">
              {/* {console.log("onePolicy?.getPolicies?.body ", onePolicy?.getPolicies?.body)} */}
              <SunEditor
                showToolbar={true}
                // value={ policyData?.edit ? onePolicy?.getPolicies?.body : policyData?.body}
                onChange={(content) =>
                  setPolicyData((prevData) => ({
                    ...prevData,
                    body: content,
                  }))
                }
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "image",
                      "preview",
                      "link",
                    ],
                  ],
                }}
                setContents={policyData?.body || ""}
                height="200px"
              />
            </div>
          </section>
        </section>
      </div>
      <div className="flex items-center justify-center mt-8 gap-5">
        <Button intent="primary" mode={mode} onClick={addUpdatePolicy}>
          Save
        </Button>
        <Button intent="secondary" mode={mode} onClick={() => setPolicyData("")}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default CreatePolicies;
