/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { Link, useParams } from "react-router-dom";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const ViewPolicy = () => {
  const { organisation } = useContext(ContextThemeProvider);

  const { policyId } = useParams();
  const [policyDetails, setPolicyDetails] = useState();

  const getPolicyDetails = async () => {
    try {

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/policies/get-policies?id=${policyId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      if (result?.data) {
        setPolicyDetails(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPolicyDetails();
  }, [policyId]);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/policies"
        className={`text-[32px] flex items-center gap-1 text-primaryText`}
      >
        <MdOutlineKeyboardBackspace /> View Policy
      </Link>
      <section className="h-[1488px] w-full my-5 p-[66px] bg-white shadow-sm">
        <h1 className="text-[#333] text-center text-[36px]">
          {policyDetails?.getPolicies?.headline}
        </h1>
        <p className="w-[100px] h-1 bg-[#3D4EFE] my-[60px]"></p>
        <div
          className="my-4"
          dangerouslySetInnerHTML={{ __html: policyDetails?.getPolicies?.body }}
        ></div>
      </section>
    </div>
  );
};

export default ViewPolicy;
