/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { BsCalendar2 } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL } from "../../utils/config";
import Badge from "../Badge";
import { useSnackbar } from "../../context/SnackbarContext";
import { MdOutlineCalendarMonth } from "react-icons/md";

const tabData = [
  {
    id: 1,
    tabName: "Attendance",
  },
  {
    id: 2,
    tabName: "Overtime",
  },
];

const EmployeeAttendance = () => {
  let [tab, setTab] = useState("Attendance");
  const { showSnackbar } = useSnackbar();
  const { empId } = useParams();
  const { color, mode, organisation } = useContext(ContextThemeProvider);

  const [monthlyAttendance, setMonthlyAttendance] = useState([]);
  console.log("monthlyAttendance", monthlyAttendance);

  const getMonthlyAttendance = async () => {
    try {
      // Set the headers with the API key
      const headers = {
        "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/get-AttendanceByEmployee?id=${empId}`,
        { headers }
      );
      setMonthlyAttendance(result?.data);
    } catch (error) {
      console.error("error", error);
      showSnackbar(`Something is wrong, please try again.`, "red");
    }
  };

  const [empOvertime, setEmpOvertime] = useState();
  console.log("empOvertime", empOvertime);
  const getEmpOvertime = async () => {
    try {
      // Set the headers with the API key
      const headers = {
        "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      };

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/overTime/getOverTimeByEmployee?id=${empId}`,
        { headers }
      );
      setEmpOvertime(result?.data);
    } catch (error) {
      console.error("error", error);
      showSnackbar(`Something is wrong, please try again.`, "red");
    }
  };

  useEffect(() => {
    getMonthlyAttendance(monthlyAttendance);
    getEmpOvertime();
  }, [empId]);

  // Function to extract time from a datetime string
  function extractTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  const currentYear = new Date().getFullYear();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonthName = (number) => {
    return monthNames[number - 1];
  };

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Attendance":
        return (
          <MonthlyAttendance
            monthlyAttendance={monthlyAttendance}
            setMonthlyAttendance={setMonthlyAttendance}
            mode={mode}
            extractTime={extractTime}
            organisation={organisation}
            getMonthName={getMonthName}
            currentYear={currentYear}
          />
        );
      case "Overtime":
        return (
          <MonthlyOvertime
            empId={empId}
            empOvertime={empOvertime}
            extractTime={extractTime}
            organisation={organisation}
            getMonthName={getMonthName}
            currentYear={currentYear}
          />
        );
      default:
    }
  };

  return (
    <div
      className={`px-[31px] py-[26px] cardShadow rounded-lg ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      }`}
    >
      <h1 className="text-[26px] font-medium text-primaryText">
        Attendance History
      </h1>
      <section className="my-[28px] flex gap-6 border-b">
        {tabData.map((i) => {
          return (
            <>
              <h3
                className={`cursor-pointer pb-2 transition-all ease ${
                  tab === i.tabName
                    ? "text-primaryBg border-b-2 border-primaryBg"
                    : "text-primaryText"
                }`}
                onClick={() => {
                  setTab(i.tabName);
                }}
              >
                {i.tabName}
              </h3>
            </>
          );
        })}
      </section>
      <section className="pb-[5em]">{getCorrectPage(tab)}</section>
    </div>
  );
};

const MonthlyAttendance = ({
  monthlyAttendance,
  mode,
  extractTime,
  organisation,
  getMonthName,
  currentYear,
}) => {
  console.log(monthlyAttendance);

  return (
    <div>
      {monthlyAttendance?.attendance &&
        Object.entries(monthlyAttendance?.attendance)
          ?.filter(([year, _]) => year === currentYear.toString())
          ?.map(([year, month]) => (
            <>
              <h1 className="text-primaryText my-4 text-[24px] flex gap-1 items-center">
                {year} <MdOutlineCalendarMonth />
              </h1>
              <div key={year} className="">
                {Object?.entries(month)?.map(([month, records]) => (
                  <div className="">
                    <h2 className="text-primaryText my-4 text-[22px]">
                      {getMonthName(month)}
                    </h2>
                    <div className="grid grid-cols-5 gap-4">
                      {records?.map((rec) => (
                        <section
                          key={rec?.date}
                          className={`h-[116px] bg-[#F7F7FF] p-[15px] text-primaryText rounded-lg ${
                            organisation?.organisationTheme?.name === "dark"
                              ? "bg-primaryDark"
                              : "bg-[F7F7FF]"
                          }`}
                        >
                          {console.log("month", month)}
                          <div className="flex justify-between items-center mb-5">
                            <section className="flex items-center gap-2">
                              <BsCalendar2 size={13} />
                              <span className="font-medium text-[14px]">
                                {rec?.date}
                              </span>
                            </section>
                            <Badge
                              intent={
                                rec?.punch_in_status === "On time"
                                  ? "success"
                                  : "warning"
                              }
                              size="medium"
                              roundness="pill"
                            >
                              {rec?.punch_in_status}
                            </Badge>
                          </div>

                          <div className="flex items-center justify-between">
                            <section>
                              <h4 className="font-[300] text-[12px]">
                                Check In Time
                              </h4>
                              <h3 className="font-medium text-[14px] uppercase">
                                {extractTime(rec?.punch_in)}
                              </h3>
                            </section>
                            <section>
                              <h4 className="font-[300] text-[12px]">
                                Check Out Time
                              </h4>
                              <h3 className="font-medium text-[14px] uppercase">
                                {extractTime(rec?.punch_out)}
                              </h3>
                            </section>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ))}
    </div>
  );
};

const MonthlyOvertime = ({
  mode,
  empOvertime,
  extractTime,
  organisation,
  getMonthName,
  currentYear,
}) => {
  return (
    <div>
      {empOvertime?.overTime &&
        Object?.entries(empOvertime?.overTime)
          ?.filter(([year, _]) => year === currentYear.toString())
          ?.map(([year, month]) => (
            <>
              <h1 className="text-primaryText my-4 text-[24px] flex gap-1 items-center">
                {year} <MdOutlineCalendarMonth />
              </h1>
              <div key={year} className="">
                {Object?.entries(month)?.map(([month, records]) => (
                  <div className="">
                    <h2 className="text-primaryText my-4 text-[22px]">
                      {getMonthName(month)}
                    </h2>
                    <div className="grid grid-cols-4 gap-4">
                      {records?.map((rec) => (
                          <section
                          className={`h-[116px] bg-[#F7F7FF] p-[15px] text-primaryText ${
                            organisation?.organisationTheme?.name === "dark"
                              ? "bg-primaryDark"
                              : "bg-[F7F7FF]"
                          }`}
                        >
                          <div className="flex justify-between items-center mb-5">
                            <section className="flex items-center gap-2">
                              <BsCalendar2 size={13} />
                              <span className="font-medium text-[14px]">
                                {rec?.date}
                              </span>
                            </section>
                            <span>Monday</span>
                          </div>
                          <div className="flex items-center justify-between">
                            <section>
                              <h4 className="font-[300] text-[12px] mb-1">
                                Start Overtime
                              </h4>
                              <h3 className="font-medium text-[14px] uppercase">
                                {extractTime(rec?.punch_in)}
                              </h3>
                            </section>
                            <section>
                              <h4 className="font-[300] text-[12px] mb-1">
                                End Overtime
                              </h4>
                              <h3 className="font-medium text-[14px] uppercase">
                                {extractTime(rec?.punch_out)}
                              </h3>
                            </section>
                            <section>
                              <h4 className="font-[300] text-[12px] mb-1">
                                Duration
                              </h4>
                              <h3 className="font-medium text-[14px]">
                                {rec?.production_time}
                              </h3>
                            </section>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ))}
    </div>
  );
};

export default EmployeeAttendance;
