/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../context/ThemeContext";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";
import Button from "../components/Button";

const tabData = [
  {
    id: 1,
    tabName: "Full time",
  },
  {
    id: 2,
    tabName: "Intern",
  },
  {
    id: 3,
    tabName: "Probation",
  },
];

const PayslipCustomisation = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  let [tab, setTab] = useState("Full time");

  // get all payslip template
  const [allPaylsipTemplates, setAllPayslipTemplates] = useState();
  console.log("allPaylsipTemplates", allPaylsipTemplates);

  //   get one organisation
  const [oneOrganisation, setOneOrganisation] = useState();
  console.log("oneOrganisation", oneOrganisation);

  const getAllPayslipTemplate = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/salaryTemp/get-all-salaryTemp`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );
      setAllPayslipTemplates(result?.data);
    } catch (error) {
      showSnackbar(`Something went wrong. Please try again.`, "red");
      console.error("Error occurred:", error);
    }
  };
  
  // Get one organisation
  const getOneOrganisation = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/organisation/getOne-organisation`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );
      setOneOrganisation(result?.data?.result?.[0]);
    } catch (error) {
      showSnackbar(`Something went wrong. Please try again.`, "red");
      console.error("Error occurred:", error);
    }
  };
  

  useEffect(() => {
    getAllPayslipTemplate();
    getOneOrganisation();
  }, []);

  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Full time":
        return <FullTime 
        allPaylsipTemplates={allPaylsipTemplates} 
        oneOrganisation={oneOrganisation}
        showSnackbar={showSnackbar}
        />;
      case "Intern":
        return <h1>sdfghjk</h1>;
      case "Probation":
        return <h1>sdfghjk</h1>;
      default:
    }
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className="text-primaryText">Payslip Customisation</h1>
      <div>
        <section className="my-[28px] flex gap-6 border-b">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`cursor-pointer pb-2 transition-all ease ${
                    tab === i.tabName
                      ? "text-primaryBg border-b-2 border-primaryBg"
                      : "text-primaryText"
                  }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <section className="pb-[5em]">{getCorrectPage(tab)}</section>
      </div>
    </div>
  );
};

const FullTime = ({ allPaylsipTemplates ,mode, oneOrganisation,showSnackbar}) => {
  console.log("oneOrganisation",oneOrganisation  );
  const updateTheme = (id) => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/organisation/update-organisation?id=${oneOrganisation?._id}`,
        {
          organisationPaySlip: id
        }, {
          headers: {
            'api-key': apiKey,
            'Authorization': `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        window.location.reload();
        // navigate('/pay-roll');
      })
      .catch((error) => {
        console.log(error);
        // Handle the 404 error and show a snackbar
        if (error.response && error.response.status === 404) {
          showSnackbar(
            `Wait for Some time and try again.`,
            "red"
          );
        }
      });
  };
  return (
    <div className="grid grid-cols-3 gap-3">
      {allPaylsipTemplates?.map((d, i) => {
        return ( 
          <div className="flex flex-col gap-2 w-[250px]">
                  <div className="shadow-sm w-[250px] h-[380px] p-[10px] relative bg-white">
        <section className="flex justify-between items-center px-3 border-b border-[#3D4EFE]">
          <h2 className="text-[6px] font-[700]">Atomos</h2>
          <img
            // src={orgData?.organisationLogo}
            src="https://miratsfilestorage.s3.ap-south-1.amazonaws.com/Logo/Mirats.png"
            alt="logo"
            className="w-[64px] h-[28px] object-cover"
          />
        </section>
        <section
          className="h-[70px] pt-3 border-b border-[#3D4EFE] px-3"
          style={d?.paySummarySection}
        >
          <h2 className="text-[5px] text-[#333] font-[700]">
            Payslip for the month of 27/10/23
          </h2>
          <p className="text-[5px] text-[#3D4EFE]">EMPLOYEE PAY SUMMARY</p>
          <div className="text-[#333] flex justify-between">
            <section className="w-[50%]">
              <table className="text-[5px] w-full">
                <tr>
                  <td className="">Employee Name</td>
                  <td className="font-semibold">
                    {/* : {payslip?.paySlip_id?.emp_id?.emp_name} */}: Name
                  </td>
                </tr>
                <tr>
                  <td className="">Designation</td>
                  <td className="font-semibold">
                    {/* : {payslip?.paySlip_id?.emp_id?.emp_designation?.des_name} */}
                    Designation
                  </td>
                </tr>
                <tr>
                  <td className="">Date of Joining</td>
                  <td className="font-semibold">
                    {/* : {formatDate(payslip?.paySlip_id?.emp_id?.emp_joingDate)} */}
                    27/10/23
                  </td>
                </tr>
                <tr>
                  <td className="">Pay Period</td>
                  <td className="font-semibold">
                    {/* : {formatMonth(payslip?.paySlip_id?.month)} */}
                    October
                  </td>
                </tr>
                <tr>
                  <td className="">Pay Date</td>
                  <td className="font-semibold">
                    {/* :{" "}
                    {payslip?.paymentDate
                      ? payslip?.paymentDate
                      : "Payment Pending"} */}
                    : Payment Pending
                  </td>
                </tr>
              </table>
            </section>
            <section className="mr-7">
              <h2 className="text-[5px] text-center">Employee Net Pay</h2>
              <h1 className="text-[8px] font-[600]">
                {/* ₹ {payslip?.paySlip_id?.gross_Salary} */}₹ 2000000
              </h1>
            </section>
          </div>
        </section>
        <div className="">
          <table className="w-full text-[5px]">
            <thead
              className="h-[10px] text-[#3D4EFE]"
              // style={allPaylsipTemplates?.[1]?.earningsBg}
            >
              <th className="w-[50%] text-left font-[400] px-3">EARNINGS</th>
              <th className="w-[50%] text-right pr-10 font-[400]">AMOUNT</th>
            </thead>
            <tbody
              className="text-[5px] text-[#333]"
              style={d?.tableBodyBg}
            >
              <tr className="">
                <td className="px-3 font-semibold">Basic </td>
                <td className="text-right pr-10 font-semibold">
                  ₹ basic_Salary
                  {/* {payslip?.paySlip_id?.basic_Salary
                    ? payslip?.paySlip_id?.basic_Salary
                    : "-"} */}
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">House Rent Allowance </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.hra
                    ? `₹ ${payslip?.paySlip_id?.hra}`
                    : "-"} */}
                  hra
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Conveyance Allowance </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.convyance
                    ? `₹ ${payslip?.paySlip_id?.convyance}`
                    : "-"} */}
                  conveyance
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Statutory Bonus </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.statutory_bonus
                    ? `₹ ${payslip?.paySlip_id?.statutory_bonus}`
                    : "-"} */}
                  statutory_bonus
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Other Allowances </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.other_allowance
                    ? `₹ ${payslip?.paySlip_id?.other_allowance}`
                    : "-"} */}
                  other_allowance
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Wifi/Mobile Recharge </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.wifi_mobile_recharge
                    ? `₹ ${payslip?.paySlip_id?.wifi_mobile_recharge}`
                    : "-"} */}
                  wifi_mobile_recharge
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Overtime </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.overTime
                    ? `₹ ${payslip?.paySlip_id?.overTime}`
                    : "-"} */}
                  overTime
                </td>
              </tr>
              <tr>
                <td className="px-3  font-semibold">Incentive </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.incentive
                    ? `₹ ${payslip?.paySlip_id?.incentive}`
                    : "-"} */}
                  incentive
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Arrears </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.arrears
                    ? `₹ ${payslip?.paySlip_id?.arrears}`
                    : "-"} */}
                  arrears
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Bonus </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.bonus
                    ? `₹ ${payslip?.paySlip_id?.bonus}`
                    : "-"} */}
                  bonus
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="">
          <table className="w-full text-[5px]">
            <thead
              className="h-[10px] text-[#3D4EFE]"
              style={d?.earningsBg}
            >
              <th className="w-[50%] text-left font-[400] px-3">DEDUCTIONS</th>
              <th className="w-[50%] text-right pr-10 font-[400]">AMOUNT</th>
            </thead>
            <tbody
              className="text-[5px] text-[#333]"
              style={d?.tableBodyBg}
            >
              <tr className="">
                <td className="px-3 font-semibold">ESI </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.esi
                    ? `₹ ${payslip?.paySlip_id?.esi}`
                    : "-"} */}
                  esi
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">PF </td>
                <td className="text-right pr-10">
                  {" "}
                  {/* {payslip?.paySlip_id?.pf
                    ? `₹ ${payslip?.paySlip_id?.pf}`
                    : "-"} */}
                  pf
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Mediclaim Gratuity </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.mediclaim_gratuity
                    ? `₹ ${payslip?.paySlip_id?.mediclaim_gratuity}`
                    : "-"} */}
                  mediclaim_gratuity
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Half Day </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.half_day
                    ? `₹ ${payslip?.paySlip_id?.half_day}`
                    : "-"} */}
                  half_day
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Unpaid Leave </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.unpaid_leave
                    ? `₹ ${payslip?.paySlip_id?.unpaid_leave}`
                    : "-"} */}
                  unpaid_leave
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Late Login </td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.late_login
                    ? `₹ ${payslip?.paySlip_id?.late_login}`
                    : "-"} */}
                  late_login
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">TDS</td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.tds
                    ? `₹ ${payslip?.paySlip_id?.tds}`
                    : "-"} */}
                  tds
                </td>
              </tr>
              <tr>
                <td className="px-3 font-semibold">Other Deductions</td>
                <td className="text-right pr-10 font-semibold">
                  {/* {payslip?.paySlip_id?.other_deductions
                    ? `₹ ${payslip?.paySlip_id?.other_deductions}`
                    : "-"} */}
                  other_deductions
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="">
          <table className="w-full text-[5px]">
            <tbody
              className="h-[10px] text-[#3D4EFE]"
              style={d?.earningsBg}
            >
              <tr className="">
                <td className="px-3 font-semibold">
                  NET PAY (Gross Earnings - Total Deductions){" "}
                </td>
                <td className="text-right pr-10 font-semibold">
                  {/* ₹ {payslip?.paySlip_id?.gross_Salary} */}₹ ₹ gross_Salary
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <footer className="text-[#333] text-center absolute bottom-7 text-[5px]">
          <h3 className="font-semibold">End Of Payslip</h3>
          <p className="mx-10">
            This is an authenticated electronic statement. Employees are
            requested to immediately notify the accounts department of any
            discrepancy in the statement.
          </p>
          <p className="font-semibold mt-2">
            Email us at -{" "}
            <a
              href="mailto:payment_issues@miratsinsights.com"
              className="text-[#1765dc]"
            >
              payment_issues@miratsinsights.com
            </a>{" "}
          </p>
        </footer>
      </div>
      <Button
  intent="primary"
  mode={mode}
  onClick={() => updateTheme(d?._id)}// Add your click handler here
  disabled={oneOrganisation?.organisationPaySlip?._id === d?._id}
>
  select
</Button>

      </div>
        );
      })}
     
    </div>
  );
};

export default PayslipCustomisation;
