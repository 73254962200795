/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import Badge from "./components/Badge";
import Button from "./components/Button";
import { ContextThemeProvider } from "./context/ThemeContext";

// const colors = ["green", "red", "blue"];
// const modes = ["light", "dark"];

const Customisation = () => {
  // const [color, setColor] = useState(colors[0]);
  // const [mode, setMode] = useState(modes[0]);

  // const handleColorChange = (e) => {
  //   setColor(e.target.value);
  // };

  const { color, mode } = useContext(ContextThemeProvider);

  console.log("custom", mode);

  return (
    <>
      <div
      // className={[
      //   "text-center h-[500px] flex flex-col justify-center",
      //   color && `theme-${color}`,
      //   mode && `theme-${mode}`,
      // ]
      //   .filter(Boolean)
      //   .join(" ")}
      >
        <div
          className={`p-3 w-[200px] m-6 rounded text-neutralBg border border-primaryBg shadow-lg ${
            mode
              ? " text-black bg-primaryBg"
              : "bg-neutralBg text-onNeutralBg"
          }`}
        >
          lkjkljlk
        </div>
        <Button intent="secondary" mode={mode}>
          Secondary
        </Button>

        <Button intent="default" mode={mode}>
          Default
        </Button>

        <Badge intent="active" size="medium" roundness="pill">
          Paid
        </Badge>
      </div>
      <div
        className={`p-3 w-[200px] m-6 rounded text-neutralBg border border-primaryBg shadow-lg ${
          mode ? "bg-onNeutralBg text-black" : "bg-neutralBg text-onNeutralBg"
        }`}
      >
        <h1 className="text-[18px]">Total Employees</h1>
        <p
          className={`text-[24px] ${mode ? "text-primaryBg" : "text-primary"} `}
        >
          1000
        </p>
      </div>

      <div
        className={`p-3 w-[200px] m-6 rounded text-neutralBg border border-primaryBg shadow-lg ${
          mode ? "bg-onNeutralBg text-black" : "bg-neutralBg text-onNeutralBg"
        }`}
      >
        <h1 className="text-[18px]">Total Employees</h1>
        <p
          className={`text-[24px] ${mode ? "text-primaryBg" : "text-primary"} `}
        >
          Neon Blue
        </p>
      </div>
    </>
  );
};

export default Customisation;
