/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const ViewOfferLetter = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { offerLetterId } = useParams();

  // get one agreement
  const [oneUserOfferLetter, setOneUserOfferLetter] = useState();
  console.log("oneUserOfferLetter", oneUserOfferLetter);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [offerLetter, setOfferLetter] = useState({});

  console.log("offerLetter", offerLetter);

  // get one agreement
  const getOneOfferLetter = async () => {
    try {


      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetter/get-offerLetter?id=${offerLetterId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.data) {
        setOfferLetter(result.data.offerLetter_Id);
        setOneUserOfferLetter(result.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          showSnackbar(`Wait for Some time and try again.`, "red");
        } else {
          showSnackbar(`Something went wrong. Please try again.`, "red");
        }
      } else {
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  useEffect(() => {
    if (offerLetterId) {
      getOneOfferLetter();
    }
  }, [offerLetterId]);

  console.log("line 61", oneUserOfferLetter);
  console.log("line 158", offerLetter);

  // Function to format the working schedule
  function getWorkingDays(scheduleString) {
    if (!scheduleString) {
      return "";
    }
    try {
      // Remove extra spaces around the keys
      const trimmedScheduleString = scheduleString.replace(
        /\"(\w+)\":/g,
        '"$1":'
      );
      const schedule = JSON.parse(trimmedScheduleString);

      const workingDays = Object.entries(schedule)
        .filter(([day, isWorking]) => isWorking)
        .map(([day]) => capitalizeFirstLetter(day.trim()));

      return workingDays.join(", ");
    } catch (error) {
      console.error("Error parsing working schedule:", error);
      return "";
    }
  }

  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const placeholderMapping = {
    "[%%emp_name%%]": oneUserOfferLetter?.emp_name
      ? oneUserOfferLetter?.emp_name
      : "",
    "[%%department%%]": oneUserOfferLetter?.department?.dept_name
      ? oneUserOfferLetter?.department?.dept_name
      : "",
    "[%%designation%%]": oneUserOfferLetter?.designation?.des_name
      ? oneUserOfferLetter?.designation?.des_name
      : "",
    "[%%team_name%%]": oneUserOfferLetter?.emp_team?.team_name
      ? oneUserOfferLetter?.emp_team?.team_name
      : "",
    "[%%report_to%%]": oneUserOfferLetter?.reports_to
      ? oneUserOfferLetter?.reports_to
      : "",
    "[%%date_joining%%]": formatDate(
      oneUserOfferLetter?.joining_date ? oneUserOfferLetter?.joining_date : ""
    ),
    "[%%shift_time%%]": oneUserOfferLetter?.shift_time?.shift_name
      ? oneUserOfferLetter?.shift_time?.shift_name
      : "",
    "[%%working_schedule%%]": getWorkingDays(
      oneUserOfferLetter?.working_schedule
        ? oneUserOfferLetter?.working_schedule
        : ""
    ),
    "[%%gross_salary%%]": oneUserOfferLetter?.gross_salary
      ? oneUserOfferLetter?.gross_salary
      : "",
    "[%%sign_date%%]": formatDate(
      oneUserOfferLetter?.dateOfSign ? oneUserOfferLetter?.dateOfSign : ""
    ),
    "[%%pf%%]": oneUserOfferLetter?.pf_amount
      ? oneUserOfferLetter?.pf_amount
      : "",
    "[%%country%%]": oneUserOfferLetter?.country
      ? oneUserOfferLetter?.country
      : "",
    "[%%state%%]": oneUserOfferLetter?.state ? oneUserOfferLetter?.state : "",
    "[%%city%%]": oneUserOfferLetter?.city ? oneUserOfferLetter?.city : "",
    "[%%start_date%%]": formatDate(
      oneUserOfferLetter?.start_date ? oneUserOfferLetter?.start_date : ""
    ),
    "[%%end_date%%]": formatDate(
      oneUserOfferLetter?.end_date ? oneUserOfferLetter?.end_date : ""
    ),
    "[%%stipend%%]": oneUserOfferLetter?.stipend
      ? oneUserOfferLetter?.stipend
      : "",
    "[%%notice_period%%]": oneUserOfferLetter?.notice_period
      ? oneUserOfferLetter?.notice_period
      : "",
  };

  const offerLetterContent = offerLetter?.letterBody?.replace(
    /\[%%(.*?)%%\]/g,
    (match, key) => placeholderMapping[match] || ""
  );

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/offer-letter"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px] capitalize"
      >
        <MdOutlineKeyboardBackspace />
        {offerLetter?.employmentType} Offer letter Layout
      </Link>
      <div className="flex gap-4 justify-center">
        <div className="w-[60%] ">
          <section className="w-[100%] h-auto bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              <div>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>Date : {formatDate(oneUserOfferLetter?.date)}</span>
                </h1>
                <h2 className="text-[#333]">
                  {offerLetter?.offerLetterHeading}
                </h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{ __html: offerLetterContent }}
                ></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewOfferLetter;
