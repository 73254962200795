/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";
import Button from "../../components/Button";
import axios from "axios";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../../components/table/Table";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import TablePagination from "../../components/table/TablePagination";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import Avatar from "../../components/Avatar";
import { useNavigate, useParams } from "react-router";
import Badge from "../../components/Badge";
import { useSnackbar } from "../../context/SnackbarContext";
import jwt_decode from "jwt-decode";
import Modal from "../../components/Modal";
import { BsUpload } from "react-icons/bs";

const UserReimbursement = () => {
  const { color, mode, organisation, desData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const decodedToken = jwt_decode(token);

  const [designationSelect, setDesignationSelect] = useState();
  const [statusSelect, setStatusSelect] = useState();

  const [reimbursement, setReimbursement] = useState([]);
  console.log("reimbursement", reimbursement);

  // add announcements
  const [addAnnouncements, setAddAnnouncements] = useState(false);
  const openAnnouncementModal = () => {
    setAddAnnouncements((prev) => !prev);
  };

  const status = [
    {
      label: "Approved",
      value: "true",
    },
    {
      label: "Rejected",
      value: "false",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const filterData = reimbursement?.filter((blog) => {
    const departData = designationSelect
      ? blog?.emp_id?.emp_designation?.des_name === designationSelect
      : true;

    if (statusSelect === "pending") {
      return !blog?.status || blog?.status === "pending";
    } else if (statusSelect === "true") {
      return blog?.status === true;
    } else if (statusSelect === "false") {
      return blog?.status === false;
    }

    return departData;
  });

  console.log("filterData", filterData);

  // get all reimbursement
  const getUserReimbursement = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/reimbursement/get-emp-reimbursement?empId=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      setReimbursement(result?.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showSnackbar("Reimbursement data not found", "red");
      } else {
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };

  const statusReimbursement = (id, status) => {
    console.log("api approve id", id, status);
    axios
      .put(
        `${LOCALHOST_BASE_URL}/reimbursement/update-reimbursement?id=${id}`,
        {
          status: status,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getUserReimbursement();
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again.", "red");
      });
  };

  useEffect(() => {
    getUserReimbursement();
  }, []);

  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const [reimbursePageNumber, setReimbursePageNumber] = useState(1);
  const [reimburseRowsPerPage, setReimburseRowsPerPage] = useState(10);

  const [reimbursementData, setReimbursementData] = useState({});
  const [image, setImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleAnnouncementSave = async () => {
    // First, check if there is an image to upload.
    if (image) {
      try {
        // Create a FormData object and append the file
        const formData = new FormData();
        formData.append("file", image);

        // Perform the image upload
        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        // If the upload is successful, extract the image URL from the response
        if (response.ok) {
          const data = await response.json();
          console.log("Uploaded Image URL:", data.viewURl);

          // Add the image URL to the reimbursement data
          const reimbursementDataWithImage = {
            ...reimbursementData,
            bill_upload: data.viewURl,
            emp_id: decodedToken?.id,
          };
          // Make the POST request with the complete data
          await axios.post(
            `${LOCALHOST_BASE_URL}/reimbursement/add-reimbursement`,
            reimbursementDataWithImage,
            {
              headers: {
                "api-key": apiKey,
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Handle the successful announcement addition
          console.log("Announcement added successfully");
          getUserReimbursement();
          setAddAnnouncements(false); // Presumably closes a modal
        } else {
          // If the image upload is not successful, throw an error
          throw new Error("Failed to upload the image.");
        }
      } catch (error) {
        // Handle errors that occurred during the image upload or the POST request
        console.error("Error:", error);
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    } else {
      // If no image is selected, prompt the user to select one
      showSnackbar("Please select an image to upload.", "red");
    }
  };

  // delete reimbursement
  const deleteReimbursement = async (id) => {
    // Replace 'token' with your actual token key from local storage.

    try {
      axios
        .delete(
          `${LOCALHOST_BASE_URL}/reimbursement/delete-reimbursement?id=${id}`,
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          getUserReimbursement();
          console.log("Reimbursement deleted", response);
        })
        .catch((err) => {
          console.log(err);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Error deleting reimbursement:", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  //    const handlemageupload = async()=>{
  //       try {
  //    if(image){
  //      const formdata = new FormData()
  //      formdata.append("file", image)
  //       const response = await fetch(`${LOCALHOST_BASE_URL}/fileStorage/upload`, {
  //        method:"POST",
  //        body:formdata
  //       })

  //       if(response.ok){
  //         const data = await response.json()
  //         console.log(data.viewURl);
  //         handleUpload(data.viewURl);
  //       }

  //    }
  //       } catch (error) {
  //        console.log(error);
  //       }
  //    }

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Reimbursement</h1>
      <div className="my-[51px] mb-[34px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setStatusSelect(e.target.value)}
            value={statusSelect ? statusSelect : ""}
          >
            <option value="status" selected>
              Status
            </option>
            {status?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.label}
              </option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setDesignationSelect("");
              setStatusSelect("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <section className="flex flex-row-reverse items-center gap-4">
          <Button intent="primary" mode={mode} onClick={openAnnouncementModal}>
            + Add Reimbursement
          </Button>
        </section>
      </div>
      <section>
        <div className="text-primaryText">
          <TableWithPagination>
            <Table>
              <TableHead>
                <TableRow>
                  <Th>
                    <span className="pl-2">Employee Name</span>
                  </Th>
                  <Th>Designation</Th>
                  <Th>Date</Th>
                  <Th>Type</Th>
                  <Th>Total</Th>
                  <Th>Document</Th>
                  <Th>Approvel Status</Th>
                  <Th>Payment Status</Th>
                  <Th>Action</Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {reimbursement
                  ?.reverse()
                  ?.slice(
                    (reimbursePageNumber - 1) * reimburseRowsPerPage,
                    reimbursePageNumber * reimburseRowsPerPage
                  )
                  // ?.reverse()
                  ?.map((re, i) => {
                    return (
                      <TableRow key={i}>
                        <Td>
                          <div className="pl-2 flex items-center gap-1">
                            <Avatar
                              type="img"
                              src={re?.emp_id?.emp_profile}
                              size="sm"
                            />
                            <span>{re?.emp_id?.emp_name}</span>
                          </div>
                        </Td>
                        <Td>{re?.emp_id?.emp_designation?.des_name}</Td>
                        <Td>{re?.date}</Td>
                        <Td>{re?.type_reimbursement}</Td>
                        <Td>{re?.totalAmount}</Td>
                        <Td>
                          <span
                            className="cursor-pointer hover:text-primaryBg"
                            onClick={() =>
                              navigate(`/view-UserReimbursement/${re?._id}`)
                            }
                          >
                            View
                          </span>
                        </Td>
                        <Td>
                          <div className="flex items-center gap-1">
                            <Badge
                              intent={
                                re?.status === true
                                  ? "success"
                                  : re?.status === false
                                  ? "danger"
                                  : "warning"
                              }
                              size="medium"
                              roundness="pill"
                            >
                              {console.log("tst 265", re?.status)}
                              <span>
                                {re?.status === false ? (
                                  <AiOutlineClose color="#FF3838" />
                                ) : re?.status === true ? (
                                  <AiOutlineCheck color="#08A758" />
                                ) : (
                                  <TbClockExclamation color="#F9A000" />
                                )}
                              </span>
                              <span>
                                {re?.status === false
                                  ? "Rejected"
                                  : re?.status === true
                                  ? "Approved"
                                  : "Pending"}
                              </span>
                            </Badge>
                          </div>
                        </Td>
                        <Td>
                          <div className="flex items-center gap-1">
                            <Badge
                              intent={
                                re?.paymentStatus === true
                                  ? "success"
                                  : re?.paymentStatus === false
                                  ? "danger"
                                  : "warning"
                              }
                              size="medium"
                              roundness="pill"
                            >
                              <span>
                                {re?.paymentStatus === false ? (
                                  <AiOutlineClose color="#FF3838" />
                                ) : re?.paymentStatus === true ? (
                                  <AiOutlineCheck color="#08A758" />
                                ) : (
                                  <TbClockExclamation color="#F9A000" />
                                )}
                              </span>
                              <span>
                                {re?.paymentStatus === false
                                  ? "Rejected"
                                  : re?.paymentStatus === true
                                  ? "Approved"
                                  : "Pending"}
                              </span>
                            </Badge>
                          </div>
                        </Td>
                        <Td>
                          {re?.status === false ? (
                            <h2
                              className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                              onClick={() => deleteReimbursement(re?._id)}
                            >
                              <RiDeleteBin6Line color="#D20E0E" />
                            </h2>
                          ) : (
                            ""
                          )}
                        </Td>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              totalDataCnt={reimbursement?.length || 0}
              setRowsPerPage={setReimburseRowsPerPage}
              rowsPerPage={reimburseRowsPerPage}
              setPageNumber={setReimbursePageNumber}
              pageNumber={reimbursePageNumber}
            />
          </TableWithPagination>
        </div>
      </section>

      {/* Add Reimbursement modal */}
      <Modal showModal={addAnnouncements} setShowModal={setAddAnnouncements}>
        <div className="w-[347px]">
          <h1 className="text-center text-[20px] font-medium mb-6">
            Add Reimbursement
          </h1>
          <div className="flex flex-col gap-[6px] mb-4">
            <label htmlFor="holidayName" className="text-primaryTextLight">
              Type Of Reimbursement
            </label>
            <input
              type="text"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementTitle"
              onChange={(e) =>
                setReimbursementData({
                  ...reimbursementData,
                  type_reimbursement: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col gap-[6px] mb-6">
            <label htmlFor="holidayDate">Date</label>
            <input
              type="date"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementDate"
              onChange={(e) =>
                setReimbursementData({
                  ...reimbursementData,
                  date: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col gap-[6px] mb-4">
            <label htmlFor="holidayName" className="text-primaryTextLight">
              Total Amount
            </label>
            <input
              type="number"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementTitle"
              onChange={(e) =>
                setReimbursementData({
                  ...reimbursementData,
                  totalAmount: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col gap-[6px] mb-4">
            <label htmlFor="holidayName" className="text-primaryTextLight">
              Reason
            </label>
            <input
              type="text"
              className="border border-primaryTextLight px-4 py-2 text-[12px] text-secondaryTextLight rounded outline-none focus:border-primaryBg"
              id="announcementTitle"
              onChange={(e) =>
                setReimbursementData({
                  ...reimbursementData,
                  reason: e.target.value,
                })
              }
            />
          </div>
          <div class="flex w-full mb-4 flex-col">
            <label
              htmlFor="holidayName"
              className="text-primaryTextLight pb-2 "
            >
              Bill Upload
            </label>
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="text-primaryBg">
                  <BsUpload />
                </span>
                <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                  Upload Logo
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                accept="image/*"
                // onChange={(e) => {
                //   console.log("Here from Upload", e.target.files[0]);
                //   setImage(e.target.files[0]);
                // }}
                onChange={handleImageChange}
              />
            </label>
          </div>
          <div className="text-center">
            <Button
              intent="primary"
              size="large"
              mode={mode}
              onClick={handleAnnouncementSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserReimbursement;
