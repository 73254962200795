/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { GoDownload } from "react-icons/go";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import Button from "../components/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
  TableActionPopUp
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { useSnackbar } from "../context/SnackbarContext";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";



const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.toLocaleString('default', { month: 'long' });

const yearOptions = Array.from({ length: currentYear - 2018 + 1 }, (_, index) => (currentYear - index).toString());
const monthOptions = Array.from({ length: 12 }, (_, index) => {
  const date = new Date(0, index);
  return date.toLocaleString('default', { month: 'long' });
});


const Payslips = () => {
  const { color, mode, organisation, deptData, desData, orgData } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [departmentSelect, setDepartmentSelect] = useState();
  const [designationSelect, setDesignationSelect] = useState();
  let [tab, setTab] = useState("Full Time Employee");


  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const tabData = [
    {
      id: 1,
      tabName: "Full Time Employee",
      tabValue: "fulltime",
    },
    {
      id: 2,
      tabName: "Intern Employee",
      tabValue: "intern",
    },
    {
      id: 3,
      tabName: "Probation Employee",
      tabValue: "probation",
    },
  ];

  const getTodayDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const monthName = months[today.getMonth()];
    const year = today.getFullYear();

    return `${year}-${monthName}`;
  };

  // Usage
  const todayDate = getTodayDate();
  console.log(todayDate);


  const [allPaySlip, setAllPaySlip] = useState();
  const getAllPaySlip = async () => {
    const combinedValue = `${selectedYear}-${selectedMonth}`;

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/payslip/get-all-payslip?month=${combinedValue !== "-" ? combinedValue : todayDate}`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );

      if (result.data.success === false) {
        // Handle the case where there's an error message
        console.log(result.data.message); // or display an error message to the user
        showSnackbar(`Something went wrong. Please try again.`, "red");
      } else {
        // Set the payroll data
        setAllPaySlip(result.data?.filteredPaySlips);
      }
    } catch (error) {
      // Handle any other errors
      console.error(error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  useEffect(() => {
    getAllPaySlip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth]);

  console.log("organisation", orgData);



  const filterPaySlipsByTabValue = (data, tab) => {
    if (tab === "Full Time Employee") {
      return data?.filter(
        (paySlip) => paySlip?.paySlip_id?.emp_id?.emp_type === "fulltime"
      );
    } else if (tab === "Intern Employee") {
      return data?.filter(
        (paySlip) => paySlip.paySlip_id.emp_id.emp_type === "intern"
      );
    } else if (tab === "Probation Employee") {
      return data?.filter(
        (paySlip) => paySlip.paySlip_id.emp_id.emp_type === "probation"
      );
    } else {
      return data; // Return all data if tab value doesn't match any category
    }
  };


  const filterData = allPaySlip?.filter((blog) => {

    console.log("Filtering data blog- ", blog);
    const departmentData = departmentSelect ? blog?.paySlip_id?.emp_id?.emp_department?.dept_name === departmentSelect : true;
    const designationData = designationSelect ? blog?.paySlip_id?.emp_id?.emp_designation?.des_name === designationSelect : true;
    return departmentData && designationData;
  });

  // Replace with the selected tab value
  const filteredData = filterPaySlipsByTabValue(filterData, tab);

  console.log("filter Data:", filterData);

  console.log("Filtered Data:", filteredData);



  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Full Time Employee":
        return <FullTimeEmployee orgData={orgData} getAllPaySlip={getAllPaySlip} filteredData={filteredData} mode={mode} showSnackbar={showSnackbar} />;
      case "Intern Employee":
        return <InternEmployee orgData={orgData} getAllPaySlip={getAllPaySlip} filteredData={filteredData} mode={mode} showSnackbar={showSnackbar} />;
      case "Probation Employee":
        return <ProbationEmployee orgData={orgData} getAllPaySlip={getAllPaySlip} filteredData={filteredData} mode={mode} showSnackbar={showSnackbar} />;
      default:
    }
  };



  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
        } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Payslips</h1>
      <section className="flex gap-4 items-center mt-[33px] mb-[41px]">
        <select
          name="dept"
          id="dept"
          className={`text-center py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect : ""}
        >
          <option value="dept" selected>
            Department
          </option>
          {deptData?.map((emp) => (
            <option key={emp?.dept_name} value={emp?.dept_name}>
              {emp?.dept_name}
            </option>
          ))}
        </select>
        <select
          name="designation"
          id="designation"
          className={`text-center py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          onChange={(e) => setDesignationSelect(e.target.value)}
          value={designationSelect ? designationSelect : ""}
        >
          <option value="designation" selected>
            Designation
          </option>
          {desData?.map((emp) => (
            <option key={emp?.dept_name} value={emp?.des_name}>
              {emp?.des_name}
            </option>
          ))}
        </select>
        <select
          name="month"
          id="month"
          className={`text-center py-2 text-[12px] w-[92px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          value={selectedMonth} onChange={handleMonthChange}
        >
          <option value="month" selected >
            Month
          </option>
          {monthOptions.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          id="year"
          className={`text-center py-2 text-[12px] w-[92px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          value={selectedYear} onChange={handleYearChange}
        >
          <option value="year" selected >
            Year
          </option>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
          {/* {generateYearOptions()} */}
        </select>
        <button className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect('');
            setDesignationSelect('');
            setSelectedYear('');
            setSelectedMonth('');
          }}
        >
          Clear Filter
        </button>
      </section>
      <div>
        <section className="flex gap-5 items-center border-b">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`pb-2 text-[14px] cursor-pointer ${tab === i.tabName
                      ? "text-primaryBg border-b border-primaryBg"
                      : "text-primaryText"
                    }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <section className="my-[33px]">{getCorrectPage(tab)}</section>
      </div>
    </div>
  );
};

const FullTimeEmployee = ({ filteredData, mode, showSnackbar, getAllPaySlip, orgData }) => {
  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = useState([]);
  const headerCheckboxRef = useRef();
  function getCheckedRowIds() {
    return selectedRows
      .filter((row) => row._id) // Filter out rows without _id
      .map((row) => row._id);
  }
  const checkedRowIds = getCheckedRowIds();

  const sentPaySlipByEmail = async (ids) => {

    try {
      for (const id of ids) {
        await axios.post(
          `${LOCALHOST_BASE_URL}/employee/sendSalarySlipEmail`,
          {
            paySlipId: id,
            orgId: orgData?._id
          },
          {
            headers: {
              'api-key': apiKey,
              'Authorization': `Bearer ${token}`,
            },
          }
        );
      }
      getAllPaySlip();
      showSnackbar(`Payslip is created successfully`, "green");
    } catch (error) {
      showSnackbar(`Email already Sent For PaySlip`, "red");
      console.log("error", error);
    }
  };

  return (
    <div className="text-primaryText">
      <TableWithPagination>
        <TableActionPopUp selectedRows={selectedRows}>
          {selectedRows?.length > 0 ? (
            <div className="flex gap-2">
              <Button
                intent="primary"
                mode={mode}
                onClick={() => sentPaySlipByEmail(checkedRowIds)}
              >
                Sent Email
              </Button>
            </div>
          ) : ""}
        </TableActionPopUp>
        <Table>
          <TableHead>
            <TableRow>
              <th className="px-1 py-2">
                <input
                  type="checkbox"
                  ref={headerCheckboxRef}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedRows(filteredData);
                    } else {
                      setSelectedRows([]);
                    }
                  }}
                  value
                  checked={selectedRows?.length === filteredData?.length}
                />
              </th>
              <Th>Employee Name</Th>
              <Th>Designation</Th>
              <Th>Department</Th>
              <Th>Email Sent Date</Th>
              <Th>Sent Email</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              // ?.reverse()
              ?.slice(
                (ftPageNumber - 1) * ftRowPerPage,
                ftPageNumber * ftRowPerPage
              )
              ?.map((data, i) => {
                return (
                  <TableRow key={i}>
                    <td
                      className={`py-[10px] px-1 primaryText font-[400] bg-transparent  text-[14px]`}
                    >
                      <input
                        type="checkbox"
                        checked={selectedRows?.filter((r) => r._id === data._id)[0]}
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            setSelectedRows((prev) => [...prev, data]);

                          } else {
                            // setSelectedRows((prev) => [...prev, data]);
                            setSelectedRows((prev) => prev.filter(d => d !== data))
                          }

                          // Update the checked state of the header checkbox accordingly.
                          headerCheckboxRef.current.checked =
                            selectedRows?.length === filteredData?.length;
                        }}
                      />
                    </td>
                    <Td>
                      <div className="flex items-center gap-2">
                        {data?.paySlip_id?.emp_id?.emp_profile ? (
                          <Avatar
                            type="img"
                            src={data?.paySlip_id?.emp_id?.emp_profile}
                            size="md"
                          />
                        ) : (
                          <Avatar size="md" type="text">
                            {data?.paySlip_id?.emp_id?.emp_name?.slice(0, 1)}
                          </Avatar>
                        )}
                        <Link
                          to={`/view-payslip?payslipId=${data?._id}`}
                          // "/view-payslip?paySlip_id={data?._id}"
                          className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                        >
                          {data?.paySlip_id?.emp_id?.emp_name}
                        </Link>
                      </div>
                    </Td>
                    <Td>
                      {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                    </Td>
                    <Td>
                      {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                    </Td>
                    <Td>{data?.paymentDate ? data?.paymentDate : "-"}</Td>
                    <Td>
                      <Button
                        intent="primary"
                        mode={mode}
                        onClick={() => sentPaySlipByEmail(checkedRowIds)} 
                        disabled={data?.emailSentStatus === true}
                      >
                        Sent Email
                      </Button>
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={filteredData?.length || 0}
          setRowsPerPage={setFtRowPerPage}
          rowsPerPage={ftRowPerPage}
          setPageNumber={setFtPageNumber}
          pageNumber={ftPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const InternEmployee = ({ filteredData, mode, showSnackbar, getAllPaySlip, orgData }) => {
  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = useState([]);
  const headerCheckboxRef = useRef();
  function getCheckedRowIds() {
    return selectedRows
      .filter((row) => row._id) // Filter out rows without _id
      .map((row) => row._id);
  }
  const checkedRowIds = getCheckedRowIds();

  const sentPaySlipByEmail = async (ids) => {

    try {
      for (const id of ids) {
        await axios.post(
          `${LOCALHOST_BASE_URL}/employee/sendSalarySlipEmail`,
          {
            paySlipId: id,
            orgId: orgData?._id
          },
          {
            headers: {
              'api-key': apiKey,
              'Authorization': `Bearer ${token}`,
            },
          }
        );
      }
      getAllPaySlip();
      showSnackbar(`Payslip is created successfully`, "green");
    } catch (error) {
      showSnackbar(`Email already Sent For PaySlip`, "red");
      console.log("error", error);
    }
  };


  return (
    <div className="text-primaryText">
    <TableWithPagination>
      <TableActionPopUp selectedRows={selectedRows}>
        {selectedRows?.length > 0 ? (
          <div className="flex gap-2">
            <Button
              intent="primary"
              mode={mode}
              onClick={() => sentPaySlipByEmail(checkedRowIds)}
            >
              Sent Email
            </Button>
          </div>
        ) : ""}
      </TableActionPopUp>
      <Table>
        <TableHead>
          <TableRow>
            <th className="px-1 py-2">
              <input
                type="checkbox"
                ref={headerCheckboxRef}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRows(filteredData);
                  } else {
                    setSelectedRows([]);
                  }
                }}
                value
                checked={selectedRows?.length === filteredData?.length}
              />
            </th>
            <Th>Employee Name</Th>
            <Th>Designation</Th>
            <Th>Department</Th>
            <Th>Email Sent Date</Th>
            <Th>Sent Email</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            // ?.reverse()
            ?.slice(
              (ftPageNumber - 1) * ftRowPerPage,
              ftPageNumber * ftRowPerPage
            )
            ?.map((data, i) => {
              return (
                <TableRow key={i}>
                  <td
                    className={`py-[10px] px-1 primaryText font-[400] bg-transparent  text-[14px]`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedRows?.filter((r) => r._id === data._id)[0]}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setSelectedRows((prev) => [...prev, data]);

                        } else {
                          // setSelectedRows((prev) => [...prev, data]);
                          setSelectedRows((prev) => prev.filter(d => d !== data))
                        }

                        // Update the checked state of the header checkbox accordingly.
                        headerCheckboxRef.current.checked =
                          selectedRows?.length === filteredData?.length;
                      }}
                    />
                  </td>
                  <Td>
                    <div className="flex items-center gap-2">
                      {data?.paySlip_id?.emp_id?.emp_profile ? (
                        <Avatar
                          type="img"
                          src={data?.paySlip_id?.emp_id?.emp_profile}
                          size="md"
                        />
                      ) : (
                        <Avatar size="md" type="text">
                          {data?.paySlip_id?.emp_id?.emp_name?.slice(0, 1)}
                        </Avatar>
                      )}
                      <Link
                        to={`/view-payslip?payslipId=${data?._id}`}
                        // "/view-payslip?paySlip_id={data?._id}"
                        className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                      >
                        {data?.paySlip_id?.emp_id?.emp_name}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                  </Td>
                  <Td>
                    {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                  </Td>
                  <Td>{data?.paymentDate ? data?.paymentDate : "-"}</Td>
                  <Td>
                    <Button
                      intent="primary"
                      mode={mode}
                      onClick={() => sentPaySlipByEmail(checkedRowIds)} 
                      disabled={data?.emailSentStatus === true}
                    >
                      Sent Email
                    </Button>
                  </Td>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        totalDataCnt={filteredData?.length || 0}
        setRowsPerPage={setFtRowPerPage}
        rowsPerPage={ftRowPerPage}
        setPageNumber={setFtPageNumber}
        pageNumber={ftPageNumber}
      />
    </TableWithPagination>
  </div>
  );
};

const ProbationEmployee = ({ filteredData, mode, showSnackbar, getAllPaySlip, orgData }) => {
  const [ftPageNumber, setFtPageNumber] = useState(1);
  const [ftRowPerPage, setFtRowPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = useState([]);
  const headerCheckboxRef = useRef();
  function getCheckedRowIds() {
    return selectedRows
      .filter((row) => row._id) // Filter out rows without _id
      .map((row) => row._id);
  }
  const checkedRowIds = getCheckedRowIds();

  const sentPaySlipByEmail = async (ids) => {
  
    try {
      for (const id of ids) {
        await axios.post(
          `${LOCALHOST_BASE_URL}/employee/sendSalarySlipEmail`,
          {
            paySlipId: id,
            orgId: orgData?._id
          },
          {
            headers: {
              'api-key': apiKey,
              'Authorization': `Bearer ${token}`,
            },
          }
        );
      }
      getAllPaySlip();
      showSnackbar(`Payslip is created successfully`, "green");
    } catch (error) {
      showSnackbar(`Email already Sent For PaySlip`, "red");
      console.log("error", error);
    }
  };


  return (
    <div className="text-primaryText">
    <TableWithPagination>
      <TableActionPopUp selectedRows={selectedRows}>
        {selectedRows?.length > 0 ? (
          <div className="flex gap-2">
            <Button
              intent="primary"
              mode={mode}
              onClick={() => sentPaySlipByEmail(checkedRowIds)}
            >
              Sent Email
            </Button>
          </div>
        ) : ""}
      </TableActionPopUp>
      <Table>
        <TableHead>
          <TableRow>
            <th className="px-1 py-2">
              <input
                type="checkbox"
                ref={headerCheckboxRef}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRows(filteredData);
                  } else {
                    setSelectedRows([]);
                  }
                }}
                value
                checked={selectedRows?.length === filteredData?.length}
              />
            </th>
            <Th>Employee Name</Th>
            <Th>Designation</Th>
            <Th>Department</Th>
            <Th>Email Sent Date</Th>
            <Th>Sent Email</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            // ?.reverse()
            ?.slice(
              (ftPageNumber - 1) * ftRowPerPage,
              ftPageNumber * ftRowPerPage
            )
            ?.map((data, i) => {
              return (
                <TableRow key={i}>
                  <td
                    className={`py-[10px] px-1 primaryText font-[400] bg-transparent  text-[14px]`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedRows?.filter((r) => r._id === data._id)[0]}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setSelectedRows((prev) => [...prev, data]);

                        } else {
                          // setSelectedRows((prev) => [...prev, data]);
                          setSelectedRows((prev) => prev.filter(d => d !== data))
                        }

                        // Update the checked state of the header checkbox accordingly.
                        headerCheckboxRef.current.checked =
                          selectedRows?.length === filteredData?.length;
                      }}
                    />
                  </td>
                  <Td>
                    <div className="flex items-center gap-2">
                      {data?.paySlip_id?.emp_id?.emp_profile ? (
                        <Avatar
                          type="img"
                          src={data?.paySlip_id?.emp_id?.emp_profile}
                          size="md"
                        />
                      ) : (
                        <Avatar size="md" type="text">
                          {data?.paySlip_id?.emp_id?.emp_name?.slice(0, 1)}
                        </Avatar>
                      )}
                      <Link
                        to={`/view-payslip?payslipId=${data?._id}`}
                        // "/view-payslip?paySlip_id={data?._id}"
                        className="text-primaryText text-[14px] border-b hover:text-primaryBg"
                      >
                        {data?.paySlip_id?.emp_id?.emp_name}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                  </Td>
                  <Td>
                    {data?.paySlip_id?.emp_id?.emp_designation?.des_name}
                  </Td>
                  <Td>{data?.paymentDate ? data?.paymentDate : "-"}</Td>
                  <Td>
                    <Button
                      intent="primary"
                      mode={mode}
                      onClick={() => sentPaySlipByEmail(checkedRowIds)} 
                      disabled={data?.emailSentStatus === true}
                    >
                      Sent Email
                    </Button>
                  </Td>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        totalDataCnt={filteredData?.length || 0}
        setRowsPerPage={setFtRowPerPage}
        rowsPerPage={ftRowPerPage}
        setPageNumber={setFtPageNumber}
        pageNumber={ftPageNumber}
      />
    </TableWithPagination>
  </div>
  );
};

export default Payslips;
