/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ThemeContext from "./context/ThemeContext";
import Context from "./context/Context";
import SnackbarContextProvider from "./context/SnackbarContext.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <SnackbarContextProvider>
        <ThemeContext>
        <App />
        </ThemeContext>
     </SnackbarContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
