/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect,useState } from "react";
import { ContextThemeProvider } from "./context/ThemeContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Customisation from "./Customisation";
import TestDashboard from "./TestDashboard.jsx";
import Test from "./pages/Test.jsx";
import Button from "../src/components/Button";
import Dashboard from "./pages/Dashboard";
import MainContainer from "./pages/MainContainer";
import RegisterEmployee from "./components/RegisterEmployee";
import DailyReport from "./pages/DailyReport";
import Employee from "./pages/Employee";
import EmployeeDetails from "./pages/EmployeeDetails";
import Attendance from "./pages/Attendance";
import Department from "./pages/Department";
import Team from "./pages/Teams";
import Leave from "./pages/Leave";
import Designation from "./pages/Designation";
import Timesheet from "./pages/Timesheet";
import TimesheetEmpDetails from "./components/TimesheetEmpDetails";
import ShiftAndSchedule from "./pages/ShiftAndSchedule";
import Termination from "./pages/Termination";
import ViewTermination from "./pages/ViewTermination";
import Resignation from "./pages/Resignation";
import ViewResignation from "./pages/ViewResignation";
import Announcements from "./pages/Announcements";
import Payslips from "./pages/Payslips";
import ViewPayslips from "./pages/ViewPayslips";
import Layoffs from "./pages/Layoffs";
import Agreements from "./pages/Agreements";
import PayRoll from "./pages/payroll";
import CreatePayRoll from "./components/CreatePayRoll";
import CreateAgreement from "./pages/CreateAgreement";
import CreateOfferLetterTmp from "./pages/CreateOfferLetterTmp";
import PaymentDetails from "./pages/paymentDetails";
import LeaveCustomisation from "./pages/LeaveCustomisation";
import ViewAgreement from "./pages/ViewAgreement";
import Policies from "./pages/Policies";
import CreatePolicies from "./pages/CreatePolicies";
import Members from "./pages/Members";
import Benefits from "./pages/Benefits";
import CreateBenefits from "./pages/CreateBenefits";
import Reimbursement from "./pages/Reimbursement";
import Login from "./pages/Login";
import VerifyCation from "./pages/VerifyCation";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordRecoverySuccessful from "./pages/PasswordRecoverySuccessful";
import ViewReimbursement from "./pages/ViewReimbursement";
import ExpandableTable from "./components/ExpandableTable";
import OrganisationSetting from "./pages/OrganisationSetting";
import Theme from "./pages/Theme";
import OfferLetter from "./pages/OfferLetter";
import PayslipCustomisation from "./pages/PayslipCustomisation";
import CreateOfferrLetter from "./pages/CreateOfferrLetter";
import ViewOfferLetterTmp from "./pages/ViewOfferLetterTmp";
import ViewOfferLetter from "./pages/ViewOfferLetter";
import ViewBenefit from "./pages/ViewBenefit";
import ViewLayoff from "./pages/ViewLayoff";
import ViewEmpAgreement from "./pages/ViewEmpAgreement";
import jwt_decode from "jwt-decode";
import UserNav from "./pages/UserSide/UserNav";
import OvertimeUser from "./pages/UserSide/OvertimeUser";
import AttendanceUser from "./pages/UserSide/AttendanceUser";
import UserDashboard from "./pages/UserSide/UserDashboard";
import PolicyUser from "./pages/UserSide/PolicyUser";
import ViewUserPolicie from "./pages/UserSide/ViewUserPolicie";
import ThemeUser from "./pages/UserSide/ThemeUser";
import UserLeave from "./pages/UserSide/UserLeave";
import UserPayslip from "./pages/UserSide/UserPayslip";
import ViewUserPayslip from "./pages/UserSide/ViewUserPayslip";
import UserAssets from "./pages/UserSide/UserAssets";
import ViewUserAsset from "./pages/UserSide/ViewUserAsset";
import UserResignation from "./pages/UserSide/UserResignation";
import ResignationReasons from "./pages/UserSide/ResignationReasons";
import UserDocument from "./pages/UserSide/UserDocument";
import UserBenefits from "./pages/UserSide/UserBenefits";
import ViewUserBenefit from "./pages/UserSide/ViewUserBenefit";
import UserReimbursement from "./pages/UserSide/UserReimbursement";
import UserViewReimbursement from "./pages/UserSide/UserViewReimbursement";
import UserDetails from "./pages/UserSide/UserDetails";
import ViewPolicy from "./pages/ViewPolicy";
import LoadingSpinner from "./components/LoadingSpinner";
import EmployeeNotFound from "./components/EmployeeNotFound";
import { getAuth, getCookie, isLoggedIn, signIn,setCookie } from "@mirats/mirats-auth";
import { LOCALHOST_BASE_URL } from "./utils/config.js";
import axios from "axios";
import Cookies from "js-cookie";

const ProtectedRoute = ({ adminOnly, children }) => {
  const { user, loading } = isLoggedIn();
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const navigate = useNavigate();

  if (user && !loading) {
    let token = getCookie("frontendCookie");

    if (!getCookie("tokenpeoples")) {
      const decodedToken = jwt_decode(token);

      const loginData = {
        user_id: decodedToken?.user,
      };

      axios
        .post(`${LOCALHOST_BASE_URL}/employee/login`, loginData)
        .then((response) => {
          const tokenpeoples = response?.data?.Token;
          setCookie("tokenpeoples", tokenpeoples);
          console.error("tokenpeoples: ", tokenpeoples);
          navigate(`/Udashboard`);
          window.location.reload();
          if (tokenpeoples) {
            try {
              const decodedToken = jwt_decode(tokenpeoples);
              const userRole = decodedToken.role;

              if (adminOnly && userRole !== "admin") {
                navigate("/Udashboard");
              }

              return userRole === "admin" || !adminOnly ? (
                children
              ) : (
                <Navigate to="/unauthorized" replace />
              );
            } catch (error) {
              console.error("Error decoding the token: ", error);
            }
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
          // Handle errors as needed
        });
    } else {
      // "tokenpeoples" cookie already exists, no need to refresh
      // You may add additional checks or handling if needed
    }

    return children;
  } else {
    signIn(window.location.origin);
  }
};


 function App() {
  const { color, mode } = useContext(ContextThemeProvider);
  const getUser = async () => {
    let user = await getAuth();
    console.log("app 178",user);
  };
  
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div
      className={["", color && `theme-${color}`, mode && `theme-${mode}`]
        .filter(Boolean)
        .join(" ")}
    >
      <Router>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserDashboard />
                  </UserNav>
                </ProtectedRoute>
              }
            />
             <Route
              path="/Udashboard"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserDashboard />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route path="/unauthorized" element={
              <LoadingSpinner />
            } />
             <Route path="/employee-not-found" element={
              <EmployeeNotFound />
            } />
            <Route path="/verifyEmail" element={<VerifyCation />} />
            <Route
              path="/password-Recovery-Successfull"
              element={<PasswordRecoverySuccessful />}
            />
            
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Dashboard />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/register-employee"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <RegisterEmployee />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-OfferrLetter/:tempId/:offerrLetterId?"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateOfferrLetter />
                  </MainContainer>
                </ProtectedRoute>
              }
            />

            <Route
              path="/daily-report"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <DailyReport />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/employees"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Employee />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/department"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Department />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/team"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Team />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/employee-details/:empId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <EmployeeDetails />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/attendance"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Attendance />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/leave"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Leave />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/designation"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Designation />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/timesheet"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Timesheet />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/timesheet-details/:employeeId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <TimesheetEmpDetails />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/shift-and-schedule"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ShiftAndSchedule />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/termination"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Termination />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-termination/:terminationId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewTermination />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/resignation"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Resignation />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/pay-roll"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <PayRoll />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-pay-roll"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreatePayRoll />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/paymentDetails"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <PaymentDetails />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-resignation/:resignationId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewResignation />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/announcements"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Announcements />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/lay-off"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Layoffs />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-layoffs/:layoffId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewLayoff />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/payslip"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Payslips />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-payslip"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewPayslips />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/agreements"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Agreements />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-agreement"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateAgreement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-agreement/:agreementId?"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateAgreement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-agreement/:agreementId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewAgreement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-emp-agreement/:agreementId/:selectedEmpAgreementId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewEmpAgreement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />

            <Route
              path="/create-CreateOfferLetterTmp/:offerLetterTmpId?"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateOfferLetterTmp />
                  </MainContainer>
                </ProtectedRoute>
              }
            />

            <Route
              path="/leave-customisation"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <LeaveCustomisation />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/policies"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Policies />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-policies/:policyId?"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreatePolicies />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-policy/:policyId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewPolicy />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/members"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Members />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/benefits"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Benefits />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-benefits"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateBenefits />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-benefits/:benefitId?"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <CreateBenefits />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-benefit/:benefitId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewBenefit />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/reimbursement"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Reimbursement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-reimbursement/:reimbursementId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewReimbursement />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/organisation-setting"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <OrganisationSetting />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/theme"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <Theme />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/offer-letter"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <OfferLetter />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/payslip-customisation"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <PayslipCustomisation />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/ViewOfferLetterTmp/:offerLetterTmpId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewOfferLetterTmp />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/ViewOfferLetter/:offerLetterId"
              element={
                <ProtectedRoute adminOnly={true}>
                  <MainContainer>
                    <ViewOfferLetter />
                  </MainContainer>
                </ProtectedRoute>
              }
            />
            <Route
              path="/over-time"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <OvertimeUser />
                  </UserNav>
                </ProtectedRoute>
              }
            />

            <Route
              path="/user-leave"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserLeave />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-attendance"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <AttendanceUser />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-pay-slip"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserPayslip />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-user-payslip/:payslipId"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ViewUserPayslip />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-policies"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <PolicyUser />
                  </UserNav>
                </ProtectedRoute>
              }
            />

            <Route
              path="/viewUserPolicies/:policyId"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ViewUserPolicie />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-assets"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserAssets />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-user-asset/:agreementId/:selectedEmpAgreementId"
              // path="/view-emp-agreement/:agreementId/:selectedEmpAgreementId"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ViewUserAsset />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-resignation"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserResignation />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-resignation-reasons"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ResignationReasons />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-theme"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ThemeUser />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/documents"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserDocument />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-benefits"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserBenefits />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-details"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserDetails />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-benefit/:benefitId"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <ViewUserBenefit />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-reimbursement"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserReimbursement />
                  </UserNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-UserReimbursement/:reimbursementId"
              element={
                <ProtectedRoute adminOnly={false}>
                  <UserNav>
                    <UserViewReimbursement />
                  </UserNav>
                </ProtectedRoute>
              }
            />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
