/* eslint-disable no-unused-vars */
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import { ContextThemeProvider } from "../../context/ThemeContext";
import resignation from "../../assets/resignation.svg";

const UserResignation = () => {
  const { color, mode, organisation, deptData, orgData } =
    useContext(ContextThemeProvider);

  const navigate = useNavigate();

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText mb-[32px]`}>Resignation</h1>
      <p className="text-primaryBg text-[20px] mb-5">
        {orgData?.organisationName}
      </p>
      <div className="text-primaryText">
        <h3 className="mb-1">It's sad to see you go</h3>
        <p className="w-[60%] mb-8">
          If you've already made up your mind, please fill out the form and
          answer a few questions. This will allow us to send your request to the
          HR team.
        </p>
        <Button
          intent="primary"
          mode={mode}
          onClick={() => navigate(`/user-resignation-reasons`)}
        >
          Proceed
        </Button>
      </div>
      <section className="flex justify-center">
        <img
          // src="https://peoples.miratsoneservices.com/static/media/newResign.356e0f27cd36208419b4dfccebd3c7dd.svg"
          src={resignation}
          alt=""
        />
      </section>
    </div>
  );
};

export default UserResignation;
