/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { RiDeleteBin6Line, RiLock2Line } from "react-icons/ri";
import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Badge from "../../components/Badge";
import { BsCalendar } from "react-icons/bs";
import axios from "axios";
import { LOCALHOST_BASE_URL, token, apiKey } from "../../utils/config";
import { useSnackbar } from "../../context/SnackbarContext";
import jwt_decode from "jwt-decode";
import { getGreetMsg } from "../../utils/utils";

const ThemeUser = () => {
  const {
    mode,
    theme,
    color,
    setColor,
    setMode,
    organisation,
    changeColor,
    setChangeColor,
    getOrganisation,
  } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  console.log("color", color);

  const decodedToken = jwt_decode(token);

  const handleColorChange = (e) => {
    setColor(e.target.value);
    setChangeColor(true);
  };

  console.log(mode);

  useEffect(() => {
    setMode(organisation?.organisationTheme?.name);
  }, [organisation?.organisationTheme?.name]);

  console.log("mode", organisation?.organisationTheme);

  // ["", (organisation?.themeColor) && `theme-${organisation?.themeColor}`, mode && `theme-${  organisation?.name}`]

  console.log(
    organisation?.organisationTheme?.name === "dark" && mode === "dark"
  );

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-primaryLight"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Theme</h1>

      <div>
        <section className="h-full">
          {" "}
          <EnterprisePlan
            mode={mode}
            setMode={setMode}
            theme={theme}
            color={color}
            setColor={setColor}
            handleColorChange={handleColorChange}
            organisation={organisation}
            changeColor={changeColor}
            showSnackbar={showSnackbar}
            decodedToken={decodedToken}
          />
        </section>
      </div>
    </div>
  );
};

const EnterprisePlan = ({
  mode,
  setMode,
  theme,
  color,
  setColor,
  handleColorChange,
  organisation,
  handleUpdateTheme,
  changeColor,
  showSnackbar,
  decodedToken,
}) => {
  const orgId = organisation?._id;
  const organisationThemeID = organisation?.organisationTheme?._id;
  const themeColor = organisation?.themeColor;
  console.log("line 103", organisation?.organisationTheme?._id);

  const [changeTheme, setChangeTheme] = useState({
    organisationTheme: organisationThemeID,
    themeColor: themeColor,
  });

  console.log("changeTheme", changeTheme);

  const updateTheme = () => {
    axios
      .put(
        `${LOCALHOST_BASE_URL}/employee/update-OtherEmployee?id=${decodedToken?.id}`,
        {
          organisationTheme: changeTheme?.organisationTheme
            ? changeTheme?.organisationTheme
            : organisationThemeID,
          themeColor: changeTheme?.themeColor ? changeTheme?.themeColor : "",
        },
        {
          headers: {
            "api-key": apiKey,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        window.location.reload();
        // navigate('/pay-roll');
      })
      .catch((error) => {
        console.log(error);
        // Handle the 404 error and show a snackbar
        if (error.response && error.response.status === 404) {
          showSnackbar(`Wait for Some time and try again.`, "red");
        }
      });
  };

  console.log("line 659", changeTheme);

  return (
    <div>
      <div className="flex gap-5">
        <section className="w-[75%] border shadow-sm rounded">
          <div
            // className={`py-[29px]  px-4 ${
            //   organisation?.organisationTheme?.name === "dark"
            //     ? "bg-secondaryDark"
            //     : "bg-primaryLight"
            // } `}
            className={
              `py-[29px] px-4 ${
                organisation?.organisationTheme?.name === "dark" &&
                mode === "dark"
                  ? "bg-secondaryDark"
                  : "bg-primaryLight"
              } ${
                organisation?.organisationTheme?.name === "dark" &&
                mode === "dark"
                  ? "bg-secondaryDark"
                  : [
                      "",
                      (color || organisation?.themeColor) &&
                        `theme-${
                          changeColor ? color : organisation?.themeColor
                        }`,
                      organisation?.organisationTheme?.name &&
                        `theme-${
                          changeColor
                            ? "light"
                            : organisation?.organisationTheme?.name
                        }`,
                    ]
                      .filter(Boolean)
                      .join(" ")
              }`
              // organisation?.organisationTheme?.name === "dark" &&
              // mode === "dark"
              //   ? "bg-secondaryDark"
              //   : [
              //       "",
              //       (color || organisation?.themeColor) &&
              //         `theme-${
              //           changeColor ? color : organisation?.themeColor
              //         }`,
              //       organisation?.organisationTheme?.name &&
              //         `theme-${
              //           changeColor
              //             ? "light"
              //             : organisation?.organisationTheme?.name
              //         }`,
              //     ]
              //       .filter(Boolean)
              //       .join(" ")
            }
          >
            <section className={`flex justify-between items-center`}>
              <h1 className={`text-[20px]`}>
                {" "}
                <span
                  className={`font-[500] ${
                    organisation?.organisationTheme?.name === "dark" &&
                    mode === "dark"
                      ? "text-primaryTextDark"
                      : "text-primaryTextLight"
                  }`}
                >
                  {getGreetMsg()}’
                </span>{" "}
                <span className="text-primaryBg font-[600]">Badden Kherr</span>
              </h1>
              <div className="flex items-center gap-4">
                <Button intent="primary" mode={mode}>
                  Register Employee
                </Button>
                <Button intent="secondary" mode={mode}>
                  Add Announcement
                </Button>
              </div>
            </section>

            <div className="py-[34px] grid grid-cols-3 gap-[45px]">
              <section
                className={`py-[20px] px-4 rounded-lg flex gap-5 ${
                  organisation?.organisationTheme?.name === "dark" &&
                  mode === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                } `}
              >
                <span
                  className={`${
                    organisation?.organisationTheme?.name === "dark" &&
                    mode === "dark"
                      ? "bg-primaryDark"
                      : "bg-primaryLight"
                  } w-[56px] h-[56px] flex justify-center items-center rounded-full`}
                >
                  <BsCalendar
                    color={
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "white"
                        : "black"
                    }
                  />
                </span>
                <div>
                  <span className={`text-[14px] text-[#A3AED0]`}>
                    cardHeader
                  </span>
                  <h1
                    className={`mt-2 text-[24px] ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    } `}
                  >
                    cardBody
                  </h1>
                </div>
              </section>
            </div>

            <div className="flex gap-4 mb-[57px] w-full">
              <section
                className={`w-[70%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
                  organisation?.organisationTheme?.name === "dark" &&
                  mode === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <h1 className={`flex justify-between items-center pr-[31px]`}>
                  <span
                    className={`text-[18px] font-[700] ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    Daily Report
                  </span>
                  <Link
                    to="/daily-report"
                    className={`text-[12px] flex items-center  ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-primaryBg"
                    }`}
                  >
                    <span>View All</span>
                    <MdKeyboardArrowRight />
                  </Link>
                </h1>
                <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
                  <table className="border-collapse text-left w-full text-[14px]">
                    <thead
                      className={`h-[60px] font-medium ${
                        organisation?.organisationTheme?.name === "dark" &&
                        mode === "dark"
                          ? "text-[#A3AED0]"
                          : "text-[#A3AED0]"
                      } `}
                    >
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Check in</th>
                        <th>Check out</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`font-medium ${
                          organisation?.organisationTheme?.name === "dark" &&
                          mode === "dark"
                            ? "text-primaryTextDark"
                            : "text-[#2B3674]"
                        } `}
                      >
                        <td className="py-2">employeeName</td>
                        <td>date</td>
                        <td>punchIn</td>
                        <td>punchOut</td>
                        <td>productionTime</td>
                        <td>
                          <Badge
                            intent="success"
                            size="medium"
                            roundness="pill"
                          >
                            status
                          </Badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section
                className={`w-[30%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
                  organisation?.organisationTheme?.name === "dark" &&
                  mode === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <h1
                  className={`flex justify-between items-center mb-4 pr-[31px] `}
                >
                  <span
                    className={`text-[18px] font-[700] ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    Holidays
                  </span>
                  <div
                    className={`p-2 rounded cursor-pointer hover:bg-gray-100 ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "bg-secondaryDark"
                        : "bg-primaryLight"
                    } `}
                  >
                    <AiFillPlusCircle color="var(--primaryBg)" />
                  </div>
                </h1>
                <div className="scrollContainer overflow-y-auto h-[250px] pr-[31px]">
                  <section
                    className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg  ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "bg-primaryDark"
                        : "bg-white"
                    }`}
                  >
                    <div
                      className={`w-[50px] h-[50px] rounded-full flex items-center justify-center flex-col text-[14px] font-[700] ${
                        organisation?.organisationTheme?.name === "dark" &&
                        mode === "dark"
                          ? "bg-secondaryDark text-primaryTextDark"
                          : "bg-primaryLight text-primaryBg"
                      }`}
                    >
                      20/11/23
                    </div>
                    <p
                      className={`font-[700] ${
                        organisation?.organisationTheme?.name === "dark" &&
                        mode === "dark"
                          ? "text-primaryTextDark"
                          : "text-[#2B3674]"
                      }`}
                    >
                      holiday_name
                    </p>
                    <RiDeleteBin6Line color="red" />
                  </section>
                </div>
              </section>
            </div>

            <div className="flex gap-4">
              <section
                className={`w-[35%] h-[345px] pl-[23px] py-[19px] rounded-lg ${
                  organisation?.organisationTheme?.name === "dark" &&
                  mode === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <h1
                  className={`flex justify-between items-center mb-4 pr-[23px] `}
                >
                  <span
                    className={`text-[18px] font-[700] ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    Birthdays / Work Anniversary
                  </span>
                </h1>
                <div className="scrollContainer overflow-y-auto h-[250px] pr-[23px]">
                  <section
                    className={`flex items-center gap-7 px-4 py-3 mb-4 shadow-md rounded-lg ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "bg-primaryDark"
                        : "bg-white"
                    }`}
                  >
                    <Avatar
                      type="img"
                      src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                      size="md"
                    />
                    <div>
                      <h3
                        className={`font-[700] ${
                          organisation?.organisationTheme?.name === "dark" &&
                          mode === "dark"
                            ? "text-primaryTextDark"
                            : "text-[#2B3674]"
                        }`}
                      >
                        emp_name
                      </h3>
                      <h3
                        className={`font-[500] text-[12px] ${
                          organisation?.organisationTheme?.name === "dark" &&
                          mode === "dark"
                            ? "text-primaryTextDark"
                            : "text-[#A3AED0]"
                        }`}
                      >
                        occasion
                      </h3>
                    </div>
                  </section>
                </div>
              </section>
              <section
                className={`w-[65%] h-[345px] pl-[31px] py-[19px] rounded-lg ${
                  organisation?.organisationTheme?.name === "dark" &&
                  mode === "dark"
                    ? "bg-primaryDark"
                    : "bg-white"
                }`}
              >
                <h1 className={`flex justify-between items-center pr-[31px]`}>
                  <span
                    className={`text-[18px] font-[700] ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-[#2B3674]"
                    }`}
                  >
                    Leave
                  </span>
                  <Link
                    to="/leave"
                    className={`text-[12px] flex items-center  ${
                      organisation?.organisationTheme?.name === "dark" &&
                      mode === "dark"
                        ? "text-primaryTextDark"
                        : "text-primaryBg"
                    }`}
                  >
                    <span>View All</span>
                    <MdKeyboardArrowRight />
                  </Link>
                </h1>
                <div className="scrollContainer overflow-y-auto h-[280px] pr-[31px]">
                  <table className="border-collapse text-left w-full text-[14px]">
                    <thead
                      className={`h-[60px] font-medium ${
                        organisation?.organisationTheme?.name === "dark"
                          ? "text-[#A3AED0]"
                          : "text-[#A3AED0]"
                      } `}
                    >
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`font-medium ${
                          organisation?.organisationTheme?.name === "dark"
                            ? "text-primaryTextDark"
                            : "text-[#2B3674]"
                        } `}
                      >
                        <td className="py-2">emp_name</td>
                        <td>date</td>
                        <td>duration day</td>
                        <td>
                          <span className="capitalize">sick</span>
                        </td>
                        <td>
                          <p className="capitalize flex items-center gap-1">
                            approved
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
        <section className="w-[25%] p-[21px] border shadow-sm rounded">
          <h2 className="text-secondaryText text-[18px] pb-1 border-b">
            Select Theme
          </h2>
          {console.log("theme page", theme)}

          {mode === "dark" ? (
            <p className="text-primaryText py-6 text-[14px] capitalize">
              You're in dark mode, enable light mode to select theme
            </p>
          ) : (
            <div className="py-6">
              {/* <input type="color" id="favcolor" name="favcolor" value="#3D4EFE" disabled /> */}
              {theme?.map((t) => (
                <section>
                  {t?.colors?.map((c) => (
                    <div className="bg-primaryLight">
                      {/* {console.log("c", c)} */}
                      <label
                        key={c}
                        className={`${
                          color === c
                            ? "checked:text-onPrimaryBg ui-checked:bg-primaryBg ring-4 ui-checked:ring-primary ui-not-checked:ring-onNeutralBg"
                            : ""
                        } w-full justify-center items-center flex-wrap font-bold uppercase cursor-pointer text-[12px] text-primaryBg`}
                      >
                        <input
                          type="radio"
                          value={c}
                          checked={color === c}
                          onChange={(e) => {
                            setChangeTheme({
                              ...changeTheme,
                              themeColor: e.target.value,
                            });
                            handleColorChange(e);
                          }}
                          className="hidden"
                        />

                        {c}
                      </label>
                    </div>
                  ))}
                </section>
              ))}
            </div>
          )}

          <div className="py-6 border-t border-b">
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked={mode === "dark" ? true : false}
                onChange={(e) => {
                  console.log(
                    "Checkbox value organisationTheme:",
                    e.target.checked
                  );
                  console.log("organisation Theme:", theme);

                  // Update changeTheme state based on the checkbox value
                  setChangeTheme({
                    organisationTheme: e.target.checked
                      ? theme[1]._id // "light" theme
                      : theme[0]._id, // "dark" theme
                  });

                  // Update mode state based on the checkbox value
                  setMode(e.target.checked ? "dark" : "light");
                }}
              />

              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-medium text-primaryText">
                Enable Dark Mode
              </span>
            </label>
          </div>
        </section>
      </div>
      <section className="my-6 text-center flex gap-4 items-center justify-center">
        <Button intent="secondary" mode={mode}>
          Clear
        </Button>
        <Button intent="primary" mode={mode} onClick={() => updateTheme()}>
          Save
        </Button>
      </section>
    </div>
  );
};

export default ThemeUser;
