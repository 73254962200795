/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { BsFillGridFill } from "react-icons/bs";
import { PiListFill } from "react-icons/pi";
import { TbClockExclamation } from "react-icons/tb";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL } from "../../utils/config";
import Avatar from "../Avatar";
import Badge from "../Badge";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../table/Table";
import TablePagination from "../table/TablePagination";

const EmployeeLeave = ({employeeInfo}) => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const [tab, setTab] = useState(1);

  const tabData = [
    {
      id: 1,
      tabIcon: <PiListFill color={tab === 1 ? "white" : "black"} />,
    },
    {
      id: 2,
      tabIcon: <BsFillGridFill color={tab === 2 ? "white" : "black"} />,
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case 1:
        return (
          <EmployeeLeaveTable
            employeeLeave={employeeInfo?.employee}
            formatDate={formatDate}
            mode={mode}
            organisation={organisation}
          />
        );
      case 2:
        return (
          <EmployeeLeaveCards
            employeeLeave={employeeInfo?.employee}
            mode={mode}
            formatDate={formatDate}
            organisation={organisation}
          />
        );
      default:
    }
  };

  return (
    <div
      className={`h-full py-[29px] ${
        organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Leave</h1>
      <div className="my-[32px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="dept"
            id="dept"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[122px] ${
              organisation?.organisationTheme
?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="dept" selected disabled>
              Department
            </option>
          </select>
          <select
            name="month"
            id="month"
            className={`py-2 px-2 text-[12px] rounded outline-none  focus:border-primaryBg w-[92px]  ${
              organisation?.organisationTheme
?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="month" selected disabled>
              Month
            </option>
          </select>
          <select
            name="year"
            id="year"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme
?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="year" selected disabled>
              Year
            </option>
          </select>
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme
?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
          >
            <option value="status" selected disabled>
              Status
            </option>
          </select>
          <button className={`text-primaryBg border-b border-primaryBg`}>
            Clear Filter
          </button>
        </section>
        <section className="flex flex-row-reverse items-center gap-4">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`px-2 py-2 rounded cursor-pointer ${
                    tab === i.id ? "bg-primaryBg" : ""
                  }`}
                  onClick={() => {
                    setTab(i.id);
                  }}
                >
                  {i.tabIcon}
                </h3>
              </>
            );
          })}
        </section>
      </div>

      <div>{getCorrectPage(tab)}</div>
    </div>
  );
};

const EmployeeLeaveTable = ({ employeeLeave, formatDate,organisation }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  console.log("employeeLeave?.emp_leave",employeeLeave?.emp_leave);
  return (
    <div className="overflow-auto text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th><span className="pl-4">Type</span></Th>
              <Th>From - To</Th>
              <Th>Duration</Th>
              <Th>Reason</Th>
              <Th>Status</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeLeave?.emp_leave
              ?.reverse()
              ?.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage)
              ?.map((l, i) => {
                return (
                  <TableRow key={i}>
                    <Td><span className="pl-4">{l?.leave_type?.leave_type}</span></Td>
                    <Td>
                      {formatDate(l?.from_date)} -{" "}
                      {formatDate(l?.to_date)}
                    </Td>
                    <Td>{l?.duration}</Td>
                    <Td>{l?.reason}</Td>
                    <Td>
                      {l?.leave_status === "approved" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <AiOutlineCheck color="#08A758" />
                          {l?.leave_status}
                        </p>
                      ) : l?.leave_status === "pending" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <TbClockExclamation color="#F9A000" />
                          {l?.leave_status}
                        </p>
                      ) : l?.leave_status === "reject" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <AiOutlineClose color="#FF3838" />
                          {l?.leave_status}
                        </p>
                      ) : (
                        ""
                      )}
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={employeeLeave?.length || 0}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const EmployeeLeaveCards = ({ employeeLeave, formatDate, mode,organisation }) => {
  return (
    <div className="grid grid-cols-4 gap-[26px]">
      {employeeLeave?.emp_leave?.reverse()?.map((l) => (
        <section
          className={`h-[230px] shadow-md rounded py-3 cardShadow ${
            organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center mb-4">
            <h2 className="text-primaryText">
              {l?.leave_type?.leave_type} Request
            </h2>
            <Badge
              intent={
                l?.leave_status === "approved"
                  ? "success"
                  : l?.leave_status === "reject"
                  ? "danger"
                  : l?.leave_status === "pending"
                  ? "warning"
                  : ""
              }
              size="small"
              roundness="pill"
            >
              <span className="capitalize">{l?.leave_status}</span>
            </Badge>
          </div>
         
          <div
            className={`py-[14px] text-center ${
              organisation?.organisationTheme
?.name === "dark" ? "bg-[#2A2E3D]" : "bg-primaryLight"
            }`}
          >
            <h2 className="text-primaryBg font-semibold mb-1">
              {l?.duration} Day
            </h2>
            <h2 className="text-primaryText text-[14px]">
              {formatDate(l?.from_date)}
              {" - "}
              {formatDate(l?.to_date)}
            </h2>
          </div>
          <div className="px-4 mt-2">
            <h3 className="text-secondaryText">Reason:</h3>
            <p className="h-[70px] text-[12px] text-primaryText font-medium">
              {l?.reason}
            </p>
          </div>
        </section>
      ))}
    </div>
  );
};

export default EmployeeLeave;
