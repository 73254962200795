/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
/* eslint-disable no-unused-vars */
// import { useContext } from "react";
import { ContextThemeProvider } from "../context/ThemeContext";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import { LOCALHOST_BASE_URL, apiKey, token } from "../utils/config";
import axios from "axios";
import { FiEdit2 } from "react-icons/fi";
import { useSnackbar } from "../context/SnackbarContext";
import { Link } from "react-router-dom";

const Policies = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { showSnackbar } = useSnackbar();
  const getAllPolices = () => {
    try {
      axios
        .get(`${LOCALHOST_BASE_URL}/policies/get-all-policies`, {
          headers: {
            "api-key": apiKey,
          },
        })
        .then((response) => {
          if (response) {
            console.log("Here is the Data", response?.data?.getAll);
            setData(response?.data?.getAll);
          }
        })
        .catch((error) => {
          console.log("Here is the Error from then-catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  useEffect(() => {
    getAllPolices();
  }, []);

  const handleDeletePolicies = (id) => {
    try {
      console.log("Here inside the Delete", id);
      axios
        .put(`${LOCALHOST_BASE_URL}/policies/delete-policies?id=${id}`, null, {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response) {
            getAllPolices();
          }
        })
        .catch((error) => {
          console.log("Here is the Error from then-catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Here is the Error from try-Catch", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  const handleEditPolicies = (id) => {
    try {
      const body = data.find((ele) => ele._id === id);
      axios
        .put(
          `${LOCALHOST_BASE_URL}/policies/update-policies?id=${id}`,
          {
            data: body,
          },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log("Here is the Data", response.data);
            getAllPolices();
          }
        })
        .catch((error) => {
          console.log("Here is the Error from then-catch", error);
          showSnackbar("Something went wrong. Please try again.", "red");
        });
    } catch (error) {
      console.log("Here is the Error", error);
      showSnackbar("Something went wrong. Please try again.", "red");
    }
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <div className="flex justify-between gap-4 items-center">
        <h1 className={`text-[32px] text-primaryText`}>Policies </h1>
        <Button
          intent="primary"
          mode={mode}
          onClick={() => navigate(`/create-policies`)}
        >
          Add New Policy
        </Button>
      </div>

      {/* card */}
      <div className="grid grid-cols-3 gap-[26px] my-[54px]">
        {data?.reverse()?.map((d) => (
          <section
            className={`w-[342px] h-[230px] shadow-md rounded py-3 cardShadow ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            {/* <h2 className="text-primaryText px-4 font-semibold text-[20px]">
              {d?.headline} <Link to={`/view-policy/${d?._id}`}>View</Link>
            </h2> */}
            <div className="flex justify-between h-[60px]  pr-3 line-clamp-2">
              <h2 className="text-primaryText px-4   font-semibold text-[20px]">
                {d?.headline}
              </h2>
              <h2
                className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                onClick={() => navigate(`/create-policies/${d._id}`)}
              >
                <FiEdit2 color="var(--primaryText)" />
              </h2>
            </div>
            <p className="h-[95px] text-[12px]  my-2 px-4 overflow-hidden text-primaryText">
              <div
                className="h-[100px]  "
                dangerouslySetInnerHTML={{ __html: d?.body }}
              ></div>
            </p>
            <div className="flex justify-center items-center gap-4">
              <Button
                intent="secondary"
                mode={mode}
                onClick={() => navigate(`/view-policy/${d?._id}`)}
              >
                View
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={(e) => handleDeletePolicies(d._id)}
              >
                Delete
              </Button>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default Policies;
