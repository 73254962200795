/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
/* eslint-disable no-unused-vars */
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey} from "../../utils/config";
import { useSnackbar } from "../../context/SnackbarContext";


const ViewUserPolicies = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { policyId } = useParams();

  // get one agreement
  const [onePolicy, setOnePolicy] = useState();


  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [policyData, setPolicyData] = useState({
    variableTempBody: [
      {
        variableName: "",
        variableValue: "",
      },
    ],
    headline: "",
    date: "",
    body: "",
  });

  const [variables, setVariables] = useState([
    {
      variableName: "",
      variableValue: "",
    },
  ]);

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const data = [...policyData?.variableTempBody];
    data[i][name] = value;
    data[i].variableValue = `%%${value}%%`; // Update variableValue to match variableName
    setPolicyData((prev) => ({ ...prev, variableTempBody: data }));
  };

  // const handleOnChange = (name, value) => {
  //   console.log("Here is the date", value)
  //   setPolicyData((prev) => ({ ...prev, [name]: value }));
  //   policyData.edit?  setOnePolicy((prev) => ({ ...prev, [name]: value })): setOnePolicy({})
  // };
  const handleOnChange = (name, value) => {
    console.log("Here is the date", value)
    setPolicyData((prev) => ({ ...prev, [name]: value }));
    // setOnePolicy((prev) => ({ ...prev, [name]: value }))
  };




  

  // get one Policy
  const getSinglePolicy = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/policies/get-policies?id=${policyId}`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );
      console.log(result);
      if (result) {
        setPolicyData(result?.data?.getPolicies);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };
  

  

  // add pages
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(0);
  console.log("activePage", activePage);

  const addNewPage = () => {
    const newPage = {
      headline: policyData?.headline,
      date: policyData?.date,
      body: policyData?.body,
      variableTempBody: policyData?.variableTempBody,
    };
    setPages([...pages, newPage]);
  };

  const deletePage = (index) => {
    const updatedPages = [...pages];
    updatedPages.splice(index, 1);
    setPages(updatedPages);
  };

  useEffect(() => {
    const initialPage = {
      variableTempBody: [
        {
          variableName: "",
          variableValue: "",
        },
      ],
      headline: "",
      date: "",
      body: "",
    };
    setPages([initialPage]);
  }, []);

  useEffect(() => {
    if (policyId) {
      getSinglePolicy();
    }
  }, [policyId]);

  console.log("policyData?.body--->", policyData);



  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/user-policies"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px]"
      >
        <MdOutlineKeyboardBackspace />
       {policyData?.headline}
      </Link>
      <div className="flex justify-center ">
        <div className="w-[60%]">
          <section className="w-[100%] h-[842px] bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              {pages.slice(activePage, activePage + 1).map((page, index) => (
                <div key={index}>
                  {/* {console.log("page", page)} */}
                  <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                    <span>{page.companyName || policyData.companyName}</span>
                    <span>
                      Date : {formatDate(page.date || policyData.date)}
                    </span>
                  </h1>
                  <h2 className="text-[#333]">
                    {page.headline || policyData.headline}
                  </h2>
                  <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                  <p>{policyData?.headline}</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: policyData?.body }}
                  ></div>
                </div>
              ))}
            </div>
          </section>

        </div>
      </div>
    
    </div>
  );
};

export default ViewUserPolicies;
