/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Avatar from "../components/Avatar";
import Badge from "../components/Badge";
import CustomTable from "../components/CustomTable";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey} from "../utils/config";

import { useSnackbar } from "../context/SnackbarContext";


const tabData = [
  {
    id: 1,
    tabName: "Daily Report",
  },
  {
    id: 2,
    tabName: "Overtime",
  },
];

const DailyReport = () => {
  const { color, mode, getDepartments, organisation,deptData } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const months = [
    { label: "January", value: "jan" },
    { label: "February", value: "feb" },
    { label: "March", value: "march" },
    { label: "April", value: "apr" },
    { label: "May", value: "may" },
    { label: "June", value: "june" },
    { label: "July", value: "july" },
    { label: "August", value: "aug" },
    { label: "September", value: "sept" },
    { label: "October", value: "oct" },
    { label: "November", value: "nov" },
    { label: "December", value: "dec" },
  ];


  let [tab, setTab] = useState("Daily Report");
  const getCorrectPage = (tab) => {
    switch (tab) {
      case "Daily Report":
        return (
          <DailyReportTable
            setYear={setYear}
            year={year}
            setMonth={setMonth}
            month={month}
            mode={mode}
            getDepartments= {getDepartments}
            organisation={organisation}
            showSnackbar={showSnackbar}
            deptData={deptData}
          />
        );
      case "Overtime":
        return <Overtime organisation={organisation} showSnackbar={showSnackbar} deptData={deptData}/>;
      default:
    }
  };


  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${organisation?.organisationTheme
?.name === "dark" ? "bg-secondaryDark" : "bg-white"
        } `}
    >
      <h1
        className={`text-[32px] mb-[46px] ${organisation?.organisationTheme
?.name === "dark" ? "text-primaryTextDark" : "text-primaryTextLight"
          }`}
      >
        Daily Report
      </h1>

      {/* tabs */}
      <div>
        <section className="flex items-center gap-9">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`text-[20px] cursor-pointer ${tab === i.tabName
                      ? "border-b-2 border-primaryBg text-primaryBg"
                      : "text-primaryText"
                    }`}
                  onClick={() => {
                    setTab(i.tabName);
                  }}
                >
                  {i.tabName}
                </h3>
              </>
            );
          })}
        </section>
        <div className="">{getCorrectPage(tab)}</div>
      </div>
    </div>
  );
};

const DailyReportTable = ({ month, setMonth, setYear, year, mode ,getDepartments, organisation,showSnackbar,deptData}) => {
  const [dailyReport, setDeliyReport] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();
  const [dailyAttendance, setDailyAttendance] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(pageSizeOptions[0].value);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [todaysDate, setTodaysDate] = useState();
  const getTodayDate = () => {
    const today = new Date();

    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    getTodayDate()
  });

  const getDailyAttendance = async () => {
  
  
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/attendance/getDailyAttendance?date=${todaysDate}`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );
  
      if (result?.data?.success === false) {
        // Handle any specific error response from the API
        console.error("API returned an error: ", result?.data?.message);
        showSnackbar("API error: " + result?.data?.message, "red");
      } else {
        setDailyAttendance(result?.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Today data is not available.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };
  

  useEffect(() => {
    if (todaysDate) {
      getDailyAttendance();
    }
  }, [todaysDate]);

  // console.log(attendance);

  // Object?.entries(attendance)?.map(([key, value]) => {
  //   console.log(value);
  // });

  const formatTime = (timestamp) => {
    if (!timestamp || timestamp === "-") {
      return "-";
    }

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format
    };
    const formattedTime = new Date(timestamp).toLocaleTimeString([], options);
    return formattedTime;
  };

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const filterData = dailyAttendance?.filter((blog) => {
    const autherData = departmentSelect ? blog?.employeeDepartment === departmentSelect : true;
    return autherData;
  });

  return (
    <div>
      <div className="my-[32px] flex gap-4 items-center">
      <select
          name="dept"
          id="dept"
          className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect:""}
        >
          <option value="dept" selected disable>
            Select Department
          </option>
          {deptData?.map((emp) => (
            <option key={emp?.value} value={emp?.value}>
              {emp?.dept_name}
            </option>
          ))}
        </select>
        <button className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect('');}}
        >
          Clear Filter
        </button>
      </div>
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th><p className="pl-2">Employee Name</p></Th>
              <Th>Department</Th>
              <Th>Date</Th>
              <Th>Shift Time</Th>
              <Th>Check in</Th>
              <Th>Check Out</Th>
              <Th>Duration</Th>
              <Th>Status</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData
              ?.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage)
              .map((d, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2 pl-2">
                        {d?.employeeProfileUrl ? (
                          <Avatar
                            type="img"
                            src={d?.employeeProfileUrl}
                            size="md"
                          />
                        ) : (
                          <Avatar size="md" type="text">
                            MD
                          </Avatar>
                        )}

                        <div className="">
                          <h4 className="text-primaryText text-[14px]">
                            {d?.employeeName}
                          </h4>
                          <h4 className="text-primaryText text-[10px]">
                            {d?.employeeDesignation}
                          </h4>
                        </div>
                      </div>
                    </Td>
                    <Td>{d?.employeeDepartment}</Td>
                    <Td>{formatDate(d?.date)}</Td>
                    <Td>{d?.employeeShift}</Td>
                    <Td>{formatTime(d?.punchIn)}</Td>
                    <Td>{formatTime(d?.punchOut)}</Td>
                    <Td>{d?.productionTime ? d?.productionTime : "-"}</Td>
                    <Td>
                      <Badge
                        intent={
                          d?.punchInStatus === "On time" ? "success" : "warning"
                        }
                        size="large"
                        roundness="pill"
                      >
                        {d?.punchInStatus}
                      </Badge>
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={dailyAttendance?.length || 0}
          // itemsPerPageArray={pageSizeOptions}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const Overtime = ({ mode, organisation,showSnackbar,deptData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [otPageNumber, setOtPageNumber] = useState(1);
  const [otRowsPerPage, setOtRowsPerPage] = useState(10);
  const [departmentSelect, setDepartmentSelect] = useState();

  const [overtime, setOvertime] = useState();

  const [todaysDate, setTodaysDate] = useState();
  const getTodayDate = () => {
    const today = new Date();

    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with '0' if needed

    // Combine them in "yyyy-mm-dd" format
    const formattedDate = `${year}-${month}-${day}`;
    setTodaysDate(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    getTodayDate();
  });

  const getOvertime = async () => {
  
  
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/overtime/get-all-overtime?date=${todaysDate}`,
        {
          headers: {
            'api-key': apiKey,
          },
        }
      );
  
      if (result?.data?.success === false) {
        // Handle any specific error response from the API
        console.error("API returned an error: ", result?.data?.message);
        showSnackbar("API error: " + result?.data?.message, "red");
      } else {
        setOvertime(result.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error
        console.error("Resource not found");
        showSnackbar("Wait for Some time and try again.", "red");
      } else {
        // Handle other errors
        console.error("Something went wrong");
        showSnackbar("Something went wrong. Please try again.", "red");
      }
    }
  };
  

  useEffect(() => {
    getOvertime();
  }, [todaysDate]);

  const filterData = overtime?.filter((blog) => {
    const autherData = departmentSelect ? blog?.employeeDepartment === departmentSelect : true;
    return autherData;
  });

  // console.log("overtime", overtime);

  return (
    <div>
      <div className="my-[32px] flex gap-4 items-center">
        <select
          name="dept"
          id="dept"
          className={`py-2 text-[12px] rounded outline-none focus:border-primaryBg  ${organisation?.organisationTheme
?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setDepartmentSelect(e.target.value)}
          value={departmentSelect ? departmentSelect:""}
        >
          <option value="dept" selected disable>
            Select Department
          </option>
          {deptData?.map((emp) => (
            <option key={emp?.value} value={emp?.value}>
              {emp?.dept_name}
            </option>
          ))}
        </select>
        <button className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDepartmentSelect('');}}
        >
          Clear Filter
        </button>
      </div>

      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th>Employee Name</Th>
              <Th>Department</Th>
              <Th>Date</Th>
              <Th>Overtime</Th>
              <Th>End Overtime</Th>
              <Th>Overtime Duration</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData
              ?.slice(
                (otPageNumber - 1) * otRowsPerPage,
                otPageNumber * otRowsPerPage
              )
              .map((d, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2">
                        {d?.employeeProfileUrl ? (
                          <Avatar
                            type="img"
                            src={d?.employeeProfileUrl}
                            size="md"
                          />
                        ) : (
                          <Avatar size="md" type="text">
                            MD
                          </Avatar>
                        )}

                        <div className="">
                          <h4 className="text-primaryText text-[14px]">
                            {d?.employeeName}
                          </h4>
                          <h4 className="text-primaryText text-[10px]">
                            {d?.employeeDesignation}
                          </h4>
                        </div>
                      </div>
                    </Td>
                    <Td>{d?.employeeDepartment}</Td>
                    <Td>{d?.date}</Td>
                    <Td>{d?.punchIn}</Td>
                    <Td>{d?.punchOut}</Td>
                    <Td>{d?.productionTime}</Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={overtime?.length || 0}
          // itemsPerPageArray={pageSizeOptions}
          setRowsPerPage={setOtRowsPerPage}
          rowsPerPage={otRowsPerPage}
          setPageNumber={setOtPageNumber}
          pageNumber={otPageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

export default DailyReport;
