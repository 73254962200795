/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { BsFillGridFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbClockExclamation } from "react-icons/tb";
import { PiListFill } from "react-icons/pi";
import Avatar from "../components/Avatar";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import Badge from "../components/Badge";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { LOCALHOST_BASE_URL , apiKey , token } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const Leave = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [leave, setLeave] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();
  const [statusSelect, setStatusSelect] = useState();
  // console.log("leave", leave);

  // accept leave
  const [acceptLeave, setAcceptLeave] = useState(false);
  const openAcceptLeave = () => {
    setAcceptLeave((prev) => !prev);
  };

  const status = [
    {
      label: "Approve",
      value: "approved",
    },
    {
      label: "Reject",
      value: "reject",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  // reject leave
  const [rejectLeave, setRejectLeave] = useState(false);
  const openRejectLeave = () => {
    setRejectLeave((prev) => !prev);
  };

  const getLeaves = async () => {
  
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/leave/getAllLeave`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setLeave(result?.data);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  const deleteLeaves = async (empid, leaveId) => {

    axios
      .post(
        `${LOCALHOST_BASE_URL}/employee/deleteEmpLeave`,
        {
          employeeId: empid,
          leaveId: leaveId,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getLeaves();
        showSnackbar("Leaves Rejected Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  useEffect(() => {
    getLeaves();
  }, []);

  const filterData = leave?.filter((blog) => {
    const departData = departmentSelect
      ? blog?.emp_department === departmentSelect
      : true;
    const statusData = statusSelect
      ? blog?.emp_leave?.leave_status === statusSelect
      : true;
    // const categoryData = categorySelect
    //   ? blog?.category === categorySelect
    //   : true;
    // return categoryData && autherData;
    return departData && statusData;
  });

  const [tab, setTab] = useState(1);

  const tabData = [
    {
      id: 1,
      tabIcon: <PiListFill color={tab === 1 ? "white" : "black"} />,
    },
    {
      id: 2,
      tabIcon: <BsFillGridFill color={tab === 2 ? "white" : "black"} />,
    },
  ];

  const getCorrectPage = (tab) => {
    switch (tab) {
      case 1:
        return (
          <LeaveTable
            leave={filterData}
            formatDate={formatDate}
            openAcceptLeave={openAcceptLeave}
            openRejectLeave={openRejectLeave}
            organisation={organisation}
            getLeaves={getLeaves}
            deleteLeaves={deleteLeaves}
          />
        );
      case 2:
        return (
          <LeaveCards
            leave={filterData}
            formatDate={formatDate}
            mode={mode}
            organisation={organisation}
            deleteLeaves={deleteLeaves}
          />
        );
      default:
    }
  };

  // formatted date
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1 className={`text-[32px] text-primaryText`}>Leave</h1>
      <div className="my-[32px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="dept"
            id="dept"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setDepartmentSelect(e.target.value)}
            value={departmentSelect ? departmentSelect : ""}
          >
            <option value="dept" selected>
              Department
            </option>
            {deptData?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.dept_name}
              </option>
            ))}
          </select>
          <select
            name="status"
            id="status"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            onChange={(e) => setStatusSelect(e.target.value)}
            value={statusSelect ? statusSelect : ""}
          >
            <option value="status" selected>
              Status
            </option>
            {status?.map((emp) => (
              <option key={emp?.value} value={emp?.value}>
                {emp?.label}
              </option>
            ))}
          </select>
          <button
            className={`text-primaryBg border-b border-primaryBg`}
            onClick={() => {
              setDepartmentSelect("");
              setStatusSelect("");
            }}
          >
            Clear Filter
          </button>
        </section>
        <section className="flex flex-row-reverse items-center gap-4">
          {tabData.map((i) => {
            return (
              <>
                <h3
                  className={`px-2 py-2 rounded cursor-pointer ${
                    tab === i.id ? "bg-primaryBg" : ""
                  }`}
                  onClick={() => {
                    setTab(i.id);
                  }}
                >
                  {i.tabIcon}
                </h3>
              </>
            );
          })}
        </section>
      </div>

      <div>{getCorrectPage(tab)}</div>

      {/* accept leave modal */}
      <Modal showModal={acceptLeave} setShowModal={setAcceptLeave}>
        <div className="w-[342px] text-center">
          <p className="mt-2 mb-8">
            Are you sure, you want to Approve This request ?{" "}
          </p>
          <div className="flex gap-4 items-center justify-center">
            <button
              className={`border border-primaryBg font-[500] text-[12px] px-[32px] py-[8px] rounded text-primaryBg`}
            >
              Cancel
            </button>
            <Button intent="primary" mode={mode}>
              Approve
            </Button>
          </div>
        </div>
      </Modal>

      {/* reject leave modal */}
      <Modal showModal={rejectLeave} setShowModal={setRejectLeave}>
        <div className="w-[342px] text-center">
          <p className="mt-2 mb-8">
            Are you sure, you want to Reject This request ?{" "}
          </p>
          <div className="flex gap-4 items-center justify-center">
            <button
              className={`border border-primaryBg font-[500] text-[12px] px-[32px] py-[8px] rounded text-primaryBg`}
            >
              Cancel
            </button>
            <button
              className={`bg-[#D6562D] font-[500] text-[12px] px-[32px] py-[8px] rounded border text-white`}
            >
              Reject
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const LeaveTable = ({
  leave,
  formatDate,
  openAcceptLeave,
  openRejectLeave,
  organisation,
  getLeaves
}) => {
  const { showSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const openAcceptPayroll = (empid, leaveId) => {
  

    axios
      .post(
        `${LOCALHOST_BASE_URL}/employee/updateLeaveStatus`,
        {
          employeeId: empid,
          leaveId: leaveId,
          leave_status: "approved",
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getLeaves();
        showSnackbar("Leaves Accepted Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  const openRejectPayroll = (empid, leaveId) => {
  
    
    axios
      .post(
        `${LOCALHOST_BASE_URL}/employee/updateLeaveStatus`,
        {
          employeeId: empid,
          leaveId: leaveId,
          leave_status: "reject",
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getLeaves();
        showSnackbar("Leaves Rejected Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  const deleteLeaves = async (empid, leaveId) => {
  


    axios
      .post(
        `${LOCALHOST_BASE_URL}/employee/deleteEmpLeave`,
        {
          employeeId: empid,
          leaveId: leaveId,
        },
        {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Response data:", response.data);
        getLeaves();
        showSnackbar("Leaves Rejected Successfully", "green");
      })
      .catch((error) => {
        console.log(error);
        showSnackbar("Something went wrong. Please try again", "red");
      });
  };

  return (
    <div className="overflow-auto text-primaryText">
      <TableWithPagination>
        <Table>
          <TableHead>
            <TableRow>
              <Th><p className="pl-2">Employee Name</p></Th>
              <Th>Department</Th>
              <Th>Type</Th>
              <Th>From - To</Th>
              <Th>Duration</Th>
              <Th>Reason</Th>
              <Th>Status</Th>
              <Th>Approval</Th>
              <Th>Action</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {leave
              ?.reverse()
              ?.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage)
              ?.map((l, i) => {
                return (
                  <TableRow key={i}>
                    <Td>
                      <div className="flex items-center gap-2 pl-2">
                        {l?.emp_profile ? (
                          <Avatar type="img" src={l?.emp_profile} size="md" />
                        ) : (
                          <Avatar size="md" type="text">
                            {l?.emp_name?.slice(0, 1)}
                          </Avatar>
                        )}

                        <div className="">
                          <h4 className="text-primaryText text-[14px]">
                            {l?.emp_name}
                          </h4>
                          <h4 className="text-primaryText text-[10px]">
                            {l?.emp_designation}
                          </h4>
                        </div>
                      </div>
                    </Td>
                    <Td>{l?.emp_department}</Td>
                    <Td>{l?.emp_leave?.leave_type}</Td>
                    <Td>
                      {formatDate(l?.emp_leave?.from_date)} -{" "}
                      {formatDate(l?.emp_leave?.to_date)}
                    </Td>
                    <Td>{l?.emp_leave?.duration}</Td>
                    <Td>{l?.emp_leave?.reason}</Td>
                    <Td>
                      {l?.emp_leave?.leave_status === "approved" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <AiOutlineCheck color="#08A758" />
                          {l?.emp_leave?.leave_status}
                        </p>
                      ) : l?.emp_leave?.leave_status === "pending" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <TbClockExclamation color="#F9A000" />
                          {l?.emp_leave?.leave_status}
                        </p>
                      ) : l?.emp_leave?.leave_status === "reject" ? (
                        <p className="flex items-center gap-1 capitalize">
                          <AiOutlineClose color="#FF3838" />
                          {l?.emp_leave?.leave_status}
                        </p>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>
                      {l?.emp_leave?.leave_status === "approved" ? (
                        <div className="flex gap-2">
                          <AiOutlineCheckCircle
                            color="#B0B0B0"
                            cursor="not-allowed"
                          />
                          <AiOutlineCloseCircle
                            color="#B0B0B0"
                            cursor="not-allowed"
                          />
                        </div>
                      ) : l?.emp_leave?.leave_status === "reject" ? (
                        <div className="flex gap-2">
                          <AiOutlineCheckCircle
                            onClick={() =>
                              openAcceptPayroll(
                                l?._id,
                                l?.emp_leave?.emp_leave_id
                              )
                            }
                            color="#08A758"
                            cursor="pointer"
                          />
                          <AiOutlineCloseCircle
                            onClick={() =>
                              openRejectPayroll(
                                l?._id,
                                l?.emp_leave?.emp_leave_id
                              )
                            }
                            color="#B0B0B0"
                            cursor="not-allowed"
                          />
                        </div>
                      ) : (
                        <div className="flex gap-2">
                          <AiOutlineCheckCircle
                            onClick={() =>
                              openAcceptPayroll(
                                l?._id,
                                l?.emp_leave?.emp_leave_id
                              )
                            }
                            color="#08A758"
                            cursor="pointer"
                          />
                          <AiOutlineCloseCircle
                            onClick={() =>
                              openRejectPayroll(
                                l?._id,
                                l?.emp_leave?.emp_leave_id
                              )
                            }
                            color="#DB2C2C"
                            cursor="pointer"
                          />
                        </div>
                      )}
                    </Td>
                    <Td>
                      <RiDeleteBin6Line
                        color="#D20E0E"
                        cursor="pointer"
                        onClick={() =>
                          deleteLeaves(l?._id, l?.emp_leave?.emp_leave_id)
                        }
                      />
                    </Td>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          totalDataCnt={leave?.length || 0}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      </TableWithPagination>
    </div>
  );
};

const LeaveCards = ({
  leave,
  formatDate,
  mode,
  organisation,
  deleteLeaves,
}) => {
  return (
    <div className="grid grid-cols-4 gap-[26px]">
      {leave?.reverse()?.map((l) => (
        <section
          className={`w-[261px] h-[327px] shadow-md rounded py-3 cardShadow ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center">
            <h2 className="text-primaryText">
              {l?.emp_leave?.leave_type} Request
            </h2>
            <Badge
              intent={
                l?.emp_leave?.leave_status === "approved"
                  ? "success"
                  : l?.emp_leave?.leave_status === "reject"
                  ? "danger"
                  : l?.emp_leave?.leave_status === "pending"
                  ? "warning"
                  : ""
              }
              size="small"
              roundness="pill"
            >
              <span className="capitalize">{l?.emp_leave?.leave_status}</span>
            </Badge>
          </div>
          <div className="flex justify-between mt-2 mb-[14px] px-4">
            <section className="flex items-center gap-2">
              {l?.emp_profile ? (
                <Avatar type="img" src={l?.emp_profile} size="md" />
              ) : (
                <Avatar size="md" type="text">
                  {l?.emp_name?.slice(0, 1)}
                </Avatar>
              )}

              <div className="">
                <h4 className="text-primaryText text-[14px]">{l?.emp_name}</h4>
                <h4 className="text-secondaryText text-[10px]">
                  {l?.emp_designation}
                </h4>
              </div>
            </section>
            <section>
              <h4 className="text-primaryText text-[10px]">
                {l?.emp_department}
              </h4>
            </section>
          </div>
          <div
            className={`py-[14px] text-center ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-[#2A2E3D]"
                : "bg-primaryLight"
            }`}
          >
            <h2 className="text-primaryBg font-semibold mb-1">
              {l?.emp_leave?.duration} Day
            </h2>
            <h2 className="text-primaryText text-[14px]">
              {formatDate(l?.emp_leave?.from_date)}
              {" - "}
              {formatDate(l?.emp_leave?.to_date)}
            </h2>
          </div>
          <div className="px-4 mt-2">
            <h3 className="text-secondaryText">Reason:</h3>
            <p className="h-[70px] text-[12px] text-primaryText font-medium">
              {l?.emp_leave?.reason}
            </p>
          </div>
          <div className="flex justify-center items-center gap-2">
            {l?.emp_leave?.leave_status === "pending" ? (
              <>
                <Button intent="secondary" mode={mode}>
                  Reject
                </Button>
                <Button intent="primary" mode={mode}>
                  Approve
                </Button>
              </>
            ) : l?.emp_leave?.leave_status === "reject" ? (
              <>
                <Button
                  intent="secondary"
                  mode={mode}
                  onClick={() =>
                    deleteLeaves(l?._id, l?.emp_leave?.emp_leave_id)
                  }
                >
                  Delete
                </Button>
              </>
            ) : l?.emp_leave?.leave_status === "approved" ? (
              <>
                <Button intent="secondary" mode={mode}>
                  Delete
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Leave;
