/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { LOCALHOST_BASE_URL , apiKey} from "../../utils/config";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useSnackbar } from "../../context/SnackbarContext";

const ViewReimbursement = () => {
  const { reimbursementId } = useParams();
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const [reimbursementDetails, setReimbursementDetails] = useState();
  console.log("reimbursementDetails", reimbursementDetails);

  // Helper function to handle errors and show a snackbar message
  const handleError = (error, errorMessage) => {
    if (error.response) {
      if (error.response.status === 404) {
        showSnackbar(`Resource not found. ${errorMessage}`, "red");
      } else {
        showSnackbar(`Something went wrong. ${errorMessage}`, "red");
      }
    } else {
      showSnackbar(`Something went wrong. ${errorMessage}`, "red");
    }
  };

  const getReimbursementDetails = async () => {
    try {
  
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/reimbursement/get-reimbursement?id=${reimbursementId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setReimbursementDetails(result?.data);
    } catch (error) {
      handleError(error, "while fetching reimbursement details.");
    }
  };

  useEffect(() => {
    getReimbursementDetails(reimbursementDetails);
  }, []);

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/user-reimbursement"
        className={`text-[32px] flex items-center gap-1 text-primaryText`}
      >
        <MdOutlineKeyboardBackspace /> Reimbursement
      </Link>

      <div className="flex flex-col justify-center items-center my-5">
        {console.log("Reimbursement",reimbursementDetails?.bill_upload)}
        <img className="object-contain" src={reimbursementDetails?.bill_upload} alt="" />
        {/* <img src={reimbursementDetails?.bill_upload} alt="" /> */}
      </div>
    </div>
  );
};

export default ViewReimbursement;
