/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";
import Button from "../Button";
import Modal from "../Modal";


const EmployeeDocument = ({ employeeInfo }) => {
  const { color, mode,organisation } = useContext(ContextThemeProvider);



  const [showModal, setShowModal] = useState(false);
  const [cardUrl, setCardUrl] = useState('');

  const openModal = (url) => {
    setCardUrl(url);
    setShowModal((prev) => !prev);
  };
  console.log("cardUrl", cardUrl);
  return (
    <>
      <div className="grid grid-cols-4 gap-[26px]">

        {/* Adhar Card */}
        {employeeInfo?.employee?.emp_PanCardURl && (
          <section
            className={`h-[330px] border shadow-md rounded py-3  ${organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-white"
              }`}   >
            <div className="px-4 flex justify-between items-center mb-4">
              <p className="text-[20px] text-primaryText">
                Adhar Card
              </p>
            </div>
            <div className="w-[270px] h-[170px] px-4 flex justify-center">
              <img
                src={employeeInfo?.employee?.emp_AadharCardURl} // Include the base URL before the image URL
                alt="Employee Aadhar Card" className="cardShadow"
              />
            </div>
            <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
              <Button intent="secondary" mode={mode} onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_AadharCardURl) {
                  window.location.href = employeeInfo.employee.emp_AadharCardURl;
                }
              }}>
                Download
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={() => openModal(employeeInfo?.employee?.emp_AadharCardURl)}
              >
                view
              </Button>
            </div>
          </section>
        )}
        {/* Pan Card */}
        {employeeInfo?.employee?.emp_PanCardURl && (
          <section
            className={`h-[330px] border shadow-md rounded py-3  ${organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-white"
              }`}   >
            <div className="px-4 flex justify-between items-center mb-4">
              <p className="text-[20px] text-primaryText">
                Pan Card
              </p>
            </div>
            <div className="w-[270px] h-[170px] px-4 flex justify-center">

              <img
                src={employeeInfo?.employee?.emp_PanCardURl} // Include the base URL before the image URL
                alt="Employee Aadhar Card" className="cardShadow"
              />

            </div>
            <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
              <Button intent="secondary" mode={mode} onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_PanCardURl) {
                  window.location.href = employeeInfo.employee.emp_PanCardURl;
                }
              }}>
                Download
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={() => openModal(employeeInfo?.employee?.emp_PanCardURl)}
              >
                view
              </Button>
            </div>
          </section>
        )}
        {/* Voter Card */}
        {employeeInfo?.employee?.emp_VoterCardURl && (
          <section
            className={`h-[330px] border shadow-md rounded py-3  ${organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-white"
              }`}   >
            <div className="px-4 flex justify-between items-center mb-4">
              <p className="text-[20px] text-primaryText">
                Voter Card
              </p>
            </div>

            <div className="w-[270px] h-[170px] px-4 flex justify-center">
              <img
                src={employeeInfo?.employee?.emp_VoterCardURl} // Include the base URL before the image URL
                alt="Employee Aadhar Card" className="cardShadow"
              />
            </div>
            <div className="w-[270px] h-[170px] mt-9 px-4 grid grid-cols-2 gap-2 ">
              <Button intent="secondary" mode={mode} onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_VoterCardURl) {
                  window.location.href = employeeInfo.employee.emp_VoterCardURl;
                }
              }}>
                Download
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={() => openModal(employeeInfo?.employee?.emp_VoterCardURl)}
              >
                view
              </Button>
            </div>
          </section>
        )}
        {/* Driving Licence */}
        {employeeInfo?.employee?.emp_DrivingLicenceURL && (
          <section
            className={`h-[330px] border shadow-md rounded py-3  ${organisation?.organisationTheme
?.name === "dark" ? "bg-primaryDark" : "bg-white"
              }`}   >
            <div className="px-4 flex justify-between items-center mb-4">
              <p className="text-[20px] text-primaryText">
                Driving Licence
              </p>
            </div>
            <div className="w-[270px] h-[170px] px-4 flex justify-center">
              <img
                src={employeeInfo?.employee?.emp_DrivingLicenceURL} // Include the base URL before the image URL
                alt="Employee Aadhar Card" className="cardShadow"
              />
            </div>
            <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
              <Button intent="secondary" mode={mode} onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_DrivingLicenceURL) {
                  window.location.href = employeeInfo.employee.emp_DrivingLicenceURL;
                }
              }}>
                Download
              </Button>
              <Button
                intent="primary"
                mode={mode}
                onClick={() => openModal(employeeInfo?.employee?.emp_DrivingLicenceURL)}
              >
                view
              </Button>
            </div>
          </section>
        )}
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="flex flex-col gap-3 items-center h-full overflow-auto">
          <p className="text-[20px] text-primaryText "> View Document</p>
          <div className="w-full px-4 flex justify-center">
            <img
              src={cardUrl} // Include the base URL before the image URL
              alt="Employee Aadhar Card"
            />
          </div>
        </div>
      </Modal>

    </>
  );
};



export default EmployeeDocument;
