/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LOCALHOST_BASE_URL , apiKey  ,token} from "../../utils/config";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { useSnackbar } from "../../context/SnackbarContext";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import jwt_decode from "jwt-decode";
import { BsUpload } from "react-icons/bs";

const UserDocument = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const [image, setImage] = useState(null);
  const decodedToken = jwt_decode(token);
  const { showSnackbar } = useSnackbar();

  const [showImageModal, setShowImageModal] = useState(false);
  const [cardUrl, setCardUrl] = useState("");

  const openShowImage = (url) => {
    setCardUrl(url);
    setShowImageModal((prev) => !prev);
  };

  const [employeeInfo, setEmployeeInfo] = useState();
  const getEmployeeInfo = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/get-one-employee?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setEmployeeInfo(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getEmployeeInfo();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [documentValue, setDocumentValue] = useState("");
  const openModal = (value) => {
    setDocumentValue(value);
    setShowModal((prev) => !prev);
  };

  //handleimagechange
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handlemageupload = async () => {
    try {
      if (image) {
        const formdata = new FormData();
        formdata.append("file", image);
        const response = await fetch(
          `${LOCALHOST_BASE_URL}/fileStorage/upload`,
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data?.viewURl);
          handleUpload(data?.viewURl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = async (url) => {
  
  

    try {
      setShowModal(!showModal);
      await axios
        .put(
          `${LOCALHOST_BASE_URL}/employee/update-OtherEmployee?id=${decodedToken?.id}`,
          {
            [documentValue]: url, // Use square brackets for dynamic key
          },
          {
            headers: {
              "api-key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Here is the Response from Upload", response.data);
          getEmployeeInfo();
        })
        .catch((error) => {
          console.log("Here is the Error from try-Catch", error);
          showSnackbar("Something went wrong 1. Please try again", "red");
        });
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  return (
    <div
      className={`pl-[36px] py-[29px] pr-[45px] h-[100vh] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-primaryLight"
      } `}
    >
      <h1 className={`text-[34px] text-primaryText mb-6`}> Documents</h1>

      <div className="grid grid-cols-4 gap-[26px]">
        {/* Adhar Card */}
        <section
          className={`h-[330px] border shadow-md rounded py-3  ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="text-[20px] text-primaryText">Adhar Card</p>
          </div>
          <div className="w-[270px] h-[170px] px-4 flex justify-center">
            {employeeInfo?.employee?.emp_AadharCardURl ? (
              <img
                src={employeeInfo?.employee?.emp_AadharCardURl} // Include the base URL before the image URL
                alt="Employee Aadhar Card"
                className="cardShadow"
                onClick={() =>
                  openShowImage(employeeInfo?.employee?.emp_AadharCardURl)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_AadharCardURl) {
                  window.location.href =
                    employeeInfo.employee.emp_AadharCardURl;
                }
              }}
            >
              Download
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => openModal("emp_AadharCardURl")}
            >
              Upload
            </Button>
          </div>
        </section>

        {/* Pan Card */}
        <section
          className={`h-[330px] border shadow-md rounded py-3  ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="text-[20px] text-primaryText">Pan Card</p>
          </div>
          <div className="w-[270px] h-[170px] px-4 flex justify-center">
            {employeeInfo?.employee?.emp_PanCardURl ? (
              <img
                src={employeeInfo?.employee?.emp_PanCardURl} // Include the base URL before the image URL
                alt="Employee Pan Card"
                className="cardShadow"
                onClick={() =>
                  openShowImage(employeeInfo?.employee?.emp_PanCardURl)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_PanCardURl) {
                  window.location.href = employeeInfo?.employee.emp_PanCardURl;
                }
              }}
            >
              Download
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => openModal("emp_PanCardURl")}
            >
              Upload
            </Button>
          </div>
        </section>

        {/* Voter Card */}
        <section
          className={`h-[330px] border shadow-md rounded py-3  ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="text-[20px] text-primaryText">Voter Card</p>
          </div>

          <div className="w-[270px] h-[170px] px-4 flex justify-center">
            {employeeInfo?.employee?.emp_VoterCardURl ? (
              <img
                src={employeeInfo?.employee?.emp_VoterCardURl} // Include the base URL before the image URL
                alt="Employee Aadhar Card"
                className="cardShadow"
                onClick={() =>
                  openShowImage(employeeInfo?.employee?.emp_VoterCardURl)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_VoterCardURl) {
                  window.location.href = employeeInfo.employee.emp_VoterCardURl;
                }
              }}
            >
              Download
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => openModal("emp_VoterCardURl")}
            >
              Upload
            </Button>
          </div>
        </section>

        {/* Driving Licence */}
        <section
          className={`h-[330px] border shadow-md rounded py-3  ${
            organisation?.organisationTheme?.name === "dark"
              ? "bg-primaryDark"
              : "bg-white"
          }`}
        >
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="text-[20px] text-primaryText">Driving Licence</p>
          </div>
          <div className="w-[270px] h-[170px] px-4 flex justify-center">
            {employeeInfo?.employee?.emp_DrivingLicenceURL ? (
              <img
                src={employeeInfo?.employee?.emp_DrivingLicenceURL} // Include the base URL before the image URL
                alt="Employee Driving Licence"
                className="cardShadow"
                onClick={() =>
                  openShowImage(employeeInfo?.employee?.emp_DrivingLicenceURL)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="w-[270px] mt-9 px-4 grid grid-cols-2 gap-2 ">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => {
                // Check if emp_AadharCardURl is defined before triggering the download
                if (employeeInfo?.employee?.emp_DrivingLicenceURL) {
                  window.location.href =
                    employeeInfo.employee.emp_DrivingLicenceURL;
                }
              }}
            >
              Download
            </Button>
            <Button
              intent="primary"
              mode={mode}
              onClick={() => openModal("emp_DrivingLicenceURL")}
            >
              Upload
            </Button>
          </div>
        </section>
      </div>

      <Modal showModal={showImageModal} setShowModal={setShowImageModal}>
        <div className="flex flex-col gap-3 items-center h-full overflow-auto">
          <p className="text-[20px] text-primaryText "> View Document</p>
          <div className="w-full px-4 flex justify-center">
            <img
              src={cardUrl} // Include the base URL before the image URL
              alt="Employee Aadhar Card"
            />
          </div>
        </div>
      </Modal>

      {/* upload modal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="w-[361px]">
          <h2 className="mb-3 text-center">
            {documentValue === "emp_AadharCardURl" && "Upload Aadhar Card"}
            {documentValue === "emp_PanCardURl" && "Upload Pan Card"}
            {documentValue === "emp_VoterCardURl" && "Upload Voter Card"}
            {documentValue === "emp_DrivingLicenceURL" &&
              "Upload Driving Licence Card"}
          </h2>
          <div class="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-[67px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="text-primaryBg">
                  <BsUpload />
                </span>
                <p className="mt-2 text-[12px] text-[#626D94] font-semibold">
                  Upload Logo
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                // onChange={(e) => {
                //   console.log("Here from Upload", e.target.files[0]);
                //   setImage(e.target.files[0]);
                // }}
                onChange={handleImageChange}
              />
            </label>
          </div>
          <div className="">
            {image !== null ? (
              <>
                <p className={`auto text-green-500 text-[14px]`}>
                  Logo Uploaded
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <section className="mt-8 flex justify-center items-center gap-4">
            <Button
              intent="secondary"
              mode={mode}
              onClick={() => setImage(null)}
            >
              Clear
            </Button>
            <Button intent="primary" mode={mode} onClick={handlemageupload}>
              Save
            </Button>
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default UserDocument;
