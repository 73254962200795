import React, { useState, useEffect } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { NavLink } from "react-router-dom";

const SidebarMenu = ({ data, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className="menu transition-all duration-75 ease-in hover:bg-primaryLight hover:text-primaryBg"
        onClick={toggleMenu}
      >
        <div className="flex items-center gap-4" >
          {/* <span>{data?.icon}</span> */}
          <img src={data?.icon} alt="" />
          <h4 className="">
            {isOpen && <span className="">{data.name}</span>}
          </h4>
        </div>
        {isOpen && (
          <span
            style={
              isMenuOpen
                ? {
                    rotate: -90,
                  }
                : { rotate: 0 }
            }
          >
            {isMenuOpen ? (
              <MdOutlineKeyboardArrowUp />
            ) : (
              <MdOutlineKeyboardArrowDown />
            )}
          </span>
        )}
      </div>

      <div className="transition-all duration-75 ease-in">
        {isMenuOpen && (
          <div className="menu_container">
            {data?.subRoutes.map((subRoute, i) => (
              <NavLink
                to={subRoute.link}
                className={({ isActive }) =>
                  isActive ? "active" : "text-primaryText"
                }
                // activeClassName="text-primaryText bg-primaryBg"
                // className="text-primaryText"
              >
                <section className="flex items-center gap-4 pl-[32px] py-[13px] text-[14px] hover:bg-primaryLight hover:text-primaryBg">
                  {/* <span>{subRoute?.icon}</span> */}
                  <img src={subRoute?.icon} alt="" />
                  <h4 className="text-[14px]">{subRoute.name}</h4>
                </section>
              </NavLink>
            ))}
          </div>
        )}{" "}
      </div>
    </>
  );
};

export default SidebarMenu;
