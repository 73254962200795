/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey} from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const Employee = () => {
  const { color, mode, organisation, desData } =
    useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const [employeeCards, setEmployees] = useState();
  const [designationSelect, setDesignationSelect] = useState();

  const getAemployeeCard = async () => {
  

    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/employee/getAllEmployeeAdmin`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.status === 200) {
        setEmployees(result?.data);
      } else {
        console.error(
          "Request was not successful. Status Code:",
          result.status
        );
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };

  useEffect(() => {
    getAemployeeCard();
  }, []);

  const filterData = employeeCards?.filter((blog) => {
    const designationData = designationSelect
      ? blog?.empDesignation === designationSelect
      : true;
    // const categoryData = categorySelect
    //   ? blog?.category === categorySelect
    //   : true;
    // return categoryData && autherData;
    return designationData;
  });

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <h1
        className={`text-[32px] ${
          organisation?.organisationTheme?.name === "dark"
            ? "text-primaryTextDark"
            : "text-primaryTextLight"
        }`}
      >
        Employees
      </h1>
      <div className="my-[51px] mb-[34px] flex gap-4 items-center">
        <select
          name="designation"
          id="designation"
          className={`py-2 text-[12px] w-[122px] rounded outline-none focus:border-primaryBg  ${
            organisation?.organisationTheme?.name === "dark"
              ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
              : "border border-primaryTextLight text-primaryTextLight"
          }  `}
          onChange={(e) => setDesignationSelect(e.target.value)}
          value={designationSelect ? designationSelect : ""}
        >
          <option value="designation" selected disabled>
            Designation
          </option>
          {desData?.map((emp) => (
            <option key={emp?.value} value={emp?.des_name}>
              {emp?.des_name}
            </option>
          ))}
        </select>
        <button
          className={`text-primaryBg border-b border-primaryBg`}
          onClick={() => {
            setDesignationSelect("");
          }}
        >
          Clear Filter
        </button>
      </div>

      {/* employee cards */}
      <div className="grid grid-cols-4 gap-5">
        {filterData?.map((card, index) => (
          <Link to={`/employee-details/${card?.empId}`}>
            <section
              className={`py-[22px] px-[43px] flex flex-col gap-y-[14px] cursor-pointer justify-center items-center rounded-lg border border-transparent shadow-sm hover:border hover:border-primaryBg hover:shadow-cardHover ${
                organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-[#F8F9FF]"
              } `}
            >
              {card?.employeeProfileUrl ? (
                <Avatar type="img" src={card?.employeeProfileUrl} size="xlg" />
              ) : (
                <Avatar size="xlg" type="text">
                  {card?.empName
                    .split(" ")
                    .map((word) => word[0])
                    .join("")}
                </Avatar>
              )}
              <h2 className="text-primaryText text-[20px]">{card?.empName}</h2>
              <h3 className="text-primaryText text-[14px]">
                {card?.employeeDepartment}
              </h3>
              <h4 className="text-primaryText text-[12px]">
                {card?.empDesignation}
              </h4>
            </section>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Employee;
