/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { ContextThemeProvider } from "../context/ThemeContext";
import { LOCALHOST_BASE_URL , apiKey , token  } from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";

const ViewOfferLetterTmp = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { offerLetterTmpId } = useParams();

  // get one agreement
  const [oneAgreement, setOneAgreement] = useState();
  console.log("oneAgreement", oneAgreement);

  // convert date
  function formatDate(inputDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const [offerLetterTmp, setOfferLetterTmp] = useState({
    letterBody: "",
    date: "",
    offerLetterHeading: "",
    employmentType: "",
    description: "",
  });


  // get one agreement
  const getOneAgreement = async () => {
    try {

      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/offerLetterTemp/get-offerLetterTemp?id=${offerLetterTmpId}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );

      if (result.data) {
        setOfferLetterTmp((prevData) => ({
          ...prevData,
          offerLetterHeading: result.data.offerLetterHeading,
          date: result.data.date,
          employmentType: result.data.employmentType,
          letterBody: result.data.letterBody,
          description: result.data.description,
        }));
        setOneAgreement(result.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          showSnackbar(`Wait for Some time and try again.`, "red");
        } else {
          showSnackbar(`Something went wrong. Please try again.`, "red");
        }
      } else {
        showSnackbar(`Something went wrong. Please try again.`, "red");
      }
    }
  };

  // add pages
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(0);
  console.log("activePage", activePage);

  useEffect(() => {
    if (offerLetterTmpId) {
      getOneAgreement();
    }
  }, [offerLetterTmpId]);

  console.log("line 158", offerLetterTmp);
  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <Link
        to="/offer-letter"
        className="flex items-center gap-2 text-[32px] text-primaryText mb-[40px] capitalize"
      >
        <MdOutlineKeyboardBackspace />
        {offerLetterTmp?.employmentType} Offer letter Layout
      </Link>
      <div className="flex gap-4 justify-center">
        <div className="w-[60%] ">
          <section className="w-[100%] h-auto bg-white shadow-sm">
            <div className="px-[66px] py-[26px]">
              <div>
                <h1 className="flex justify-between items-center mb-[70px] text-[#333]">
                  <span>Date : {formatDate(offerLetterTmp?.date)}</span>
                </h1>
                <h2 className="text-[#333]">
                  {offerLetterTmp.offerLetterHeading}
                </h2>
                <p className="w-[50px] h-[2px] my-[30px] bg-[#3D4EFE]"></p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: offerLetterTmp?.letterBody,
                  }}
                ></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewOfferLetterTmp;
