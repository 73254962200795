/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../components/table/Table";
import TablePagination from "../components/table/TablePagination";
import { ContextThemeProvider } from "../context/ThemeContext";
import axios from "axios";
import { LOCALHOST_BASE_URL , apiKey , token} from "../utils/config";
import { useSnackbar } from "../context/SnackbarContext";


const LeaveCustomisation = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const { showSnackbar } = useSnackbar();

  const [leavePageNumber, setLeavePageNumber] = useState(1);
  const [leaveRowsPerPage, setLeaveRowsPerPage] = useState(10);
  const [allLeaves, setAllLeaves] = useState([]);
  const [leaveform, setLeaveForm] = useState({});
  const [editCheck, setEditCheck] = useState(false);

  const getAllLeaves = async () => {
    const url = `${LOCALHOST_BASE_URL}/leaveType/get-all-leaveType`;
  
  
    try {
      const response = await axios.get(url, {
        headers: {
          'api-key': apiKey,
        },
      });
  
      if (response.status === 200) {
        console.log("Here is the Response", response.data);
        setAllLeaves(response.data);
      } else {
        console.error("Request was not successful. Status Code:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };
  

  useEffect(() => {
    getAllLeaves();
  }, []);

  const handleAddNewLeave = async () => {
    const url = `${LOCALHOST_BASE_URL}/leaveType/add-leaveType`;
  
    const headers = {
      'api-key': apiKey,
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    try {
      const response = await axios.post(url, leaveform, { headers });
  
      if (response.status === 200) {
        console.log("Here is the Response from Post API", response.data);
        openAddLeaveDetails();
        getAllLeaves();
      } else {
        console.error("Request was not successful. Status Code:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };
  

  // const handleOneLeave = async (e)=>{
  //   const id= e.currentTarget.id;
  //   console.log("Here is the Id", id);
  //   const url = `${LOCALHOST_BASE_URL}/leaveType/get-one-leaveType`;
  //   try {
  //     await axios
  //       .get(url, {params: { id }})
  //       .then((response) => {
  //         if (response) {
  //           console.log("Here is the Response", response.data);
  //           // setEditCheck(true);
  //           setLeaveForm({
  //             balanceBased: response.data.leaveType.balanceBased,
  //             leave_name: response.data.leaveType.leave_name,
  //             leave_type: response.data.leaveType.leave_type,
  //             total_annualLeave: response.data.leaveType.total_annualLeave,
  //           });
  //           openAddLeaveDetails();
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("Here is the Error from then-catch", error);
  //       });
  //   } catch (error) {
  //     console.log("Error is here", error);
  //   }
  // }

  const handleEditLeave = async (e) => {
    const id = leaveform._id;
    console.log("Here is the Id", id);
    const url = `${LOCALHOST_BASE_URL}/leaveType/update-leaveType`;
    const headers = {
      'api-key': apiKey,
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    try {
      const response = await axios.put(url, leaveform, { headers, params: { id } });
  
      if (response.status === 200) {
        console.log("Here is the Response", response.data);
        openAddLeaveDetails();
        getAllLeaves();
      } else {
        console.error("Request was not successful. Status Code:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };
  

  const handleDeleteLeave = async (e) => {
    const id = e.currentTarget.id;
    const url = `${LOCALHOST_BASE_URL}/leaveType/delete-leave`;
    const headers = {
      'api-key': apiKey,
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    try {
      const response = await axios.delete(url, { headers, params: { id } });
  
      if (response.status === 200) {
        console.log("Here is the Response from API", response.data);
        getAllLeaves();
      } else {
        console.error("Request was not successful. Status Code:", response.status);
        showSnackbar("Something went wrong. Please try again", "red");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Something went wrong. Please try again", "red");
    }
  };
  

  //   add leave modal
  const [addLeaveModal, setAddLeaveModal] = useState(false);
  const openAddLeaveDetails = () => {
    setAddLeaveModal((prev) => !prev);
  };

  return (
    <div
      className={`h-full pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center mb-[44px]">
        <h1 className={`text-[32px] text-primaryText`}>Leave Customisation</h1>
        <Button intent="primary" mode={mode} onClick={openAddLeaveDetails}>
          + Add Leave
        </Button>
      </section>
      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>
                  <span className="pl-2">Leave Name</span>
                </Th>
                <Th>Leave Type</Th>
                <Th>Total annual leave</Th>
                <Th>Balance based on</Th>
                <Th>Action</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {allLeaves
                ?.slice(
                  (leavePageNumber - 1) * leaveRowsPerPage,
                  leavePageNumber * leaveRowsPerPage
                )
                // ?.reverse()
                ?.map((leave, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>
                        <span className="pl-2">{leave.leave_name}</span>
                      </Td>
                      <Td>{leave.leave_type}</Td>
                      <Td>{leave.total_annualLeave}</Td>
                      <Td>{leave.balanceBased}</Td>
                      <Td>
                        <div className="flex gap-3 items-center">
                          <h2
                            id={leave._id}
                            onClick={() => {
                              setAddLeaveModal(true);
                              setLeaveForm({
                                ...leaveform,
                                ...leave,
                                edit: true,
                              });
                            }}
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#D1F4DF] hover:text-[#08A758] cursor-pointer transition-all ease-in-out"
                          >
                            <FiEdit2 />
                          </h2>
                          <h2
                            id={leave._id}
                            onClick={(e) => handleDeleteLeave(e)}
                            className="w-[25px] h-[25px] flex justify-center items-center rounded-full hover:bg-[#FEE3EB] hover:text-[#FB1C24] cursor-pointer transition-all ease-in-out"
                          >
                            <RiDeleteBin6Line />
                          </h2>
                        </div>
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={allLeaves?.length || 0}
            setRowsPerPage={setLeaveRowsPerPage}
            rowsPerPage={leaveRowsPerPage}
            setPageNumber={setLeavePageNumber}
            pageNumber={leavePageNumber}
          />
        </TableWithPagination>
      </div>

      <Modal showModal={addLeaveModal} setShowModal={setAddLeaveModal}>
        <div className="w-[384px]">
          <h1 className="text-primaryTextLight text-[20px] font-medium text-center">
            Add New Leave
          </h1>
          <div className="mt-[24px] mb-[36px]">
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="leaveName">
                Leave Name <span className="">*</span>
              </label>
              <input
                type="text"
                placeholder={
                  editCheck ? `${leaveform?.leave_name}` : "Add Leave Name"
                }
                value={leaveform?.leave_name}
                onChange={(e) => {
                  setLeaveForm({
                    ...leaveform,
                    leave_name: e.target.value,
                  });
                }}
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="leaveType">
                Leave Type <span className="">*</span>
              </label>
              <select
                name="type"
                id="type"
                value={leaveform?.leave_type}
                onChange={(e) => {
                  setLeaveForm({
                    ...leaveform,
                    leave_type: e.target.value,
                  });
                }}
                className={`py-1 h-[36px] text-[12px] rounded outline-none focus:border-primaryBg  ${
                  organisation?.organisationTheme?.name === "dark"
                    ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                    : "border border-primaryTextLight text-primaryTextLight"
                }  `}
              >
                <option value="type" selected disabled>
                  Select Type
                </option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>{" "}
              </select>
            </section>
            <section className="flex flex-col gap-[6px] mb-5">
              <label htmlFor="totalAnnLeaves">
                Total Annual Leave <span className="">*</span>
              </label>
              <input
                type="text"
                value={leaveform?.total_annualLeave}
                onChange={(e) => {
                  setLeaveForm({
                    ...leaveform,
                    total_annualLeave: e.target.value,
                  });
                }}
                required
                className={`px-2 text-black font-[500] py-1 text-[14px] rounded outline-none border border-black focus:border-blue-500 w-full`}
              />
            </section>
            <section className="">
              <label htmlFor="balanceBasedOn">Balance based on</label>
              <section className="grid grid-cols-2 items-center gap-5 mt-1">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="fixed"
                    name="fav_language"
                    value="Fixed entitlement"
                    onClick={(e) => {
                      setLeaveForm({
                        ...leaveform,
                        balanceBased: e.target.value,
                      });
                    }}
                  />
                  <label for="fixed">Fixed entitlement</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="leaveGrant"
                    name="fav_language"
                    value="Leave Grant"
                    onClick={(e) => {
                      setLeaveForm({
                        ...leaveform,
                        balanceBased: e.target.value,
                      });
                    }}
                  />
                  <label for="leaveGrant">Leave Grant</label>
                </div>
              </section>
            </section>
          </div>
          <div className="text-center mt-8">
            <Button
              intent="primary"
              mode={mode}
              onClick={leaveform.edit ? handleEditLeave : handleAddNewLeave}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LeaveCustomisation;
