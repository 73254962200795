/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LOCALHOST_BASE_URL,apiKey ,token } from "../../utils/config";
import { ContextThemeProvider } from "../../context/ThemeContext";
import { GrGroup } from "react-icons/gr";
import { BsCalendar3 } from "react-icons/bs";
import { PiClockCountdownBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Badge from "../../components/Badge";
import { AiOutlineCheck, AiOutlineClose, AiOutlineClockCircle } from "react-icons/ai";
import { TbClockExclamation } from "react-icons/tb";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import UserNav from "./UserNav";
import { useSnackbar } from "../../context/SnackbarContext";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableWithPagination,
  Td,
  Th,
} from "../../components/table/Table";
import TablePagination from "../../components/table/TablePagination";
import jwt_decode from "jwt-decode";
import { FiClock } from "react-icons/fi";

const OvertimeUser = () => {
  const { color, mode, organisation } = useContext(ContextThemeProvider);
  const decodedToken = jwt_decode(token);
  const { showSnackbar } = useSnackbar();
  const [overtime, setOvertime] = useState();


  const getovertimes = async () => {
    console.log("Inside the getovertimes");
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/overTime/get-one-Employee?id=${decodedToken?.id}`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      console.log("result: ", result)
      setOvertime(result?.data?.result);
    } catch (error) {
      console.log("Here is the Error from Try-Catch", error);
      showSnackbar(`Something went wrong. Please try again.`, "red");
    }
  };

  useEffect(() => {
    getovertimes();
  }, []);



  const [deptPageNumber, setDeptPageNumber] = useState(1);
  const [deptRowsPerPage, setDeptRowsPerPage] = useState(10);

  const lastEntry = overtime?.[overtime?.length - 1];

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return ''; // or return a placeholder if you prefer
    }
    const time = new Date(timeString);
    return time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
  };


  const getDayOfWeek = (dateString) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  const headerCards = [
    {
      cardIcon: <BsCalendar3 size={25} color="var(--primaryBg)" />,
      cardHeader: "Overtime Date",
      cardBody: formatDate(lastEntry?.date),
    },
    {
      cardIcon: <AiOutlineClockCircle size={25} color="var(--primaryBg)" />,
      cardHeader: "Start overtime",
      cardBody: formatTime(lastEntry?.punch_in),
    },
    {
      cardIcon: <AiOutlineClockCircle size={25} color="var(--primaryBg)" />,
      cardHeader: "End Overtime",
      cardBody: formatTime(lastEntry?.punch_out ? lastEntry?.punch_out:"-"),
    },
    {
      cardIcon: <PiClockCountdownBold size={25} color="var(--primaryBg)" />,
      cardHeader: "Overtime Duration",
      cardBody: lastEntry?.production_time ? lastEntry?.production_time:"",
    },
  ];

  console.log("overtime", overtime);
  console.log("lastEntry",lastEntry?._id);

  const [elapsedTime, setElapsedTime] = useState('00:00:00');
  useEffect(() => {
    if (lastEntry?.punch_in) {
      const punchInTime = new Date(lastEntry.punch_in);
      const currentTime = new Date();

      const timeDifference = currentTime - punchInTime;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setElapsedTime(`${hours} : ${minutes} : ${seconds} hrs`);
    }
  }, [lastEntry?.punch_in]);

    // punch in
    const handlePunchIn = () => {
 
  
      const requestBody = {
        emp_id: decodedToken?.id,
      };
  
      axios
        .post(`${LOCALHOST_BASE_URL}/overtime/punch-in`, requestBody, {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response data:", response?.data);
          console.log("Punch In Successful");
          getovertimes();
          showSnackbar("Punch In Successful", "green");
        })
        .catch((error) => {
          console.log("error", error);
          showSnackbar("Punch In Unsuccessful", "red");
        });
    };
  
    // punch out
    const handlePunchOut = () => {
 
  
      // Assuming you need to send emp_id in the request body
      const requestBody = {
        // date: new Date().toISOString().split('T')[0], // Today's date in 'YYYY-MM-DD' format
        emp_id: decodedToken?.id,
        overtimeId: lastEntry?._id
      };
  
      console.log("requestBody", requestBody);
  
      axios
        .put(`${LOCALHOST_BASE_URL}/overtime/punchOut`, requestBody, {
          headers: {
            "api-key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response data:", response?.data);
          console.log("Punch Out Successful");
          getovertimes();
          showSnackbar("Punch Out Successful", "green");
        })
        .catch((error) => {
          console.log("error", error);
          showSnackbar("Punch Out Unsuccessful", "red");
        });
    };


  return (
    <div
    className={`pl-[36px] py-[29px] pr-[45px] ${
      organisation?.organisationTheme?.name === "dark"
        ? "bg-secondaryDark"
        : "bg-white"
    } `}
    >
      <section className={`flex justify-between items-center`}>
        <h1 className={`text-[32px]`}>
          {" "}
          <span
            className={`font-[500] ${organisation?.organisationTheme?.name === "dark"
                ? "text-primaryTextDark"
                : "text-primaryTextLight"
              }`}
          >
            Overtime
          </span>
        </h1>
        <div className="flex items-center gap-4">
        {lastEntry?.punch_out ? (
        // If punch_out exists, render Check In button
        <Button intent="secondary" mode={mode} onClick={handlePunchIn}>
          <section className="flex items-center gap-3">
            <FiClock size={20} />
            <div className="text-left">
              <h4 className="text-[12px] font-semibold">Check In</h4>
              <span>{lastEntry?.production_time} hrs</span>
            </div>
          </section>
        </Button>
      ) : (
        // If punch_out doesn't exist, render Check Out button
        <Button intent="primary" mode={mode} onClick={handlePunchOut}>
          <section className="flex items-center gap-3">
            <FiClock size={20} />
            <div className="text-left">
              <h4 className="text-[12px] font-semibold">Check Out</h4>
              <span>{elapsedTime} hrs</span>
            </div>
          </section>
        </Button>
      )}
        </div>
      </section>

      <div className={`py-[34px] grid grid-cols-4 gap-[45px] `}>
        {headerCards?.map((card) => (
          <section
            className={`py-[20px] px-4 rounded-lg flex gap-5  ${organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-primaryLight"
              }`}
          >
            <span
              className={`${organisation?.organisationTheme?.name === "dark"
                  ? "bg-primaryDark"
                  : "bg-white"
                } w-[56px] h-[56px] flex justify-center items-center rounded-full`}
            >
              {card?.cardIcon}
            </span>
            <div>
              <span className={`text-[14px] text-[#A3AED0]`}>
                {card?.cardHeader}
              </span>
              <h1
                className={`mt-2 text-[24px] ${organisation?.organisationTheme?.name === "dark"
                    ? "text-primaryTextDark"
                    : "text-[#2B3674]"
                  } `}
              >
                {card?.cardBody}
              </h1>
            </div>
          </section>
        ))}
      </div>

      <div className="text-primaryText">
        <TableWithPagination>
          <Table>
            <TableHead>
              <TableRow>
                <Th>Date</Th>
                <Th>Day</Th>
                <Th>Start Overtime</Th>
                <Th>End Overtime</Th>
                <Th>Overtime Duration</Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {overtime
                ?.slice(
                  (deptPageNumber - 1) * deptRowsPerPage,
                  deptPageNumber * deptRowsPerPage
                )
                // ?.reverse()
                ?.map((dept, i) => {
                  return (
                    <TableRow key={i}>
                      <Td>{dept?.date}</Td>
                      <Td>{getDayOfWeek(dept?.date)} </Td>
                      <Td>
                        {formatTime(dept?.punch_in)}
                      </Td>
                      <Td>
                        {formatTime(dept?.punch_out ? dept.punch_out : "")}
                      </Td>
                      <Td>
                        {dept?.production_time}
                      </Td>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalDataCnt={overtime?.length || 0}
            setRowsPerPage={setDeptRowsPerPage}
            rowsPerPage={deptRowsPerPage}
            setPageNumber={setDeptPageNumber}
            pageNumber={deptPageNumber}
          />
        </TableWithPagination>
      </div>


    </div>
  )
}

export default OvertimeUser