/* eslint-disable no-unused-vars */
import { useContext, useState } from "react";
import { ContextThemeProvider } from "../../context/ThemeContext";
import Button from "../../components/Button";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "axios";
import { LOCALHOST_BASE_URL, apiKey } from "../../utils/config";
import { useEffect } from "react";

const UserPayslip = () => {
  const { color, mode, organisation, deptData } =
    useContext(ContextThemeProvider);

  const [showAmt, setShowAmt] = useState(false);
  const handleShowAmt = () => {
    setShowAmt((prev) => !prev);
  };

  // get - localhost:7065/api/v1/peoples/payslip/get-employeePayslip?empId=6500414ba8cd139df986127a

  // get payslip
  const [empPayslip, setEmpPayslip] = useState();
  // console.log("empPayslip", empPayslip);

  const getEmpPayslips = async () => {
    try {
      const result = await axios.get(
        `${LOCALHOST_BASE_URL}/payslip/get-employeePayslip?empId=6500414ba8cd139df986127a`,
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setEmpPayslip(result?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getEmpPayslips();
  }, []);

  return (
    <div
      className={`h-[100vh] pl-[36px] py-[29px] pr-[45px] ${
        organisation?.organisationTheme?.name === "dark"
          ? "bg-secondaryDark"
          : "bg-white"
      } `}
    >
      <section className="flex justify-between items-center">
        <h1 className={`text-[32px] text-primaryText`}>Payslips</h1>
        <Button intent="primary" mode={mode} onClick={handleShowAmt}>
          {showAmt ? "Hide" : "Show"} Amount
        </Button>
      </section>

      <div className="my-[32px] flex justify-between gap-4 items-center">
        <section className="flex gap-4 items-center">
          <select
            name="year"
            id="year"
            className={`py-2 px-2 text-[12px] rounded outline-none focus:border-primaryBg w-[92px] ${
              organisation?.organisationTheme?.name === "dark"
                ? "border border-primaryTextDark text-primaryTextDark bg-transparent"
                : "border border-primaryTextLight text-primaryTextLight"
            }  `}
            // onChange={(e) => setStatusSelect(e.target.value)}
            // value={statusSelect ? statusSelect:""}
          >
            <option value="year" selected disabled>
              Select Year
            </option>
          </select>
          <button className={`text-primaryBg border-b border-primaryBg`}>
            Clear Filter
          </button>
        </section>
      </div>

      <div className="grid grid-cols-4 gap-[26px]">
        {empPayslip?.getpaySlip?.map((payslip) => (
          <section
            className={`w-[238px] h-[250px] shadow-md rounded-lg p-6 cardShadow text-primaryText ${
              organisation?.organisationTheme?.name === "dark"
                ? "bg-primaryDark"
                : "bg-white"
            }`}
          >
            <div className="text-center">
              <h2 className="text-[20px] font-semibold">
                {payslip?.paySlip_id?.month}
              </h2>
              <h2 className="text-primaryBg text-[30px] font-semibold py-4">
                {showAmt ? (
                  <span>₹ {payslip?.paySlip_id?.gross_Salary}</span>
                ) : (
                  " ₹ ---------"
                )}
              </h2>
              <p className="mb-4 text-[#8F9BBA] text-[14px]">
                Salary Slip for the month of {payslip?.paySlip_id?.month}
              </p>
              <Link
                to={`/view-user-payslip/${payslip?._id}`}
                className="py-[6px] px-2 block w-full bg-primaryBg text-white rounded-2xl text-[14px]"
              >
                View Payslip
              </Link>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default UserPayslip;
