/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import computerLogin from "../assets/computerLogin-rafiki.svg";
import logo from "../assets/logo.svg";
import FormsPana from "../assets/Forms-pana.svg";
import ForgotPasswordImg from "../assets/Forgot-password-pana.svg";
import ConfirmPassword from "../assets/ConfirmPassword.svg";
import PasswordRecoverySuccessfulImg from "../assets/PasswordRecoverySuccessful.svg";
import login from "../assets/login.svg";
import { BsUpload } from "react-icons/bs";
import { useSnackbar } from "../context/SnackbarContext";

import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { ContextThemeProvider } from "../context/ContextData";
import { LOCALHOST_BASE_URL } from "../utils/config";

const PasswordRecoverySuccessful = () => {
    const navigate = useNavigate();


    return (
            <>
            <header
      className={`pl-[26px] pr-[50px]  mt-[25px] ml-7`}
    >
      <div className="flex justify-between items-center">
        <section className="flex items-center gap-4 py-[20px]">
          <img src={logo} alt="logo" />
          <h1
            className={``}
          >
            People’s Console
          </h1>
        </section>
      </div>
    </header>
             <div className="grid grid-cols-2 px-[50px] py-[50px]">
              <div className="w-[454px] p-[30px] signupShadow rounded-lg">
                <h1 className="font-[500] text-[32px]">Password Recovery Successful</h1>
                <p className="text-[12px] text-[#999] mb-[24px]">
                  Return to the login screen to enter the application
                </p>
                <button
                  type="submit"
                  className="my-[24px] bg-[#5860F8] px-4 py-2 w-full font-[500] text-[#fff] rounded hover:bg-[#5860F8]"
                  onClick={() => navigate(`/`)}
                >
                  Return to login
                </button>
              </div>
              <div className="h-[300px]">
                <img src={PasswordRecoverySuccessfulImg} alt="" />
              </div>
              </div>
          </>
          );
};

export default PasswordRecoverySuccessful;
